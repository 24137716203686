import { Form, Row, Col, Container } from "react-bootstrap";
import {
  updateDataToFields,
  LookupText,
  displayInDateFormat,
} from "../../../GlobalComponents/ValidatorAndSubmitForm";
export default function GuarantorInfo({
  setpatientTabMode,
  patientObj,
  lookUpData,
  setinsuranceBlockList,
  editMode,
  setEditMode,
  patientDataArray,
}) {
  return (
    <Container className="label-style parentcontainer">
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Relation</h5>
          <input
            type="text"
            name="relation"
            id="Guarantor.relation"
            data-type="lookup"
            className="input-style form-control person"
            aria-label="Relation"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.relationData,
                    patientDataArray["guarantors.relation"]
                  )
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Last Name</h5>
          <input
            type="text"
            name="lastName"
            id="Guarantor.lastName"
            data-type="text"
            className="input-style form-control person"
            aria-label="Last Name"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.lastName"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">First Name</h5>
          <input
            type="text"
            className="input-style form-control person"
            disabled
            name="firstName"
            id="Guarantor.firstName"
            data-type="text"
            aria-label="First Name"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.firstName"]
                : ""
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Date of Birth</h5>
          <input
            type="text"
            className="input-style form-control person"
            name="dob"
            aria-label="Date of Birth"
            id="Guarantor.dob"
            data-type="date"
            value={
              patientDataArray.length != 0
                ? displayInDateFormat(
                    patientDataArray["guarantors.persons.dob"]
                  )
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender</h5>
          <input
            type="text"
            className="input-style form-control person"
            name="sex"
            id="Guarantor.sex"
            data-type="lookup"
            aria-label="Sex"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.sexData,
                    patientDataArray["guarantors.persons.sex"]
                  )
                : ""
            }
            disabled
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col></Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Street</h5>
          <input
            type="text"
            aria-label="Street"
            className="input-style form-control address"
            disabled
            name="street1"
            id="Guarantor.street1"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.addresses.street1"]
                : ""
            }
            data-type="text"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">ZIP Code</h5>
          <input
            aria-label="Zip"
            type="text"
            className="input-style form-control address validate_zip"
            disabled
            name="zip"
            id="Guarantor.zip"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.addresses.zip"]
                : ""
            }
            data-type="text"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">City</h5>
          <input
            aria-label="City"
            type="text"
            className="input-style form-control address"
            disabled
            name="city"
            id="Guarantor.city"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.addresses.city"]
                : ""
            }
            data-type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">State/Province/Region</h5>
          <input
            aria-label="State"
            type="text"
            className="input-style form-control address"
            disabled
            name="state"
            id="Guarantor.state"
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.addresses.state"]
                : ""
            }
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Primary Phone #</h5>
          <input
            aria-label="Primary phone"
            type="text"
            className="input-style form-control person"
            disabled
            name="phone"
            id="Guarantor.phone"
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray["guarantors.persons.phone"]
                : ""
            }
          />
        </Col>
      </Row>
    </Container>
  );
}
