import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import moment from "moment/moment";
import { ApiPostCalls, ApiGetCalls } from "../../services/Api";
import { useParams } from "react-router-dom";
import { Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsPrinter, BsPencil } from "react-icons/bs";
import DropdownTreeSelect from "react-dropdown-tree-select";
import MaskedInput from "react-text-mask";
import {
  getAllPhysicalFacilitiesFromTree,
  getChildObjStructure,
  getTreeOptionsSelected,
  getAllPhysicalFromTree,
} from "../OrderPage/Components/SearchFacilityModel";

import { LoginInfoContext, LoginUserLocationContext } from "../../Context";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import ReactPaginate from "react-paginate";
import CustomLoader from "../../GlobalComponents/Loader";
import { allInputField, apiUrl } from "../../config/config";
import { snackBar } from "../../services/Alert";
import DatePickerComponent from "../../GlobalComponents/DatePicker";

function PhlebotomyOrders(props) {
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  var curr = new Date();
  const currentDate = new Date();
  const next2DaysDate = new Date(currentDate);
  const [dateRange, setDateRange] = useState([
    currentDate.setDate(currentDate.getDate() - 7),
    (curr),
  ]);
  const [startDate, endDate] = dateRange;
  const [searchFilter, setSearchFilter] = useState({});
  const [
    updateCollectionInformationModalState,
    setUpdateCollectionInformationModalState,
  ] = useState(false);
  const [selectedFacilityArray, setSelectedFacilityArray] = useState([]);
  const [selectedFacilityIdsArray, setSelectedFacilityIdsArray] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [pagenationState, setPagenationState] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allFacilities, setAllFacilities] = useState([]);
  const [allFacilitiesIds, setAllFacilitiesIds] = useState([]);
  const [orderNumberState, setOrderNumberState] = useState([]);
  const [checkboxList, setCheckboxList] = useState([]);
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);
  const [bulkPrinted, setBulkPrinted] = useState(false);
  const [collectionInforamtionState, setCollectionInforamtionState] = useState({
    id: null,
    collectedBy: null,
    collectionDate: null,
  });
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  const [OrderStatusDetail, setOrderStatusDetail] = useState([]);
  const GetOrderStatusDetail = async () => {
    const response = await ApiGetCalls(
      "GetQuickLookupsByName/OrderStatusDetail"
    );
    if (response.length > 0) {
      setOrderStatusDetail(response);
    }
  };
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Reports";
    GetOrderStatusDetail();
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("#/");
    } else {
      setSearchFilter({
        startDate: moment(
          new Date(currentDate.setDate(currentDate.getDate() - 7))
        ).format("yyyy-MM-DD"),
        endDate: moment(
          new Date(curr)
        ).format("yyyy-MM-DD"),
        dateType: "Order",
        facilities: [],
        facilitiesIdsArray: [],
        patientFirstName: null,
        patientLastName: null,
        requisitionNumber: null,
        accessionNumber: null,
        facilityId: JSON.parse(localStorage.getItem("selectedLocation")),
        collector: null,
        status: null,
        dob: null,
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
      });
      setPagenationState(Math.random());
    }
  }, []);
  const [orderList, setOrderList] = useState([]);
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({
      ...values,
      [name]: value == "" ? null : value,
    }));
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilterChange("pageNumber", 1);
      setPagenationState(Math.random());
      setCurrentPage(0);
    }
  };
  useEffect(() => {
    if (selectedFacilityArray.length == 0) {
      var facilityIds = [];
      var nonSelectedfacilityCodes = [];
      var physicalFacilities = getAllPhysicalFacilitiesFromTree(
        JSON.parse(localStorage.getItem("locations")),
        []
      );
      // physicalFacilities.map((val) => {
      //   facilityIds.push(val.id);
      // });
      physicalFacilities.map((val) => {});
      physicalFacilities.map((val) => {
        if (val.id == JSON.parse(localStorage.getItem("selectedLocation"))) {
          nonSelectedfacilityCodes.push(val.code);
          facilityIds.push(val.id);
        }
      });
      // physicalFacilities.map((val) => {
      //   nonSelectedfacilityCodes.push(val.code);
      // });
      setAllFacilities(nonSelectedfacilityCodes);
      setAllFacilitiesIds(facilityIds);
      handleSearchFilterChange("facilities", nonSelectedfacilityCodes);
      //handleTestAnalyteJsonFilter("facilities", nonSelectedfacilityCodes);
      handleSearchFilterChange("facilitiesIdsArray", facilityIds);
      //handleTestAnalyteJsonFilter("facilitiesIdsArray", facilityIds);
      handleSearchFilterChange("providers", []);
      //handleTestAnalyteJsonFilter("providers", []);
      //GetMultiFacilityProviders(facilityIds);
      setSelectedFacilityIdsArray(facilityIds);
      setSelectedFacilityArray(nonSelectedfacilityCodes);
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "facilityCode",
          nonSelectedfacilityCodes
        )
      );
    } else {
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "facilityCode",
          selectedFacilityArray
        )
      );
    }
  }, [selectedFacilityArray]);
  useEffect(() => {
    //setProvidersData([]);
    // if (selectedFacilityIdsArray.length != 0)
    //   GetMultiFacilityProviders(selectedFacilityIdsArray);
  }, [selectedFacilityIdsArray]);

  let { id } = useParams();
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  async function getResults() {
    try {
      var body = JSON.stringify(searchFilter);
      var response = await ApiPostCalls(body, "GetPhlebxOrders");
      if (response.data.length > 0) {
        setTotalRecords(
          Math.ceil(response.data[0].totalRecords / totalRecordPerPage)
        );
        setOrderList(response.data);
      } else {
        setOrderList([]);
        setTotalRecords(0);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});

  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });

  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getResults();
    }
  }, [pagenationState]);
  const [showLoader, setLoader] = useState(false);
  const [printing, setPrintng] = useState(false);
  const [labelCopies, setLabelCopies] = useState(1);
  const [pdflabel, setPdflabel] = useState("");
  const [lablesCountInPdf, setLablesCountInPdf] = useState(null);
  const [pdfBlob, setPdfBlob] = useState("");
  const [orderPdfDetail, setOrderPdfDetail] = useState("");
  async function printOrderReport(orderNumber) {
    if (printing) {
      return;
    }
    setPrintng(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      // body: JSON.stringify({
      //   orderNumber: "A000071",
      // }),
    };
    const response = await fetch(
      apiUrl + "GenerateOrderLabel/" + orderNumber[0],
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            printOrderReport(orderNumber);
            setLabelCopies(1);
          }
          return false;
        }
      })
      .then(async (blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          setPdflabel(base64data);
        };
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: orderNumber,
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };
        const response = await fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail("Requisition Report");
              var a = document.createElement("a");
              a.setAttribute("data-bs-toggle", "modal");
              a.setAttribute(
                "data-bs-target",
                "#PrintPreviewDialogInOrderHistory"
              );
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setPrintng(false);
              setLoader(false);
            };
          });
      });
  }
  useEffect(() => {
    if (lablesCountInPdf != null) {
      setPrintng(true);
      setLoader(true);
      async function printOrderReportOnLablesCount(orderNumber) {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: orderNumber,
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };

        var response = fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then(async (response) => {
            if (response.status.toString() == "200") {
              return response.blob();
            } else {
              if (response.status.toString() == "401") {
                const accessToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                const refreshPayload = JSON.stringify({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                });

                var refreshTokenResponse = await ApiPostCalls(
                  refreshPayload,
                  "refresh-token"
                );
                localStorage.setItem(
                  "token",
                  refreshTokenResponse["accessToken"]
                );
                localStorage.setItem(
                  "refreshToken",
                  refreshTokenResponse["refreshToken"]
                );
                printOrderReportOnLablesCount(orderNumber);
              }
              return false;
            }
          })
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail("Requisition Report");
              setPrintng(false);
              setLoader(false);
            };
          });
      }
      printOrderReportOnLablesCount(orderNumberState);
    }
  }, [lablesCountInPdf]);
  return (
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight-orderPage">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Phlebotomy Orders
            </h3>
            <Row>
              <Col md={{ span: 6 }} lg={{ span: 3 }}>
                <h5 className="label-style">Date Range</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM-dd-yyyy"
                  onChange={(update) => {
                    handleSearchFilterChange(
                      "startDate",
                      moment(new Date(update[0])).format("yyyy-MM-DD")
                    );
                    if (update[1] != null) {
                      handleSearchFilterChange(
                        "endDate",
                        moment(new Date(update[1])).format("yyyy-MM-DD")
                      );
                    }
                    setDateRange(update);
                  }}
                  //disabled={showDateRange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                />
              </Col>
              <Col md={{ span: 6 }} lg={{ span: 3 }}>
                <h5 className="label-style">Date Type</h5>
                <Form.Select
                  className="form-control"
                  name="dateType"
                  value={
                    searchFilter["dateType"] == null
                      ? ""
                      : searchFilter["dateType"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                >
                  <option value="Order">Order Date</option>
                  <option value="Collection">Collection Date</option>
                  {/*
                  remove RequestedDateTime filed from Orchard 
                  <option value="Order">Order Date</option> 
                  */}
                </Form.Select>
              </Col>
              <Col className="d-flex flex-row align-items-end align-content-end"></Col>
            </Row>
            <Container className="resultAdvancesearch">
              <Row className="mt-3">
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Patient Last Name</h5>
                  <input
                    name="patientLastName"
                    value={
                      searchFilter["patientLastName"] == null
                        ? ""
                        : searchFilter["patientLastName"]
                    }
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Patient First Name</h5>
                  <input
                    name="patientFirstName"
                    value={
                      searchFilter["patientFirstName"] == null
                        ? ""
                        : searchFilter["patientFirstName"]
                    }
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">DOB</h5>
                  <DatePicker
                    preventOpenOnFocus={true}
                    name="dob"
                    ariaLabelledBy="dob"
                    className="input-style form-control"
                    selected={
                      searchFilter["dob"] != undefined &&
                      searchFilter["dob"] != null
                        ? new Date(searchFilter["dob"])
                        : ""
                    }
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    onChange={(date) => {
                      if (date != null) {
                        handleSearchFilterChange(
                          "dob",
                          moment(date).format("yyyy-MM-DD")
                        );
                      } else {
                        handleSearchFilterChange(date, "advancedSearchDob");
                      }
                    }}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    autoComplete="off"
                    customInput={
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder={"MM/dd/yyyy"}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    name="requisitionNumber"
                    value={
                      searchFilter["requisitionNumber"] == null
                        ? ""
                        : searchFilter["requisitionNumber"]
                    }
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="input-style form-control mb-3"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                {/* <Col md={{ span: 4 }}>
                  <h5 className="label-style">Accession #</h5>
                  <input
                    name="accessionNumber"
                    value={
                      searchFilter["accessionNumber"] == null
                        ? ""
                        : searchFilter["accessionNumber"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="input-style form-control mb-3"
                  />
                </Col> */}
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Collector</h5>
                  <input
                    name="collector"
                    value={
                      searchFilter["collector"] == null
                        ? ""
                        : searchFilter["collector"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Status</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="status"
                    value={
                      searchFilter["status"] == null
                        ? ""
                        : searchFilter["status"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                  >
                    <option value={""}></option>
                    {OrderStatusDetail?.map((valueStatus, indexStatus) => (
                      <option value={valueStatus.id}>
                        {valueStatus.options}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 6 }} className="resultFacilityTree">
                  <h5 className="label-style">Facility</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    data={locationsDataJson}
                    showPartiallySelected={true}
                    onChange={(e) => {
                      // setSelectedProvidersArray([]);
                      // setProvidersData([]);
                      // setProvidersData([]);
                      if (e.facilityCode != null) {
                        var test = selectedFacilityArray;
                        var idsArray = selectedFacilityIdsArray;
                        if (selectedFacilityArray.length == 0) {
                        }

                        if (test.indexOf(e.facilityCode) == -1) {
                          test.push(e.facilityCode);
                          handleSearchFilterChange("facilities", test);
                          setSelectedFacilityArray([...test]);
                        } else {
                          test.splice(test.indexOf(e.facilityCode), 1);
                          handleSearchFilterChange("facilities", test);

                          setSelectedFacilityArray([...test]);
                        }
                        //setShowValueField([]);

                        if (idsArray.indexOf(e.id) == -1) {
                          idsArray.push(e.id);
                          // handleSearchFilterChange("facilities", test);
                          //setSelectedFacilityIdsArray([...idsArray]);
                        } else {
                          idsArray.splice(idsArray.indexOf(e.id), 1);
                        }
                        setSelectedFacilityIdsArray([...idsArray]);
                        handleSearchFilterChange(
                          "facilitiesIdsArray",
                          idsArray
                        );
                      } else {
                        const found = [];
                        found.push(
                          getChildObjStructure(
                            JSON.parse(localStorage.getItem("locations")),
                            e.id
                          )
                        );
                        var physicalFacilities =
                          getAllPhysicalFacilitiesFromTree(found, []);
                        physicalFacilities.map((val) => {
                          var test = selectedFacilityArray;
                          var idsArray = selectedFacilityIdsArray;
                          if (test.indexOf(val.code) == -1) {
                            test.push(val.code);
                            handleSearchFilterChange("facilities", test);
                            //handleTestAnalyteJsonFilter("facilities", test);

                            setSelectedFacilityArray([...test]);
                          } else if (e.checked == false) {
                            test.splice(test.indexOf(val.code), 1);
                            handleSearchFilterChange("facilities", test);
                            //handleTestAnalyteJsonFilter("facilities", test);
                            setSelectedFacilityArray([...test]);
                          }
                          //setShowValueField([]);

                          if (idsArray.indexOf(val.id) == -1) {
                            idsArray.push(val.id);
                            // handleSearchFilterChange("facilities", test);
                            //setSelectedFacilityIdsArray([...idsArray]);
                          } else if (e.checked == false) {
                            idsArray.splice(idsArray.indexOf(val.id), 1);
                          }
                          setSelectedFacilityIdsArray([...idsArray]);
                          handleSearchFilterChange(
                            "facilitiesIdsArray",
                            idsArray
                          );
                          // handleTestAnalyteJsonFilter(
                          //   "facilitiesIdsArray",
                          //   idsArray
                          // );
                        });
                      }
                    }}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "facilityTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom facilityTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("facilityTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    className="bootstrap-demo facilityTree"
                    //onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
            </Container>
            <hr></hr>
            <Row>
              <Col>
                <Button
                  variant="primary "
                  className="primary button mb-3 ml-auto"
                  disabled={!checkboxList.length > 0}
                  onClick={() => {
                    setLoader(true);
                    setOrderNumberState(checkboxList);
                    printOrderReport(checkboxList);
                    setLabelCopies(1);
                    setBulkPrinted(true);
                  }}
                >
                  Print
                </Button>
              </Col>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                <Button
                  variant="outline "
                  className="outline button mb-3 ml-auto"
                  onClick={() => {
                    var curr = new Date();
                    const currentDate = new Date();
                    const next2DaysDate = new Date(currentDate);
                    var curr1 = new Date();
                    const currentDate1 = new Date();
                    const next2DaysDate1 = new Date(currentDate1);
                    setSearchFilter({
                      startDate: moment(
                        new Date(currentDate.setDate(currentDate.getDate() - 7))
                      ).format("yyyy-MM-DD"),
                      endDate: moment(
                        new Date(curr)
                      ).format("yyyy-MM-DD"),
                      facilityId: JSON.parse(localStorage.getItem("selectedLocation")),
                      dateType: "Order",
                      facilities: [],
                      facilitiesIdsArray: [JSON.parse(localStorage.getItem("selectedLocation"))],
                      patientFirstName: null,
                      patientLastName: null,
                      requisitionNumber: null,
                      accessionNumber: null,
                      collector: null,
                      status: null,
                      dob: null,
                      pageNumber: 1,
                      totalRecordPerPage: totalRecordPerPage,
                    });
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                    setSelectedFacilityArray([]);
                    setSelectedFacilityIdsArray();
                    setDateRange([
                      currentDate1.setDate(currentDate1.getDate() - 7),
                      curr1,
                    ]);
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  className="primary btn button ml-2 mb-3"
                  onClick={() => {
                    handleSearchFilterChange("pageNumber", 1);
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                  }}
                >
                  Filter
                </Button>
              </Col>
            </Row>
            <Table className="mt-4 shrink-table w-100" responsive bordered hover>
              <thead>
                <tr className="DarkHeading">
                  <th className="checkboxHeader">
                    <Form.Check
                      inline={true}
                      type="checkbox"
                      onChange={(e) => {
                        var checkboxListTmp = [];
                        if (e.target.checked) {
                          setAllCheckBoxChecked(true);
                          if (orderList.length > 0) {
                            orderList.map((value, index) => {
                              checkboxListTmp.push(value.requisitionNumber);
                            });
                            setCheckboxList([...checkboxListTmp]);
                            // setViewedArrayForCheck(viewedCheckedArray);
                          }
                        } else {
                          setAllCheckBoxChecked(false);
                          setCheckboxList([]);
                          //setViewedArrayForCheck([]);
                        }
                      }}
                      checked={allCheckBoxChecked}
                    />
                  </th>
                  <th className="whiteSpaceNoWrap">Account #</th>
                  <th>Order Date</th>
                  <th className="whiteSpaceNoWrap">Requisition #</th>
                  {/* <th className="whiteSpaceNoWrap">Accession #</th> */}
                  <th className="whiteSpaceNoWrap">Patient Name</th>
                  <th>DOB</th>
                  <th>Collector</th>
                  <th className="whiteSpaceNoWrap">Collection date</th>
                  <th>Tests</th>
                  <th>Status</th>
                  <th className="w-90">Action</th>
                </tr>
              </thead>
              <tbody>
                {orderList.length > 0 ? (
                  orderList.map((value, index) => {
                    return (
                      <tr>
                        <td className="">
                          <Form.Check
                            type="checkbox"
                            value={value.requisitionNumber}
                            inline={true}
                            checked={
                              checkboxList.indexOf(value.requisitionNumber) > -1
                            }
                            onChange={(e) => {
                              if (e.target.checked == false) {
                                checkboxList.splice(
                                  checkboxList.indexOf(value.requisitionNumber),
                                  1
                                );
                                setCheckboxList([...checkboxList]);
                                setAllCheckBoxChecked(false);
                              } else {
                                checkboxList.push(value.requisitionNumber);
                                setCheckboxList([...checkboxList]);
                                //  setAllCheckBoxChecked(true);
                              }
                            }}
                          ></Form.Check>
                        </td>
                        <td>
                          {value["account"] != null
                            ? value["account"].toLocaleString()
                            : "-"}
                        </td>
                        <td>
                          {value["orderDate"] != null
                            ? moment(value["orderDate"]).format(
                                "MM/DD/yyyy HH:mm:ss.SSS"
                              )
                            : "-"}
                        </td>
                        <td>
                          {value["requisitionNumber"] != null
                            ? value["requisitionNumber"].toLocaleString()
                            : "-"}
                        </td>
                        {/* <td>
                          {value["accessionNumber"] != null
                            ? value["accessionNumber"].toLocaleString()
                            : "-"}
                        </td> */}
                        <td>
                          {value["patientName"] != null
                            ? value["patientName"].toLocaleString()
                            : "-"}
                        </td>
                        <td>
                          {value["dob"] != null
                            ? moment(value["dob"]).format("MM/DD/yyyy")
                            : "-"}
                        </td>

                        <td>
                          {value["collector"] != null
                            ? value["collector"].toLocaleString()
                            : "-"}
                        </td>
                        <td>
                          {value["collectionDate"] != null
                            ? moment(value["collectionDate"]).format(
                                "MM/DD/yyyy HH:mm:ss.SSS"
                              )
                            : "-"}
                        </td>
                        <td>
                          {value["orderTests"] != null
                            ? value["orderTests"].length > 0
                              ? value["orderTests"]
                                  .map((testValue, testIndex) => {
                                    return testValue.testName;
                                  })
                                  .join(", ")
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {value["status"] != null
                            ? value["status"].toLocaleString()
                            : "-"}
                        </td>
                        <td className="whiteSpaceNoWrap">
                          <BsPencil
                            title="Edit Collection Information"
                            className="pl-l cursorPointer"
                            size={20}
                            onClick={() => {
                              setUpdateCollectionInformationModalState(true);
                              setCollectionInforamtionState({
                                ...collectionInforamtionState,
                                id: value.id,
                                collectedBy: value.collector,
                                collectionDate: value.collectionDate,
                              });
                            }}
                          />
                          <BsPrinter
                            title="View Requisition"
                            color="#398fda"
                            className="mb-2 ml-2 cursorPointer"
                            size={20}
                            onClick={() => {
                              setLoader(true);
                              setOrderNumberState([value.requisitionNumber]);
                              printOrderReport([value.requisitionNumber]);
                              setLabelCopies(1);
                              setBulkPrinted(false);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>{"No Files Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Form.Select>
              <span>per page</span>
            </>
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
      <div
        class="modal fade requisitionPreview"
        id="PrintPreviewDialogInOrderHistory"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="PrintPreviewDialogInOrderHistory"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title " id="PrintPreviewDialogInOrderHistory">
                {orderPdfDetail}
              </h5>
              <button
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <Row>
                  <Col md={{ span: bulkPrinted ? 12 : 9 }}>
                    <iframe
                      className="requisitionPreviewIframe"
                      src={pdfBlob}
                      frameBorder="0"
                      scrolling="auto"
                      width="100%"
                    ></iframe>
                    {!bulkPrinted ? (
                      <Row>
                        <Col className="mt-2 d-flex justify-content-end  align-items-center">
                          Labels to Print on Requisition:
                          <Form.Select
                            disabled={printing}
                            className="w-auto ml-1"
                            name="status"
                            onChange={async (e) => {
                              setLablesCountInPdf(e.target.value);
                            }}
                            value={lablesCountInPdf}
                          >
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={24}>24</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                  {!bulkPrinted ? (
                    <Col md={{ span: 3 }}>
                      <iframe
                        id="label1"
                        className="labelDiv"
                        title="Label"
                        allowtransparency={true}
                        src={
                          pdflabel +
                          "#zoom=120%&scrollbar=0&toolbar=0&navpanes=0"
                        }
                        height={"159px"}
                        width={"88%"}
                        type="application/pdf"
                      />
                      <div className="d-flex justify-content-start  align-items-center pr-3">
                        <span>
                          Label Copies:
                          <input
                            min={1}
                            onKeyDown={(e) => {
                              if (e.target.value < 1) {
                                setLabelCopies(1);
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.target.value < 1) {
                                setLabelCopies(1);
                              }
                            }}
                            value={labelCopies}
                            type="number"
                            className="labelCopies ml-1 form-control labelCopiesInput"
                            onChange={(e) => {
                              setLabelCopies(e.target.value);
                            }}
                          ></input>
                        </span>
                        <a
                          style={{ color: "white" }}
                          className="primary button btn btn-primary mr-4"
                          disabled={labelCopies > 0 ? false : true}
                          onClick={() => {
                            ApiGetCalls(
                              "GenerateLabelString/" +
                                orderNumberState +
                                "/" +
                                labelCopies
                            ).then((value) => {
                              if (value["status"] == "Success") {
                                window.open(value["data"], "_self");
                              } else {
                                snackBar(
                                  "Error! " + value["message"],
                                  "danger"
                                );
                              }
                            });
                          }}
                          // href={
                          //   props.patientDetails != undefined
                          //     ? "Atvivo:" +
                          //       props.patientDetails["patientName"] +
                          //       "&" +
                          //       props.tmpOrderNumber +
                          //       "&" +
                          //       props.patientDetails["patientDob"].replaceAll(
                          //         "-",
                          //         "/"
                          //       ) +
                          //       "&" +
                          //       moment(
                          //         new Date(props.orderData["collectionDate"])
                          //       ).format("MM/DD/yyyy hh:mm A") +
                          //       "&" +
                          //       labelCopies
                          //     : ""
                          // }
                        >
                          {"Print"}
                        </a>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Result"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={updateCollectionInformationModalState}
        onHide={() => {
          setCollectionInforamtionState({
            id: null,
            collectedBy: null,
            collectionDate: null,
          });
          setUpdateCollectionInformationModalState(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Collection Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col className="mb-3" md={{ span: 6 }}>
                <h5 className="label-style">Collected By</h5>

                <Form.Control
                  required
                  aria-label="Collected by"
                  type="text"
                  value={collectionInforamtionState.collectedBy}
                  name="collectedBy"
                  maxLength={allInputField}
                  className="input-style"
                  onChange={(e) => {
                    setCollectionInforamtionState({
                      ...collectionInforamtionState,
                      collectedBy: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col md={{ span: 6 }}>
                <DatePickerComponent
                  required="Y"
                  className="input-style form-control"
                  formValue="Collection Date"
                  orderStatus={"Draft"}
                  defaultValue={collectionInforamtionState.collectionDate}
                  onChange={(name, value) => {
                    setCollectionInforamtionState({
                      ...collectionInforamtionState,
                      collectionDate: value,
                    });
                  }}
                  name="collectionDate"
                  showTimeSelect={true}
                  dateFormat="MM/dd/yyyy, HH:mm"
                  onChangeFunction={() => {}}
                  defaultValue2="orderInfo"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              collectionInforamtionState.id == null ||
              collectionInforamtionState.collectedBy == null ||
              collectionInforamtionState.collectedBy == "" ||
              collectionInforamtionState.collectionDate == null
                ? true
                : false
            }
            variant="primary"
            className="primary btn button"
            // onClick={handleCloseSaveCollectionChangesModal}
            onClick={() => {
              ApiPostCalls(
                collectionInforamtionState,
                "updateOrderCollectionData"
              ).then((value) => {
                if (value["status"] == "Success") {
                  snackBar(value["message"], "success");
                  setCollectionInforamtionState({
                    id: null,
                    collectedBy: null,
                    collectionDate: null,
                  });
                  setLoader(true);
                  getResults();
                  setUpdateCollectionInformationModalState(false);
                } else {
                  snackBar(value["message"], "danger");
                }
              });
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default PhlebotomyOrders;
