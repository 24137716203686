import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Row, Col, Container } from "react-bootstrap";
import SelectDiagnosis from "./SearchAndSelectDiagnosis";

function DiagnosisTab(props) {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>DIAGNOSIS</Accordion.Header>
          <Accordion.Body>
            <SelectDiagnosis
              locationBasedData={props.locationBasedData}
              handleClinicalFormChange={props.handleClinicalFormChange}
              orderData={props.orderData}
              userLocationId={props.userLocationId}
              orderIdAsParam={props.orderIdAsParam}
              favIconHandler={props.favIconHandler}
              setFavIconHandler={props.setFavIconHandler}
              listOptionsDiagnosis={props.listOptionsDiagnosis}
              setListOptionsDiagnosis={props.setListOptionsDiagnosis}
              selectedDiagnosesId={props.selectedDiagnosesId}
              setSelectedDiagnosesId={props.setSelectedDiagnosesId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default DiagnosisTab;
