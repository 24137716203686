import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import moment from "moment/moment";
import { ApiPostCalls, ApiGetCalls } from "../../services/Api";
import { useParams } from "react-router-dom";
import { Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { LoginInfoContext, LoginUserLocationContext } from "../../Context";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import ReactPaginate from "react-paginate";
import CustomLoader from "../../GlobalComponents/Loader";

function Hl7ResultsStatus(props) {
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  var curr = new Date();
  const currentDate = new Date();
  const next2DaysDate = new Date(currentDate);
  const [dateRange, setDateRange] = useState([
    currentDate.setDate(currentDate.getDate() - 7),
    curr,
  ]);
  const [startDate, endDate] = dateRange;
  const [searchFilter, setSearchFilter] = useState({});
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [pagenationState, setPagenationState] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lookOrderInfo, setLookOrderInfo] = useState({
    messageTypeStatus: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [messageTypeStatus] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/Hl7SummaryMessageType"),
      ]);
      setLookOrderInfo({
        messageTypeStatus: messageTypeStatus,
      });
    } catch (error) {}
  }
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Reports";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("#/");
    } else {
      var curr1 = new Date();
      setSearchFilter({
        startDate: moment(new Date(curr1.setDate(curr1.getDate() - 7))).format(
          "yyyy-MM-DD"
        ),
        endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
        fileName: null,
        requisition: null,
        accession: null,
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
        messageType: "",
        status: "failed",
      });
      fetchStatusesInfoDataOnLoad();
      setPagenationState(Math.random());
    }
  }, []);
  const [orderList, setOrderList] = useState([]);
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({
      ...values,
      [name]: value == "" ? null : value,
    }));
  };
  let { id } = useParams();
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilterChange("pageNumber", 1);
      setPagenationState(Math.random());
      setCurrentPage(0);
    }
  };
  async function getResults() {
    try {
      var body = JSON.stringify(searchFilter);
      var data = await ApiPostCalls(body, "getHl7LogSummary");
      if (data.length > 0) {
        setTotalRecords(Math.ceil(data[0].totalRecords / totalRecordPerPage));
        setOrderList(data);
      } else {
        setOrderList([]);
        setTotalRecords(0);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});

  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });

  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getResults();
    }
  }, [pagenationState]);
  const [showLoader, setLoader] = useState(false);
  return (
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight-orderPage">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Hl7 Results
            </h3>
            <Row>
              <Col md={{ span: 6 }} lg={{ span: 3 }}>
                <h5 className="label-style">Date Range</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM-dd-yyyy"
                  onChange={(update) => {
                    handleSearchFilterChange(
                      "startDate",
                      moment(new Date(update[0])).format("yyyy-MM-DD")
                    );
                    if (update[1] != null) {
                      handleSearchFilterChange(
                        "endDate",
                        moment(new Date(update[1])).format("yyyy-MM-DD")
                      );
                    }
                    setDateRange(update);
                  }}
                  //disabled={showDateRange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                />
              </Col>
              <Col className="d-flex flex-row align-items-end align-content-end"></Col>
            </Row>
            <Container className="resultAdvancesearch">
              <Row>
                <Col>
                  <h5 className="label-style">File Name</h5>
                  <input
                    name="fileName"
                    value={
                      searchFilter["fileName"] == null
                        ? ""
                        : searchFilter["fileName"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="input-style form-control mb-3"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    name="requisition"
                    value={
                      searchFilter["requisition"] == null
                        ? ""
                        : searchFilter["requisition"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="input-style form-control mb-3"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col className="col-md-4">
                  <h5 className="label-style">Accession #</h5>
                  <input
                    name="accession"
                    value={
                      searchFilter["accession"] == null
                        ? ""
                        : searchFilter["accession"]
                    }
                    type="text"
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    className="input-style form-control mb-3"
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-md-4">
                  <h5 className="label-style">Status</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="status"
                    value={
                      searchFilter["status"] == null
                        ? ""
                        : searchFilter["status"]
                    }
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}>All</option>
                    <option value={"failed"}>Failed</option>
                    <option value={"passed"}>Passed</option>
                  </Form.Select>
                </Col>
                <Col className="col-md-4">
                  <h5 className="label-style">Message Type</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="messageType"
                    value={
                      searchFilter["messageType"] == null
                        ? ""
                        : searchFilter["messageType"]
                    }
                    onChange={(e) => {
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}>All</option>
                    {lookOrderInfo?.messageTypeStatus.map((val, ind) => {
                      return (
                        <option value={val.optionCode}>{val.options}</option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Container>
            <hr></hr>
            <Row>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                <Button
                  variant="outline "
                  className="outline button mb-3 ml-auto"
                  onClick={() => {
                    var curr = new Date();
                    const currentDate = new Date();
                    const next2DaysDate = new Date(currentDate);
                    var curr1 = new Date();
                    const currentDate1 = new Date();
                    const next2DaysDate1 = new Date(currentDate1);
                    setSearchFilter({
                      startDate: moment(
                        new Date(currentDate.setDate(currentDate.getDate() - 7))
                      ).format("yyyy-MM-DD"),
                      endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
                      fileName: null,
                      requisition: null,
                      accession: null,
                      pageNumber: 1,
                      totalRecordPerPage: totalRecordPerPage,
                      messageType: "",
                      status: "failed",
                    });
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                    setDateRange([
                      currentDate1.setDate(currentDate1.getDate() - 7),
                      curr1,
                    ]);
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  className="primary btn button ml-2 mb-3"
                  onClick={() => {
                    handleSearchFilterChange("pageNumber", 1);
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                  }}
                >
                  Filter
                </Button>
              </Col>
            </Row>
            <Table className="mt-4" responsive="md" bordered hover>
              <thead>
                <tr className="DarkHeading">
                  <th>Process id</th>
                  <th>Message Type</th>
                  <th>Total files processed</th>
                  {/* <th>User Name</th> */}

                  <th className="text-center">No of files failed</th>
                  <th className="text-center">No of files passed</th>
                  <th className="text-center">Started at</th>
                  <th className="text-center">Ended at</th>
                  <th className="text-center">Process duration</th>
                </tr>
              </thead>
              <tbody>
                {orderList.length > 0 ? (
                  orderList.map((value, index) => {
                    return (
                      <tr
                        className="cursorPointer"
                        key={index}
                        onClick={() => {
                          navigate("/Hl7ResultDetail/" + value.id, {
                            state: searchFilter,
                          });
                        }}
                      >
                        <td>{value.id}</td>
                        <td>{value.messageType}</td>
                        <td>{value["totalFilesProcessed"].toLocaleString()}</td>
                        <td>{value["noOfFilesFailed"].toLocaleString()}</td>
                        <td>{value["noOfSuccess"].toLocaleString()}</td>
                        <td>
                          {moment(value["startedAt"]).format(
                            "MM/DD/yyyy HH:mm:ss.SSS"
                          )}
                        </td>
                        <td>
                          {moment(value["endedAt"]).format(
                            "MM/DD/yyyy HH:mm:ss.SSS"
                          )}
                        </td>
                        <td>
                          {Math.floor(
                            moment
                              .duration(
                                moment(new Date(value["endedAt"])).diff(
                                  moment(new Date(value["startedAt"]))
                                )
                              )
                              .asHours()
                          ) +
                            moment
                              .utc(
                                moment(new Date(value["endedAt"])).diff(
                                  moment(new Date(value["startedAt"]))
                                )
                              )
                              .format(":mm:ss")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>{"No Files Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Form.Select>
              <span>per page</span>
            </>
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default Hl7ResultsStatus;
