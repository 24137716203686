import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { searchSelectedHighlightColor } from "../config/config";
import FilterSelectData from "./FilterSelectData";

function SelectBody(props) {
  const [checked, setChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState(
    props.multiSelect == true
      ? props.selectedValues.length > 0
        ? {
            checkedValues: props.selectedValues,
          }
        : {
            checkedValues: [],
          }
      : {
          checkedValues: [],
        }
  );
  const columnHeader = [];
  if (props.multiSelect) {
    columnHeader.push(
      <Col
        key="defaultHearder"
        xs={{ span: 1 }}
        md={{ span: 1 }}
        lg={{ span: 1 }}
      >
        <input
          className="searchCheckBox"
          type="checkbox"
          checked={allChecked}
          //value={props.dataArray[i][0]}
          onChange={(e) => {
            if (e.target.checked) {
              const allCheckValues = [];
              for (let i = 0; i < props.dataArray.length; i++) {
                allCheckValues.push({
                  id: props.dataArray[i][0],
                  valueArray: props.dataArray[i],
                });
              }
              setCheckBoxArray({
                checkedValues:
                  checkBoxArray.checkedValues?.concat(allCheckValues),
              });
            } else {
              setCheckBoxArray({
                checkedValues: [],
              });
            }
            setAllChecked(!allChecked);
          }}
        />
      </Col>
    );
  }
  for (let i = 0; i < props.column.length; i++) {
    columnHeader.push(
      <Col key={"select_Col" + i.toString()}>{props.column[i]}</Col>
    );
  }

  const divInnerData = [];
  // for (let i = 0; i < props.dataArray.length; i++) {
  props.dataArray.map((value, index) => {
    if (
      !props.multiSelect ||
      !props.selectedValues?.some((item) => value[0] === item.id)
    ) {
      const array = value.filter((data) =>
        data?.toLowerCase().includes(props.filter.toLowerCase())
      );
      if (array.length > 0) {
        const dataTest = [...value];
        const code = dataTest[1].split(new RegExp(`(${props.filter})`, "gi"));
        dataTest[1] = code.map((part, index) =>
          part.toLowerCase() === props.filter.toLowerCase() ? (
            <b
              className="boldHighlightedCharcter"
              onClick={() => {
                if (!props.multiSelect) {
                  //props.rowClickHandler(value, value[0]);
                }
                // props.setShowResults(false);
              }}
              key={"code" + index}
              style={{ background: searchSelectedHighlightColor }}
            >
              {part}
            </b>
          ) : (
            part
          )
        );
        if (dataTest[2] != undefined) {
          const description = dataTest[2].split(
            new RegExp(`(${props.filter})`, "gi")
          );
          dataTest[2] = description.map((part, index) =>
            part.toLowerCase() === props.filter.toLowerCase() ? (
              <b
                className="boldHighlightedCharcter"
                onClick={() => {
                  //if (!props.multiSelect)
                  // props.rowClickHandler(value, value[0]);
                  // props.setShowResults(false);
                }}
                key={"description" + index}
                style={{ background: searchSelectedHighlightColor }}
              >
                {part}
              </b>
            ) : (
              part
            )
          );
        }
        if (dataTest[3] != undefined) {
          const description = dataTest[3].split(
            new RegExp(`(${props.filter})`, "gi")
          );
          dataTest[3] = description.map((part, index) =>
            part.toLowerCase() === props.filter.toLowerCase() ? (
              <b
                className="boldHighlightedCharcter"
                onClick={() => {
                  if (!props.multiSelect)
                    props.rowClickHandler(value, value[0]);
                  // props.setShowResults(false);
                }}
                key={"description" + index}
                style={{ background: searchSelectedHighlightColor }}
              >
                {part}
              </b>
            ) : (
              part
            )
          );
        }
        divInnerData.push(
          <Row
            key={"select_Row" + index.toString()}
            className="rowhover searchResultDiv cursorPointer"
            onClick={() => {
              if (!props.multiSelect) props.rowClickHandler(value, value[0]);
              // props.setShowResults(false);
            }}
          >
            {props.multiSelect && (
              <Col
                className="searchResultcol"
                key={"searchCheckBox_" + index.toString()}
                xs={{ span: 1 }}
                md={{ span: 1 }}
                lg={{ span: 1 }}
              >
                <input
                  className="searchCheckBox"
                  type="checkbox"
                  checked={
                    checkBoxArray.checkedValues?.length > 0
                      ? checkBoxArray.checkedValues?.some(
                          (item) => value[0] === item.id
                        )
                      : checked
                  }
                  value={value[0]}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckBoxArray({
                        checkedValues: checkBoxArray.checkedValues?.concat({
                          id: value[0],
                          valueArray: value,
                        }),
                      });
                    } else {
                      setAllChecked(false);
                      setCheckBoxArray({
                        checkedValues: checkBoxArray.checkedValues?.filter(
                          (value) => e.target.value != value.id
                        ),
                      });
                    }
                    setChecked(!checked);
                  }}
                />
              </Col>
            )}
            <FilterSelectData
              filteredData={dataTest}
              key={"filterSelectData_" + index.toString()}
              filter={props.filter}
              multiSelect={props.multiSelect}
              keyId={index}
            />
          </Row>
        );
      }
    }
  });
  return (
    <>
      <div className={props.multiSelect && "customParentDiv"}>
        <div className="patients-search-results">
          <Row className="DarkHeading sticky-top">{columnHeader}</Row>
          {divInnerData.length > 0 ? divInnerData : "No record found"}
        </div>
        {props.multiSelect && (
          <div className="customSearchButton">
            <button
              onClick={() => {
                props.rowClickHandler(checkBoxArray.checkedValues);
              }}
              className="primary btn button SearchBtnCustom"
            >
              ok
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default SelectBody;
