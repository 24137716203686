import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SplitData = (idx) => {
  var k = 0;
  const tmpArray = idx;

  return (
    // <Container> {tmpArray} </Container>
    <p dangerouslySetInnerHTML={{ __html: tmpArray }}></p>
    // <Container className="test-information-container">
    //   {tmpArray[k] == "PREFERRED SAMPLE" ? (
    //     <Row>
    //       <Col md={{ span: 4 }}>Preferred Sample</Col>
    //       <Col md={{ span: 8 }}>
    //         {tmpArray[k + 1]}
    //         <span hidden>{(k = k + 2)}</span>
    //       </Col>
    //     </Row>
    //   ) : null}
    //   {tmpArray[k] == "CONTAINER" ? (
    //     <Row>
    //       <Col md={{ span: 4 }}>Container</Col>
    //       <Col md={{ span: 8 }}>
    //         {tmpArray[k + 1]}
    //         <span hidden>{(k = k + 2)}</span>
    //       </Col>
    //     </Row>
    //   ) : null}
    //   {tmpArray[k] == "MINIMUN VOLUME" ? (
    //     <Row>
    //       <Col md={{ span: 4 }}>Minimum Volume</Col>
    //       <Col md={{ span: 8 }}>
    //         {tmpArray[k + 1]}
    //         <span hidden>{(k = k + 2)}</span>
    //       </Col>
    //     </Row>
    //   ) : null}

    //   {tmpArray[k] == "SPECIMEN TRANSPORT" ? (
    //     <Row>
    //       <Col md={{ span: 4 }}>Specimen Transport</Col>
    //       <Col md={{ span: 8 }}>
    //         {tmpArray[k + 1]}
    //         <span hidden>{(k = k + 2)}</span>
    //       </Col>
    //     </Row>
    //   ) : null}

    //   {tmpArray[k] == "STABILITY" ? (
    //     <Row>
    //       <Col md={{ span: 4 }}>Stability</Col>
    //       <Col md={{ span: 8 }}>{tmpArray[k + 1]}</Col>
    //     </Row>
    //   ) : null}
    // </Container>
  );
};

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="info-model-body">
        <p>
          {props.showComponent == "fav"
            ? props.infoPopupDetailStateFav[props.infoPopupIndexState] !=
              undefined
              ? SplitData(
                  props.infoPopupDetailStateFav[props.infoPopupIndexState]
                )
              : null
            : props.infoPopupDetailState[props.infoPopupIndexState] != undefined
            ? SplitData(props.infoPopupDetailState[props.infoPopupIndexState])
            : null}
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default InfoModal;
