import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  displayInDateFormat,
  handleUpdateClassesOnFormObjects,
  lookupDataFeed,
  MobileNotAvailable,
  setDateInDbFormat,
  updateDataToFields,
  removeErrorForDisabledDivs,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import Spinner from "react-bootstrap/Spinner";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import moment from "moment/moment";
import { ApiGetCalls } from "../../../services/Api";
import InputMask from "react-input-mask";
import {
  allInputField,
  email,
  addressFieldLimit,
  middleNameLength,
} from "../../../config/config";
import { useRef } from "react";
function DemographicInfo({
  patientTabMode,
  patientObj,
  lookUpData,
  setinsuranceBlockList,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  handleAllFormChange,
  handleDateChange,
  editMode,
  inputs,
  setInputs,
  facilityId,
  relation,
  sameAddressAsPatient,
}) {
  const [dropDownOtherInputs, setdropDownOtherInputs] = React.useState({
    genderIdentityOther: false,
    sexualOrientationOther: false,
  });
  const [selectedPhoneOption, setSelectedPhoneOption] = useState("Mobile");

  const [zipMask, setzipMask] = React.useState(
    patientTabMode === "view"
      ? inputs["persons.addresses.zip"] == undefined
        ? "99999"
        : inputs["persons.addresses.zip"].length > 5
        ? "99999-9999"
        : "99999"
      : "99999"
  );
  useEffect(() => {
    if (Object.keys(inputs).length != 0) {
      if (inputs["persons.addresses.zip"] != undefined)
        setzipMask(
          inputs["persons.addresses.zip"].length > 5 ? "99999-9999" : "99999"
        );
    }
  }, [relation, sameAddressAsPatient]);
  const [fetchingAddress, setFetchingAddress] = React.useState(false);
  const [mobileNumberAvailability, setMobileNumberAvailability] =
    React.useState(true);

  useEffect(() => {
    let otherInputsObject = { ...dropDownOtherInputs };
    if (inputs["genderIdentity"] == undefined) {
      inputs["genderIdentity"] = 28;
    }
    if (inputs["sexualOrientation"] == undefined) {
      inputs["sexualOrientation"] = 21;
    }
    if (inputs["race"] == undefined) {
      inputs["race"] = 7;
    }
    if (inputs["ethnicGroup"] == undefined) {
      inputs["ethnicGroup"] = 13;
    }
    if (inputs["genderIdentity"] == 27) {
      otherInputsObject["genderIdentityOther"] = true;
    }
    if (inputs["sexualOrientation"] == 19) {
      otherInputsObject["sexualOrientationOther"] = true;
    }
    setdropDownOtherInputs(otherInputsObject);
  }, [inputs]);
  useEffect(() => {
    if (inputs["persons.mobile"] == "") {
      setMobileNumberAvailability(false);
    }
    if (
      inputs["persons.preferredPhone"] == null ||
      inputs["persons.preferredPhone"] == ""
    ) {
      inputs["persons.mobile"] == ""
        ? setSelectedPhoneOption("Phone")
        : setSelectedPhoneOption("Mobile");
    } else {
      setSelectedPhoneOption(inputs["persons.preferredPhone"]);
    }
  }, [patientObj]);
  function getAddressByZipCode(zipCode) {
    var filteredZipCode = zipCode.split("-");
    if (filteredZipCode[0] != "") {
      ApiGetCalls("GetAddressByZip/" + filteredZipCode[0]).then((data) => {
        if (data != {}) {
          setInputs({
            ...inputs,
            "persons.addresses.city": data?.City,
            "persons.addresses.state": data?.State,
          });
        }
        setFetchingAddress(false);
      });
    } else {
      setFetchingAddress(false);
    }
  }
  return (
    <Container className="label-style parentcontainer">
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          {/* {patientTabMode == "view" && (
            <input
              type="text"
              name="id"
              required
              value={patientObj?.id}
              className="input-style form-control"
              hidden
            />
          )} */}
          <h5 className="label-style">Last Name</h5>
          <input
            type="text"
            name="persons.lastName"
            id="Demographic.lastName"
            maxLength={allInputField}
            required
            aria-label="Last name"
            className="input-style form-control Demographic.persons"
            data-type="text"
            onChange={(e) => {
              handleAllFormChange(e);
              handleDateChange(selectedPhoneOption, "persons.preferredPhone");
              handleDateChange(facilityId, "facilityId");
            }}
            value={inputs["persons.lastName"]}
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">First Name</h5>
          <input
            type="text"
            name="persons.firstName"
            id="Demographic.firstName"
            required
            maxLength={allInputField}
            aria-label="First name"
            className="input-style form-control Demographic.persons"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.firstName"]}
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Middle Initial</h5>
          <input
            type="text"
            name="persons.middleName"
            id="Demographic.middleName"
            maxLength={middleNameLength}
            aria-label="Middle initial"
            className="input-style form-control Demographic.persons"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.middleName"]}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Date of Birth</h5>
          <DatePicker
            preventOpenOnFocus={true}
            name="persons.dob"
            id="persons.dob"
            data-type="date"
            ariaLabelledBy="Date of birth"
            className="input-style form-control Demographic.persons"
            selected={
              inputs["persons.dob"] != undefined && inputs["persons.dob"] != ""
                ? new Date(inputs["persons.dob"])
                : null
            }
            dateFormat="MM/dd/yyyy"
            maxDate={new Date()}
            onChange={(date) => {
              handleDateChange(
                date != null
                  ? moment(date).format("yyyy-MM-DDT00:00:00")
                  : null,
                "persons.dob"
              );
            }}
            showMonthDropdown={true}
            showYearDropdown={true}
            dropdownMode="select"
            required
            autoComplete="off"
            // onKeyDown={(e) => {
            //   if (e.key === "Tab") {
            //     e.preventDefault();
            //     inputRef.current.focus();
            //   }
            // }}
            customInput={
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder={"mm/dd/yyyy"}
                keepCharPositions={true}
                guide={true}
              />
            }
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender</h5>

          <Form.Select
            required
            name="persons.sex"
            id="Demographic.sex"
            aria-label="Gender"
            data-type="text"
            className="input-style form-control Demographic.persons"
            onChange={handleAllFormChange}
            value={inputs["persons.sex"]}
            autoComplete="off"
          >
            <option key={"sex"} value=""></option>
            {lookUpData.sexData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={{ span: 4 }} className="">
          <h5 className="label-style">Email</h5>
          <input
            type="text"
            name="persons.email"
            id="Demographic.email"
            aria-label="Email"
            maxLength={email}
            className="input-style form-control Demographic.persons validate_email"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.email"]}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        {/* <Col md={{ span: 4 }} className="">
          <h5 className="label-style">Office Id</h5>
          <input
            type="text"
            name="officeId"
            id="Demographic.officeId"
            aria-label="Office id"
            className="input-style form-control root-level"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["officeId"]}
            autoComplete="off"
          />
        </Col> */}
        <Col md={{ span: 4 }} className="">
          <h5 className="label-style">MRN</h5>
          <input
            type="text"
            name="mrn"
            id="Demographic.mrn"
            aria-label="Mrn"
            className="input-style form-control root-level"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["mrn"]}
            autoComplete="off"
            disabled={inputs["isUpdatedByHL7"] == "Yes" ? false : true}
          />
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Room</h5>
          <input
            type="text"
            name="persons.addresses.room"
            id="Demographic.room"
            aria-label="Room"
            maxLength={allInputField}
            className="input-style form-control Demographic.addresses"
            data-type="text"
            value={inputs["persons.addresses.room"]}
            onChange={handleAllFormChange}
            autoComplete="off"
          />
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Street 1</h5>
          <input
            type="text"
            name="persons.addresses.street1"
            id="Demographic.street1"
            aria-label="Street 1"
            required
            maxLength={addressFieldLimit}
            className="input-style form-control Demographic.addresses"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.addresses.street1"]}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Street 2</h5>
          <input
            type="text"
            name="persons.addresses.street2"
            id="Demographic.street2"
            aria-label="Street 2"
            maxLength={addressFieldLimit}
            className="input-style form-control Demographic.addresses"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.addresses.street2"]}
            autoComplete="off"
          />
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Zip/Postal Code</h5>
          <InputMask
            mask={zipMask}
            maskChar=""
            name="persons.addresses.zip"
            id="Demographic.zip"
            aria-label="Zip"
            required
            className="input-style form-control Demographic.addresses validate_zip"
            data-type="text"
            value={inputs["persons.addresses.zip"]}
            onKeyDown={(event) => {
              if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105)
              ) {
                let reGexForZip = /(^\d{5})/;
                if (reGexForZip.test(event.target.value)) {
                  setzipMask("99999-9999");
                } else {
                  setzipMask("99999");
                }
              }
            }}
            onBlur={(event) => {
              if (event.target.value.split("-")[1] == "") {
                setzipMask("99999");
              }
              setFetchingAddress(true);
              getAddressByZipCode(event.target.value);
            }}
            onChange={handleAllFormChange}
            autoComplete="off"
          />
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">City</h5>
          <input
            type="text"
            name="persons.addresses.city"
            id="Demographic.city"
            aria-label="City"
            required
            maxLength={allInputField}
            className="input-style form-control Demographic.addresses"
            data-type="text"
            value={inputs["persons.addresses.city"]}
            onChange={handleAllFormChange}
            autoComplete="off"
          />
          {fetchingAddress ? (
            <Spinner
              className="spinner_field"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">State/Province/Region</h5>
          <input
            type="text"
            name="persons.addresses.state"
            id="Demographic.state"
            required
            aria-label="State"
            maxLength={allInputField}
            className="input-style form-control Demographic.addresses"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.addresses.state"]}
            autoComplete="off"
          />
          {fetchingAddress ? (
            <Spinner
              className="spinner_field"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Primary Phone # </h5>
          <InputMask
            mask="+1\ (999) 999-9999"
            maskChar=" "
            type="text"
            name="persons.phone"
            id="Demographic.phone"
            aria-label="Primary phone"
            // defaultValue={
            //   patientTabMode === "view"
            //     ? phoneValues["persons.phone"] == null
            //       ? null
            //       : phoneValues["persons.phone"]
            //     : null
            // }
            required
            className="input-style form-control Demographic.persons validate_mobile"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["persons.phone"]}
            autoComplete="off"
          />
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Race</h5>
          <Form.Select
            required
            data-type="text"
            name="race"
            id="Demographic.race"
            aria-label="Race"
            className="input-style form-control root-level"
            onChange={handleAllFormChange}
            value={inputs["race"]}
            autoComplete="off"
          >
            <option key={"race"} value=""></option>
            {lookUpData.raceData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender Identity</h5>
          <Form.Select
            required
            data-type="text"
            name="genderIdentity"
            id="Demographic.genderIdentity"
            aria-label="Gender Identity"
            className="input-style form-control root-level"
            onChange={async (e) => {
              if (e.target.value == 27) {
                setdropDownOtherInputs({
                  ...dropDownOtherInputs,
                  genderIdentityOther: true,
                });
                setInputs({ ...inputs, genderIdentity: e.target.value });
              } else {
                setdropDownOtherInputs({
                  ...dropDownOtherInputs,
                  genderIdentityOther: false,
                });
                await setInputs({
                  ...inputs,
                  genderIdentity: e.target.value,
                  genderIdentityOther: "",
                });
                removeErrorForDisabledDivs("DemographicAccordion");
              }
            }}
            value={inputs["genderIdentity"]}
            autoComplete="off"
          >
            <option key={"gender"} value=""></option>
            {lookUpData.genderData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Gender Identity (Additional) </h5>
          <input
            type="text"
            name="genderIdentityOther"
            className="input-style form-control"
            aria-label="Gender Identity (Additional)"
            maxLength={allInputField}
            value={
              inputs["genderIdentity"] == 27
                ? inputs["genderIdentityOther"]
                : ""
            }
            onChange={handleAllFormChange}
            disabled={dropDownOtherInputs["genderIdentityOther"] ? false : true}
            required={dropDownOtherInputs["genderIdentityOther"] ? true : false}
          />
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Ethinic Group</h5>
          <Form.Select
            required
            name="ethnicGroup"
            id="Demographic.ethnicGroup"
            aria-label="Ethinic Group"
            data-type="text"
            className="input-style form-control root-level"
            onChange={handleAllFormChange}
            value={inputs["ethnicGroup"]}
            autoComplete="off"
          >
            <option key={"eg"} value=""></option>
            {lookUpData.ethinicData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Sexual Orientation</h5>
          <Form.Select
            data-type="text"
            required
            name="sexualOrientation"
            id="Demographic.sexualOrientation"
            aria-label="Sexual Orientation"
            className="input-style form-control root-level"
            onChange={async (e) => {
              if (e.target.value == 19) {
                setdropDownOtherInputs({
                  ...dropDownOtherInputs,
                  sexualOrientationOther: true,
                });
                setInputs({ ...inputs, sexualOrientation: e.target.value });
              } else {
                setdropDownOtherInputs({
                  ...dropDownOtherInputs,
                  sexualOrientationOther: false,
                });
                await setInputs({
                  ...inputs,
                  sexualOrientation: e.target.value,
                  sexualOrientationOther: "",
                });
                removeErrorForDisabledDivs("DemographicAccordion");
              }
            }}
            value={inputs["sexualOrientation"]}
            autoComplete="off"
          >
            <option key={"sO"} value=""></option>
            {lookUpData.sexualOrientationData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Sexual Orientation (Additional) </h5>
          <input
            type="text"
            aria-label="Sexual Orientation (Additional)"
            name="sexualOrientationOther"
            className="input-style form-control"
            maxLength={allInputField}
            value={
              inputs["sexualOrientation"] == 19
                ? inputs["sexualOrientationOther"]
                : ""
            }
            onChange={handleAllFormChange}
            disabled={
              dropDownOtherInputs["sexualOrientationOther"] ? false : true
            }
            required={
              dropDownOtherInputs["sexualOrientationOther"] ? true : false
            }
          />
        </Col>

        <Col md={{ span: 4 }} className="textinputwithcheckbox mandatory">
          <h5 className="label-style">Mobile Number</h5>
          <span>
            <InputMask
              mask="+1\ (999) 999-9999"
              maskChar=" "
              type="text"
              name="persons.mobile"
              id="Demographic.mobile"
              aria-label="Mobile"
              value={mobileNumberAvailability ? inputs["persons.mobile"] : ""}
              required
              className="input-style form-control Demographic.persons validate_mobile"
              data-type="text"
              disabled={mobileNumberAvailability ? false : true}
              onChange={handleAllFormChange}
              autoComplete="off"
            />
          </span>
          <div className="form-check">
            <input
              type="checkbox"
              name="persons.mobileCheckBoxNA"
              id="Demographic.mobileCheckBoxNA"
              maxLength={allInputField}
              onClick={async (e) => {
                if (e.target.checked == true) {
                  setMobileNumberAvailability(false);
                  setSelectedPhoneOption("Phone");
                  handleDateChange("Phone", "persons.preferredPhone");
                } else {
                  await handleDateChange("", "persons.mobile");
                  setMobileNumberAvailability(true);
                  // setSelectedPhoneOption("Mobile");
                  // handleDateChange("Mobile", "persons.preferredPhone");
                }
              }}
              className="form-check-input form-control  Demographic.persons"
              value="Y"
              checked={mobileNumberAvailability ? false : true}
              onChange={handleAllFormChange}
            />
            Mobile number not available
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Vaccination Status</h5>
          <Form.Select
            name="vaccinationStatus"
            id="vaccinationStatus"
            aria-label="Vaccination status"
            className="input-style form-control"
            onChange={handleAllFormChange}
            value={inputs["vaccinationStatus"]}
            autoComplete="off"
          >
            <option key={"vaccination"} value=""></option>
            {lookUpData.vaccinationData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Preferred Phone Number</h5>
          {/* <div className="radio"> */}
          <label className="mr-3 mt-2">
            <input
              className="mr-2"
              name="persons.preferredPhone"
              //id="Demographic.preferredPhone"
              type="radio"
              value="Phone"
              checked={selectedPhoneOption == "Phone"}
              onChange={(e) => {
                setSelectedPhoneOption(e.target.value);
                handleAllFormChange(e);
              }}
            />
            Primary
          </label>
          <label className="mt-2">
            <input
              className="mr-2"
              disabled={mobileNumberAvailability ? false : true}
              name="persons.preferredPhone"
              //id="Demographic.preferredPhone"
              type="radio"
              value="Mobile"
              checked={selectedPhoneOption == "Mobile"}
              onChange={(e) => {
                setSelectedPhoneOption(e.target.value);
                handleAllFormChange(e);
              }}
            />
            Mobile
          </label>
          {/* </div>
        <div className="radio"> */}

          {/* </div> */}
        </Col>
      </Row>
    </Container>
  );
}

export default DemographicInfo;
