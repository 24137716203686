import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  handleUpdateClassesOnFormObjects,
  checkIfRelationisSelf,
  copySameAddressAsPatient,
  lookupDataFeed,
  updateDataToFields,
  removeErrorForDisabledDivs,
  displayInDateFormat,
  numberOfInsurancesInJSON,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { Form, Row, Col, Container } from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectBody from "./SelectBody";
import InputWithLoader from "./SearchInputFieldWithLoader";
import InputMask from "react-input-mask";
import { removeInsurance } from "./ManageInsuranceBlocks";
import {
  employerNameLength,
  middleNameLength,
  sampleObject,
} from "../../../config/config";
import WarningModel from "./../../../GlobalComponents/WarningModel";
import Spinner from "react-bootstrap/Spinner";
import { ApiGetCalls } from "../../../services/Api";
import { allInputField, addressFieldLimit } from "../../../config/config";
import MaskedInput from "react-text-mask";

function InsuranceInfo({
  patienDemographicForm,
  patientObj,
  datePickerStatus,
  setdatePickerStatus,
  lookUpData,
  insuranceFieldsReferenceNo,
  insuranceBlockList,
  setinsuranceBlockList,
  patientTabMode,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  insuranceCompainesValues,
  setInsuranceCompainesValues,
  handleAllFormChange,
  handleDateChange,
  setRelation,
  inputs,
  relation,
  setInputs,
  setSameAddressAsPatient,
  sameAddressAsPatient,
  primaryInsurance,
  setPrimaryInsurance,
}) {
  const [showResults, setShowResults] = useState(false);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState(0);
  const [filter, setFilter] = useState("");
  const [insurances, setInsurance] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const onConfirmationYes = async () => {
    setModalShow(false);
    await removeInsurance(
      inputs,
      insuranceFieldsReferenceNo * 1 - 1,
      setInputs
    );
    await setinsuranceBlockList(--insuranceBlockList);
    for (
      var i = insuranceFieldsReferenceNo * 1 - 1;
      i < numberOfInsurancesInJSON(sampleObject);
      i++
    ) {
      if (relation["patientInsurances." + (i + 1).toString()] != undefined) {
        relation["patientInsurances." + i.toString()] =
          relation["patientInsurances." + (i + 1).toString()];
      }
      if (
        sameAddressAsPatient["patientInsurances." + (i + 1).toString()] !=
        undefined
      ) {
        sameAddressAsPatient["patientInsurances." + i.toString()] =
          sameAddressAsPatient["patientInsurances." + (i + 1).toString()];
      }
    }
    relation[
      "patientInsurances." +
        (numberOfInsurancesInJSON(sampleObject) - 1).toString()
    ] = "";
    sameAddressAsPatient[
      "patientInsurances." +
        (numberOfInsurancesInJSON(sampleObject) - 1).toString()
    ] = false;
    await setRelation(relation);
    await setSameAddressAsPatient(sameAddressAsPatient);
    if (primaryInsurance == insuranceFieldsReferenceNo * 1 - 1) {
      await handleDateChange("Y", "patientInsurances.0.primaryInsurance");
    }
  };
  const rowClickHandler = (selectedInsuranceObj, id) => {
    setShowResults(false);
    setInsuranceCompanyId(id);
    setFilter(selectedInsuranceObj[1]);
    handleDateChange(
      id,
      "patientInsurances." +
        (insuranceFieldsReferenceNo * 1 - 1).toString() +
        ".insuranceCompanyId"
    );
    handleDateChange(
      selectedInsuranceObj[1],
      "patientInsurances." +
        (insuranceFieldsReferenceNo * 1 - 1).toString() +
        ".insuranceCompanies.name"
    );
  };

  const insurancesData = [];
  for (let i = 0; i < insurances.length; i++) {
    const insurancesDataObj = [
      insurances[i].id.toString(),
      insurances[i].name,
      insurances[i].payerId.toString(),
      insurances[i].billType !== null ? insurances[i].billType.toString() : "",
    ];
    insurancesData.push(insurancesDataObj);
  }
  useEffect(() => {
    if (Object.keys(inputs).length != 0) {
      if (inputs["persons.addresses.zip"] != undefined)
        setzipMask(
          inputs["persons.addresses.zip"] != undefined
            ? inputs["persons.addresses.zip"].length > 5
              ? "99999-9999"
              : "99999"
            : "99999"
        );
    }
    removeErrorForDisabledDivs(
      "InsuranceAccordion" + insuranceFieldsReferenceNo
    );
  }, [relation, sameAddressAsPatient]);
  const [zipMask, setzipMask] = React.useState(
    patientTabMode === "view"
      ? inputs[
          "patientInsurances." +
            (insuranceFieldsReferenceNo * 1 - 1) +
            ".persons.addresses.zip"
        ] == null
        ? "99999"
        : inputs[
            "patientInsurances." +
              (insuranceFieldsReferenceNo * 1 - 1) +
              ".persons.addresses.zip"
          ].length > 5
        ? "99999-9999"
        : "99999"
      : "99999"
  );
  const [dateValue, onDateChange] = useState("");
  useEffect(() => {
    setFilter(
      inputs[
        "patientInsurances." +
          (insuranceFieldsReferenceNo * 1 - 1).toString() +
          ".insuranceCompanies.name"
      ]
    );
    setInsuranceCompanyId(
      inputs[
        "patientInsurances." +
          (insuranceFieldsReferenceNo * 1 - 1).toString() +
          ".insuranceCompanyId"
      ]
    );
    if (
      relation[
        "patientInsurances." + (insuranceFieldsReferenceNo * 1 - 1).toString()
      ] == "self" ||
      sameAddressAsPatient[
        "patientInsurances." + (insuranceFieldsReferenceNo * 1 - 1).toString()
      ]
    ) {
      setzipMask(
        inputs["persons.addresses.zip"] != undefined
          ? inputs["persons.addresses.zip"].length > 5
            ? "99999-9999"
            : "99999"
          : "99999"
      );
    } else {
      setzipMask(
        inputs[
          "patientInsurances." +
            (insuranceFieldsReferenceNo * 1 - 1) +
            ".persons.addresses.zip"
        ] != undefined
          ? inputs[
              "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1) +
                ".persons.addresses.zip"
            ].length > 5
            ? "99999-9999"
            : "99999"
          : "99999"
      );
    }
  }, [inputs]);
  const [fetchingAddress, setFetchingAddress] = React.useState(false);
  function getAddressByZipCode(zipCode) {
    var filteredZipCode = zipCode.split("-");
    if (filteredZipCode[0] != "") {
      ApiGetCalls("GetAddressByZip/" + filteredZipCode[0]).then((data) => {
        if (data != {}) {
          setInputs({
            ...inputs,
            ["patientInsurances." +
            (insuranceFieldsReferenceNo * 1 - 1).toString() +
            ".persons.addresses.city"]: data?.City,
            ["patientInsurances." +
            (insuranceFieldsReferenceNo * 1 - 1).toString() +
            ".persons.addresses.state"]: data?.State,
          });
        }
        setFetchingAddress(false);
      });
    } else {
      setFetchingAddress(false);
    }
  }
  return (
    <>
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />
      <Container className="label-style parentcontainer">
        <Row>
          <Col md={{ span: 12 }} className="mandatory ">
            <Form.Check
              type="switch"
              reverse
              label="Primary Insurance"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".primaryInsurance"
              }
              value={insuranceFieldsReferenceNo == 1 ? "Y" : "N"}
              onChange={() => {}}
              onClick={() => {
                handleDateChange("N", "patientInsurances.0.primaryInsurance");
                handleDateChange("N", "patientInsurances.1.primaryInsurance");
                handleDateChange("N", "patientInsurances.2.primaryInsurance");
                handleDateChange("N", "patientInsurances.3.primaryInsurance");

                handleDateChange(
                  "Y",
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".primaryInsurance"
                );
              }}
              id={"primaryInsurance" + insuranceFieldsReferenceNo}
              checked={primaryInsurance == insuranceFieldsReferenceNo * 1 - 1}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory InsuranceCompanies">
            {/* <input
            type="radio"
            name={
              "patientInsurances." +
              (insuranceFieldsReferenceNo * 1 - 1).toString() +
              ".primaryInsurance"
            }
         
            value={insuranceFieldsReferenceNo == 1 ? "Y" : "N"}
            onClick={() => {
              handleDateChange(
                "Y",
                "patientInsurances." +
                  (insuranceFieldsReferenceNo * 1 - 1).toString() +
                  ".primaryInsurance"
              );
            }}
            id={"primaryInsurance" + insuranceFieldsReferenceNo}
            checked
          /> */}
            {/* {patienDemographicForm == "data" && (
              <input
                type="text"
                name="id"
                required
                value={
                  patientObj.length !== 0
                    ? patientObj?.patientInsurances[0]?.id
                    : ""
                }
                className="input-style form-control"
                hidden
              />
            )} */}
            <h5 className="label-style">Insurance</h5>

            <input
              type="hidden"
              name="InsuranceCompanyId"
              id={"Insurance" + insuranceFieldsReferenceNo + ".InsuranceId"}
              value={insuranceCompanyId}
              className="input-style form-control insurance-root"
            />
            <InputWithLoader
              id={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".insuranceCompanyId"
              }
              filter={filter}
              placeholder="Start typing..."
              apiEndPoint="GetInsuranceCompaniesByName/"
              setData={setInsurance}
              setShowResults={setShowResults}
              setFilter={setFilter}
              name="insuranceCompanies"
              aria_label="Insurance company name"
              className="input-style form-control"
              selectedId={insuranceCompanyId}
              required={true}
              data_type="text"
              data_jsonMapping={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".insuranceCompanies.name"
              }
              onChange={handleDateChange}
              onBlur={() => {
                if (
                  filter !=
                  inputs[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".insuranceCompanies.name"
                  ]
                ) {
                  setFilter("");
                }
              }}
            />
            {showResults ? (
              <SelectBody
                rowClickHandler={rowClickHandler}
                filter={filter}
                column={["Name", "Payer Id", "Bill Type"]}
                dataArray={insurancesData}
                setShowResults={setShowResults}
              />
            ) : null}
          </Col>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Policy ID</h5>
            <input
              aria-label="Policy id"
              maxLength={allInputField}
              type="text"
              data-type="text"
              className="input-style form-control insurance-root"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".policyId"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".policyId"}
              required
              onChange={handleAllFormChange}
              value={
                inputs[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".policyId"
                ]
              }
              autoComplete="off"
            />
          </Col>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Relation</h5>

            <Form.Select
              onChange={(e) => {
                if (e.target.value == 31) {
                  setRelation({
                    ...relation,
                    ["patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()]: "self",
                  });
                  setSameAddressAsPatient({
                    ...sameAddressAsPatient,
                    ["patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()]: false,
                  });
                } else {
                  if (
                    relation[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString()
                    ] == "self"
                  ) {
                    let updateFieldsState = {
                      ...inputs,
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.lastName"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.firstName"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.middleName"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.sex"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.dob"]: null,
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.street1"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.street2"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.zip"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.state"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.city"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.phone"]: "",
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      "employerName"]: "",
                    };
                    setInputs(updateFieldsState);
                  }
                  relation[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString()
                  ] = "";
                  setRelation(relation);
                }
                // checkIfRelationisSelf(
                //   e.target.value,
                //   "Insurance" + insuranceFieldsReferenceNo
                // );
                // removeErrorForDisabledDivs(
                //   "InsuranceAccordion" + insuranceFieldsReferenceNo
                // );
                handleAllFormChange(e);
              }}
              required
              data-type="text"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".relation"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".relation"}
              aria-label="Relation"
              className="input-style form-control insurance-root"
              value={
                inputs[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".relation"
                ]
              }
              autoComplete="off"
            >
              <option
                key={"Ireation" + insuranceFieldsReferenceNo}
                value=""
              ></option>
              {lookUpData.relationData?.map((value, Index) => (
                <option key={Index} value={value.id}>
                  {value.options}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Last Name</h5>
            <input
              aria-label="Last name"
              maxLength={allInputField}
              type="text"
              data-type="text"
              className="input-style form-control insurance.persons"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.lastName"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".lastName"}
              required
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.lastName"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.lastName"
                    ]
              }
              autoComplete="off"
            />
          </Col>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">First Name</h5>
            <input
              aria-label="First name"
              maxLength={allInputField}
              type="text"
              data-type="text"
              className="input-style form-control insurance.persons"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.firstName"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".firstName"}
              required
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.firstName"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.firstName"
                    ]
              }
              autoComplete="off"
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
            />
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Middle Initial</h5>
            <input
              aria-label="Middle initial"
              maxLength={middleNameLength}
              type="text"
              data-type="text"
              className="input-style form-control insurance.persons"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.middleName"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".middleName"}
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.middleName"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.middleName"
                    ]
              }
              autoComplete="off"
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Gender</h5>
            <Form.Select
              required
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.sex"
              }
              data-type="text"
              id={"Insurance" + insuranceFieldsReferenceNo + ".sex"}
              aria-label="Sex"
              className="input-style form-control insurance.persons"
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.sex"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.sex"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
              autoComplete="off"
            >
              <option
                key={"isex" + insuranceFieldsReferenceNo}
                value=""
              ></option>
              {lookUpData.sexData?.map((value, Index) => (
                <option key={Index} value={value.id}>
                  {value.options}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Group ID</h5>
            <input
              aria-label="Group id"
              maxLength={allInputField}
              type="text"
              data-type="text"
              className="input-style form-control insurance-root"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".groupId"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".groupId"}
              onChange={handleAllFormChange}
              value={
                inputs[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".groupId"
                ]
              }
              autoComplete="off"
              // disabled={
              //   relation[
              //     "patientInsurances." +
              //       (insuranceFieldsReferenceNo * 1 - 1).toString()
              //   ] !== undefined
              //     ? true
              //     : false
              // }
            />
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Group Name</h5>
            <input
              aria-label="Group name"
              maxLength={allInputField}
              data-type="text"
              type="text"
              className="input-style form-control insurance-root"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".groupName"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".groupName"}
              onChange={handleAllFormChange}
              value={
                inputs[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".groupName"
                ]
              }
              autoComplete="off"
              // disabled={
              //   relation[
              //     "patientInsurances." +
              //       (insuranceFieldsReferenceNo * 1 - 1).toString()
              //   ] !== undefined
              //     ? true
              //     : false
              // }
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Date of Birth</h5>

            <DatePicker
              preventOpenOnFocus={true}
              className="input-style form-control insurance.persons"
              ariaLabelledBy="Date of birth"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                "persons.dob"
              }
              data-type="text"
              id={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1) +
                ".persons.dob"
              }
              maxDate={new Date()}
              selected={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.dob"] != undefined
                    ? new Date(inputs["persons.dob"])
                    : null
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.dob"
                    ] != undefined && inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.dob"
                    ] != ''
                  ? new Date(
                      inputs[
                        "patientInsurances." +
                          (insuranceFieldsReferenceNo * 1 - 1).toString() +
                          ".persons.dob"
                      ]
                    )
                  : null
              }
              dateFormat="MM/dd/yyyy"
              onChange={(date) => {
                handleDateChange(
                  date != null
                    ? moment(date).format("yyyy-MM-DDT00:00:00")
                    : null,
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString() +
                    ".persons.dob"
                );
              }}
              required
              showMonthDropdown={true}
              showYearDropdown={true}
              dropdownMode="select"
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
              autoComplete="off"
              customInput={
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder={"mm/dd/yyyy"}
                  keepCharPositions={true}
                  guide={true}
                />
              }
            />
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Employer Name</h5>
            <input
              aria-label="Employer name"
              maxLength={employerNameLength}
              data-type="text"
              type="text"
              className="input-style form-control insurance-root"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".employerName"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".employerName"}
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" && relation["guarantors"] == "self"
                  ? inputs["guarantors.employer"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".employerName"
                    ]
                // inputs[
                //   "patientInsurances." +
                //     (insuranceFieldsReferenceNo * 1 - 1).toString() +
                //     ".employerName"
                // ]
              }
              autoComplete="off"
              // disabled={
              //   relation[
              //     "patientInsurances." +
              //       (insuranceFieldsReferenceNo * 1 - 1).toString()
              //   ] !== undefined
              //     ? true
              //     : false
              // }
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control insurance.addresses"
                name={
                  "patientInsurances." +
                  (insuranceFieldsReferenceNo * 1 - 1).toString() +
                  ".persons.addresses.useSameAddressAsPatient"
                }
                id={
                  "Insurance" +
                  insuranceFieldsReferenceNo +
                  ".useSameAddressAsPatient"
                }
                data-status="UN-CHECKED"
                onClick={(e) => {
                  if (e.target.checked == true) {
                    setSameAddressAsPatient({
                      ...sameAddressAsPatient,
                      ["patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString()]: true,
                    });
                  } else {
                    if (
                      sameAddressAsPatient[
                        "patientInsurances." +
                          (insuranceFieldsReferenceNo * 1 - 1).toString()
                      ]
                    ) {
                      let updateFieldsState = {
                        ...inputs,

                        ["patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.street1"]: "",
                        ["patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.street2"]: "",
                        ["patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.zip"]: "",
                        ["patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.state"]: "",
                        ["patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.city"]: "",
                      };
                      setInputs(updateFieldsState);
                    }
                    sameAddressAsPatient[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString()
                    ] = false;
                    setSameAddressAsPatient(sameAddressAsPatient);
                  }
                  // removeErrorForDisabledDivs(
                  //   "InsuranceAccordion" + insuranceFieldsReferenceNo
                  // );
                }}
                onChange={handleAllFormChange}
                disabled={
                  relation[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString()
                  ] == "self"
                    ? true
                    : false
                }
                checked={
                  sameAddressAsPatient[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString()
                  ]
                }
              />{" "}
              <span>Same address as patient</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Street 1</h5>
            <input
              type="text"
              data-type="text"
              aria-label="Street 1"
              maxLength={addressFieldLimit}
              className="input-style form-control insurance.addresses"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.addresses.street1"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".street1"}
              required
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
                  ? inputs["persons.addresses.street1"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.street1"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
              }
              autoComplete="off"
            />
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Street 2</h5>
            <input
              autoComplete="off"
              type="text"
              data-type="text"
              aria-label="Street 2"
              maxLength={addressFieldLimit}
              className="input-style form-control insurance.addresses"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.addresses.street2"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".street2"}
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
                  ? inputs["persons.addresses.street2"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.street2"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
              }
            />
          </Col>

          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">Zip/Postal Code</h5>
            <InputMask
              mask={zipMask}
              maskChar=""
              aria-label="Zip"
              data-type="text"
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
                  ? inputs["persons.addresses.zip"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.zip"
                    ]
              }
              type="text"
              className="input-style form-control insurance.addresses validate_zip"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.addresses.zip"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".zip"}
              required
              onKeyDown={(event) => {
                if (
                  (event.keyCode >= 48 && event.keyCode <= 57) ||
                  (event.keyCode >= 96 && event.keyCode <= 105)
                ) {
                  let reGexForZip = /(^\d{5})/;
                  if (reGexForZip.test(event.target.value)) {
                    setzipMask("99999-9999");
                  } else {
                    setzipMask("99999");
                  }
                }
              }}
              onChange={handleAllFormChange}
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
              }
              onBlur={(event) => {
                if (event.target.value.split("-")[1] == "") {
                  setzipMask("99999");
                }
                setFetchingAddress(true);
                getAddressByZipCode(event.target.value);
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">City</h5>
            <input
              aria-label="City"
              data-type="text"
              type="text"
              maxLength={allInputField}
              className="input-style form-control insurance.addresses"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.addresses.city"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".city"}
              required
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
                  ? inputs["persons.addresses.city"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.city"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
              }
              autoComplete="off"
            />
            {fetchingAddress ? (
              <Spinner
                className="spinner_field"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Col>
          <Col md={{ span: 4 }} className="mandatory">
            <h5 className="label-style">State/Province/Region</h5>
            <input
              aria-label="State"
              type="text"
              data-type="text"
              maxLength={allInputField}
              className="input-style form-control insurance.addresses"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.addresses.state"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".state"}
              required
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
                  ? inputs["persons.addresses.state"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.addresses.state"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self" ||
                sameAddressAsPatient[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ]
              }
              autoComplete="off"
            />
            {fetchingAddress ? (
              <Spinner
                className="spinner_field"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Col>
          <Col md={{ span: 4 }}>
            <h5 className="label-style">Primary Phone #</h5>
            <InputMask
              mask="+1\ (999) 999-9999"
              maskChar=" "
              aria-label="Primary phone"
              type="text"
              data-type="text"
              // defaultValue={
              //   patientTabMode === "view"
              //     ? phoneValues[
              //         "patientInsurances." +
              //           (insuranceFieldsReferenceNo * 1 - 1) +
              //           ".persons.phone"
              //       ] == null
              //       ? null
              //       : phoneValues[
              //           "patientInsurances." +
              //             (insuranceFieldsReferenceNo * 1 - 1) +
              //             ".persons.phone"
              //         ]
              //     : null
              // }
              className="input-style form-control insurance.persons validate_mobile"
              name={
                "patientInsurances." +
                (insuranceFieldsReferenceNo * 1 - 1).toString() +
                ".persons.phone"
              }
              id={"Insurance" + insuranceFieldsReferenceNo + ".phone"}
              onChange={handleAllFormChange}
              value={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? inputs["persons.phone"]
                  : inputs[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.phone"
                    ]
              }
              disabled={
                relation[
                  "patientInsurances." +
                    (insuranceFieldsReferenceNo * 1 - 1).toString()
                ] == "self"
                  ? true
                  : false
              }
              autoComplete="off"
            />
          </Col>
        </Row>
        <div className="col-align-right">
          {insuranceFieldsReferenceNo * 1 === insuranceBlockList &&
            insuranceBlockList < 3 && (
              <button
                onClick={() => {
                  setinsuranceBlockList(++insuranceBlockList);
                }}
                className="primary btn button"
              >
                Add Insurance
              </button>
            )}{" "}
          &nbsp;{" "}
          {insuranceBlockList > 1 &&
            insuranceFieldsReferenceNo * 1 - 1 != primaryInsurance && (
              <button
                onClick={async () => {
                  setModalShow(true);
                }}
                className="primary btn button"
              >
                Remove Insurance
              </button>
            )}
        </div>
      </Container>
    </>
  );
}

export default InsuranceInfo;
