import React, { useState, useEffect } from "react";
import CustomNavBar from "../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../GlobalComponents/mobileNavBar";
import CustomFooter from "../GlobalComponents/Footer";
import { useNavigate, useParams } from "react-router-dom";

import "./OrderPage/OrderPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  ApiGetCalls,
  ApiGetCallsWithOutToken,
  downloadResultFile,
} from "../services/Api";
import InputMask from "react-input-mask";
import moment from "moment/moment";
import { snackBar } from "../services/Alert";
import CustomLoader from "../GlobalComponents/Loader";
import { handleValidation } from "../GlobalComponents/ValidatorAndSubmitForm";

function DownloadResultPdf(props) {
  const navigate = useNavigate();
  var { id } = useParams();
  const [checkGuid, setCheckGuid] = useState("");
  const [dob, setDob] = useState("");
  useEffect(() => {
    document.title = "ATVIVO - Result Pdf";
    if (id != undefined) {
      setLoader(true);
      verifyId();
      setFormValues({ guid: id, dob: "", firstName: "", lastName: "" });
    }
  }, []);
  const verifyId = async () => {
    const response = await ApiGetCallsWithOutToken("GetResultGuid/" + id);
    if (response.error != undefined && response.error.status != undefined) {
      setCheckGuid("false");
      setLoader(false);
    } else {
      if (response.length > 0) {
        setCheckGuid("true");
        setLoader(false);
      } else {
        setCheckGuid("false");
        setLoader(false);
      }
    }
  };
  const [formValues, setFormValues] = useState({
    guid: "",
    dob: "",
    firstName: "",
    lastName: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "dob") {
      // Extract month, day, and year
      const [month, day, year] = value.split("-");

      // Check if month is greater than 12, and if so, restrict it to 12
      // let sanitizedMonth = parseInt(month, 10);
      // sanitizedMonth = Math.min(Math.max(sanitizedMonth, 1), 12);

      // Convert sanitizedMonth back to a string with a leading zero if necessary
      // const monthString = sanitizedMonth.toString();//.padStart(2, "0");

      // Construct the new date value in "MM-DD-YYYY" format
      const newValue = `${year}-${month}-${day}`;
      setFormValues({
        ...formValues,
        [name]: newValue,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const clickHandle = async (e) => {
    var validation = handleValidation("downloadPdf");
    if (validation) {
      if (moment(formValues.dob, "YYYY-MM-DD", true).isValid()) {
        setLoader(true);
        const response = await downloadResultFile(
          "AuthenticatePatient",
          JSON.stringify(formValues)
        );
        if (response) {
          if (response.status === 401 || response.status === 403) {
            setCheckGuid("false");
          }
        }
      } else {
        snackBar("Error! Invalid dob.", "danger");
      }
    } else {
      snackBar(
        "Error! Could not download result pdf, please fix the indicated errors.",
        "danger"
      );
    }
    setLoader(false);
  };
  const [showLoader, setLoader] = useState(false);
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      clickHandle();
    }
  };
  return (
    <div>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        screenLogin={true}
        patientDetails={{ patientName: "-" }}
        sexLookup=""
      />
      <CollapsibleMobileNav
        screenLogin={true}
        patientDetails={{ patientName: "-" }}
        sexLookup=""
      />
      <div id="downloadPdf" className="pageheight login">
        <Container className="logincontainer">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Download Result Pdf
            </h3>

            <hr></hr>
            {checkGuid != "" ? (
              checkGuid == "true" ? (
                <>
                  <Row className="mb-2 mt-4">
                    {
                      <Col md={{ span: 12 }}>
                        <h5 className="label-style mandatory">First Name *</h5>
                        <input
                          required
                          name="firstName"
                          aria-label="First name"
                          value={formValues.firstName}
                          className="input-style form-control"
                          onChange={handleChange}
                          onKeyDown={handleKeypress}
                        />
                      </Col>
                    }
                  </Row>
                  <Row className="mb-2">
                    {
                      <Col md={{ span: 12 }}>
                        <h5 className="label-style mandatory">Last Name *</h5>
                        <input
                          required
                          name="lastName"
                          aria-label="Last name"
                          value={formValues.lastName}
                          className="input-style form-control"
                          onChange={handleChange}
                          onKeyDown={handleKeypress}
                        />
                      </Col>
                    }
                  </Row>
                  <Row className="mb-2">
                    {
                      <Col md={{ span: 12 }}>
                        <h5 className="label-style">Dob *</h5>
                        <InputMask
                          placeholder="MM-DD-YYYY"
                          required
                          name="dob"
                          aria-label="Dob"
                          mask="99-99-9999" // This mask enforces the MM-DD-YYYY format
                          value={dob}
                          className="input-style form-control"
                          onChange={(e) => {
                            setDob(e.target.value);
                            handleChange(e);
                          }}
                          onKeyDown={handleKeypress}
                        />
                      </Col>
                    }
                  </Row>
                  <Row>
                    <Col
                      md={{ span: 12 }}
                      className="d-flex flex-row align-items-end align-content-end "
                    >
                      <Button
                        onClick={clickHandle}
                        variant="primary"
                        className="primary btn button ml-auto"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <h4>Link Expired</h4>
              )
            ) : (
              <h4>Processing...</h4>
            )}
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default DownloadResultPdf;
