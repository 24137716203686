import React from "react";
import { Col } from "react-bootstrap";
function FilterSelectData({ filteredData }) {
  const columnData = [];
  for (let i = 1; i < filteredData.length; i++) {
    columnData.push(
      <Col
        className="searchResultcol"
        key={"filterSelectDataCol_" + i.toString()}
      >
        {filteredData[i]}
      </Col>
    );
  }
  return columnData;
}
export default FilterSelectData;
