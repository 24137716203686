import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import PatientTab from "../../OrderPage/Components/PatientTab";
import CustomNavBar from "../../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../../GlobalComponents//mobileNavBar";
import "../../OrderPage/OrderPage.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginInfoContext, LoginUserLocationContext } from "../../../Context";
import CustomFooter from "../../../GlobalComponents/Footer";

function AddPatient(props) {
  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});
  const [patientInUpdateMode, setPatientInUpdateMode] = useState(false);
  const [orderData, setOrderData] = useState({ newPatient: "Y" });
  const [clinicalInputs, setClinicalInputs] = useState({});
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [patientDetails, setPatientDetails] = useState({
    patientId: "0",
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  var { id } = useParams();
  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - New Patient";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    }
    // fetchlocationBasedDataOnLoad();
  }, []);

  const handleClinicalFormChange = (name, value) => {
    setClinicalInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div onClick={() => setShowResults(false)}>
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div
        className={
          patientDetails.patientName != "-"
            ? "pageheight"
            : "pageheight resultsPage"
        }
      >
        <Container className="container">
          <PatientTab
            showResults={showResults}
            setShowResults={setShowResults}
            setPatientDetails={setPatientDetails}
            patientDetails={patientDetails}
            setSexLookup={setSexLookup}
            sexLookup={sexLookup}
            handleClinicalFormChange={handleClinicalFormChange}
            orderData={orderData}
            selectedOrderPatientId={props.selectedOrderPatientId}
            setPatientInUpdateMode={setPatientInUpdateMode}
            orderId={id}
            patientId={id}
            createPatient={"Y"}
            setSelectedOrderPatientId={props.setSelectedOrderPatientId}
            facilityId={selectedLocation}
          />
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default AddPatient;
