import React, { useState, useEffect, useContext } from "react";
import CustomNavBar from "../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../GlobalComponents/mobileNavBar";
import CustomFooter from "../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import { ApiPostCalls } from "../services/Api";
import "./OrderPage/OrderPage.css";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { snackBar } from "../services/Alert";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ChangePassword(props) {
  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Download";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    }
  }, []);

  var context = JSON.parse(localStorage.getItem("userInfo"));

  var nameAbbrArray = [];
  if (context != undefined) {
    nameAbbrArray = context.userFullName?.split(" ");
  }
  var nameAbbr = "";
  if (
    nameAbbrArray != undefined &&
    nameAbbrArray[0] != undefined &&
    nameAbbrArray[1] != undefined
  ) {
    nameAbbr = nameAbbrArray[1][0] + nameAbbrArray[0][0];
  }
  const [sexLookup, setSexLookup] = useState({});
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const handleSave = async () => {
    if (oldPassword != "" && newPassword != "" && confirmPassword != "") {
      if (newPassword == confirmPassword) {
        const jsonValues = {
          userId: localStorage.getItem("userId"),
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        const response = await ApiPostCalls(
          JSON.stringify(jsonValues),
          "changePassword"
        );
        if (response.status == "Success") {
          if (response.data.succeeded) {
            snackBar("Success! Password updated successfully", "success");
            localStorage.setItem("firstLogin", "Yes");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            navigate(-1);
          } else if (response.data.errors[0].code == "PasswordMismatch") {
            snackBar(
              "Error! Your old password does not match with the password you provided. Please try again",
              "danger"
            );
          } else {
            snackBar("Error! The password format is invalid.", "danger");
          }
        } else {
          snackBar("Error! Unable to updated password", "danger");
        }
      } else {
        snackBar("Error! The password confirmation does not match.", "danger");
      }
    }
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };
  return (
    <div>
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="patients-list-pageheight pb-3">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Change Password
              {/* <Button variant="primary" className="primary btn button ml-auto">
                Edit Profile Info
              </Button> */}
            </h3>
            <hr></hr>

            <Container>
              {localStorage.getItem("firstLogin") == "No" && 
              <div className="alert alert-warning mb-5">You must change your password before continuing.</div>
            }
              <Row>
                {/* <Col md={{ span: 4 }}>
                  <div className="profileiconbigger">{nameAbbr}</div>
                </Col> */}

                <Col md={{ span: 6 }}>
                  <div>
                    <h5 className="label-style">Old Password</h5>
                    {/* <p>{fullName}</p> */}
                    <InputGroup className="input-style">
                      <Form.Control
                        name="oldPassword"
                        id="oldPassword"
                        aria-label="OldPassword"
                        required
                        data-type="text"
                        value={oldPassword}
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                        type={oldPasswordShown ? "text" : "password"}
                        autoComplete="off"
                        onKeyDown={handleKeypress}
                      />
                      <Button
                        onClick={() => {
                          setOldPasswordShown(oldPasswordShown ? false : true);
                        }}
                        variant="outline-secondary"
                        className="mb-3"
                        id="button-addon2"
                      >
                        {oldPasswordShown ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputGroup>
                  </div>

                  <div>
                    <h5 className="label-style">New Password</h5>
                    {/* <p>{fullName}</p> */}
                    <InputGroup className="input-style">
                      <Form.Control
                        name="newPassword"
                        id="newPassword"
                        aria-label="newPassword"
                        required
                        data-type="text"
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        type={newPasswordShown ? "text" : "password"}
                        autoComplete="off"
                        onKeyDown={handleKeypress}
                      />
                      <Button
                        onClick={() => {
                          setNewPasswordShown(newPasswordShown ? false : true);
                        }}
                        variant="outline-secondary"
                        className="mb-3"
                        id="button-addon2"
                      >
                        {newPasswordShown ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputGroup>
                  </div>

                  <div>
                    <h5 className="label-style">Confirm Password</h5>
                    {/* <p>{fullName}</p> */}
                    <InputGroup className="input-style">
                      <Form.Control
                        name="confirmPassword"
                        id="confirmPassword"
                        aria-label="confirmPassword"
                        required
                        data-type="text"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        type={confirmPasswordShown ? "text" : "password"}
                        autoComplete="off"
                        onKeyDown={handleKeypress}
                      />
                      <Button
                        onClick={() => {
                          setConfirmPasswordShown(
                            confirmPasswordShown ? false : true
                          );
                        }}
                        variant="outline-secondary"
                        className="mb-3"
                        id="button-addon2"
                      >
                        {confirmPasswordShown ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputGroup>
                  </div>
                </Col>
                <Col md={{ span: 6 }}>
                  <div className="spacingdivfade alert alert-info show">
                    <div className="alert-heading h4">
                      <span className="admonitionIcon_Ibzs"></span>Instructions
                    </div>
                    <div className="admonitionContent_vXIg">
                      <p>
                        Passwords must be at least eight characters long and
                        must contain at least:
                      </p>
                      <ul>
                        <li>one upper-case letter</li>
                        <li>one lower-case letter</li>
                        <li>one number</li>
                        <li>one of these special characters: @$!%*#?&</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <span className="float-right">
                  <Button
                    disabled={
                      oldPassword != "" &&
                      newPassword != "" &&
                      confirmPassword != ""
                        ? false
                        : true
                    }
                    variant="primary"
                    className="primary btn button float-right ml-2 "
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="primary"
                    className="outline button btn btn-outline float-right"
                    onClick={() => {
                      if(localStorage.getItem("firstLogin") == "No"){
                        localStorage.clear();
                          navigate("/");
                      }else{
                      navigate(-1);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </span>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default ChangePassword;
