import axios from "axios";
import { apiUrl } from "../config/config";
import { snackBar } from "./Alert";
import { redirect } from "react-router-dom";

export async function authenticate(data, endPoint) {
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await axios
    .post(apiUrl + endPoint, data, { headers })
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return false;
    });
  return response;
}

export async function ApiGetCalls(endPoint, loginScreen = false) {
  try {
    const headers = withAuth(loginScreen);

    const options = {
      headers: headers,
    };

    const response = await axios.get(apiUrl + endPoint, options);
    const data = response.data;

    if (data == "Password not updated.") {
      localStorage.setItem("firstLogin", "No");
      window.location = "#/changePassword";
    } else {
      return data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      window.location = "/";
    }
  }
}
export async function ApiGetCallsWithOutToken(endPoint) {
  const options = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(apiUrl + endPoint, options);
    const data = response.data;
    if (data == "Password not updated.") {
      localStorage.setItem("firstLogin", "No");
      window.location = "#/changePassword";
    } else {
      return data;
    }
  } catch (error) {
    // Handle the error, e.g., log it or perform specific actions
    if (error.response && error.response.status === 500) {
      return {
        data: null,
        error: { status: 500, message: "Internal Server Error" },
      };
    } else {
      return {
        data: null,
        error: { status: 500, message: "Internal Server Error" },
      };
    }

    // You might choose to rethrow the error or return a default value
    throw error;
  }
}
export async function downloadResultFile(endPoint, data) {
  var requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Response-Type": "blob",
    },
    body: data,
  };

  try {
    const response = await fetch(apiUrl + endPoint, requestOptions);

    if (response.status.toString() === "200") {
      const blob = await response.blob();
      if (blob != null) {
        // Get the current date and time in a specific format
        var today = new Date();
        var fileNameWithDateString =
          today.getFullYear().toString() +
          (today.getMonth() + 1).toString().padStart(2, "0") +
          today.getDate().toString() +
          today.getHours().toString() +
          today.getMinutes().toString() +
          today.getSeconds().toString();
        snackBar("Success! File downloaded successfully.", "success");
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          "result_" + fileNameWithDateString + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        return response;
      } else {
        snackBar("Error! File not received.", "danger");
        return response;
      }
    } else if (response.status === 401 || response.status === 403) {
      // Handle the link expiration scenario here
      snackBar("Error! Link has expired.", "danger");
      return response;
    } else if (response.status === 404) {
      // Handle the file not found scenario here
      snackBar("Error! File not found.", "danger");
      return response;
    } else {
      snackBar("Error! Authentication failed.", "danger");
    }
  } catch (error) {
    snackBar("Error! Something went wrong.", "danger");
  }
}
export async function downloadFile(endPoint) {
  var requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Response-Type": "blob",
    },
  };
  const response = await fetch(apiUrl + endPoint, requestOptions)
    .then(async (response) => {
      if (response.status.toString() == "200") {
        return response.blob();
      }
    })
    .then(async (blob) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        // setPdfBlob(base64data);
        document
          .getElementById("resultPreviewBlob")
          .setAttribute("src", base64data);
        var a = document.createElement("a");
        a.setAttribute("data-bs-toggle", "modal");
        a.setAttribute("data-bs-target", "#ResultPreviewModal");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      };
    });
}

function withAuth(loginScreen, headers) {
  const token = localStorage.getItem("token");
  const selectedLocation = localStorage.getItem("selectedLocation");

  if (!token || (!loginScreen && !selectedLocation)) {
    localStorage.removeItem("locations");
    localStorage.removeItem("token");
    localStorage.removeItem("selectedLocation");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userPermissions");
    localStorage.removeItem("selectableLocations");
    localStorage.removeItem("userId");
    window.location = `${window.location.origin}/#/`;
    return;
  }

  if (!headers) {
    headers = {
      "Content-Type": "application/json",
    };
  }

  headers.Authorization = `Bearer ${token}`;

  return headers;
}

export async function ApiPostCalls(data, endPoint, loginScreen = false) {
  try {
    const headers = withAuth(loginScreen);
    const response = await axios.post(apiUrl + endPoint, data, { headers });
    if (response.data == "Password not updated.") {
      localStorage.setItem("firstLogin", "No");
      window.location = "#/changePassword";
    } else {
      return response.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      window.location = "/";
    }
  }
}

// export async function ApiPostFileCalls(data, endPoint) {
//   const response = await axios.post(apiUrl + endPoint, data, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       // Add any other required headers
//     },
//   });
//   return response.data;
// }
export async function ApiPostFileCalls(data, endPoint) {
  var token = localStorage.getItem("token");
  const response = await axios.post(apiUrl + endPoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
      // Add any other required headers
    },
  });
  return response.data;
}

export async function renewToken() {
  const accessToken = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const headers = {
    "Content-Type": "application/json",
  };
  if (!refreshToken) throw new Error("refresh token does not exist");

  const refreshPayload = JSON.stringify({
    accessToken: accessToken,
    refreshToken: refreshToken,
  });

  const response = await axios.post(apiUrl + "refresh-token", refreshPayload, {
    headers,
  });
  const token = response.data.accessToken;

  const newRefreshToken = response.data.refreshToken;

  return [token, newRefreshToken];
}

function isUnauthorizedError(error) {
  const {
    response: { status, statusText },
  } = error;
  return status === 401;
}

let refreshingFunc = undefined;

axios.interceptors.response.use(
  (res) => res,
  async (error) => {
    // if(error.status == 403)
    // {
    //   console.log('subhan');
    //   window.location = '/';
    // }
    const originalConfig = error.config;
    const token = localStorage.getItem("token");

    // if we don't have token in local storage or error is not 401 just return error and break req.
    if (!token || !isUnauthorizedError(error)) {
      return Promise.reject(error);
    }

    try {
      // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
      if (!refreshingFunc) refreshingFunc = renewToken();

      const [newToken, newRefreshToken] = await refreshingFunc;

      localStorage.setItem("token", newToken);
      localStorage.setItem("refreshToken", newRefreshToken);

      originalConfig.headers.Authorization = `Bearer ${newToken}`;

      // retry original request
      try {
        return await axios.request(originalConfig);
      } catch (innerError) {
        // if original req failed with 401 again - it means server returned not valid token for refresh request
        if (isUnauthorizedError(innerError)) {
          throw innerError;
        }
      }
    } catch (err) {
      localStorage.removeItem("locations");
      localStorage.removeItem("token");
      localStorage.removeItem("selectedLocation");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userPermissions");
      localStorage.removeItem("selectableLocations");
      localStorage.removeItem("userId");

      window.location = `${window.location.origin}/#/`;
    } finally {
      refreshingFunc = undefined;
    }
  }
);
