import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaHome, FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ApiGetCalls } from "../services/Api";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LoginInfoContext, LoginUserLocationContext } from "../Context";
import WarningModel from "./WarningModel";
import SearchFacility from "../Pages/OrderPage/Components/SearchFacilityModel";
import { facilitiesListLimit } from "../config/config";
import dateFormat from "dateformat";
import { Row, Col, Table, Form, Button, Accordion } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import moment from "moment/moment";

function CollapsibleMobileNav({
  patientDetails,
  sexLookup,
  setSelectedOrderPatientId,
  selectedOrderPatientId,
  setSavedOrderId,
  screenLogin,
  showNavBar,
  detailData,
  lookupSexData,
  LookupText,
  age,
  open,
  setOpen,
  savedOrderId,
}) {
  var sexString = "";
  const navigate = useNavigate();
  const [addNewOrderState, setAddNewOrderState] = useState(false);
  const clickHandle = (e) => {
    navigate("/patients");
  };
  const [context, setContext] = useContext(LoginInfoContext);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [searchFacilityModel, setSearchFacilityModel] = useState(false);
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [locations, setLocations] = useState([]);
  var nameAbbr = "";
  if (context.userInfo != undefined) {
    nameAbbr =
      context.userInfo.userLastName[0].toUpperCase() +
      context.userInfo.userFirstName[0].toUpperCase();
  }

  const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

  useEffect(() => {
    if (
      localStorage.getItem("userPermissions") != null &&
      JSON.parse(localStorage.getItem("userPermissions")).length > 0
    ) {
      JSON.parse(localStorage.getItem("userPermissions")).map(
        (value, index) => {
          if (value.facilityId == selectedLocation) {
            if (value.featureName == "Orders") {
              if (value.createFeature == "N") {
                setAddNewOrderState(true);
              }
            }
          }
        }
      );
    }
    if (localStorage.getItem("selectableLocations") != null) {
      setLocations(JSON.parse(localStorage.getItem("selectableLocations")));
    }
  }, []);
  useEffect(() => {
    setAddNewOrderState(false);
    if (
      localStorage.getItem("userPermissions") != null &&
      JSON.parse(localStorage.getItem("userPermissions")).length > 0
    ) {
      JSON.parse(localStorage.getItem("userPermissions")).map(
        (value, index) => {
          if (value.facilityId == selectedLocation) {
            if (value.featureName == "Orders") {
              if (value.createFeature == "N") {
                setAddNewOrderState(true);
              }
            }
          }
        }
      );
    }
  }, [selectedLocation]);
  return (
    <>
      <header className="showmobile">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="navbar navbar-light sticky-top showmobile"
        >
          <Container>
            <Navbar.Brand>
              <img src="/atvivo-logo.png" />
            </Navbar.Brand>
            {!screenLogin && (
              <div className="toplinks ml-auto mt-2">
                {locations != undefined && locations.length >= 2 ? (
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={locations?.map((x) => {
                      if (x.id == selectedLocation) {
                        return x.label;
                      }
                    })}
                    menuVariant="dark"
                  >
                    {locations
                      ?.filter((x) => x.id != selectedLocation)
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .slice(0, facilitiesListLimit)
                      .map((value, index) => (
                        <NavDropdown.Item
                          // className={ ((index % 2)==1)?"navlink":""}
                          key={index}
                          onClick={() => {
                            setSelectedFacilityId(value.id);
                            setCancelModalShow(true);
                          }}
                        >
                          {value.label}
                        </NavDropdown.Item>
                      ))}
                    <NavDropdown.Item
                      key={"searchFacility"}
                      onClick={() => {
                        setSearchFacilityModel(true);
                        // setSelectedFacilityId(value.facilityId);
                        // setCancelModalShow(true);
                      }}
                    >
                      {"Search Facility"}
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  locations?.map((x) => {
                    if (x.id == selectedLocation) {
                      return x.label;
                    }
                  })
                )}
                {/* <a href="#" title="Change Location">
                    Elmhurst Care Center
                  </a> */}{" "}
                <span className="profileicon">
                  <NavDropdown
                    id="profileicondropdown"
                    title={nameAbbr}
                    menuVariant="dark"
                    drop="down-start"
                  >
                    {userPermissions != null && userPermissions.length > 0
                      ? userPermissions[0].roleName == "Administrator" && (
                          <NavDropdown.Item
                            onClick={() => {
                              var url = window.location.href.split("#")[0];
                              window.location.assign(url + "admin/#/dashboard");
                            }}
                          >
                            Admin Panel
                          </NavDropdown.Item>
                        )
                      : null}
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/downloads");
                      }}
                    >
                      Downloads
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                        onClick={() => {
                          navigate("/changePassword");
                        }}
                      >
                        Change Password
                      </NavDropdown.Item> */}

                    <NavDropdown.Item
                      onClick={() => {
                        localStorage.clear();
                        navigate("/");
                      }}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </span>
              </div>
            )}
            {!screenLogin && (
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            )}
            {!screenLogin && (
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                  <Nav.Link onClick={clickHandle} className="ml-auto">
                    <FaHome />
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Results"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/results");
                      }}
                    >
                      Facility Reports
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/resultList");
                      }}
                    >
                      Result Inbox
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/hl7ResultsStatus");
                      }}
                    >
                      Hl7 Results
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Orders"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        setSelectedOrderPatientId("");
                        if (savedOrderId == undefined) {
                          setSavedOrderId(-1);
                        } else {
                          setSavedOrderId(savedOrderId - 1);
                        }

                        navigate("/order/new");

                        // navigate(0);
                      }}
                    >
                      New Order
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/upload/orders");

                        // navigate(0);
                      }}
                    >
                      Upload Orders
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/orderManifest");

                        // navigate(0);
                      }}
                    >
                      Order Manifest
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Patients"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"Patients"}
                      onClick={clickHandle}
                    >
                      All Patients
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newPatient"}
                      onClick={() => {
                        setSelectedOrderPatientId(0);
                        navigate("/patient/new");
                      }}
                    >
                      Add Patient
                    </NavDropdown.Item>
                  </NavDropdown>
                  {userPermissions != null && userPermissions.length > 0
                    ? userPermissions.find(
                        (item) =>
                          item.facilityId == selectedLocation &&
                          item.featureName == "Phlebotomy Orders" &&
                          item.viewFeature == "Y"
                      ) && (
                        <NavDropdown
                          id="nav-dropdown-dark-example"
                          title="PSC"
                          menuVariant="dark"
                        >
                          <NavDropdown.Item
                            //disabled={addNewOrderState}
                            key={"PhlebotomyOrders"}
                            onClick={() => {
                              navigate("/PhlebotomyOrders");
                            }}
                          >
                            Phlebotomy Orders
                          </NavDropdown.Item>
                        </NavDropdown>
                      )
                    : null}
                </Nav>
              </Navbar.Collapse>
            )}
            {patientDetails.patientName != "-" && (
              <div className=" grayboxcenter">
                <div className="topinfo">
                  <img
                    className="position-icon"
                    src="https://ewsatvivo-dev.s3.amazonaws.com/paper-pen.svg"
                  />
                  <span>
                    Name: <b>{patientDetails.patientName}</b>
                  </span>
                  <span>
                    Phone: <b>{patientDetails.patientPhone}</b>
                  </span>
                  <span>
                    DOB: <b>{patientDetails.patientDob}</b>
                  </span>
                  <span>
                    Sex:{" "}
                    <b>
                      {patientDetails.patientSex != "-"
                        ? Object.keys(sexLookup).length > 0
                          ? sexLookup.sex.map((x) => {
                              if (
                                x.id.toString() ==
                                patientDetails.patientSex.toString()
                              ) {
                                sexString = x.options;
                              }
                            })
                          : "-"
                        : "Male"}
                      {sexString}
                    </b>
                  </span>
                  <span>
                    Primary Insurance: <b>{patientDetails.patientInsurance}</b>
                  </span>
                </div>
              </div>
            )}
          </Container>
        </Navbar>
        <WarningModel
          show={cancelModalShow}
          heading="Warning"
          bodytext="You are trying to change the facility. Any changes that you have made will be lost. Are you sure you want to change the facility?"
          onHide={() => setCancelModalShow(false)}
          onyes={async () => {
            if (
              JSON.parse(localStorage.getItem("userInfo")) != undefined &&
              JSON.parse(localStorage.getItem("userInfo")).userType == "Portal"
            ) {
              const response = await ApiGetCalls(
                "GetUserPermissions/" + selectedFacilityId
              );
              if (response["status"] === "Success") {
                localStorage.setItem(
                  "userPermissions",
                  JSON.stringify(response.data)
                );
              }
            }
            localStorage.setItem("selectedLocation", selectedFacilityId);
            setSelectedLocation(selectedFacilityId);
            setCancelModalShow(false);
            navigate("/patients");
          }}
        />
        <SearchFacility
          searchFacilityState={searchFacilityModel}
          setSearchFacilityState={setSearchFacilityModel}
          selectFacility={() => {}}
          setFacility={false}
        />
      </header>
    </>
  );
}

export default CollapsibleMobileNav;
