import { Form, Row, Col, Container } from "react-bootstrap";
import {
  updateLookupCollectionCustom,
  updateDataToFields,
  LookupText,
  displayInDateFormat,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";

export default function InsuranceInfoView({
  insuranceFieldsReferenceNo,
  patientObj,
  lookUpData,
  setinsuranceBlockList,
  editMode,
  setEditMode,
  patientDataArray,
}) {
  return (
    <Container className="label-style parentcontainer">
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Insurance</h5>
          <input
            aria-label="Insurance Company"
            className="input-style form-control"
            name="insuranceCompanies"
            id={
              "Insurance" + insuranceFieldsReferenceNo + ".InsuranceCompanyId"
            }
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".insuranceCompanies.name"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Policy ID</h5>
          <input
            aria-label="Policy Id"
            type="text"
            className="input-style form-control"
            disabled
            name="policyId"
            id={"Insurance" + insuranceFieldsReferenceNo + ".policyId"}
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".policyId"
                  ]
                : ""
            }
            data-type="text"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Relation</h5>
          <input
            aria-label="Relation"
            type="text"
            className="input-style form-control"
            disabled
            name="relation"
            id={"Insurance" + insuranceFieldsReferenceNo + ".relation"}
            data-type="lookup"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.relationData,
                    patientDataArray[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".relation"
                    ]
                  )
                : ""
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Last Name</h5>
          <input
            aria-label="Last Name"
            type="text"
            className="input-style form-control person"
            name="lastName"
            id={"Insurance" + insuranceFieldsReferenceNo + ".lastName"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.lastName"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">First Name</h5>
          <input
            aria-label="First Name"
            type="text"
            className="input-style form-control person"
            name="firstName"
            id={"Insurance" + insuranceFieldsReferenceNo + ".firstName"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.firstName"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Date of Birth</h5>
          <input
            aria-label="Date of Birth"
            type="text"
            className="input-style form-control person"
            name="dob"
            id={"Insurance" + insuranceFieldsReferenceNo + ".dob"}
            data-type="date"
            value={
              patientDataArray.length != 0
                ? displayInDateFormat(
                    patientDataArray[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.dob"
                    ]
                  )
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender</h5>
          <input
            aria-label="Sex"
            type="text"
            className="input-style form-control person"
            name="sex"
            id={"Insurance" + insuranceFieldsReferenceNo + ".sex"}
            data-type="lookup"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.sexData,
                    patientDataArray[
                      "patientInsurances." +
                        (insuranceFieldsReferenceNo * 1 - 1).toString() +
                        ".persons.sex"
                    ]
                  )
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Street</h5>
          <input
            type="text"
            aria-label="Street"
            className="input-style form-control address"
            name="street1"
            id={"Insurance" + insuranceFieldsReferenceNo + ".street1"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.street1"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">ZIP Code</h5>
          <input
            aria-label="Zip"
            type="text"
            className="input-style form-control address validate_zip"
            name="zip"
            id={"Insurance" + insuranceFieldsReferenceNo + ".zip"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.zip"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">City</h5>
          <input
            aria-label="City"
            type="text"
            className="input-style form-control address"
            name="city"
            id={"Insurance" + insuranceFieldsReferenceNo + ".city"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.city"
                  ]
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">State/Province/Region</h5>
          <input
            aria-label="State"
            type="text"
            className="input-style form-control address"
            name="state"
            id={"Insurance" + insuranceFieldsReferenceNo + ".state"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.addresses.state"
                  ]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Primary Phone #</h5>
          <input
            aria-label="Primary phone"
            type="text"
            className="input-style form-control person"
            name="phone"
            id={"Insurance" + insuranceFieldsReferenceNo + ".phone"}
            data-type="text"
            value={
              patientDataArray.length != 0
                ? patientDataArray[
                    "patientInsurances." +
                      (insuranceFieldsReferenceNo * 1 - 1).toString() +
                      ".persons.phone"
                  ]
                : ""
            }
            disabled
          />
        </Col>
      </Row>
    </Container>
  );
}
