import React from "react";
import { ColorRing } from "react-loader-spinner";

function CustomLoader() {
  return (
    <div className="overlay">
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#03316d", "#03316d", "#03316d", "#03316d", "#03316d"]}
      />
    </div>
  );
}

export default CustomLoader;
