import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import StarIcon from "./StarIcon";
import { FaPlus, FaInfoCircle } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineGroupAdd } from "react-icons/md";
import { BiAddToQueue } from "react-icons/bi";

function Favorites({
  title,
  favouritesData,
  setFavouritesData,
  setListOptions,
  listOptions,
  sendRequest,
  propsOnChange,
  setSelectedId,
  selectedId,
  objName,
  locationId,
  infoDetailState,
  orderId,
  infoPopupDetailState,
  setInfoModalShow,
  setInfoPopupIndexState,
  setShowComponent,
  orderStatus,
  setTestHeading,
  favIconHandler,
  setFavIconHandler,
  setShowTestGroupModel,
}) {
  const addTestFromGroup = (groupTests) => {
    var listOptionsTemp = listOptions.options;
    groupTests.map((selectedObj) => {
      if (
        !listOptions.options?.some((item) => selectedObj.tests.id == item.id)
      ) {
        listOptionsTemp.push({
          id: selectedObj.tests.id,
          testName: selectedObj.tests.testName,
          testCode: selectedObj.tests.testCode,
        });

        orderId != ""
          ? selectedId.push({
              orderId: orderId,
              testId: selectedObj.tests.id * 1,
            })
          : selectedId.push({
              testId: selectedObj.tests.id * 1,
            });
        propsOnChange(selectedObj.tests.id);
      }
    });
    setListOptions({ options: listOptionsTemp });
    setSelectedId(selectedId);
  };

  const addTestFromFav = (obj) => {
    if (!listOptions.options?.some((item) => obj.id == item.id)) {
      setListOptions({ options: listOptions.options?.concat(obj) });
      setSelectedId(
        orderId != ""
          ? selectedId.concat({
              orderId: orderId,
              [objName]: obj.id * 1,
            })
          : selectedId.concat({
              [objName]: obj.id * 1,
            })
        // }
      );
      if (sendRequest) propsOnChange(obj.id);
    }
  };
  const updateInfoModalData = (idx) => {
    setShowComponent("fav");
    setInfoModalShow(true);
    setInfoPopupIndexState(idx.index);
  };

  return (
    <Row>
      <Col md="12">
        <h5>Favorites</h5>
      </Col>
      {favouritesData.length > 0 ? (
        favouritesData.map((value, index) => (
          <Col md="4" key={index} className="mb-3">
            <div className="pills">
              <span>{value.testCode + ": " + value.testName}</span>
              {(orderStatus == "Draft" || orderStatus == undefined) && (
                <FaPlus
                  title={title}
                  className="favPlusIcon ml-1"
                  onClick={() => {
                    if (value.testCode == "Group") {
                      addTestFromGroup(value.testsGroups);
                    } else {
                      addTestFromFav({
                        id: value.id,
                        testName: value.testName,
                        testCode: value.testCode,
                        fav: value.fav,
                      });
                    }
                  }}
                />
              )}
              {(orderStatus == "Draft" || orderStatus == undefined) && (
                <StarIcon
                  showIcon={value.fav == "Y" ? true : false}
                  className="favIcon"
                  favouritesData={favouritesData}
                  setFavouritesData={setFavouritesData}
                  testObj={{
                    id: value.id,
                    locationId: locationId,
                    testId: objName == "testId" ? value.id * 1 : null,
                    diagnosisId: objName == "testId" ? null : value.id * 1,
                    favId: value.favId,
                    testName: value.testName,
                    testCode: value.testCode,
                    fav: value.fav,
                  }}
                  setListOptions={setListOptions}
                  listOptions={listOptions}
                  favIconHandler={favIconHandler}
                  setFavIconHandler={setFavIconHandler}
                />
              )}
              {objName == "testId" ? (
                <BsInfoCircle
                  //title={infoDetailState[index]}
                  title={"More info"}
                  color={"#000"}
                  className="iconInfo"
                  onClick={() => {
                    updateInfoModalData({
                      index,
                    });
                    setTestHeading(value.testCode + ": " + value.testName);
                  }}
                />
              ) : null}
              {/* {objName == "testId" && (
                <BiAddToQueue
                  //title={infoDetailState[index]}
                  title={"Group"}
                  color={"#000"}
                  className="groupIcon"
                  onClick={() => {
                    setShowTestGroupModel(true);
                  }}
                />
              )} */}
            </div>
          </Col>
        ))
      ) : (
        <Col>No favorites found</Col>
      )}
    </Row>
  );
}
export default Favorites;
