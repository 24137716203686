import React, { useState, useEffect } from "react";
import SelectBody from "./SelectBody";
import InputWithLoader from "./SearchInputFieldWithLoader";
import WarningModel from "../../../GlobalComponents/WarningModel";
import { Col, Row, Form } from "react-bootstrap";
import Favorites from "./DiagnosisAndTestComponents/Favorites";
import SelectedTestAndDiagnosisList from "./DiagnosisAndTestComponents/SelectedTestAndDiagnosisList";

function SearchAndSelectDiagnosis(props) {
  // const [selectedDiagnosesId, setSelectedDiagnosesId] = useState([]);
  useEffect(() => {
    props.handleClinicalFormChange("orderDiagnoses", props.selectedDiagnosesId);
  }, [props.selectedDiagnosesId]);

  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [locationId, setLocationId] = useState(props.userLocationId);
  const [orderId, setOrderId] = useState("");
  const [showSearctField, setShowSearctField] = useState(false);
  useEffect(() => {
    setLocationId(props.userLocationId);
  }, [props.userLocationId]);
  const rowClickHandler = (selectedObj, id) => {
    setShowResults(false);
    setSearchText("");
    if (!props.listOptionsDiagnosis.options?.some((item) => id == item.id)) {
      props.setListOptionsDiagnosis(
        // listOptions.options?.concat({ id: id, testName: selectedObj[1] })
        {
          options: props.listOptionsDiagnosis.options?.concat({
            id: id,
            testName: selectedObj[2],
            testCode: selectedObj[1],
          }),
        }
      );
      props.setSelectedDiagnosesId(
        orderId != ""
          ? props.selectedDiagnosesId.concat({
              orderId: orderId,
              diagnosesId: id * 1,
            })
          : props.selectedDiagnosesId.concat({
              diagnosesId: id * 1,
            })
        // }
      );
      // props.propsOnChange(id);
    }
    // props.propsOnChange(id);
  };

  const onConfirmationYes = () => {
    setModalShow(false);
    // props.handleDelete(testId);
    handleDeleteFromPanal(diagnosisId);
  };
  function handleDeleteFromPanal(testId) {
    // setListOptions(listOptions.filter((value) => testId != value.id));

    props.setSelectedDiagnosesId(
      props.selectedDiagnosesId.filter((value) => testId != value.diagnosesId)
      // }
    );
    props.setListOptionsDiagnosis({
      options: props.listOptionsDiagnosis.options?.filter(
        (value) => testId != value.id
      ),
    });
  }

  const [favouritesData, setFavouritesData] = useState([]);
  const diagnosisDataGrid = [];
  // const favouritesData = [];
  const favouritesArray = [];
  for (let i = 0; i < diagnosisData.length; i++) {
    const diagnosisDataObj = [
      diagnosisData[i].id.toString(),
      diagnosisData[i].diagnosisCode,
      diagnosisData[i].diagnosisName,
    ];
    diagnosisDataGrid.push(diagnosisDataObj);
  }
  var loopCheck = false;
  for (let i = 0; i < props.locationBasedData.length; i++) {
    if (props.locationBasedData[i].diagnosisId !== null) {
      const locationBasedDataObj = {
        id: props.locationBasedData[i].diagnoses.id.toString(),
        testCode: props.locationBasedData[i].diagnoses.diagnosisCode,
        favId: props.locationBasedData[i].id.toString(),
        testName: props.locationBasedData[i].diagnoses.diagnosisName,
        fav: "Y",
      };

      favouritesArray.push(locationBasedDataObj);
      loopCheck = true;
    }
  }
  useEffect(() => {
    setFavouritesData(favouritesArray);
  }, [loopCheck]);
  useEffect(() => {
    if (
      props.orderIdAsParam == undefined ||
      props.orderData.status == "Draft"
    ) {
      setShowSearctField(true);
    } else {
      setShowSearctField(false);
    }
    if (
      props.orderData.orderDiagnoses != undefined &&
      props.listOptionsDiagnosis.options.length == 0
    ) {
      props.setSelectedDiagnosesId("");
      var listOptionsArray = [];
      var selectedArrayDiagnosis = [];
      props.orderData.orderDiagnoses.map((item, index) => {
        setOrderId(item.orderId);
        if (
          !props.listOptionsDiagnosis.options?.some(
            (value) => item.diagnoses.id == value.id
          )
        ) {
          listOptionsArray.push({
            id: item.diagnoses.id,
            testName: item.diagnoses.diagnosisName,
            testCode: item.diagnoses.diagnosisCode,
          });
          selectedArrayDiagnosis.push({
            orderId: item.orderId * 1,
            diagnosesId: item.diagnoses.id * 1,
          });
        }
      });
      props.setSelectedDiagnosesId(selectedArrayDiagnosis);
      props.setListOptionsDiagnosis({ options: listOptionsArray });
      //
    } else {
      if (props.orderData.orderDiagnoses == undefined) {
        props.setSelectedDiagnosesId([]);
        props.setListOptionsDiagnosis({ options: [] });
      }
    }
  }, [props.orderData]);

  return (
    <>
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />

      {showSearctField && (
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <InputWithLoader
              id="DignosisSelect"
              filter={searchText}
              placeholder="Start typing..."
              apiEndPoint="GetSearchedDiagnosis/"
              setData={setDiagnosisData}
              setShowResults={setShowResults}
              setFilter={setSearchText}
              name="diagnosis"
              aria_label="Diagnosis Name"
              className="input-style form-control mt-4"
              required={true}
              data_type="text"
            />
            {showResults ? (
              <SelectBody
                rowClickHandler={rowClickHandler}
                filter={searchText}
                column={["Code", "Name"]}
                dataArray={diagnosisDataGrid}
                setShowResults={setShowResults}
              />
            ) : null}
          </Col>
        </Row>
      )}

      <Favorites
        heading="Selected Diagnosis"
        title="Add Diagnosis"
        favouritesData={favouritesData}
        setFavouritesData={setFavouritesData}
        setListOptions={props.setListOptionsDiagnosis}
        listOptions={props.listOptionsDiagnosis}
        sendRequest={false}
        propsOnChange={props.propsOnChange}
        setSelectedId={props.setSelectedDiagnosesId}
        selectedId={props.selectedDiagnosesId}
        objName="diagnosesId"
        locationId={locationId}
        orderId={orderId}
        orderStatus={props.orderData.status}
        favIconHandler={props.favIconHandler}
        setFavIconHandler={props.setFavIconHandler}
      />

      {props.listOptionsDiagnosis["options"]?.length > 0 ? (
        <SelectedTestAndDiagnosisList
          heading="Selected Diagnosis Codes"
          favouritesData={favouritesData}
          setFavouritesData={setFavouritesData}
          setListOptions={props.setListOptionsDiagnosis}
          listOptions={props.listOptionsDiagnosis}
          setId={setDiagnosisId}
          setModalShow={setModalShow}
          locationId={locationId}
          orderStatus={props.orderData.status}
          favIconHandler={props.favIconHandler}
          setFavIconHandler={props.setFavIconHandler}
          orderId={orderId}
          diagnosislistOptions={props.listOptionsDiagnosis}
          diagnosisSetlistOptions={props.setListOptionsDiagnosis}
          selectedDiagnosesIdProp={props.selectedDiagnosesId}
          setSelectedDiagnosesIdProp={props.setSelectedDiagnosesId}
        />
      ) : null}
    </>
  );
}

export default SearchAndSelectDiagnosis;
