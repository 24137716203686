export const removeInsurance = async (inputs, insuranceBlockNo, setInputs) => {
  delete inputs["patientInsurances." + insuranceBlockNo + ".groupId"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".groupId"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".groupName"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".groupName"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".id"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".id"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.billType"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.billType"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.createdAt"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.createdAt"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.id"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.id"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.name"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.name"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.payerId"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.payerId"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanies.updatedAt"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanies.updatedAt"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".insuranceCompanyId"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".insuranceCompanyId"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".patientId"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".patientId"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".personId"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".personId"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.addressId"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addressId"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.city"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.city"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.createdAt"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.createdAt"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.id"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.id"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.state"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.state"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.street1"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.street1"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.street2"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.street2"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.updatedAt"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.updatedAt"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.addresses.zip"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.addresses.zip"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.createdAt"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.createdAt"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.dob"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.dob"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.email"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.email"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.firstName"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.firstName"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.id"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.id"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.lastName"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.lastName"
  );
  delete inputs[
    "patientInsurances." + insuranceBlockNo + ".persons.middleName"
  ];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.middleName"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.mobile"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.mobile"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.phone"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.phone"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.sex"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.sex"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".persons.updatedAt"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".persons.updatedAt"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".policyId"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".policyId"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".relation"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".relation"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".updatedAt"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".updatedAt"
  );
  delete inputs["patientInsurances." + insuranceBlockNo + ".primaryInsurance"];
  inputs = patientInsurancesArrayIndexDecrementor(
    inputs,
    insuranceBlockNo + 1,
    ".primaryInsurance"
  );
  //   inputs.patientInsurances.splice(-1);
  await setInputs({ ...inputs });
};

const patientInsurancesArrayIndexDecrementor = (
  inputs,
  currentBlock,
  index
) => {
  if (inputs["patientInsurances." + currentBlock + index] !== undefined) {
    inputs["patientInsurances." + (currentBlock - 1) + index] =
      inputs["patientInsurances." + currentBlock + index];
    if (inputs["patientInsurances." + currentBlock + 1 + index] == undefined) {
      delete inputs["patientInsurances." + currentBlock + index];
    }
    patientInsurancesArrayIndexDecrementor(inputs, currentBlock + 1, index);
  }
  return inputs;
};
