import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { handleUpdateClassesOnFormObjects } from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import moment from "moment/moment";
import { ApiGetCalls } from "../../../services/Api";
import DemographicInfoView from "./DemographicInfoView";
import DemographicInfoForm from "./DemographicInfoForm";

function DemographicInfo({
  patientTabMode,
  setpatientTabMode,

  patientObj,
  demoDateState,
  setdemoDateState,
  lookUpData,
  setinsuranceBlockList,
  editMode,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  handleAllFormChange,
  handleDateChange,
  inputs,
  patientDataArray,
  setInputs,
  facilityId,
  relation,
  sameAddressAsPatient,
}) {
  return (
    <Accordion defaultActiveKey="0" id="DemographicAccordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header id="demographics">Demographics</Accordion.Header>
        <Accordion.Body>
          {patientTabMode == "view" &&
            !editMode && ( // load demographic section in view mode
              <DemographicInfoView
                patientObj={patientObj}
                setpatientTabMode={setpatientTabMode}
                lookUpData={lookUpData}
                setinsuranceBlockList={setinsuranceBlockList}
                editMode={editMode}
                setEditMode={setEditMode}
                patientDataArray={patientDataArray}
              />
            )}

          {(patientTabMode == "form" || editMode) && ( // load demographic section in form mode
            <DemographicInfoForm
              setpatientTabMode={setpatientTabMode}
              patientTabMode={patientTabMode}
              patientObj={patientObj}
              datePickerStatus={demoDateState}
              setdatePickerStatus={setdemoDateState}
              lookUpData={lookUpData}
              setinsuranceBlockList={setinsuranceBlockList}
              setEditMode={setEditMode}
              datePickerValues={datePickerValues}
              setDatePickerValues={setDatePickerValues}
              zipValues={zipValues}
              setZipValues={setZipValues}
              phoneValues={phoneValues}
              setPhoneValues={setPhoneValues}
              handleAllFormChange={handleAllFormChange}
              handleDateChange={handleDateChange}
              editMode={editMode}
              inputs={inputs}
              setInputs={setInputs}
              facilityId={facilityId}
              relation={relation}
              sameAddressAsPatient={sameAddressAsPatient}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default DemographicInfo;
