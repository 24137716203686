import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { ApiGetCalls, ApiPostCalls } from "../services/Api";
function InputWithLoader(props) {
  const [loader, setLoader] = useState(false);
  const changeHandler = (value) => {
    props.setFilter(value);
    if (value) {
      setLoader(true);
      fetchData(value);
    } else if (value) {
      props.setShowResults(true);
    } else {
      props.setShowResults(false);
    }
  };
  const fetchData = async (value) => {
    if (props.method == "post") {
      const response = await ApiPostCalls(
        { ...props.postApiJson, ["searchedText"]: value },

        props.apiEndPoint,
        props.loginScreen
      );
      props.setData(response.data);
      setLoader(false);
      props.setShowResults(true);
    } else {
      if (props.apiEndPoint !== "") {
        ApiGetCalls(props.apiEndPoint + value).then((data) => {
          props.setData(data);
          setLoader(false);
          props.setShowResults(true);
        });
      } else {
        setLoader(false);
        props.setShowResults(true);
      }
    }
  };
  const concernedElement = document.querySelector("#root");
  document.addEventListener("mousedown", (event) => {
    const notConcernedElement =
      document.getElementsByClassName("searchResultDiv");
    if (
      concernedElement.contains(event.target) &&
      notConcernedElement.length > 0 &&
      !event.target.classList.contains("searchResultcol") &&
      !event.target.classList.contains("searchCheckBox") &&
      !event.target.classList.contains("customSearchButton") &&
      !event.target.classList.contains("SearchBtnCustom") &&
      !event.target.classList.contains("boldHighlightedCharcter") &&
      !event.target.classList.contains("patients-search-results")
    ) {
      props.setShowResults(false);
    }
  });
  return (
    <div className="inputWithLoading">
      <input
        id={props.id}
        className={props.className}
        name={props.name}
        type="text"
        value={props.filter}
        placeholder={props.placeholder}
        data-id={props.selectedId}
        required={props.required}
        data-type={props.data_type}
        disabled={props.disabled}
        autoComplete="off"
        onBlur={props.onBlur}
        onChange={(event) => {
          //props.changeHandler(event.target.value);
          changeHandler(event.target.value);
          // props.onChange(props.selectedId, props.id);
        }}
        aria-label={props.aria_label}
      />
      {loader ? (
        <Spinner
          className="spinner_field"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : null}
    </div>
  );
}
export default InputWithLoader;
