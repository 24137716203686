import React, { useState, useEffect, useContext } from "react";
import { Container, FormCheck, Modal } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents//mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import moment from "moment/moment";
import dateFormat from "dateformat";
import {
  ApiGetCalls,
  ApiPostCalls,
  ApiPostFileCalls,
} from "../../services/Api";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { LoginInfoContext, LoginUserLocationContext } from "../../Context";
import { numberOfInsurancesInJSON } from "../../GlobalComponents/ValidatorAndSubmitForm";
import { apiUrl } from "../../config/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {
  BsFillCheckSquareFill,
  BsFillPrinterFill,
  BsCheckSquare,
  BsPrinter,
  BsPersonCheck,
} from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  FaTimesCircle,
  FaRegTimesCircle,
  FaRegCheckSquare,
} from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import { snackBar } from "../../services/Alert";
import WarningModel from "../../GlobalComponents/WarningModel";
import ReactInputMask from "react-input-mask";
import MaskedInput from "react-text-mask";
import CustomLoader from "../../GlobalComponents/Loader";

function Upload(props) {
  const [showLoader, setLoader] = useState(false);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [modalShow, setModalShow] = useState(false);
  const [approveWarningModalShow, setApproveWarningModalShow] = useState(false);
  const [
    approveDemographicWarningModalShow,
    setApproveDemographicWarningModalShow,
  ] = useState(false);
  const [rejectWarningModalShow, setRejectWarningModalShow] = useState(false);
  const [reportId, setReportId] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});
  const [reportErrors, setReportErrors] = useState([]);
  const navigate = useNavigate();
  const [addNewOrderState, setAddNewOrderState] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [pagenationState, setPagenationState] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pdfFileLocation, setPdfFileLocation] = useState("");

  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Reports";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      setSearchFilter({
        userId: null,
        batchNumber: null,
        fileName: null,
        status: null,
        uploadedDate: null,
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
      });
    }
    setPagenationState(Math.random());
    fetchStatusesInfoDataOnLoad();
  }, []);
  const [orderList, setOrderList] = useState({ list: [] });
  const [fetchData, setfetchData] = useState(false);
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({
      ...values,
      [name]: value == "" ? null : value,
    }));
  };
  let { id } = useParams();
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  async function getReports() {
    try {
      var body = JSON.stringify(searchFilter);
      await ApiPostCalls(body, "GetOrdersReportByUser").then((data) => {
        setOrderList({ ...orderList, list: data["data"] });
        if (data["data"].length > 0) {
          setTotalRecords(
            Math.ceil(data["data"][0].totalRecords / totalRecordPerPage)
          );
          setfetchData(true);
        }
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  }

  async function rejectReport(id) {
    try {
      setRejectWarningModalShow(false);
      await ApiGetCalls("RejectExcelImportedData/" + id).then((data) => {
        if (data["status"] == "Success") {
          snackBar("Success! File rejected successfully", "success");
          getReports();
        } else {
          snackBar("Error! " + data["message"], "danger");
        }
      });
    } catch (error) {}
  }
  async function approveReport(id) {
    try {
      setApproveWarningModalShow(false);
      await ApiGetCalls("ApproveExcelFile/" + id).then(async (data) => {
        if (data["status"] == "Success") {
          snackBar("Success! File approve in progress", "success");
          getReports();
          await ApiGetCalls("ProcessExcelImportedData/" + id).then((data) => {
            snackBar("Success! Process completed successfully", "success");
            getReports();
          });
        } else {
          snackBar("Error! " + data["message"], "danger");
        }
      });
    } catch (error) {}
  }

  async function approveReportForDemographic(id) {
    try {
      setApproveDemographicWarningModalShow(false);
      await ApiGetCalls("ApproveDemographicsExcelFile/" + id).then(
        async (data) => {
          if (data["status"] == "Success") {
            getReports();
            await ApiGetCalls("ProcessExcelPatientData/" + id).then(
              async (data) => {
                snackBar("Success! Process completed successfully", "success");
                getReports();
              }
            );
          } else {
            snackBar("Error! " + data["message"], "danger");
          }
        }
      );
    } catch (error) {}
  }
  // useEffect(() => {
  //   if (selectedLocation != "") getReports();
  // }, [selectedLocation]);

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});

  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const clickHandle = (e) => {
    navigate("/order/new");
  };
  const [file, setFile] = useState(null);

  async function printOrdersReport(path) {
    //    ApiGetCalls("GenerateOrdersPdf/" + id).then((value) => {
    document.getElementById("resultPreviewBlob").setAttribute("src", path);
    var a = document.createElement("a");
    a.setAttribute("data-bs-toggle", "modal");
    a.setAttribute("data-bs-target", "#ResultPreviewModal");
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
    //});
    // var requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     "Response-Type": "blob",
    //   },
    // };

    // const response = await fetch(
    //   apiUrl + "GenerateOrdersPdf/" + id,
    //   requestOptions
    // )
    //   .then(async (response) => {
    //     if (response.status.toString() == "200") {
    //       return response.blob();
    //     } else {
    //       if (response.status.toString() == "401") {
    //         const accessToken = localStorage.getItem("token");
    //         const refreshToken = localStorage.getItem("refreshToken");
    //         const refreshPayload = JSON.stringify({
    //           accessToken: accessToken,
    //           refreshToken: refreshToken,
    //         });

    //         var refreshTokenResponse = await ApiPostCalls(
    //           refreshPayload,
    //           "refresh-token"
    //         );
    //         localStorage.setItem("token", refreshTokenResponse["accessToken"]);
    //         localStorage.setItem(
    //           "refreshToken",
    //           refreshTokenResponse["refreshToken"]
    //         );
    //         printOrdersReport(id);
    //       }
    //       return false;
    //     }
    //   })
    //   .then(async (blob) => {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     reader.onloadend = function () {
    //       var base64data = reader.result;

    //       document
    //         .getElementById("resultPreviewBlob")
    //         .setAttribute("src", base64data);
    //       var a = document.createElement("a");
    //       a.setAttribute("data-bs-toggle", "modal");
    //       a.setAttribute("data-bs-target", "#ResultPreviewModal");
    //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //       a.click();
    //       a.remove();
    //     };
    //   });
  }

  async function exportOrdersReport(id) {
    setLoader(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
    };
    const response = await fetch(
      apiUrl + "DownloadOrdersReportFile/" + id,
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            exportOrdersReport(id);
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var today = new Date();
        var fileNameWithDateString =
          today.getFullYear().toString() +
          (today.getMonth() + 1).toString().padStart(2, "0") +
          today.getDate().toString() +
          today.getHours().toString() +
          today.getMinutes().toString();
        a.download = "orders_" + fileNameWithDateString + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getReports();
    }
  }, [pagenationState]);

  const [lookOrderInfo, setLookOrderInfo] = useState({
    fileStatuses: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [fileStatuses] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/excelorderuploadstatus"),
      ]);
      setLookOrderInfo({
        fileStatuses: fileStatuses,
      });
    } catch (error) {}
  }
  return (
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight-orderPage">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Uploaded Files
              {/* <Button
                onClick={clickHandle}
                disabled={addNewOrderState}
                variant="primary"
                className="primary btn button ml-auto"
              >
                Upload New
              </Button> */}
            </h3>
            <Row>
              <Col md={{ span: 5 }}></Col>
              <Col lg={{ span: 6 }} md={{ span: 5 }}>
                <input
                  type="file"
                  className="form-control"
                  accept=".xlsx"
                  value={file != null ? file.fileName : ""}
                  onChange={(e) => {
                    if (e.target.files) {
                      setFile(e.target.files[0]);
                    }
                  }}
                ></input>
              </Col>
              <Col lg={{ span: 1 }} md={{ span: 2 }} className="d-flex">
                <button
                  className="primary btn button ml-auto btn btn-primary"
                  disabled={file ? false : true}
                  onClick={(e) => {
                    if (!file) {
                      return;
                    }
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("facilityId", selectedLocation);
                    const reader = new FileReader();
                    setLoader(true);
                    ApiPostFileCalls(formData, "uploadOrdersFile").then(
                      async (value) => {
                        setLoader(false);
                        if (value["status"] == "Success") {
                          setFile(null);
                          getReports();
                          snackBar(
                            "Success! " + "File uploaded successfully",
                            "success"
                          );
                          ApiGetCalls(
                            "ProcessFile/" + value["data"]["id"]
                          ).then((val) => {
                            getReports();
                          });
                        } else {
                          snackBar("Error! " + value["message"], "danger");
                        }
                      }
                    );
                    // setTimeout(() => {
                    //   getReports();
                    // }, 3000);
                  }}
                >
                  Upload
                </button>
              </Col>
            </Row>

            <hr></hr>
            <h5>Search</h5>
            <hr></hr>

            <Row className="mobile-col-row">
              <Col>
                <h5 className="label-style">Batch Number</h5>
                <input
                  name="batchNumber"
                  type="text"
                  value={
                    searchFilter["batchNumber"] == null
                      ? ""
                      : searchFilter["batchNumber"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  className="input-style form-control mb-3"
                />
              </Col>
              <Col>
                <h5 className="label-style">File Name</h5>
                <input
                  name="fileName"
                  value={
                    searchFilter["fileName"] == null
                      ? ""
                      : searchFilter["fileName"]
                  }
                  type="text"
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  className="input-style form-control mb-3"
                />
              </Col>
              {/* <Col>
                <h5 className="label-style">User Name</h5>
                <input
                  name="advancedSearchOrderNumber"
                  type="text"
                  className="input-style form-control mb-3"
                />
              </Col> */}
              <Col>
                <h5 className="label-style">Status</h5>
                <Form.Select
                  name="status"
                  value={
                    searchFilter["status"] == null ? "" : searchFilter["status"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                >
                  <option value={""}>All</option>
                  {lookOrderInfo?.fileStatuses.map((val, ind) => {
                    return (
                      <option value={val.optionCode}>{val.options}</option>
                    );
                  })}
                  {/* <option value={"completed"}>Completed</option>
                  <option value={"approved"}>Approved</option>
                  <option value={"rejected"}>Rejected</option>
                  <option value={"demography approved"}>
                    Demography Approved
                  </option>
                  <option value={"has errors"}>Has Errors</option>
                  <option value={"queued for upload"}>Queued for Upload</option>
                  <option value={"queued for approval"}>
                    Queued for Approval
                  </option> */}
                </Form.Select>
              </Col>
              <Col>
                <h5 className="label-style">Uploaded Date</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  name="uploadedDate"
                  id="uploadedDate"
                  data-type="date"
                  ariaLabelledBy="Date of birth"
                  className="input-style form-control"
                  selected={
                    searchFilter["uploadedDate"] != undefined ||
                    searchFilter["uploadedDate"] == ""
                      ? new Date(searchFilter["uploadedDate"])
                      : null
                  }
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  onChange={(date) => {
                    handleSearchFilterChange(
                      "uploadedDate",
                      date != null
                        ? moment(date).format("yyyy-MM-DDT00:00:00")
                        : null
                    );
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  required
                  autoComplete="off"
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder={"MM/dd/yyyy"}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                />
              </Col>

              <Col className="d-flex flex-row align-items-end align-content-end ">
                <Button
                  variant="outline "
                  className="outline button mb-3 ml-auto"
                  onClick={() => {
                    setSearchFilter({
                      userId: null,
                      batchNumber: null,
                      fileName: null,
                      status: null,
                      uploadedDate: null,
                      pageNumber: 1,
                      totalRecordPerPage: totalRecordPerPage,
                    });
                    setPagenationState(Math.random());
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  className="primary btn button ml-2 mb-3"
                  onClick={() => {
                    handleSearchFilterChange("pageNumber", 1);
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                  }}
                >
                  Filter
                </Button>
              </Col>
            </Row>
            <Table className="mt-4" responsive="md" bordered hover>
              <thead>
                <tr className="DarkHeading">
                  <th>Batch Number</th>
                  <th width="40%">File Name</th>
                  {/* <th>User Name</th> */}
                  <th className="text-center">Status</th>
                  <th className="text-center">Uploaded Date</th>
                  <th className="text-center">Errors & Warnings</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {fetchData ? (
                  orderList.list != null ? (
                    orderList.list.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{value["batchNumber"]}</td>
                          <td>
                            <a
                              title="Download Excel"
                              className="errorsCount cursorPointer"
                              onClick={() => {
                                exportOrdersReport(value["id"]);
                              }}
                            >
                              {value["fileOriginalName"]}
                            </a>
                          </td>
                          {/* <td>
                          {
                            JSON.parse(localStorage.getItem("userInfo"))
                              .userFullName
                          }
                        </td> */}
                          <td align="center">{value["status"]}</td>
                          <td align="center">
                            {moment(new Date(value["updatedAt"])).format(
                              "MM/DD/yyyy"
                            )}
                          </td>
                          <td align="center">
                            <a
                              title="View Errors"
                              className={
                                value["errorsCount"] + value["warningsCount"] >
                                0
                                  ? "errorsCount cursorPointer"
                                  : ""
                              }
                              onClick={() => {
                                if (
                                  value["errorsCount"] +
                                    value["warningsCount"] >
                                  0
                                ) {
                                  setReportErrors(value["errors"]);

                                  setModalShow(true);
                                }
                              }}
                            >
                              ({value["errorsCount"] + value["warningsCount"]})
                            </a>
                          </td>
                          <td align="center">
                            {(value["errorsCount"] < 1 &&
                              value["status"] == "Completed") ||
                            (value["warningsCount"] > 0 &&
                              value["errorsCount"] < 1 &&
                              value["status"] == "Has Errors") ? (
                              <>
                                <FaRegCheckSquare
                                  title="Approve"
                                  className=" mr-2 cursorPointer"
                                  size={20}
                                  onClick={() => {
                                    setReportId(value["id"]);
                                    setApproveWarningModalShow(true);
                                    //approveReport(value["id"]);
                                  }}
                                />
                                <FaRegTimesCircle
                                  title="Reject"
                                  className=" mr-2 cursorPointer"
                                  size={20}
                                  onClick={() => {
                                    setReportId(value["id"]);
                                    setRejectWarningModalShow(true);
                                    // rejectReport(value["id"]);
                                  }}
                                />
                                {value["status"] != "Demography Approved" && (
                                  <BsPersonCheck
                                    title="Approve Demography"
                                    className=" mr-2 cursorPointer"
                                    size={22}
                                    onClick={() => {
                                      setReportId(value["id"]);
                                      setApproveDemographicWarningModalShow(
                                        true
                                      );
                                      //approveReport(value["id"]);
                                    }}
                                  />
                                )}
                              </>
                            ) : null}
                            {value["errorsCount"] < 1 &&
                              value["status"] == "Approved" && (
                                <BsPrinter
                                  onClick={async () => {
                                    await ApiGetCalls(
                                      "DownloadOrdersReports/" + value["id"]
                                    ).then((data) => {
                                      if (data["status"] == "Success") {
                                        printOrdersReport(data["data"]);
                                      } else {
                                        snackBar(
                                          "Error! " + data["message"],
                                          "danger"
                                        );
                                      }
                                    });
                                  }}
                                  title="Download"
                                  className="cursorPointer"
                                  size={20}
                                />
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : null
                ) : (
                  <tr>
                    <td colSpan={7}>{"No Orders Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Form.Select>
              <span>per page</span>
            </>
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setTimeout(() => {
            document
              .getElementById("selectedTestsAccordions")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 300);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Errors & Warnings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {MNdiagnosis.length != 0 && <h5>Suggested ICD Codes</h5>} */}
          <div className="suggestedDiagnosisModal">
            <table
              className="MNDialogBox table"
              cellPadding={"2"}
              cellSpacing={"0"}
            >
              <tr className="DarkHeading sticky-top">
                <th>Row #</th>
                <th>Errors & Warnings</th>
              </tr>

              {reportErrors.map((value) => {
                var decondedErrors = JSON.parse(value["errors"]);
                return (
                  <tr>
                    <td>{value["row"]}</td>
                    <td>
                      {decondedErrors["validationErrors"].map((err) => {
                        return <Row>{"Error: " + err}</Row>;
                      })}
                      {decondedErrors["validationWarnings"].map((warn) => {
                        return <Row>{"Warning: " + warn}</Row>;
                      })}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline btn button"
            onClick={() => {
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Requisition"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                  src={pdfFileLocation}
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <WarningModel
        show={approveWarningModalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to approve this import?"
        onHide={() => setApproveWarningModalShow(false)}
        onyes={() => {
          approveReport(reportId);
        }}
      />
      <WarningModel
        show={approveDemographicWarningModalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to approve this import for demographic only?"
        onHide={() => setApproveDemographicWarningModalShow(false)}
        onyes={() => {
          approveReportForDemographic(reportId);
        }}
      />
      <WarningModel
        show={rejectWarningModalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to reject this import?"
        onHide={() => setRejectWarningModalShow(false)}
        onyes={() => {
          rejectReport(reportId);
        }}
      />
    </div>
  );
}
export default Upload;
