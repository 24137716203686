import React, { useState, useEffect, useContext, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  Form,
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  Table,
  ButtonGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import DatePickerComponent from "../../../GlobalComponents/DatePicker";
import moment from "moment/moment";
import CopyToModel from "./CopyToModel";
import { ApiGetCalls } from "./../../../services/Api";
import { FaTrash } from "react-icons/fa";
import WarningModel from "../../../GlobalComponents/WarningModel";
import { removeErrorForDivs } from "../../../GlobalComponents/ValidatorAndSubmitForm";
import { LoginInfoContext, LoginUserLocationContext } from "../../../Context";
import {
  allInputField,
  standingOrderIdConfig,
  textareaField,
} from "../../../config/config";
import { BsTrash } from "react-icons/bs";
import { Calendar } from "react-multi-date-picker";
import Swal from "sweetalert2";

function OrderInformationTab(props) {
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [context, setContext] = useContext(LoginInfoContext);
  const [providersData, setProvidersData] = useState([]);
  const [copyToModalShow, setCopyToModalShow] = useState(false);
  const [copyToModalShowId, setCopyToModalShowId] = useState(0);
  const [orderCopyToProvidersList, setOrderCopyToProvidersList] = useState([]);
  const [collectedByState, setCollectedByState] = useState("");
  const [reportCommentsState, setReportCommentsState] = useState("");
  const [labCommentsState, setLabCommentsState] = useState("");

  var loopCheck = false;
  const providersArray = [];
  if (props.locationBasedData != null) {
    for (let i = 0; i < props.locationBasedData.length; i++) {
      if (props.locationBasedData[i].testId !== null) {
        const locationBasedDataObj = props.locationBasedData[i];

        providersArray.push(locationBasedDataObj);
        loopCheck = true;
      }
    }
  }
  var nameAbbrArray = "-";

  useEffect(() => {
    fetchOrderingInfoDataOnLoad();
  }, []);
  if (context.userInfo != undefined) {
    nameAbbrArray =
      context.userInfo.userLastName + " " + context.userInfo.userFirstName;
  }
  useEffect(() => {
    setProvidersData(providersArray);
  }, [loopCheck]);
  function fetchCopyToData(setCopyToLocationPersons) {
    // ApiGetCalls("GetAddressByZip/" + filteredZipCode[0]).then((data) => {

    // })

    ApiGetCalls("GetCopyToProvidersByFacility/" + selectedLocation).then(
      (data) => {
        // (res.json());
        setCopyToLocationPersons(data);
      }
    );
  }
  const [showCopyTo, setShowCopyTo] = useState(false);
  const [statState, setStatState] = useState(false);
  const [fastingState, setFastingState] = useState(false);
  const [orderingProvider, setOrderingProvider] = useState("");
  const [billTypeState, setBillTypeState] = useState("");
  const [orderTypeState, setOrderTypeState] = useState("");
  const [location, setLocation] = useState("");
  const [copyToLocationPersons, setCopyToLocationPersons] = useState([]);
  //const [dateRange, setDateRange] = useState([]);
  //const [startDate, endDate] = dateRange;

  function getSpecificDaysBetweenDates(startDate, endDate, day, week) {
    const days = [];
    var currentDate = new Date(startDate);
    currentDate.setDate(1);
    var months = [];

    while (currentDate <= endDate) {
      var monthCheck = months.some((x) => {
        if (x == currentDate.getMonth()) {
          return true;
        } else {
          return false;
        }
      });

      if (currentDate.getDay() == day) {
        if (!monthCheck) {
          months.push(currentDate.getMonth());
          // Check if the day is Monday (Monday is 1, Sunday is 0)
          // var weekOfMonth = (0 | (currentDate.getDate() / 7)) + 1;
          var currentMonth = currentDate.getMonth();
          currentDate.setDate(currentDate.getDate() + 7 * week);
          if (currentDate.getMonth() == currentMonth) {
            // if (week == 4) {
            //   week = 3;
            // }
            //currentDate.setDate(currentDate.getDate() + 7 * week);
            if (currentDate <= endDate) {
              if (currentDate >= startDate) {
                days.push(
                  currentDate.getFullYear() +
                    "-" +
                    (currentDate.getMonth() + 1).toString() +
                    "-" +
                    currentDate.getDate()
                );
              }
            } else {
              while (currentDate > endDate) {
                currentDate.setDate(currentDate.getDate() - 1);
              }
              while (currentDate.getDay() != day) {
                currentDate.setDate(currentDate.getDate() - 1);
              }
              if (currentDate >= startDate) {
                days.push(
                  currentDate.getFullYear() +
                    "-" +
                    (currentDate.getMonth() + 1).toString() +
                    "-" +
                    currentDate.getDate()
                );
              }
            }
          } else {
            currentDate.setDate(0);
            if (currentDate > endDate) {
              while (currentDate > endDate) {
                currentDate.setDate(currentDate.getDate() - 1);
              }
            }
            while (currentDate.getDay() != day) {
              currentDate.setDate(currentDate.getDate() - 1);
            }
            if (currentDate >= startDate) {
              days.push(
                currentDate.getFullYear() +
                  "-" +
                  (currentDate.getMonth() + 1).toString() +
                  "-" +
                  currentDate.getDate()
              );
            }
          }
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
  }

  useEffect(() => {
    if (props.userLocationId !== "") {
      setLocation(props.userLocationId);
    }
    if (props.orderData.orderCopyToProviders != undefined) {
      var localList = [];
      props.orderData.orderCopyToProviders.map((value, index) => {
        var orderProviderObject = {};
        orderProviderObject["orderId"] = value.orderId;
        orderProviderObject["copyToOptionSelected"] =
          value.copyToOptionSelected;
        orderProviderObject["facilityCopyToId"] = value.facilityCopyToId;
        orderProviderObject["name"] =
          value.facilityCopyToProviders.lastName +
          ", " +
          value.facilityCopyToProviders.firstName;
        localList.push(orderProviderObject);
      });
      setOrderCopyToProvidersList(localList);
    } else {
      setOrderCopyToProvidersList([]);
    }
    if (props.orderData.fasting != undefined) {
      if (props.orderData.fasting == "Yes") {
        setFastingState(true);
      } else {
        setFastingState(false);
      }
    }
    if (props.orderData.stat != undefined) {
      if (props.orderData.stat == "Yes") {
        setStatState(true);
      } else {
        setStatState(false);
      }
    }
  }, [props.locationBasedData, props.orderData]);
  async function fetchOrderingInfoDataOnLoad() {
    try {
      const [billType, orderingType] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/billtype"),
        await ApiGetCalls("GetQuickLookupsByName/orderingtype"),
      ]);
      props.setLookOrderInfo({
        billType: billType,
        orderingType: orderingType,
      });
    } catch (error) {}
  }
  useEffect(() => {
    props.handleClinicalFormChange(
      "orderCopyToProviders",
      orderCopyToProvidersList
    );
  }, [orderCopyToProvidersList]);

  useEffect(() => {
    if (props.orderData.collectedBy != undefined) {
      props.handleClinicalFormChange(
        "collectedBy",
        props.orderData.collectedBy
      );
      setCollectedByState(props.orderData.collectedBy);
    } else {
      setCollectedByState(nameAbbrArray);
      props.handleClinicalFormChange("collectedBy", nameAbbrArray);
      setOrderingProvider("");
      setBillTypeState("");
      setStatState(false);
      setFastingState(false);
    }
    if (props.orderData.stat != undefined) {
      props.handleClinicalFormChange("stat", props.orderData.stat);
    } else {
      props.handleClinicalFormChange("stat", "No");
    }
    if (props.orderData.fasting != undefined) {
      props.handleClinicalFormChange("fasting", props.orderData.fasting);
    } else {
      props.handleClinicalFormChange("fasting", "No");
    }
    if (props.orderData.orderDate != undefined) {
      props.handleClinicalFormChange("orderDate", props.orderData.orderDate);
    } else {
      props.handleClinicalFormChange(
        "orderDate",
        moment(new Date()).format("yyyy/MM/DD HH:mm:ss")
      );
    }
    if (
      props.orderData.collectionDate != undefined &&
      props.orderData.status == "Sent" &&
      props.orderData.status != undefined
    ) {
      props.handleClinicalFormChange(
        "collectionDate",
        props.orderData.collectionDate
      );
    } else {
      props.handleClinicalFormChange(
        "collectionDate",
        moment(new Date()).format("yyyy/MM/DD HH:mm:ss")
      );
    }
    if (props.orderData.orderingProvider != undefined) {
      setOrderingProvider(props.orderData.orderingProvider);
    }
    if (props.orderData.billType != undefined) {
      props.handleClinicalFormChange("billType", props.orderData.billType);
      setBillTypeState(props.orderData.billType);
    } else {
      props.handleClinicalFormChange("billType", 40);
      setBillTypeState(40);
    }

    if (props.orderData.orderType != undefined) {
      setOrderTypeState(props.orderData.orderType);
    } else {
      setOrderTypeState(38);
    }
    if (props.orderData.labComments != undefined) {
      setLabCommentsState(props.orderData.labComments);
    } else {
      setLabCommentsState("");
    }
    if (props.orderData.reportComments != undefined) {
      setReportCommentsState(props.orderData.reportComments);
    } else {
      setReportCommentsState("");
    }
  }, [props.orderData]);
  var standingOrderDates = [];
  // Array of dates
  const [dateGroupsStandingOrder, setDateGroupsStandingOrder] = useState(
    new Map()
  );

  // Create a map to group dates by year and month

  useEffect(() => {
    var dateGroups = new Map();
    if (props.standingOrderSchedule.days.length > 0) {
      if (props.standingOrderSchedule.type == "weekly") {
        const currentDate = new Date(props.standingOrderSchedule.startDate);
        while (currentDate <= props.standingOrderSchedule.endDate) {
          if (
            props.standingOrderSchedule.days.find(
              (x) => x == currentDate.getDay()
            )
          ) {
            // Monday has a day index of 1
            standingOrderDates.push(
              currentDate.getFullYear() +
                "-" +
                (currentDate.getMonth() + 1).toString() +
                "-" +
                currentDate.getDate() +
                " " +
                currentDate.getHours() +
                ":" +
                currentDate.getMinutes() +
                ":" +
                currentDate.getSeconds()
            );
          }
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        //  setStandingOrderDates(standingOrderDates);
      } else if (props.standingOrderSchedule.type == "monthly") {
        standingOrderDates = getSpecificDaysBetweenDates(
          props.standingOrderSchedule.startDate,
          props.standingOrderSchedule.endDate,
          props.standingOrderSchedule.days[0],
          props.standingOrderSchedule.week
        );
      }

      standingOrderDates.forEach((dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is zero-based

        // Create a unique key for each year and month combination
        const key = `${year}-${month}`;

        // Initialize an array for each year and month combination
        if (!dateGroups.has(key)) {
          dateGroups.set(key, []);
        }

        // Add the date to the corresponding group
        dateGroups.get(key).push(date);
      });
      setDateGroupsStandingOrder(dateGroups);
      props.setStandingOrderDates(standingOrderDates);
    } else {
      if (props.standingOrderSchedule.type != "other") {
        props.setStandingOrderDates(standingOrderDates);
        setDateGroupsStandingOrder(new Map());
      } else {
        const currentDate = new Date(props.standingOrderSchedule.startDate);
        const endDate = new Date(props.standingOrderSchedule.endDate);

        if (props.savedOrderDates.length <= 0) {
          while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
            const date = currentDate.getDate(); // Months are 0-based, so add 1

            //  yearMonthList.push({ year, month });
            const key = `${year}-${month}-${date}`;

            if (!dateGroups.has(key)) {
              dateGroups.set(key, []);
            }

            dateGroups.get(key).push(key);
            standingOrderDates.push(key);
            // Move to the next month
            currentDate.setMonth(currentDate.getMonth() + 1);

            // If we've reached the end date, exit the loop
          }
        } else {
          //savedOrderDates = props.standingOrderDates;
          props.savedOrderDates.forEach((element) => {
            var elementArray = element.split("-");
            if (!dateGroups.has(elementArray[0] + "-" + elementArray[1])) {
              dateGroups.set(elementArray[0] + "-" + elementArray[1], []);
            }
            dateGroups
              .get(elementArray[0] + "-" + elementArray[1])
              .push(element);
            standingOrderDates.push(element);
          });
        }
        props.setStandingOrderDates(standingOrderDates);
        setDateGroupsStandingOrder(dateGroups);
      }
    }
  }, [props.standingOrderSchedule]);

  const onFocus = () => {
    document.getElementById("dateRangePickerSchedule").blur();
    Swal.fire({
      allowOutsideClick: false,
      title: "Your schedule will be reset!",
      showCancelButton: true,
      confirmButtonText: "Continue",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        document.getElementById("dateRangePickerSchedule").focus();
      } else {
        document.getElementById("dateRangePickerSchedule").blur();
      }
    });
  };

  return (
    <Accordion id="OrderInformation" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>ORDER INFORMATION</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row>
              <Col className="mandatory mb-3" md={{ span: 4 }}>
                <h5 className="label-style">Ordering Provider</h5>
                {props.orderData.status == "Draft" ||
                props.orderData.status == undefined ? (
                  <Form.Select
                    value={orderingProvider}
                    disabled={
                      props.orderData.status == "Draft" ||
                      props.orderData.status == undefined
                        ? false
                        : true
                    }
                    required
                    aria-label="Ordering provider"
                    className="input-style form-control"
                    onChange={(e) => {
                      setOrderingProvider(e.target.value * 1);
                      props.handleClinicalFormChange(
                        "orderingProvider",
                        e.target.value * 1
                      );
                    }}
                  >
                    <option></option>
                    {providersData.map((value, index) => (
                      <option
                        key={index}
                        // selected={
                        //   props.orderData.orderingProvider != undefined
                        //     ? value.id == props.orderData.orderingProvider
                        //       ? true
                        //       : false
                        //     : false
                        // }
                        value={value.id}
                      >
                        {value.lastName + ", " + value.firstName}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <input
                    type="text"
                    data-type="text"
                    className="input-style form-control person"
                    value={
                      props.orderData.providers.persons.lastName +
                      ", " +
                      props.orderData.providers.persons.firstName
                    }
                    disabled
                  />
                )}
              </Col>
              <Col className="mandatory mb-3" md={{ span: 4 }}>
                <h5 className="label-style">Ordering Type</h5>
                <Form.Select
                  disabled={
                    (props.orderData.status == "Draft" ||
                      props.orderData.status == undefined) &&
                    props.orderData.orderType != standingOrderIdConfig
                      ? false
                      : true
                  }
                  value={orderTypeState}
                  required
                  aria-label="Ordering type"
                  className="input-style form-control"
                  onChange={(e) => {
                    removeErrorForDivs("OrderInformation");
                    removeErrorForDivs("clinicalAccordion");
                    props.handleClinicalFormChange("orderType", e.target.value);
                    setOrderTypeState(e.target.value * 1);
                    var orderDateValue =
                      document.getElementById("orderInfoOrderDate").value;
                    if (
                      e.target.value == 38 &&
                      moment(orderDateValue).format("yyyy-MM-DD HH:mm") >
                        moment(new Date()).format("yyyy-MM-DD HH:mm")
                    ) {
                      var field = document.getElementById("orderInfoOrderDate");
                      field.classList.add("is-invalid");
                      let valueValidationErrorDiv =
                        document.createElement("div");
                      valueValidationErrorDiv.innerHTML =
                        "You cannot select future date for draw now order";
                      valueValidationErrorDiv.className = "invalid-feedback";
                      if (
                        !field.parentElement.lastChild.classList.contains(
                          "invalid-feedback"
                        )
                      ) {
                        field.parentElement.appendChild(
                          valueValidationErrorDiv
                        );
                      }
                    }
                  }}
                >
                  {props.lookOrderInfo.orderingType?.map((value, index) => {
                    return (props.orderData.status == "Draft" ||
                      props.orderData.status == "Sent") &&
                      value.id != standingOrderIdConfig ? (
                      <option
                        key={index}
                        // selected={
                        //   props.orderData.orderType == undefined
                        //     ? value.id == 38
                        //       ? true
                        //       : false
                        //     : value.id == props.orderData.orderType
                        //     ? true
                        //     : false
                        // }
                        value={value.id}
                      >
                        {value.options}
                      </option>
                    ) : props.orderData.status == undefined ||
                      props.orderData.orderType == standingOrderIdConfig ? (
                      <option
                        key={index}
                        // selected={
                        //   props.orderData.orderType == undefined
                        //     ? value.id == 38
                        //       ? true
                        //       : false
                        //     : value.id == props.orderData.orderType
                        //     ? true
                        //     : false
                        // }
                        value={value.id}
                      >
                        {value.options}
                      </option>
                    ) : null;
                  })}
                </Form.Select>
              </Col>
              <Col className="mandatory mb-3" md={{ span: 4 }}>
                <h5 className="label-style">Bill Type</h5>
                <Form.Select
                  disabled={true}
                  // disabled={
                  //   props.orderData.status == "Draft" ||
                  //   props.orderData.status == undefined
                  //     ? false
                  //     : true
                  // }
                  value={billTypeState}
                  required
                  aria-label="Bill type"
                  className="input-style form-control"
                  onChange={(e) => {
                    props.handleClinicalFormChange("billType", e.target.value);
                    setBillTypeState(e.target.value * 1);
                  }}
                >
                  {props.lookOrderInfo.billType?.map((value, index) => {
                    return (
                      <option
                        disabled={value.id == 41 ? true : false}
                        key={index}
                        // selected={
                        //   value.id == props.orderData.billType ? true : false
                        // }
                        value={value.id}
                      >
                        {value.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              {props.clinicalInputs.orderType == standingOrderIdConfig ? (
                <>
                  <Col className="mandatory " md={{ span: 4 }}>
                    <h5 className="label-style">Date Range</h5>
                    <DatePicker
                      id="dateRangePickerSchedule"
                      onInputClick={onFocus}
                      //preventOpenOnFocus={true}
                      className="input-style form-control react-datepicker-popper"
                      selectsRange={true}
                      startDate={props.standingOrderSchedule.startDate}
                      minDate={Date.parse(
                        moment().format("YYYY-MM-DD HH:mm:ss")
                      )}
                      maxDate={Date.parse(
                        moment(props.standingOrderSchedule.startDate)
                          .add(5, "months")
                          .format("YYYY-MM-DD HH:mm:ss")
                      )}
                      endDate={props.standingOrderSchedule.endDate}
                      dateFormat="MM-dd-yyyy"
                      showNeighboringMonth={false}
                      // onFocus={onFocus}
                      onChange={(update) => {
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          startDate: update[0],
                          endDate: update[1],
                        });
                        props.setSavedOrderDates([]);

                        //setDateRange(update);
                      }}
                      //disabled={showDateRange}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      dropdownMode="select"
                    />
                    {/* <DatePickerComponent
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      required="Y"
                      className="input-style form-control"
                      formValue="Start Date"
                      onChange={props.handleClinicalFormChange}
                      onChangeFunction={() => {}}
                      name="startDate"
                      dateFormat="MM/dd/yyyy"
                      // defaultValue={props.orderD,ata.orderDate}
                      orderType={props.orderData.orderType}
                      defaultValue={
                        props.orderData.orderDate != undefined
                          ? props.orderData.orderDate
                          : undefined
                        //  != undefined ||
                        // props.clinicalInputs.orderData != undefined
                        //   ? props.orderData.orderDate
                        //   : undefined
                      }
                      defaultValue2="orderInfo"
                    />
                  </Col>
                  <Col className="mandatory" md={{ span: 4 }}>
                    <DatePickerComponent
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      required="Y"
                      className="input-style form-control"
                      formValue="End Date"
                      onChange={props.handleClinicalFormChange}
                      onChangeFunction={() => {}}
                      name="startDate"
                      dateFormat="MM/dd/yyyy"
                      // defaultValue={props.orderData.orderDate}
                      orderType={props.orderData.orderType}
                      defaultValue={
                        props.orderData.orderDate != undefined
                          ? props.orderData.orderDate
                          : undefined
                        //  != undefined ||
                        // props.clinicalInputs.orderData != undefined
                        //   ? props.orderData.orderDate
                        //   : undefined
                      }
                      defaultValue2="orderInfo"
                    /> */}
                  </Col>
                </>
              ) : (
                <>
                  <Col className="mandatory mb-3" md={{ span: 4 }}>
                    <DatePickerComponent
                      id={"orderInfoOrderDate"}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      required="Y"
                      className="input-style form-control orderInfoOrderDate"
                      formValue="Order Date"
                      onChange={props.handleClinicalFormChange}
                      onChangeFunction={() => {}}
                      name="orderDate"
                      dateFormat="MM/dd/yyyy, HH:mm"
                      showTimeSelect={true}
                      // defaultValue={props.orderData.orderDate}
                      orderType={props.orderData.orderType}
                      defaultValue={
                        props.orderData.orderDate != undefined
                          ? props.orderData.orderDate
                          : undefined
                        //  != undefined ||
                        // props.clinicalInputs.orderData != undefined
                        //   ? props.orderData.orderDate
                        //   : undefined
                      }
                      defaultValue2="orderInfo"
                    />
                  </Col>
                  <Col className="mandatory mb-3" md={{ span: 4 }}>
                    <DatePickerComponent
                      disabled={
                        // props.orderData.status == "Draft" ||
                        // props.orderData.status == undefined
                        //   ? props.clinicalInputs.orderType == 39
                        //     ? true
                        //     : false
                        //   : true
                        true
                      }
                      required="Y"
                      className="input-style form-control"
                      formValue="Collection Date"
                      onChange={props.handleClinicalFormChange}
                      name="collectionDate"
                      showTimeSelect={true}
                      dateFormat="MM/dd/yyyy, HH:mm"
                      onChangeFunction={() => {}}
                      orderType={props.orderData.orderType}
                      orderStatus={props.clinicalInputs.orderType}
                      defaultValue={
                        props.orderData.status == "Sent" &&
                        props.orderData.status != undefined
                          ? props.orderData.collectionDate
                          : undefined
                        // props.orderData.status == "Sent"
                        //   ?
                        //   : new Date()
                      }
                      defaultValue2="orderInfo"
                    />
                  </Col>
                </>
              )}
              <Col className="mandatory mb-3" md={{ span: 4 }}>
                <h5 className="label-style">Collected By</h5>

                <Form.Control
                  required
                  disabled={
                    props.orderData.status == "Draft" ||
                    props.orderData.status == undefined
                      ? false
                      : true
                  }
                  aria-label="Collected by"
                  type="text"
                  value={collectedByState}
                  name="startDate"
                  maxLength={allInputField}
                  className="input-style"
                  onChange={(e) => {
                    setCollectedByState(e.target.value);
                    props.handleClinicalFormChange(
                      "collectedBy",
                      e.target.value
                    );
                  }}
                />
              </Col>
            </Row>
            {props.clinicalInputs.orderType == standingOrderIdConfig ? (
              <>
                <Row className="scheduleType mt-3">
                  <div className="mb-3 w-auto">
                    <ButtonGroup>
                      <Button
                        active={props.standingOrderSchedule.type == "weekly"}
                        onClick={
                          props.standingOrderSchedule.type != "weekly"
                            ? () => {
                                Swal.fire({
                                  title: "Your schedule will be reset!",
                                  showCancelButton: true,
                                  confirmButtonText: "Continue",
                                }).then((result) => {
                                  /* Read more about isConfirmed, isDenied below */
                                  if (result.isConfirmed) {
                                    props.setStandingOrderSchedule({
                                      ...props.standingOrderSchedule,
                                      type: "weekly",
                                      days: [],
                                      week: 0,
                                    });
                                  }
                                });
                              }
                            : null
                        }
                      >
                        Weekly
                      </Button>

                      <Button
                        active={props.standingOrderSchedule.type == "monthly"}
                        onClick={
                          props.standingOrderSchedule.type != "monthly"
                            ? () => {
                                Swal.fire({
                                  title: "Your schedule will be reset!",
                                  showCancelButton: true,
                                  confirmButtonText: "Continue",
                                }).then((result) => {
                                  /* Read more about isConfirmed, isDenied below */
                                  if (result.isConfirmed) {
                                    props.setStandingOrderSchedule({
                                      ...props.standingOrderSchedule,
                                      type: "monthly",
                                      days: [],
                                      week: 0,
                                    });
                                  }
                                });
                              }
                            : null
                        }
                      >
                        Monthly
                      </Button>

                      <Button
                        active={props.standingOrderSchedule.type == "other"}
                        onClick={
                          props.standingOrderSchedule.type != "other"
                            ? () => {
                                Swal.fire({
                                  title: "Your schedule will be reset!",
                                  showCancelButton: true,
                                  confirmButtonText: "Continue",
                                }).then((result) => {
                                  /* Read more about isConfirmed, isDenied below */
                                  if (result.isConfirmed) {
                                    props.setStandingOrderSchedule({
                                      ...props.standingOrderSchedule,
                                      type: "other",
                                      days: [],
                                      week: 0,
                                      startDate: new Date(
                                        moment().format("YYYY-MM-DD")
                                      ),
                                      endDate: new Date(
                                        moment()
                                          .add(5, "months")
                                          .format("YYYY-MM-DD")
                                      ),
                                    });
                                    props.setSavedOrderDates([]);
                                  }
                                });
                              }
                            : null
                        }
                      >
                        Other
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
                {(props.standingOrderSchedule.type == "weekly" ||
                  props.standingOrderSchedule.type == "bi-weekly") && (
                  <Row className="scheduleDays mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Monday"
                      value={1}
                      className="mb-3"
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 1
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Tuesday"
                      className="mb-3"
                      value={2}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 2
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Wednesday"
                      className="mb-3"
                      value={3}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 3
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Thurday"
                      className="mb-3"
                      value={4}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 4
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Friday"
                      className="mb-3"
                      value={5}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 5
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Saturday"
                      className="mb-3"
                      value={6}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 6
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Sunday"
                      className="mb-3"
                      value={0}
                      checked={props.standingOrderSchedule.days.find(
                        (x) => x == 0
                      )}
                      onChange={(e) => {
                        var scheduleDaysArray =
                          props.standingOrderSchedule.days;
                        if (e.target.checked) {
                          scheduleDaysArray.push(e.target.value);
                        } else {
                          var index = scheduleDaysArray.indexOf(e.target.value); // Let's say it's Bob.
                          scheduleDaysArray.splice(index, 1);
                        }
                        props.setStandingOrderSchedule({
                          ...props.standingOrderSchedule,
                          days: scheduleDaysArray,
                        });
                      }}
                    />
                  </Row>
                )}
                {props.standingOrderSchedule.type == "monthly" && (
                  <>
                    <Row className="monthlyScheduleWeeks mb-3">
                      <div className="mb-3 mt-3 w-auto">
                        <ButtonGroup>
                          <Button
                            className="btn-secondary"
                            active={props.standingOrderSchedule.week == 0}
                            onClick={() => {
                              props.setStandingOrderSchedule({
                                ...props.standingOrderSchedule,
                                week: 0,
                              });
                            }}
                          >
                            First Week
                          </Button>
                          <Button
                            className="btn-secondary"
                            active={props.standingOrderSchedule.week == 1}
                            onClick={() => {
                              props.setStandingOrderSchedule({
                                ...props.standingOrderSchedule,
                                week: 1,
                              });
                            }}
                          >
                            Second Week
                          </Button>
                          <Button
                            className="btn-secondary"
                            active={props.standingOrderSchedule.week == 2}
                            onClick={() => {
                              props.setStandingOrderSchedule({
                                ...props.standingOrderSchedule,
                                week: 2,
                              });
                            }}
                          >
                            Third Week
                          </Button>
                          <Button
                            className="btn-secondary"
                            active={props.standingOrderSchedule.week == 3}
                            onClick={() => {
                              props.setStandingOrderSchedule({
                                ...props.standingOrderSchedule,
                                week: 3,
                              });
                            }}
                          >
                            Forth Week
                          </Button>
                          <Button
                            className="btn-secondary"
                            active={props.standingOrderSchedule.week == 4}
                            onClick={() => {
                              props.setStandingOrderSchedule({
                                ...props.standingOrderSchedule,
                                week: 4,
                              });
                            }}
                          >
                            Last Week
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Col md={{ span: 4 }}></Col>
                      <Col md={{ span: 4 }}>
                        <h5 className="label-style">Day of the week</h5>
                        <Form.Select
                          value={props.standingOrderSchedule.days[0]}
                          required
                          aria-label="Day of the week"
                          className="input-style form-control"
                          onChange={(e) => {
                            props.setStandingOrderSchedule({
                              ...props.standingOrderSchedule,
                              days: [e.target.value],
                            });
                          }}
                        >
                          <option key={"-1"} value={"-1"}></option>
                          {[
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]?.map((value, index) => {
                            return (
                              <option
                                key={index}
                                // selected={
                                //   props.orderData.orderType == undefined
                                //     ? value.id == 38
                                //       ? true
                                //       : false
                                //     : value.id == props.orderData.orderType
                                //     ? true
                                //     : false
                                // }
                                value={index}
                              >
                                {value}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col md={{ span: 4 }}></Col>
                    </Row>
                  </>
                )}
                <Row>
                  {/* <Calendar
                    value={["2023-08-10", "2023-09-15", "2023-09-10"]}
                  /> */}
                  <div className="calendarsDiv">
                    {Array.from(dateGroupsStandingOrder.keys()).map((key) => {
                      // const yearMonth = key.split("-");
                      // const year = parseInt(yearMonth[0]);
                      // const month = parseInt(yearMonth[1]);

                      return (
                        <div key={key} className="calendars">
                          <div>
                            <Calendar
                              buttons={false}
                              minDate={props.standingOrderSchedule.startDate}
                              maxDate={props.standingOrderSchedule.endDate}
                              disabled={
                                props.standingOrderSchedule.type == "other"
                                  ? false
                                  : true
                              }
                              selectRange={false}
                              showNeighboringMonth={false}
                              showNavigation={false}
                              multiple={true}
                              onChange={(e) => {
                                var yearMonth = key.split("-");
                                var formattedDates =
                                  props.standingOrderDates.filter(
                                    (item) =>
                                      !item.startsWith(
                                        yearMonth[0] + "-" + yearMonth[1]
                                      )
                                  );

                                e.forEach((value) => {
                                  formattedDates.push(
                                    new Date(value).getFullYear() +
                                      "-" +
                                      (new Date(value).getMonth() + 1) +
                                      "-" +
                                      new Date(value).getDate()
                                  );
                                });
                                props.setStandingOrderDates(formattedDates);
                              }}
                              value={dateGroupsStandingOrder.get(key)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              <Col md={{ span: 6 }} className="mb-3">
                <h5 className="label-style">Report Comments</h5>

                <Form.Control
                  as="textarea"
                  maxLength={textareaField}
                  disabled={
                    props.orderData.status == "Draft" ||
                    props.orderData.status == undefined
                      ? false
                      : true
                  }
                  name="startDate"
                  value={reportCommentsState}
                  className="input-style"
                  onChange={(e) => {
                    props.handleClinicalFormChange(
                      "reportComments",
                      e.target.value
                    );
                    setReportCommentsState(e.target.value);
                  }}
                />
              </Col>
              <Col md={{ span: 6 }} className="mb-3">
                <h5 className="label-style"> Lab Comments</h5>

                <Form.Control
                  disabled={
                    props.orderData.status == "Draft" ||
                    props.orderData.status == undefined
                      ? false
                      : true
                  }
                  as="textarea"
                  maxLength={textareaField}
                  name="startDate"
                  className="input-style"
                  value={labCommentsState}
                  onChange={(e) => {
                    props.handleClinicalFormChange(
                      "labComments",
                      e.target.value
                    );
                    setLabCommentsState(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 1 }}>
                <Form.Check
                  type="switch"
                  label="Stat"
                  name="stat"
                  checked={statState}
                  disabled={
                    props.orderData.status == "Draft" ||
                    props.orderData.status == undefined
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.handleClinicalFormChange("stat", "Yes");
                      setStatState(true);
                    } else {
                      props.handleClinicalFormChange("stat", "No");
                      setStatState(false);
                    }
                  }}
                />
              </Col>
              <Col md={{ span: 1 }}>
                <Form.Check
                  type="switch"
                  label="Fasting"
                  name="fasting"
                  checked={fastingState}
                  disabled={
                    props.orderData.status == "Draft" ||
                    props.orderData.status == undefined
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.handleClinicalFormChange("fasting", "Yes");
                      setFastingState(true);
                    } else {
                      props.handleClinicalFormChange("fasting", "No");
                      setFastingState(false);
                    }
                  }}
                  onClick={() => {}}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6 }}>
                <h5 className="label-style">Copy To</h5>
                <Table bordered>
                  <tbody>
                    {orderCopyToProvidersList.length > 0 ? (
                      orderCopyToProvidersList?.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.name}</td>
                            <td>
                              {value.copyToOptionSelected.replace(
                                /^./,
                                value.copyToOptionSelected[0].toUpperCase()
                              )}
                            </td>
                            {(props.orderData.status == "Draft" ||
                              props.orderData.status == undefined) && (
                              <td align="center">
                                <BsTrash
                                  onClick={() => {
                                    // if (
                                    //   props.orderData.status == "Draft" ||
                                    //   props.orderData.status == undefined
                                    // ) {
                                    setCopyToModalShow(true);
                                    setCopyToModalShowId(index);
                                    // }
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>Add New</td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                {(props.orderData.status == "Draft" ||
                  props.orderData.status == undefined) && (
                  <Button
                    variant="outline"
                    id="button-addon2"
                    className="input-style btn outline button float-end"
                    onClick={() => {
                      fetchCopyToData(setCopyToLocationPersons);
                      setShowCopyTo(true);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <CopyToModel
                setShowCopyTo={setShowCopyTo}
                showCopyTo={showCopyTo}
                copyToLocationPersons={copyToLocationPersons}
                locationId={location}
                setOrderCopyToProvidersList={setOrderCopyToProvidersList}
                orderCopyToProvidersList={orderCopyToProvidersList}
                handleSubmit={(arrayList) => {
                  props.handleClinicalFormChange(
                    "orderCopyToProviders",
                    arrayList
                  );
                }}
              />
              <WarningModel
                show={copyToModalShow}
                heading="Delete"
                bodytext="Are you sure you want to delete this CopyTo Provider?"
                onHide={() => setCopyToModalShow(false)}
                onyes={() => {
                  orderCopyToProvidersList.splice(copyToModalShowId, 1);
                  props.handleClinicalFormChange(
                    "orderCopyToProviders",
                    orderCopyToProvidersList
                  );
                  // setOrderCopyToProvidersList(
                  //   orderCopyToProvidersList.splice(copyToModalShowId, 1)
                  // );
                  // setOrderCopyToProvidersList(orderCopyToProvidersList);

                  setCopyToModalShow(false);
                }}
              />
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
export default OrderInformationTab;
