import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  handleUpdateClassesOnFormObjects,
  checkIfRelationisSelf,
  copySameAddressAsPatient,
  lookupDataFeed,
  removeErrorForDisabledDivs,
  updateDataToFields,
  displayInDateFormat,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { Form, Row, Col, Container } from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import Spinner from "react-bootstrap/Spinner";
import { ApiGetCalls } from "../../../services/Api";
import {
  allInputField,
  addressFieldLimit,
  middleNameLength,
  employerNameLength,
} from "../../../config/config";
import MaskedInput from "react-text-mask";

function GuarantorInfo({
  patienDemographicForm,
  patientObj,
  lookUpData,
  setinsuranceBlockList,
  patientTabMode,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  handleAllFormChange,
  handleDateChange,
  setRelation,
  inputs,
  relation,
  setInputs,
  setSameAddressAsPatient,
  sameAddressAsPatient,
}) {
  /*
  const addDisabled = () => {
    const parentDiv = document.getElementById("GuarantorAccordion");
    const formFields = parentDiv.getElementsByClassName("form-control");
    
    for (let field of formFields) {
      if (field.disabled === false) field.disabled = true;
    }
     };
  */

  useEffect(() => {
    if (Object.keys(inputs).length != 0) {
      if (inputs["persons.addresses.zip"] != undefined)
        setzipMask(
          inputs["persons.addresses.zip"] != undefined
            ? inputs["persons.addresses.zip"].length > 5
              ? "99999-9999"
              : "99999"
            : "99999"
        );
    }
    removeErrorForDisabledDivs("GuarantorAccordion");
  }, [relation, sameAddressAsPatient]);

  const [zipMask, setzipMask] = React.useState(
    patientTabMode === "view"
      ? inputs["guarantors.persons.addresses.zip"] == null
        ? "99999"
        : inputs["guarantors.persons.addresses.zip"].length > 5
        ? "99999-9999"
        : "99999"
      : "99999"
  );
  useEffect(() => {
    if (
      relation["guarantors"] == "self" ||
      sameAddressAsPatient["guarantors"]
    ) {
      setzipMask(
        inputs["persons.addresses.zip"] != undefined
          ? inputs["persons.addresses.zip"].length > 5
            ? "99999-9999"
            : "99999"
          : "99999"
      );
    } else {
      setzipMask(
        inputs["guarantors.persons.addresses.zip"] != undefined
          ? inputs["guarantors.persons.addresses.zip"].length > 5
            ? "99999-9999"
            : "99999"
          : "99999"
      );
    }
  }, [inputs]);
  const [fetchingAddress, setFetchingAddress] = React.useState(false);
  function getAddressByZipCode(zipCode) {
    var filteredZipCode = zipCode.split("-");
    if (filteredZipCode[0] != "") {
      ApiGetCalls("GetAddressByZip/" + filteredZipCode[0]).then((data) => {
        if (data != {}) {
          setInputs({
            ...inputs,
            "guarantors.persons.addresses.city": data?.City,
            "guarantors.persons.addresses.state": data?.State,
          });
        }
        setFetchingAddress(false);
      });
    } else {
      setFetchingAddress(false);
    }
  }
  return (
    <Container className="label-style parentcontainer">
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          {/* {patienDemographicForm == "data" && (
            <input
              type="text"
              name="id"
              required
              value={patientObj?.guarantors?.id}
              className="input-style form-control"
              hidden
            />
          )} */}
          <h5 className="label-style">Relation</h5>
          <Form.Select
            onChange={(e) => {
              if (e.target.value == 31) {
                setSameAddressAsPatient({
                  ...sameAddressAsPatient,
                  guarantors: false,
                });
                setRelation({ ...relation, guarantors: "self" });
              } else {
                if (relation["guarantors"] == "self") {
                  let updateFieldsState = {
                    ...inputs,
                    ["guarantors.persons.lastName"]: "",
                    ["guarantors.persons.firstName"]: "",
                    ["guarantors.persons.middleName"]: "",
                    ["guarantors.persons.sex"]: "",
                    ["guarantors.persons.dob"]: null,
                    ["guarantors.persons.addresses.street1"]: "",
                    ["guarantors.persons.addresses.street2"]: "",
                    ["guarantors.persons.addresses.zip"]: "",
                    ["guarantors.persons.addresses.state"]: "",
                    ["guarantors.persons.addresses.city"]: "",
                    ["guarantors.persons.phone"]: "",
                  };
                  setInputs(updateFieldsState);
                }
                relation["guarantors"] = "";
                setRelation(relation);
              }
              // checkIfRelationisSelf(e.target.value, "Guarantor");
              // removeErrorForDisabledDivs("GuarantorAccordion");
              handleAllFormChange(e);
            }}
            required
            name="guarantors.relation"
            id="Guarantor.relation"
            aria-label="Relation"
            className="input-style form-control Guarantor"
            data-type="text"
            value={inputs["guarantors.relation"]}
            autoComplete="off"
          >
            <option key={"gR"} value=""></option>
            {lookUpData.relationData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Last Name</h5>
          <input
            aria-label="Last name"
            type="text"
            maxLength={allInputField}
            className="input-style form-control Guarantor.persons"
            name="guarantors.persons.lastName"
            id="Guarantor.lastName"
            required
            value={
              relation["guarantors"] == "self"
                ? inputs["persons.lastName"]
                : inputs["guarantors.persons.lastName"]
            }
            data-type="text"
            onChange={handleAllFormChange}
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">First Name</h5>
          <input
            aria-label="First name"
            type="text"
            maxLength={allInputField}
            className="input-style form-control Guarantor.persons"
            name="guarantors.persons.firstName"
            id="Guarantor.firstName"
            required
            data-type="text"
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self"
                ? inputs["persons.firstName"]
                : inputs["guarantors.persons.firstName"]
            }
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Middle Initial</h5>
          <input
            aria-label="Middle initial"
            type="text"
            maxLength={middleNameLength}
            className="input-style form-control Guarantor.persons"
            name="guarantors.persons.middleName"
            id="Guarantor.middleName"
            data-type="text"
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self"
                ? inputs["persons.middleName"]
                : inputs["guarantors.persons.middleName"]
            }
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Date of Birth</h5>
          <DatePicker
            preventOpenOnFocus={true}
            className="input-style form-control Guarantor.persons"
            name="guarantors.persons.dob"
            ariaLabelledBy="Date of birth"
            id="guarantors.persons.dob"
            data-type="text"
            maxDate={new Date()}
            selected={
              relation["guarantors"] == "self"
                ? inputs["persons.dob"] != undefined
                  ? new Date(inputs["persons.dob"])
                  : null
                : inputs["guarantors.persons.dob"] != undefined && inputs["guarantors.persons.dob"] != ''
                ? new Date(inputs["guarantors.persons.dob"])
                : null
            }
            dateFormat="MM/dd/yyyy"
            onChange={(date) => {
              handleDateChange(
                date != null
                  ? moment(date).format("yyyy-MM-DDT00:00:00")
                  : null,
                "guarantors.persons.dob"
              );
            }}
            required
            showMonthDropdown={true}
            showYearDropdown={true}
            dropdownMode="select"
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
            customInput={
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder={"mm/dd/yyyy"}
                keepCharPositions={true}
                guide={true}
              />
            }
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender</h5>
          <Form.Select
            required
            name="guarantors.persons.sex"
            data-type="text"
            id="Guarantor.sex"
            aria-label="Gender"
            className="input-style form-control Guarantor.persons"
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self"
                ? inputs["persons.sex"]
                : inputs["guarantors.persons.sex"]
            }
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
          >
            <option key={"gsex"} value=""></option>
            {lookUpData.sexData?.map((value, Index) => (
              <option key={Index} value={value.id}>
                {value.options}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="form-check">
            <input
              type="checkbox"
              maxLength={allInputField}
              className="form-check-input form-control Guarantor.addresses"
              name="guarantors.persons.addresses.useSameAddressAsPatient"
              id="Guarantor.useSameAddressAsPatient"
              value="Y"
              onChange={handleAllFormChange}
              onClick={(e) => {
                if (e.target.checked == true) {
                  setSameAddressAsPatient({
                    ...sameAddressAsPatient,
                    guarantors: true,
                  });
                } else {
                  if (sameAddressAsPatient["guarantors"]) {
                    let updateFieldsState = {
                      ...inputs,

                      ["guarantors.persons.addresses.street1"]: "",
                      ["guarantors.persons.addresses.street2"]: "",
                      ["guarantors.persons.addresses.zip"]: "",
                      ["guarantors.persons.addresses.state"]: "",
                      ["guarantors.persons.addresses.city"]: "",
                    };
                    setInputs(updateFieldsState);
                  }
                  sameAddressAsPatient["guarantors"] = false;
                  setSameAddressAsPatient(sameAddressAsPatient);
                }
                // copySameAddressAsPatient("Guarantor");
                //removeErrorForDisabledDivs("GuarantorAccordion");
              }}
              disabled={relation["guarantors"] == "self" ? true : false}
              checked={sameAddressAsPatient["guarantors"]}
            />{" "}
            <span>Same address as patient</span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Street 1</h5>
          <input
            type="text"
            aria-label="Street 1"
            maxLength={addressFieldLimit}
            className="input-style form-control Guarantor.addresses"
            name="guarantors.persons.addresses.street1"
            id="Guarantor.street1"
            data-type="text"
            required
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
                ? inputs["persons.addresses.street1"]
                : inputs["guarantors.persons.addresses.street1"]
            }
            disabled={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
            }
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Street 2</h5>
          <input
            type="text"
            aria-label="Street 2"
            maxLength={addressFieldLimit}
            className="input-style form-control Guarantor.addresses"
            name="guarantors.persons.addresses.street2"
            id="Guarantor.street2"
            data-type="text"
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
                ? inputs["persons.addresses.street2"]
                : inputs["guarantors.persons.addresses.street2"]
            }
            disabled={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
            }
            autoComplete="off"
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Zip/Postal Code</h5>
          <InputMask
            mask={zipMask}
            maskChar=""
            name="guarantors.persons.addresses.zip"
            id="Guarantor.zip"
            aria-label="Zip"
            data-type="text"
            value={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
                ? inputs["persons.addresses.zip"]
                : inputs["guarantors.persons.addresses.zip"]
            }
            required
            className="input-style form-control Guarantor.addresses validate_zip"
            onKeyDown={(event) => {
              if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105)
              ) {
                let reGexForZip = /(^\d{5})/;
                if (reGexForZip.test(event.target.value)) {
                  setzipMask("99999-9999");
                } else {
                  setzipMask("99999");
                }
              }
            }}
            onChange={handleAllFormChange}
            disabled={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
            }
            onBlur={(event) => {
              if (event.target.value.split("-")[1] == "") {
                setzipMask("99999");
              }
              setFetchingAddress(true);
              getAddressByZipCode(event.target.value);
            }}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">City</h5>
          <input
            aria-label="City"
            type="text"
            maxLength={allInputField}
            className="input-style form-control Guarantor.addresses"
            name="guarantors.persons.addresses.city"
            id="Guarantor.city"
            data-type="text"
            required
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
                ? inputs["persons.addresses.city"]
                : inputs["guarantors.persons.addresses.city"]
            }
            disabled={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
            }
            autoComplete="off"
          />
          {fetchingAddress ? (
            <Spinner
              className="spinner_field"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Col>

        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">State/Province/Region</h5>
          <input
            aria-label="State"
            type="text"
            maxLength={allInputField}
            className="input-style form-control Guarantor.addresses"
            name="guarantors.persons.addresses.state"
            id="Guarantor.state"
            data-type="text"
            required
            onChange={handleAllFormChange}
            value={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
                ? inputs["persons.addresses.state"]
                : inputs["guarantors.persons.addresses.state"]
            }
            disabled={
              relation["guarantors"] == "self" ||
              sameAddressAsPatient["guarantors"]
            }
            autoComplete="off"
          />
          {fetchingAddress ? (
            <Spinner
              className="spinner_field"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Col>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Primary Phone #</h5>
          <InputMask
            mask="+1\ (999) 999-9999"
            maskChar=" "
            aria-label="Primary phone"
            value={
              relation["guarantors"] == "self"
                ? inputs["persons.phone"]
                : inputs["guarantors.persons.phone"]
            }
            type="text"
            className="input-style form-control Guarantor.persons validate_mobile"
            name="guarantors.persons.phone"
            id="Guarantor.phone"
            data-type="text"
            onChange={handleAllFormChange}
            disabled={relation["guarantors"] == "self" ? true : false}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }}>
          <h5 className="label-style">Employer Name</h5>
          <input
            aria-label="Employer"
            type="text"
            maxLength={employerNameLength}
            className="input-style form-control Guarantor"
            name="guarantors.employer"
            id="Guarantor.employer"
            data-type="text"
            onChange={handleAllFormChange}
            value={inputs["guarantors.employer"]}
            autoComplete="off"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default GuarantorInfo;
