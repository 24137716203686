import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ApiGetCalls } from "../../../services/Api";
import { Row, Col } from "react-bootstrap";
import dateFormat from "dateformat";
import SelectBody from "./SelectBody";
import InputWithLoader from "./SearchInputFieldWithLoader";
import AdvanceSearchModal from "./AdvancedSearchModal";
import Spinner from "react-bootstrap/Spinner";
import {
  updateDataToFields,
  accordionToggle,
  numberOfInsurancesInJSON,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { snackBar } from "../../../services/Alert";

function SearchPatientAndSelect({
  setpatienDemographicForm,
  onChange,
  patientObj,
  lookUpData,
  setpatientTabMode,
  patientTabMode,
  setinsuranceBlockList,
  sexLookup,
  editMode,
  setEditMode,
  setInputs,
  setRelation,
  setSameAddressAsPatient,
  UserWantToSaveUnSavedData,
  saveUserChanges,
  setPrimaryInsurance,
  setPatientInUpdateMode,
  facilityId,
  filter,
  setFilter,
}) {
  const [patients, setPatients] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {}, [patients]);

  useEffect(() => {
    if (!saveUserChanges && patientTabMode === "form") {
      setPatientInUpdateMode(false);
      setpatienDemographicForm("data");
      setpatientTabMode("view");
      if (patientObj.length != 0) {
        setinsuranceBlockList(numberOfInsurancesInJSON(patientObj));
      }
      if (patientObj.length != 0) {
        for (var i = 0; i < numberOfInsurancesInJSON(patientObj); i++) {
          if (patientObj.patientInsurances[i].primaryInsurance == "Y") {
            setPrimaryInsurance(i);
            break;
          }
        }
      }
    }

    if (!saveUserChanges && editMode) {
      setPatientInUpdateMode(false);
      setInputs({});
      setSameAddressAsPatient({});
      setRelation({});
      setEditMode(false);
      if (patientObj.length != 0) {
        setinsuranceBlockList(numberOfInsurancesInJSON(patientObj));
      }
      if (patientObj.length != 0) {
        for (var i = 0; i < numberOfInsurancesInJSON(patientObj); i++) {
          if (patientObj.patientInsurances[i].primaryInsurance == "Y") {
            setPrimaryInsurance(i);
            break;
          }
        }
      }
    }
  }, [saveUserChanges]);

  const fetchData = (value) => {
    ApiGetCalls("GetSearchedPatient/" + value).then((data) => {
      setPatients(data);
      setLoader(false);
      setShowResults(true);
    });
  };

  // const [filter, setFilter] = useState("");
  useEffect(() => {
    if (patientObj.length != 0)
      setFilter(
        patientObj?.persons?.lastName + ", " + patientObj?.persons?.firstName
      );
  }, [patientObj]);
  const [showResults, setShowResults] = useState(false);
  const rowClickHandler = (selectedPatientObj, id) => {
    setShowResults(false);
    setFilter(selectedPatientObj[1]);
    // accordionToggle("patientAccordion", "open");
    onChange(id);
    snackBar("Selected Patient: " + selectedPatientObj[1], "info");
  };
  var sexString = "-";
  const handleClickAddPatient = async () => {
    if (patientTabMode == "form") {
      await UserWantToSaveUnSavedData();
    } else {
      setPatientInUpdateMode(true);
      setPrimaryInsurance(0);
      await setInputs({});
      await setinsuranceBlockList(1);
      await setSameAddressAsPatient({});
      await setRelation({});
      accordionToggle("patientAccordion", "open");
      setpatienDemographicForm("form");
      setpatientTabMode("form");
    }
    return;
  };

  const patientData = [];
  for (let i = 0; i < patients.length; i++) {
    sexString = "-";
    if (Object.keys(sexLookup).length > 0) {
      sexLookup.sex.map((x) => {
        if (x.id.toString() === patients[i].persons.sex.toString()) {
          sexString = x.options;
        }
      });
    } else {
      sexString = "-";
    }
    const patientDataObj = [
      patients[i].id.toString(),
      patients[i].persons.lastName + ", " + patients[i].persons.firstName,
      dateFormat(patients[i].persons.dob, "mm-dd-yyyy"),

      patients[i].officeId,
      sexString,
    ];
    patientData.push(patientDataObj);
  }

  return (
    <>
      <Row className="mb-3 searchbarholder">
        <Col md={{ span: 6, offset: 2 }}>
          <div className="label-style">
            Search for an existing patient OR
            <button
              disabled={
                editMode ? true : patientTabMode === "form" ? true : false
              }
              onClick={() => {
                handleClickAddPatient();
              }}
              className="primary btn button btn-custom ml-2 slim-btn"
            >
              Add New Patient
            </button>
            {patientTabMode === "form" && (
              <button
                onClick={() => {
                  handleClickAddPatient();
                }}
                className="primary btn button btn-custom ml-2 slim-btn"
              >
                Cancel
              </button>
            )}
            {/* {patientObj.length !== 0 && patientTabMode === "view" && (
              <button
                onClick={async () => {
                  let objectRelations = {};
                  let objectSameAddressesAsPastient = {};
                  // let primaryInsuranceNumber = 0;
                  if (editMode) {
                    UserWantToSaveUnSavedData();
                  } else {
                    await setInputs(
                      JSON.parse(JSON.stringify(patientDataArray))
                    );
                    if (Object.keys(patientDataArray).length != 0) {
                      if (patientDataArray["guarantors.relation"] == 31) {
                        objectRelations = {
                          ...objectRelations,
                          guarantors: "self",
                        };
                      } else {
                        objectRelations = {
                          ...objectRelations,
                          guarantors: "",
                        };
                      }
                      if (
                        patientDataArray["guarantors.persons.addressId"] ==
                        patientDataArray["persons.addressId"]
                      ) {
                        objectSameAddressesAsPastient = {
                          ...objectSameAddressesAsPastient,
                          guarantors: true,
                        };
                      } else {
                        objectSameAddressesAsPastient = {
                          ...objectSameAddressesAsPastient,
                          guarantors: false,
                        };
                      }
                      for (
                        var i = 0;
                        i < patientObj["patientInsurances"].length;
                        i++
                      ) {
                       
                        if (
                          patientDataArray[
                            "patientInsurances." + i + ".relation"
                          ] == 31
                        ) {
                          objectRelations = {
                            ...objectRelations,
                            ["patientInsurances." + i.toString()]: "self",
                          };
                        } else {
                          objectRelations = {
                            ...objectRelations,
                            ["patientInsurances." + i.toString()]: "",
                          };
                        }
                        if (
                          patientDataArray[
                            "patientInsurances." + i + ".persons.addressId"
                          ] === patientDataArray["persons.addressId"]
                        ) {
                          objectSameAddressesAsPastient = {
                            ...objectSameAddressesAsPastient,
                            ["patientInsurances." + i.toString()]: true,
                          };
                        } else {
                          objectSameAddressesAsPastient = {
                            ...objectSameAddressesAsPastient,
                            ["patientInsurances." + i.toString()]: false,
                          };
                        }
                        // if (
                        //   patientDataArray[
                        //     "patientInsurances." + i + ".primaryInsurance"
                        //   ] === "Y"
                        // ) {
                        //   primaryInsuranceNumber =
                        //     "patientInsurances." + i + ".primaryInsurance";
                        // }
                      }
                    }
                    await setRelation(objectRelations);
                    await setSameAddressAsPatient(
                      objectSameAddressesAsPastient
                    );
                    // await setPrimaryInsurance(primaryInsuranceNumber);
                    await setEditMode(true);
                  }
                }}
                className="primary btn button btn-custom ml-2 slim-btn"
              >
                {editMode ? "Cancel" : "Edit"}
              </button>
            )} */}
          </div>
          <InputWithLoader
            id="filter"
            filter={filter}
            placeholder="Start typing..."
            apiEndPoint={"GetSearchedPatient/" + facilityId + "/"}
            setData={setPatients}
            setShowResults={setShowResults}
            setFilter={setFilter}
            className="input-style"
            disabled={
              patientTabMode === "form" ? true : editMode ? true : false
            }
          />
          {showResults ? (
            <SelectBody
              rowClickHandler={rowClickHandler}
              filter={filter}
              column={["Name", "DOB", "Office Id", "Gender"]}
              dataArray={patientData}
            />
          ) : null}
        </Col>
        <Col md={{ span: 2 }} className="flexendcol">
          <div className="col-align-right">
            <AdvanceSearchModal
              rowClickHandler={rowClickHandler}
              lookUpData={lookUpData}
              editMode={editMode}
              patientTabMode={patientTabMode}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SearchPatientAndSelect;
