import { useEffect } from "react";
import dateFormat from "dateformat";
import { ApiGetCalls, ApiPostCalls } from "../services/Api";
import moment from "moment";

/* 
This function receives accordion id as ParentID iterates all form fields which have classname="Form-Control" and 
validates them
*/

export const handleValidation = (parentId) => {
  // this flag controls and lets not to auto scroll multiple times
  let scrolled = false;

  // if any error appears this flag is turned to false and further processing is halted
  let validation = true;

  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  // iterating form fields as per the id recieved.
  let reGexForZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  let reGexForMobile = /^\+1[ ]\(?[0-9]{3}\)?[ ]([0-9]{3})[-]([0-9]{4})$/;
  let reGexForEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  for (let field of formFields) {
    if (field.disabled == false) {
      let errorDiv = document.createElement("div");
      errorDiv.innerHTML =
        field.getAttribute("aria-label") != null
          ? field.getAttribute("aria-label") + " is required!"
          : field.getAttribute("aria-labelledby") + " is required!";
      errorDiv.className = "invalid-feedback";
      field.addEventListener("change", (event) => {
        if (
          (field.value !== "" && field.value !== null) ||
          field.required === false
        ) {
          field.classList.remove("is-invalid");
          if (
            field.parentElement.lastChild.classList.contains("invalid-feedback")
          ) {
            field.parentElement.removeChild(field.parentElement.lastChild);
          }
          if (
            field.value != "" &&
            field.classList.contains("validate_zip") &&
            !reGexForZip.test(field.value)
          ) {
            field.classList.add("is-invalid");
            let valueValidationErrorDiv = document.createElement("div");
            valueValidationErrorDiv.innerHTML =
              "Please enter a valid 5-digit or 9-digit " +
              field.getAttribute("aria-label") +
              " code!";
            valueValidationErrorDiv.className = "invalid-feedback";
            if (
              !field.parentElement.lastChild.classList.contains(
                "invalid-feedback"
              )
            ) {
              field.parentElement.appendChild(valueValidationErrorDiv);
            }

            // this checks and opens accordion if it is closed
            getParentNodeWithClass(field, "accordion-collapse", parentId);
            if (!scrolled) {
              field.scrollIntoView({ behavior: "smooth", block: "center" });
              scrolled = true;
              validation = false;
            }

            // this block checks field types checkbox and radio buttons, if checkbox then validation is
            // done in a different way
          } else if (
            field.value != "" &&
            field.classList.contains("validate_mobile") &&
            !reGexForMobile.test(field.value)
          ) {
            field.classList.add("is-invalid");
            let valueValidationErrorDiv = document.createElement("div");
            valueValidationErrorDiv.innerHTML =
              "Please enter a valid " + field.getAttribute("aria-label");
            valueValidationErrorDiv.className = "invalid-feedback";
            if (
              !field.parentElement.lastChild.classList.contains(
                "invalid-feedback"
              )
            ) {
              field.parentElement.appendChild(valueValidationErrorDiv);
            }

            // this checks and opens accordion if it is closed
            getParentNodeWithClass(field, "accordion-collapse", parentId);
            if (!scrolled) {
              field.scrollIntoView({ behavior: "smooth", block: "center" });
              scrolled = true;
              validation = false;
            }

            // this block checks field types checkbox and radio buttons, if checkbox then validation is
            // done in a different way
          } else if (
            field.value != "" &&
            field.classList.contains("validate_email") &&
            !reGexForEmail.test(field.value)
          ) {
            field.classList.add("is-invalid");
            let valueValidationErrorDiv = document.createElement("div");
            valueValidationErrorDiv.innerHTML =
              "Please enter a valid " + field.getAttribute("aria-label");
            valueValidationErrorDiv.className = "invalid-feedback";
            if (
              !field.parentElement.lastChild.classList.contains(
                "invalid-feedback"
              )
            ) {
              field.parentElement.appendChild(valueValidationErrorDiv);
            }

            // this checks and opens accordion if it is closed
            getParentNodeWithClass(field, "accordion-collapse", parentId);
            if (!scrolled) {
              field.scrollIntoView({ behavior: "smooth", block: "center" });
              scrolled = true;
              validation = false;
            }

            // this block checks field types checkbox and radio buttons, if checkbox then validation is
            // done in a different way
          } else if (
            field.value != "" &&
            field.classList.contains("orderInfoOrderDate") &&
            moment(field.value).format("yyyy-MM-DD HH:mm") >
              moment(new Date()).format("yyyy-MM-DD HH:mm")
          ) {
            field.classList.add("is-invalid");
            let valueValidationErrorDiv = document.createElement("div");
            valueValidationErrorDiv.innerHTML =
              "You cannot select future date for draw now order";
            valueValidationErrorDiv.className = "invalid-feedback";
            if (
              !field.parentElement.lastChild.classList.contains(
                "invalid-feedback"
              )
            ) {
              field.parentElement.appendChild(valueValidationErrorDiv);
            }

            // this checks and opens accordion if it is closed
            getParentNodeWithClass(field, "accordion-collapse", parentId);
            if (!scrolled) {
              field.scrollIntoView({ behavior: "smooth", block: "center" });
              scrolled = true;
              validation = false;
            }

            // this block checks field types checkbox and radio buttons, if checkbox then validation is
            // done in a different way
          }
        } else {
          field.classList.add("is-invalid");
          if (
            !field.parentElement.lastChild.classList.contains(
              "invalid-feedback"
            )
          ) {
            field.parentElement.appendChild(errorDiv);
          }
        }
      });
      // this block checks field type text, select and datepicker
      if (
        field.required == true &&
        (field.value == "" || field.value == null)
      ) {
        field.classList.add("is-invalid");

        if (
          !field.parentElement.lastChild.classList.contains("invalid-feedback")
        ) {
          field.parentElement.appendChild(errorDiv);
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }

        // this block checks field types checkbox and radio buttons, if checkbox then validation is
        // done in a different way
      } else if (
        field.classList.contains("form-check") &&
        field.firstChild.required == true
      ) {
        var checkValue = null;
        var divToMention;
        for (let item of document.getElementsByName(field.firstChild.name)) {
          if (item.checked == true) {
            checkValue = item.parentElement.parentElement;
          } else {
            divToMention = item.parentElement.parentElement;
          }
        }
        if (checkValue == null) {
          field.addEventListener("click", (event) => {
            divToMention.classList.remove("is-invalid");
            // divToMention.classList.remove("form-control");
          });
          divToMention.classList.add("is-invalid");
          // divToMention.classList.add("form-control");
          getParentNodeWithClass(field, "accordion-collapse", parentId);
          if (!scrolled) {
            field.scrollIntoView({ behavior: "smooth", block: "center" });
            scrolled = true;
            validation = false;
          }
          // window.scrollTo({ behavior: "smooth", top: field.offsetTop });
        } else {
          checkValue.classList.remove("is-invalid");
          // checkValue.classList.remove("form-control");
        }
      } else if (
        field.value != "" &&
        field.classList.contains("validate_zip") &&
        !reGexForZip.test(field.value)
      ) {
        field.classList.add("is-invalid");
        let valueValidationErrorDiv = document.createElement("div");
        valueValidationErrorDiv.innerHTML =
          "Please enter a valid 5-digit or 9-digit " +
          field.getAttribute("aria-label") +
          " code!";
        valueValidationErrorDiv.className = "invalid-feedback";
        if (
          !field.parentElement.lastChild.classList.contains("invalid-feedback")
        ) {
          field.parentElement.appendChild(valueValidationErrorDiv);
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }

        // this block checks field types checkbox and radio buttons, if checkbox then validation is
        // done in a different way
      } else if (
        field.value != "" &&
        field.classList.contains("validate_mobile") &&
        !reGexForMobile.test(field.value)
      ) {
        field.classList.add("is-invalid");
        let valueValidationErrorDiv = document.createElement("div");
        valueValidationErrorDiv.innerHTML =
          "Please enter a valid " + field.getAttribute("aria-label");
        valueValidationErrorDiv.className = "invalid-feedback";
        if (
          !field.parentElement.lastChild.classList.contains("invalid-feedback")
        ) {
          field.parentElement.appendChild(valueValidationErrorDiv);
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }

        // this block checks field types checkbox and radio buttons, if checkbox then validation is
        // done in a different way
      } else if (
        field.value != "" &&
        field.classList.contains("validate_email") &&
        !reGexForEmail.test(field.value)
      ) {
        field.classList.add("is-invalid");
        let valueValidationErrorDiv = document.createElement("div");
        valueValidationErrorDiv.innerHTML =
          "Please enter a valid " + field.getAttribute("aria-label");
        valueValidationErrorDiv.className = "invalid-feedback";
        if (
          !field.parentElement.lastChild.classList.contains("invalid-feedback")
        ) {
          field.parentElement.appendChild(valueValidationErrorDiv);
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }

        // this block checks field types checkbox and radio buttons, if checkbox then validation is
        // done in a different way
      } else if (
        field.value != "" &&
        field.classList.contains("orderInfoOrderDate") &&
        moment(field.value).format("yyyy-MM-DD HH:mm") >
          moment(new Date()).format("yyyy-MM-DD HH:mm")
      ) {
        field.classList.add("is-invalid");
        let valueValidationErrorDiv = document.createElement("div");
        valueValidationErrorDiv.innerHTML =
          "You cannot select future date for draw now order";
        valueValidationErrorDiv.className = "invalid-feedback";
        if (
          !field.parentElement.lastChild.classList.contains("invalid-feedback")
        ) {
          field.parentElement.appendChild(valueValidationErrorDiv);
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }

        // this block checks field types checkbox and radio buttons, if checkbox then validation is
        // done in a different way
      }
    }
  }
  var formCheckFields = parentDiv.getElementsByClassName("form-check-input");
  for (let field of formCheckFields) {
    if (
      (field.type == "checkbox" || field.type == "radio") &&
      field.required == true
    ) {
      var checkboxes = document.getElementsByClassName(
        "checkVal_" + field.name
      );
      let isChecked = false;

      for (let checkbox of checkboxes) {
        console.log(checkbox);
        if (checkbox.firstChild.checked) {
          isChecked = true;
          break;
        }
      }
      if (!isChecked) {
        field.addEventListener("click", (event) => {
          let isChecked = false;
          var checkboxes = document.getElementsByClassName(
            "checkVal_" + field.name
          );
          for (let checkbox of checkboxes) {
            console.log(checkbox);
            if (checkbox.firstChild.checked) {
              isChecked = true;
              break;
            }
          }
          if (isChecked) {
            field.parentElement.parentElement.classList.remove("is-invalid");
            if (
              field.parentElement.parentElement.parentElement.lastChild.classList.contains(
                "invalid-feedback"
              )
            ) {
              field.parentElement.parentElement.parentElement.removeChild(
                field.parentElement.parentElement.parentElement.lastChild
              );
            }
          }
        });
        field.parentElement.parentElement.classList.add("is-invalid");
        let valueValidationErrorDiv = document.createElement("div");
        valueValidationErrorDiv.innerHTML = "Please select atleast one answer";
        valueValidationErrorDiv.className = "invalid-feedback";
        if (
          !field.parentElement.parentElement.parentElement.lastChild.classList.contains(
            "invalid-feedback"
          )
        ) {
          field.parentElement.parentElement.parentElement.appendChild(
            valueValidationErrorDiv
          );
        }

        // this checks and opens accordion if it is closed
        getParentNodeWithClass(field, "accordion-collapse", parentId);
        if (!scrolled) {
          field.scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          validation = false;
        }
      }
    } // end forloop which iterates all form fields
  }
  return validation;
  // if (validation) {
  //   // no error is found and flag is still true, proceed onwards to JSON

  //   // patientAccordion identifies that we need json for all fields

  //   // handDraft() creates JSON for all fields including demographics, gurantor and insurance
  //   if (parentId === "patientAccordion") {
  //     // patientAccordion identifies that we need json for all fields

  //     // handDraft() creates JSON for all fields including demographics, gurantor and insurance
  //     // return handleDraft(parentId);
  //     return buildSavePatientJson();
  //   } else {
  //     // handleSingleBlockDraft() creates JSON for one block either demographics or gurantor aor insurance
  //     return handleSingleBlockDraft(parentId);
  //   }
  // }
};

const handleInputValueValidation = (parentId) => {
  // this flag controls and lets not to auto scroll multiple times
  let scrolled = false;

  // if any error appears this flag is turned to false and further processing is halted
  let validation = true;

  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  // iterating form fields as per the id recieved.
  let reGex = /^[0-9]+$/;
  for (let field of formFields) {
    // this block checks field type text, select and datepicker
    if (
      field.classList.contains("validate_zip") &&
      (!reGex.test(field.value) ||
        field.value.length < 5 ||
        (field.value.length > 5 && field.value.length < 10))
    ) {
      field.classList.add("is-invalid");
      let valueValidationErrorDiv = document.createElement("div");
      valueValidationErrorDiv.innerHTML =
        "Please Provide valid " +
        field.getAttribute("aria-label") +
        " or Zip code with Zip Carrier Routes";
      valueValidationErrorDiv.className = "invalid-feedback";
      if (
        !field.parentElement.lastChild.classList.contains("invalid-feedback")
      ) {
        field.parentElement.appendChild(valueValidationErrorDiv);
      }

      // this checks and opens accordion if it is closed
      getParentNodeWithClass(field, "accordion-collapse", parentId);
      if (!scrolled) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
        scrolled = true;
        validation = false;
      }

      // this block checks field types checkbox and radio buttons, if checkbox then validation is
      // done in a different way
    }
  } // end forloop which iterates all form fields

  if (validation) {
    // no error is found and flag is still true, proceed onwards to JSON
    if (parentId === "patientAccordion") {
      // patientAccordion identifies that we need json for all fields

      // handDraft() creates JSON for all fields including demographics, gurantor and insurance
      return handleDraft(parentId);
    } else {
      // handleSingleBlockDraft() creates JSON for one block either demographics or gurantor aor insurance
      return handleSingleBlockDraft(parentId);
    }
  }
};

// recurrsive function def for opening accordion if it is closed. it accepts
// element is the field reference which needs to highlighted against validation
// parentId is the reference of accordion which contains the element (field) to be opened
// classToFind is class of accordion by which they are identified. "Accordion-Collapse"

function getParentNodeWithClass(element, classToFind, parentId) {
  if (element.id != parentId) {
    let foundElement = element.parentElement.classList.contains(classToFind);

    if (foundElement) {
      element.parentElement.classList.add("show");
    }
    element = element.parentElement;
    getParentNodeWithClass(element, classToFind, parentId);
  }
  return;
}

// Recurrsive function which keeps on iterating to find the parent accordion against the field to
// be highlighted as invalid. Once accordion is found, its element-id is returend and passed back

function getParentNodeWithClassForHandleDraft(element, classToFind, parentId) {
  if (element.id != parentId) {
    let foundElement = element.parentElement.classList.contains(classToFind);

    if (foundElement) {
      return element.parentElement.previousSibling;
    }
    element = element.parentElement;
    return getParentNodeWithClassForHandleDraft(element, classToFind, parentId);
  }
}

export const handleDraft = (parentId) => {
  let formFieldsSubCollection = {};
  let formFieldsCollectionObj = {};
  let insuranceCollectionObj = {};
  let insuranceArray = [];
  let persons = {};
  let addresses = {};

  var previousAccordion = "";
  // event.preventDefault();
  // event.stopPropagation();
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");
  for (let field of formFields) {
    var currentAccordion = getParentNodeWithClassForHandleDraft(
      field,
      "accordion-collapse",
      parentId
    );
    formFieldsSubCollection["persons"] = persons;
    formFieldsSubCollection["persons"]["addresses"] = addresses;
    if (
      currentAccordion.classList.contains("insurance") &&
      parentId == "patientAccordion"
    ) {
      insuranceCollectionObj[currentAccordion.id] = formFieldsSubCollection;
    } else if (currentAccordion.id !== "demographics") {
      formFieldsCollectionObj[currentAccordion.id] = formFieldsSubCollection;
    } else {
      formFieldsCollectionObj = formFieldsSubCollection;
    }
    if (currentAccordion.id != previousAccordion && previousAccordion != "") {
      formFieldsSubCollection = {};
      persons = {};
      addresses = {};
    }
    previousAccordion = currentAccordion.id;
    if (field.classList.contains("form-check")) {
      if (field.firstChild.checked == true) {
        if (field.classList.contains("person")) {
          persons[field.firstChild.name] = field.firstChild.value;
        } else if (field.classList.contains("address")) {
          addresses[field.firstChild.name] = field.firstChild.value;
        } else {
          formFieldsSubCollection[field.firstChild.name] =
            field.firstChild.value;
        }
      }
    } else {
      if (field.classList.contains("person")) {
        persons[field.name] = field.value;
      } else if (field.classList.contains("address")) {
        addresses[field.name] = field.value;
      } else {
        formFieldsSubCollection[field.name] = field.value;
      }
    }
  }
  Object.keys(insuranceCollectionObj).map((key) => {
    insuranceArray.push(insuranceCollectionObj[key]);
  });
  // insuranceArray.push(inc);
  if (parentId == "patientAccordion") {
    formFieldsCollectionObj["patientInsurances"] = insuranceArray;
  }
  return JSON.stringify(formFieldsCollectionObj);
};

export const handleSingleBlockDraft = (parentId) => {
  let formFieldsCollection = {};
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");
  for (let field of formFields) {
    var currentAccordion = getParentNodeWithClassForHandleDraft(
      field,
      "accordion-collapse",
      parentId
    );
    if (field.classList.contains("form-check")) {
      if (field.firstChild.checked == true) {
        formFieldsCollection[field.firstChild.name] = field.firstChild.value;
      }
    } else {
      formFieldsCollection[field.name] = field.value;
    }
  }
  return JSON.stringify(formFieldsCollection);
};

export const handleUpdateClassesOnFormObjects = (
  parentId,
  patientObj,
  datePickerStatus
) => {
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  let btnEdit = document.createElement("button");
  btnEdit.innerHTML = "Edit";
  btnEdit.className = "small-button";
  btnEdit.addEventListener("click", function () {
    datePickerStatus(false);
    handleUpdateClassesOnFormObjects(parentId, patientObj, datePickerStatus);
  });

  let btnSave = document.createElement("button");
  btnSave.innerHTML = "Save";
  btnSave.className = "small-button";
  btnSave.addEventListener("click", async function () {
    datePickerStatus(false);
    let jsonData = "";
    jsonData = handleValidation(parentId);

    if (jsonData != null) {
      let response = [];

      if (parentId == "DemographicAccordion") {
        response = await ApiPostCalls(jsonData, "updatePatient");
      }
      if (parentId == "GuarantorAccordion") {
        response = await ApiPostCalls(jsonData, "updateGuarantor");
      }
      if (parentId == "InsuranceAccordion") {
        response = await ApiPostCalls(jsonData, "updateInsurance");
      }

      if (response["status"] === "Success") {
        for (let field of actionButtons) {
          field.innerHTML = "";
          field.appendChild(btnEdit);
        }
        for (let field of formFields) {
          if (field.disabled === false) field.disabled = true;
          field.classList.remove("is-invalid");
        }
      }
    }
  });

  let btnCancel = document.createElement("button");
  btnCancel.innerHTML = "Cancel";
  btnCancel.className = "small-button";
  btnCancel.addEventListener("click", function () {
    datePickerStatus(false);
    for (let field of actionButtons) {
      field.innerHTML = "";
      field.appendChild(btnEdit);
    }
    for (let field of formFields) {
      if (field.disabled === false) field.disabled = true;
      field.classList.remove("is-invalid");
    }

    if (parentId == "DemographicAccordion") {
      formFields[0].value = patientObj?.id;
      formFields[1].value = patientObj?.persons?.lastName;
      formFields[2].value = patientObj?.persons?.firstName;
      formFields[3].value = dateFormat(patientObj?.persons?.dob, "mm-dd-yyyy");
      formFields[4].value = patientObj?.persons?.sex;
      formFields[5].value = patientObj?.persons?.addresses?.street1;
      formFields[6].value = patientObj?.persons?.addresses?.zip;
      formFields[7].value = patientObj?.persons?.addresses?.city;
      formFields[8].value = patientObj?.persons?.addresses?.state;
      formFields[9].value = patientObj?.persons?.phone;
      formFields[10].value = patientObj?.race;
      formFields[11].value = patientObj?.ethnicGroup;
    }
    if (parentId == "GuarantorAccordion") {
      formFields[0].value = patientObj?.guarantors?.id;
      formFields[1].value = patientObj?.guarantors?.relation;
      formFields[2].value = patientObj?.guarantors?.persons?.lastName;
      formFields[3].value = patientObj?.guarantors?.persons?.firstName;
      formFields[4].value = dateFormat(
        patientObj?.guarantors?.persons?.dob,
        "mm-dd-yyyy"
      );
      formFields[5].value = patientObj?.guarantors?.persons?.sex;
      formFields[6].value = patientObj?.guarantors?.persons?.addresses?.street1;
      formFields[7].value = patientObj?.guarantors?.persons?.addresses?.zip;
      formFields[8].value = patientObj?.guarantors?.persons?.addresses?.city;
      formFields[9].value = patientObj?.guarantors?.persons?.addresses?.state;
      formFields[10].value = patientObj?.guarantors?.persons?.phone;
    }

    if (parentId == "InsuranceAccordion") {
      formFields[0].value =
        patientObj.length !== 0 ? patientObj?.patientInsurances[0]?.id : "";
      formFields[1].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.insuranceCompanyId
          : "";
      formFields[2].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.policyId
          : "";
      formFields[3].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.relation
          : "";
      formFields[4].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.lastName
          : "";
      formFields[5].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.firstName
          : "";
      formFields[6].value =
        patientObj.length !== 0
          ? dateFormat(
              patientObj?.patientInsurances[0]?.persons?.dob,
              "mm-dd-yyyy"
            )
          : "";
      formFields[7].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.sex
          : "";
      formFields[8].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.addresses?.street1
          : "";
      formFields[9].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.addresses?.zip
          : "";
      formFields[10].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.addresses?.city
          : "";
      formFields[11].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.addresses?.state
          : "";
      formFields[12].value =
        patientObj.length !== 0
          ? patientObj?.patientInsurances[0]?.persons?.phone
          : "";
    }
  });

  const actionButtons = document.getElementsByClassName(
    "ActionButtons" + parentId
  );

  for (let field of actionButtons) {
    field.innerHTML = "";
    field.appendChild(btnSave);
    field.appendChild(btnCancel);
  }

  for (let field of formFields)
    if (field.disabled === true) field.disabled = false;
};

export const handleFormFieldsToAddNew = (
  parentId,
  type,
  patientObj,
  demodatePickerStatus,
  guardatePickerStatus,
  incdatePickerStatus
) => {
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  let actionDiv = document.getElementsByClassName("ActionButtons");
  if (type == "add") {
    for (let field of actionDiv) field.hidden = true;
    // demodatePickerStatus(false),
    //   guardatePickerStatus(false),
    //   incdatePickerStatus(false);
    handleUpdateClassesOnFormObjects(
      parentId,
      patientObj,
      demodatePickerStatus
    );
    for (let field of formFields) field.value = "";
  } else {
    // demodatePickerStatus(true),
    //   guardatePickerStatus(true),
    //   incdatePickerStatus(true);
    for (let field of actionDiv) {
      field.hidden = false;
      let btnEdit = document.createElement("button");
      btnEdit.innerHTML = "Edit";
      btnEdit.className = "small-button";
      btnEdit.addEventListener("click", function () {
        handleUpdateClassesOnFormObjects(
          field.closest(".accordion").id,
          patientObj,
          demodatePickerStatus
        );
      });

      field.innerHTML = "";
      field.appendChild(btnEdit);

      if (field.closest(".accordion").id == "DemographicAccordion") {
        if (patientObj.length !== 0) {
          const DemographicDiv = document.getElementById(
            "DemographicAccordion"
          );
          const DemographicFormFields =
            DemographicDiv.getElementsByClassName("form-control");
          DemographicFormFields[0].value = patientObj?.id;
          DemographicFormFields[1].value = patientObj?.persons?.lastName;
          DemographicFormFields[2].value = patientObj?.persons?.firstName;
          DemographicFormFields[3].value = dateFormat(
            patientObj?.persons?.dob,
            "mm-dd-yyyy"
          );
          DemographicFormFields[4].value = patientObj?.persons?.sex;
          DemographicFormFields[5].value =
            patientObj?.persons?.addresses?.street1;
          DemographicFormFields[6].value = patientObj?.persons?.addresses?.zip;
          DemographicFormFields[7].value = patientObj?.persons?.addresses?.city;
          DemographicFormFields[8].value =
            patientObj?.persons?.addresses?.state;
          DemographicFormFields[9].value = patientObj?.persons?.phone;
          DemographicFormFields[10].value = patientObj?.race;
          DemographicFormFields[11].value = patientObj?.ethnicGroup;
        }
      }
      if (field.closest(".accordion").id == "GuarantorAccordion") {
        if (patientObj.length !== 0) {
          const GuarantorDiv = document.getElementById("GuarantorAccordion");
          const GuarantorFormFields =
            GuarantorDiv.getElementsByClassName("form-control");
          GuarantorFormFields[0].value = patientObj?.guarantors?.id;
          GuarantorFormFields[1].value = patientObj?.guarantors?.relation;
          GuarantorFormFields[2].value =
            patientObj?.guarantors?.persons?.lastName;
          GuarantorFormFields[3].value =
            patientObj?.guarantors?.persons?.firstName;
          GuarantorFormFields[4].value = dateFormat(
            patientObj?.guarantors?.persons?.dob,
            "mm-dd-yyyy"
          );
          GuarantorFormFields[5].value = patientObj?.guarantors?.persons?.sex;
          GuarantorFormFields[6].value =
            patientObj?.guarantors?.persons?.addresses?.street1;
          GuarantorFormFields[7].value =
            patientObj?.guarantors?.persons?.addresses?.zip;
          GuarantorFormFields[8].value =
            patientObj?.guarantors?.persons?.addresses?.city;
          GuarantorFormFields[9].value =
            patientObj?.guarantors?.persons?.addresses?.state;
          GuarantorFormFields[10].value =
            patientObj?.guarantors?.persons?.phone;
        }
      }
      if (field.closest(".accordion").id == "InsuranceAccordion") {
        const InsurancesDiv = document.getElementById("InsuranceAccordion");
        const InsurancesFormFields =
          InsurancesDiv.getElementsByClassName("form-control");
        InsurancesFormFields[0].value =
          patientObj.length !== 0 ? patientObj?.patientInsurances[0]?.id : "";
        InsurancesFormFields[1].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.insuranceCompanyId
            : "";
        InsurancesFormFields[2].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.policyId
            : "";
        InsurancesFormFields[3].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.relation
            : "";
        InsurancesFormFields[4].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.lastName
            : "";
        InsurancesFormFields[5].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.firstName
            : "";
        InsurancesFormFields[6].value =
          patientObj.length !== 0
            ? dateFormat(
                patientObj?.patientInsurances[0]?.persons?.dob,
                "mm-dd-yyyy"
              )
            : "";
        InsurancesFormFields[7].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.sex
            : "";
        InsurancesFormFields[8].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.addresses?.street1
            : "";
        InsurancesFormFields[9].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.addresses?.zip
            : "";
        InsurancesFormFields[10].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.addresses?.city
            : "";
        InsurancesFormFields[11].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.addresses?.state
            : "";
        InsurancesFormFields[12].value =
          patientObj.length !== 0
            ? patientObj?.patientInsurances[0]?.persons?.phone
            : "";
      }
    }
    for (let field of formFields) {
      if (field.disabled === false) field.disabled = true;
      field.classList.remove("is-invalid");
    }
  }
};

export function accordionToggle(parentId, state) {
  let accordions = document
    .getElementById(parentId)
    .getElementsByClassName("accordion-button");
  for (let accordion of accordions) {
    if (state == "open") {
      if (accordion.getAttribute("aria-expanded") === "false") {
        accordion.click();
      }
    }
    if (state == "close") {
      if (accordion.getAttribute("aria-expanded") === "true") {
        accordion.click();
      }
    }
  }
}

export function checkIfRelationisSelf(optionSelected, SectionName) {
  const formFields = document.getElementsByClassName("form-control");
  // const SectionName = "Guarantor";

  for (let field of formFields) {
    let field_id = field.id;
    if (field_id.length > 0) {
      if (
        SectionName.length > 0 &&
        field_id.indexOf(SectionName) === 0 &&
        field_id !== SectionName + ".relation" &&
        field_id !== SectionName + ".InsuranceCompanyId" &&
        field_id !== SectionName + ".policyId"
      )
        if (optionSelected === "31") {
          if (document.getElementById(field.id).type === "checkbox") {
            document.getElementById(field.id).checked = false;
            field.disabled = true;
          }

          if (document.getElementById(field.id).type === "select-one") {
            let itemSelected = document.getElementById(
              "Demographic." + field.name
            ).selectedIndex;
            document.getElementById(field.id).options[
              itemSelected
            ].selected = true;

            field.disabled = true;
          }

          if (
            document.getElementById(field.id).type !== "checkbox" &&
            document.getElementById("Demographic." + field.name) !== null &&
            document.getElementById(field.id).type !== "select-one" &&
            field_id.name !== "employer"
          ) {
            document.getElementById(field.id).value = document.getElementById(
              "Demographic." + field.name
            ).value;
            field.disabled = true;
          }
        } else field.disabled = false;
    }
  }
}

export function MobileNotAvailable(checkboxid) {
  let mobileFieldReference = "";
  let FieldReference = "";

  FieldReference = document.getElementById(checkboxid);
  mobileFieldReference = document.getElementById("Demographic.mobile");

  if (FieldReference.checked) {
    mobileFieldReference.value = "";
    mobileFieldReference.disabled = true;
  } else mobileFieldReference.disabled = false;
}

export function copySameAddressAsPatient(copyToSectionName) {
  let useSameAddressAsPatientCheckBox = document.getElementById(
    copyToSectionName + ".useSameAddressAsPatient"
  );

  let fieldReferenceDataTypee =
    useSameAddressAsPatientCheckBox.getAttribute("data-status");

  //alert(fieldReferenceDataTypee);
  let fieldReferenceDataTypef = useSameAddressAsPatientCheckBox.setAttribute(
    "data-status",
    "CHECKED"
  );
  //alert(useSameAddressAsPatientCheckBox.getAttribute("data-status"));

  let copyFromSectionName = "Demographic";

  if (useSameAddressAsPatientCheckBox.checked) {
    document.getElementById(copyToSectionName + ".street1").value =
      document.getElementById(copyFromSectionName + ".street1").value;
    document.getElementById(copyToSectionName + ".street1").disabled = true;

    document.getElementById(copyToSectionName + ".street2").value =
      document.getElementById(copyFromSectionName + ".street2").value;
    document.getElementById(copyToSectionName + ".street2").disabled = true;

    document.getElementById(copyToSectionName + ".zip").value =
      document.getElementById(copyFromSectionName + ".zip").value;
    document.getElementById(copyToSectionName + ".zip").disabled = true;

    document.getElementById(copyToSectionName + ".city").value =
      document.getElementById(copyFromSectionName + ".city").value;
    document.getElementById(copyToSectionName + ".city").disabled = true;

    document.getElementById(copyToSectionName + ".state").value =
      document.getElementById(copyFromSectionName + ".state").value;
    document.getElementById(copyToSectionName + ".state").disabled = true;

    document.getElementById(copyToSectionName + ".phone").value =
      document.getElementById(copyFromSectionName + ".phone").value;
    document.getElementById(copyToSectionName + ".phone").disabled = true;
  } else {
    let fieldReferenceDataTypef = useSameAddressAsPatientCheckBox.setAttribute(
      "data-status",
      "UN-CHECKED"
    );
    document.getElementById(copyToSectionName + ".street1").disabled = false;
    document.getElementById(copyToSectionName + ".street2").disabled = false;
    document.getElementById(copyToSectionName + ".zip").disabled = false;
    document.getElementById(copyToSectionName + ".city").disabled = false;
    document.getElementById(copyToSectionName + ".state").disabled = false;
    document.getElementById(copyToSectionName + ".phone").disabled = false;
  }
}

export async function updateDataToFields(
  DataSet,
  lookUpData,
  setinsuranceBlockList,
  parentId,
  setDatePickerValues,
  setZipValues,
  setPhoneValues,
  setInsuranceCompainesValues
) {
  setinsuranceBlockList(numberOfInsurancesInJSON(DataSet));
  var array = convertToArray(DataSet, "", {});
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  var jsonMap;
  let fieldDataType = "";
  var dateValuesObject = {};
  var zipValuesObject = {};
  var phoneValuesObject = {};
  var insuranceCompaniesValuesObject = {};
  for (let field of formFields) {
    jsonMap = field.getAttribute("data-jsonMapping");
    fieldDataType = field.getAttribute("data-type");

    if (field.closest(".accordion").hidden === false) {
      if (field.id.indexOf("dob") > 0 && fieldDataType !== "date") {
        dateValuesObject[field.id] = array[field.id];
      }
      if (
        jsonMap !== null &&
        array[jsonMap] !== null &&
        array[jsonMap] !== ""
      ) {
        if (jsonMap.indexOf("zip") > 0) {
          zipValuesObject[jsonMap] = array[jsonMap];
        }
        if (jsonMap.indexOf("phone") > 0) {
          phoneValuesObject[jsonMap] = array[jsonMap];
        }
        if (jsonMap.indexOf("mobile") > 0) {
          phoneValuesObject[jsonMap] = array[jsonMap];
        }
        if (field.id.indexOf("insuranceCompanyId") > 0) {
          insuranceCompaniesValuesObject[field.id] = array[field.id];
          insuranceCompaniesValuesObject[jsonMap] = array[jsonMap];
        }
        if (fieldDataType === "lookup")
          field.value = updateLookupCollection(
            lookUpData,
            array[jsonMap],
            field
          );

        if (fieldDataType === "text") {
          field.value = array[jsonMap];
        }

        if (fieldDataType === "date")
          field.value = changeToDateFormat(array[jsonMap]);
      }
    }
  }
  // document.getElementById("persons.zip").value = "00000";
  await setDatePickerValues(dateValuesObject);
  await setZipValues(zipValuesObject);
  await setPhoneValues(phoneValuesObject);
  await setInsuranceCompainesValues(insuranceCompaniesValuesObject);
}

// this function returns dropdown text-value against the id passed as lookupID
// lookupData is the collection of all lookups
// lookUpID is the id to be matched
// fieldReference is the reference of field like look for race id in lookup collection
function updateLookupCollection() {} // this function should be deleted

export function LookupText(lookupDataCollection, lookUpID) {
  if (lookUpID == undefined || lookupDataCollection == undefined) return;

  let lookUpText = "";
  /*
  if (fieldReference.name === "sex") lookupDataCollection = lookupData.sexData;
  if (fieldReference.name === "race")
    lookupDataCollection = lookupData.raceData;
  if (fieldReference.name === "ethnicGroup")
    lookupDataCollection = lookupData.ethinicData;
  if (fieldReference.name === "genderIdentity")
    lookupDataCollection = lookupData.genderData;
  if (fieldReference.name === "sexualOrientation")
    lookupDataCollection = lookupData.sexualOrientationData;
  if (fieldReference.name === "relation")
    lookupDataCollection = lookupData.relationData;
*/

  for (var b = 0; b < lookupDataCollection.length; b++) {
    let collectionRow = Object.entries(lookupDataCollection[b]);

    for (var i = 0; i < collectionRow.length; i++) {
      if (collectionRow[i][1] == lookUpID) {
        lookUpText = collectionRow[i + 1][1];
      }
    }
  }
  return lookUpText;
}

export function updateDataToFields_thisFunctionisDiscarded(
  DataSet,
  lookUpData,
  setinsuranceBlockList
) {
  // DataSet is the complete JSON of user with all fields from server
  // LookUpData is the JSON for all lookup fields i.e. Sex, Race, Relation etc.

  const DataSett = {
    id: 512,
    personId: 536,
    race: 4,
    ethnicGroup: 11,
    genderIdentity: 25,
    sexualOrientation: 19,
    persons: {
      id: 536,
      addressId: 28,
      addresses: {
        id: 28,
        street1: "House no 101",
        street2: null,
        city: "New York - 102",
        zip: "10001455 -103",
        state: "New York -104",
        room: null,
        createdAt: null,
        updatedAt: "2022-11-28T13:19:22.543",
      },
      firstName: "Rashid-101",
      lastName: "Haseeb-101",
      middleName: null,
      dob: "1986-10-07T00:00:00",
      sex: 33,
      phone: "042-45457874",
      mobile: "0300-4578454",
      email: null,
      createdAt: null,
      updatedAt: "2022-11-28T13:19:22.54",
    },
    guarantorId: 8,
    guarantors: {
      id: 8,
      personId: 534,
      persons: {
        id: 534,
        addressId: 26,
        addresses: {
          id: 26,
          street1: "Street 201",
          street2: null,
          city: "New York 202",
          zip: "54121",
          state: "New York 203",
          room: null,
          createdAt: null,
          updatedAt: "2022-11-28T09:57:49.243",
        },
        firstName: "Qamar1",
        lastName: "Ali1",
        middleName: null,
        dob: "1994-11-27T00:00:00",
        sex: "33",
        phone: "9874456566",
        mobile: null,
        email: null,
        createdAt: null,
        updatedAt: "2022-11-28T09:57:49.24",
      },
      relation: 32,
      employer: null,
      createdAt: null,

      updatedAt: "2022-11-28T09:57:49.24",
    },
    officeId: null,
    mrn: null,
    ethnicGroup: "12",
    createdAt: null,
    updatedAt: "2022-11-28T13:19:22.537",
    patientInsurances: [
      {
        id: 11,
        personId: 12,
        persons: {
          id: 13,
          addressId: 14,
          addresses: {
            id: 15,
            street1: "STREET 16",
            street2: null,
            city: "New York 17",
            zip: "18",
            state: "New York 19",
            room: null,
            createdAt: null,
            updatedAt: "2022-11-20",
          },
          firstName: "ALI 21",
          lastName: "ALI 22",
          middleName: null,
          dob: "2022-11-23T00:00:00",
          sex: "33",
          phone: "2424242424",
          mobile: null,
          email: null,
          createdAt: null,
          updatedAt: "2022-11-25T09:58:04.417",
        },
        insuranceCompanyId: 26,
        insuranceCompanies: {
          id: 27,
          name: "EWS-28",
          createdAt: null,
          updatedAt: null,
        },
        patientId: 29,
        groupId: "30",
        policyId: "31",
        relation: "29",
        createdAt: null,
        updatedAt: "2022-11-28T09:58:04.413",
      },
      {
        id: 12,
        personId: 535,
        persons: {
          id: 535,
          addressId: 27,
          addresses: {
            id: 27,
            street1: "STREET 301",
            street2: null,
            city: "New York 302",
            zip: "54545",
            state: "New York 303",
            room: null,
            createdAt: null,
            updatedAt: "2022-11-28T09:58:04.417",
          },
          firstName: "ALI2",
          lastName: "ALI2",
          middleName: null,
          dob: "2022-11-28T00:00:00",
          sex: "33",
          phone: "88724455565",
          mobile: null,
          email: null,
          createdAt: null,
          updatedAt: "2022-11-28T09:58:04.417",
        },
        insuranceCompanyId: 2,
        insuranceCompanies: {
          id: 2,
          name: "EWS-101",
          createdAt: null,
          updatedAt: null,
        },
        patientId: 512,
        groupId: null,
        policyId: "988974-103",
        relation: "30",
        createdAt: null,
        updatedAt: "2022-11-28T09:58:04.413",
      },
      {
        id: 13,
        personId: 535,
        persons: {
          id: 535,
          addressId: 27,
          addresses: {
            id: 27,
            street1: "STREET 403",
            street2: null,
            city: "New York 402",
            zip: "54545",
            state: "New York 403",
            room: null,
            createdAt: null,
            updatedAt: "2022-11-28T09:58:04.417",
          },
          firstName: "SABIR2",
          lastName: "SABRI3",
          middleName: null,
          dob: "2022-11-28T00:00:00",
          sex: "33",
          phone: "88724455565",
          mobile: null,
          email: null,
          createdAt: null,
          updatedAt: "2022-11-28T09:58:04.417",
        },
        insuranceCompanyId: 1,
        insuranceCompanies: {
          id: 1,
          name: "EWS",
          createdAt: null,
          updatedAt: null,
        },
        patientId: 512,
        groupId: null,
        policyId: "988974",
        relation: "31",
        createdAt: null,
        updatedAt: "2022-11-28T09:58:04.413",
      },
    ],
  };

  setinsuranceBlockList(numberOfInsurancesInJSON(DataSet));

  // collection of all form fields
  const formFields = document.getElementsByClassName("form-control");

  // we have multiple sections on the page
  let sectionNames = ["Demographic", "Guarantor"];

  // this refers to the node of section fields in UserJSON i.e. at root level etc
  let sectionDataReferences = [DataSet, DataSet.guarantors]; //

  // this refers to the node of section persons in UserJSON i.e. lastName, firstName etc
  let sectionDataPersonReferences = [
    DataSet.persons,
    DataSet.guarantors.persons,
  ];

  // this refers to Addresses for respective sections i.e. street1, city and phone
  let sectionDataAddressReferences = [
    DataSet.persons.addresses,
    DataSet.guarantors.persons.addresses,
  ];

  let insuranceSectionLabel = "Insurance";
  let tmpLabel = "";
  let tmpCounter = 0;
  for (
    let insuranceCount = 0;
    insuranceCount < numberOfInsurancesInJSON(DataSet);
    insuranceCount++
  ) {
    tmpCounter++;
    tmpLabel = insuranceSectionLabel + tmpCounter;
    sectionNames.push(tmpLabel);
    sectionDataReferences.push(DataSet.patientInsurances[insuranceCount]);

    sectionDataPersonReferences.push(
      DataSet.patientInsurances[insuranceCount].persons
    );
    sectionDataAddressReferences.push(
      DataSet.patientInsurances[insuranceCount].persons.addresses
    );
  }

  // loop through defined sections
  for (let sectionLoop = 0; sectionLoop < sectionNames.length; sectionLoop++) {
    let sectionName = sectionNames[sectionLoop];
    let sectionDataReference = sectionDataReferences[sectionLoop];
    let sectionDataPersonReference = sectionDataPersonReferences[sectionLoop];
    let sectionDataAddressReference = sectionDataAddressReferences[sectionLoop];

    let fieldReference = "";
    let fieldReferenceDataType = "";
    let positionIndex = 0;

    // loop through form fields
    for (let field of formFields) {
      // get the field id i.e. Demographics.lastName
      let field_id = field.id;

      if (field_id.length > 0) {
        // if section is defined
        if (sectionName.length > 0 && field_id.indexOf(sectionName) === 0) {
          // get the dom object of field
          fieldReference = document.getElementById(field_id);
          fieldReferenceDataType = fieldReference.getAttribute("data-type");

          // for lookups we have to match the value from the lookup collections and then extract
          // matching value to put in field i.e. sex, race etc
          //

          if (fieldReferenceDataType === "lookup") {
            // Relation in Insurance is at different level in JSON
            // so difference dataReference need to be looked in and update value

            if (
              fieldReference.id === "Demographic.race" ||
              fieldReference.id === "Demographic.ethnicGroup" ||
              fieldReference.id === "Demographic.genderIdentity" ||
              fieldReference.id === "Demographic.sexualOrientation"
            )
              updateLookupCollection(
                lookUpData,
                sectionDataReference,
                fieldReference
              );

            if (
              (field_id.indexOf("Insurance") === 0 &&
                fieldReference.name === "relation") ||
              (field_id.indexOf("Guarantor") === 0 &&
                fieldReference.id === "Guarantor.relation")
            )
              updateLookupCollection(
                lookUpData,
                sectionDataReference,
                fieldReference
              );
            else
              updateLookupCollection(
                lookUpData,
                sectionDataPersonReference,
                fieldReference
              );
          }

          if (
            fieldReferenceDataType === "text" ||
            fieldReferenceDataType === "date"
          ) {
            // this updates root level info  i.e. policyID etc
            updateFields(sectionDataReference, fieldReference);

            // this updates person related info  i.e. lastName, firstName etc
            updateFields(sectionDataPersonReference, fieldReference);

            // this updates address related fields which i.e. street1, city etc
            updateFields(sectionDataAddressReference, fieldReference);
          }
        }
      }
    }
  }
}

// this function counts number of insurance blocks in JSON object
export const numberOfInsurancesInJSON = (DataSet) => {
  let FieldLabels = {};
  FieldLabels = Object.keys(DataSet.patientInsurances);
  return FieldLabels.length;
};

function updateFields(userDataCollection, fieldReference) {
  let FieldLabels = {};
  let FieldValues = {};
  let fieldReferenceDataType = "";
  let positionIndex = -1;

  // this extract all keys and values to separate collections for user json object
  // key will be matched from field id and on math corresponding value will be picked

  FieldLabels = Object.keys(userDataCollection);
  FieldValues = Object.values(userDataCollection);

  positionIndex = FieldLabels.indexOf(fieldReference.name);

  // If field to be updated is "insuranceCompanies", the value will be picked from a child level node
  // of object values

  if (positionIndex > -1 && fieldReference.name === "insuranceCompanies") {
    fieldReference.value = FieldValues[positionIndex]["name"];
    fieldReference.setAttribute("data-id", FieldValues[positionIndex]["id"]);

    return;
  }

  // if key is found, then pick the corresponding value
  if (positionIndex > -1) {
    fieldReferenceDataType = fieldReference.getAttribute("data-type");
    if (fieldReferenceDataType === "date")
      fieldReference.value = changeToDateFormat(FieldValues[positionIndex]);
    else fieldReference.value = FieldValues[positionIndex];
  }
}
// accept lookup collections, user json obj and field reference from page to match
// first extracts the value from user json obj against field id
// then looks for the text value from lookup collections
function updateLookupCollection_thisFunctionisDiscarded(
  lookupData,
  userDataCollection,
  fieldReference
) {
  let FieldLabels = {};
  let FieldValues = {};
  let lookupDataCollection = {};
  let positionIndex = -1;
  let searchVal = 0;

  if (fieldReference.name === "sex") lookupDataCollection = lookupData.sexData;
  if (fieldReference.name === "race")
    lookupDataCollection = lookupData.raceData;
  if (fieldReference.name === "ethnicGroup")
    lookupDataCollection = lookupData.ethinicData;
  if (fieldReference.name === "genderIdentity")
    lookupDataCollection = lookupData.genderData;

  if (fieldReference.name === "sexualOrientation")
    lookupDataCollection = lookupData.sexualOrientationData;
  if (fieldReference.name === "relation") {
    lookupDataCollection = lookupData.relationData;
  }

  FieldLabels = Object.keys(userDataCollection);
  FieldValues = Object.values(userDataCollection);

  positionIndex = FieldLabels.indexOf(fieldReference.name);

  if (positionIndex > -1) {
    searchVal = FieldValues[positionIndex];
  }

  for (var b = 0; b < lookupDataCollection.length; b++) {
    let collectionRow = Object.entries(lookupDataCollection[b]);

    for (var i = 0; i < collectionRow.length; i++) {
      if (collectionRow[i][1] == searchVal) {
        fieldReference.value = collectionRow[i + 1][1];
      }
    }
  }
}
function changeToDateFormat() {} // this should be deleted

export function displayInDateFormat(dateAsString) {
  if (dateAsString === undefined) return;
  return (
    dateAsString.substr(5, 2) +
    "/" +
    dateAsString.substr(8, 2) +
    "/" +
    dateAsString.substr(0, 4)
  );
}

function setDateInDbFormat(dateAsString) {
  return (
    dateAsString.substr(6, 4) +
    "-" +
    dateAsString.substr(0, 2) +
    "-" +
    dateAsString.substr(3, 2)
  );
}

export function buildSavePatientJson() {
  let PatientFormDataInJson = [];

  let root_level = {};
  let d_persons = {};
  let d_addresses = {};

  let guaranters = {};
  let g_persons = {};
  let g_addresses = {};

  let insurance = {};

  let insurance1 = {};
  let i_persons1 = {};
  let i_addresses1 = {};

  let insurance2 = {};
  let i_persons2 = {};
  let i_addresses2 = {};

  let insurance3 = {};
  let i_persons3 = {};
  let i_addresses3 = {};

  let insurance4 = {};
  let i_persons4 = {};
  let i_addresses4 = {};

  let insurances = [];
  let AccordionID = "";

  CollectFieldValues(root_level, "root-level", 0);
  CollectFieldValues(d_persons, "Demographic.persons", 0);
  CollectFieldValues(d_addresses, "Demographic.addresses", 0);

  PatientFormDataInJson["root"] = root_level;
  PatientFormDataInJson["root"]["persons"] = d_persons;
  PatientFormDataInJson["root"]["persons"]["addresses"] = d_addresses;

  CollectFieldValues(guaranters, "Guarantor", 0);
  CollectFieldValues(g_persons, "Guarantor.persons", 0);
  CollectFieldValues(g_addresses, "Guarantor.addresses", 0);

  PatientFormDataInJson["root"]["guarantors"] = guaranters;

  if (guaranters["relation"] === "31") {
    PatientFormDataInJson["root"]["guarantors"]["persons"] = null;
  } else {
    PatientFormDataInJson["root"]["guarantors"]["persons"] = g_persons;
    PatientFormDataInJson["root"]["guarantors"]["persons"]["addresses"] =
      g_addresses;
    if (guaranters["persons"]["addresses"]["useSameAddressAsPatient"] === "Y")
      PatientFormDataInJson["root"]["guarantors"]["persons"]["addresses"] =
        null;
  }

  CollectFieldValues(insurance1, "insurance-root", "1");
  CollectFieldValues(i_persons1, "insurance.persons", "1");
  CollectFieldValues(i_addresses1, "insurance.addresses", "1");

  if (insurance1["relation"] === "31") {
    i_persons1 = null;
    insurance1["persons"] = i_persons1;
  } else {
    insurance1["persons"] = i_persons1;

    let Insurance1Checkbox = document
      .getElementById("Insurance1.useSameAddressAsPatient")
      .getAttribute("data-status");

    if (Insurance1Checkbox === "CHECKED") i_addresses1 = null;

    i_persons1["addresses"] = i_addresses1;
  }

  insurances.push(insurance1);

  AccordionID = document.getElementById("InsuranceAccordion2");

  if (AccordionID.hidden === false) {
    CollectFieldValues(insurance2, "insurance-root", "2");
    CollectFieldValues(i_persons2, "insurance.persons", "2");
    CollectFieldValues(i_addresses2, "insurance.addresses", "2");

    if (insurance2["relation"] === "31") {
      i_persons2 = null;
      insurance2["persons"] = i_persons2;
    } else {
      insurance2["persons"] = i_persons2;

      let Insurance2Checkbox = document
        .getElementById("Insurance2.useSameAddressAsPatient")
        .getAttribute("data-status");

      if (Insurance2Checkbox === "CHECKED") i_addresses2 = null;
      i_persons2["addresses"] = i_addresses2;
    }

    insurances.push(insurance2);
  }

  AccordionID = document.getElementById("InsuranceAccordion3");
  if (AccordionID.hidden === false) {
    CollectFieldValues(insurance3, "insurance-root", "3");
    CollectFieldValues(i_persons3, "insurance.persons", "3");
    CollectFieldValues(i_addresses3, "insurance.addresses", "3");

    if (insurance3["relation"] === "31") {
      i_persons3 = null;
      insurance3["persons"] = i_persons3;
    } else {
      insurance3["persons"] = i_persons3;

      let Insurance3Checkbox = document
        .getElementById("Insurance3.useSameAddressAsPatient")
        .getAttribute("data-status");

      if (Insurance3Checkbox === "CHECKED") i_addresses3 = null;

      i_persons3["addresses"] = i_addresses3;
    }

    insurances.push(insurance3);
  }

  AccordionID = document.getElementById("InsuranceAccordion4");
  if (AccordionID.hidden === false) {
    CollectFieldValues(insurance4, "insurance-root", "4");
    CollectFieldValues(i_persons4, "insurance.persons", "4");
    CollectFieldValues(i_addresses4, "insurance.addresses", "4");

    if (insurance4["relation"] === "31") {
      i_persons4 = null;
      insurance4["persons"] = i_persons4;
    } else {
      insurance4["persons"] = i_persons4;

      let Insurance4Checkbox = document
        .getElementById("Insurance4.useSameAddressAsPatient")
        .getAttribute("data-status");

      if (Insurance4Checkbox === "CHECKED") i_addresses4 = null;

      i_persons4["addresses"] = i_addresses4;
    }
    insurances.push(insurance4);
  }

  PatientFormDataInJson["root"]["patientInsurances"] = insurances;

  return JSON.stringify(PatientFormDataInJson["root"]);
}

function CollectFieldValues(tmpArray, classReference, ICounter) {
  let formFields = "";
  let field = "";
  let fieldReference = "";
  let fieldSectionReference = "Insurance" + ICounter;
  let AccordionReference = "";
  let AccordionID = "";

  formFields = document.getElementsByClassName(classReference);

  for (field of formFields) {
    let field_id = field.id;
    fieldReference = document.getElementById(field_id);

    if (
      classReference === "insurance-root" ||
      classReference === "insurance.persons" ||
      classReference === "insurance.addresses"
    ) {
      AccordionReference = "InsuranceAccordion" + ICounter;
      AccordionID = document.getElementById(AccordionReference);

      if (
        field_id.indexOf(fieldSectionReference) === 0 &&
        AccordionID.hidden === false
      ) {
        tmpArray[fieldReference.name] = fieldReference.value;
        continue;
      }
    } else {
      if (fieldReference.type === "checkbox" && !fieldReference.checked)
        continue;

      // if field is DOB then change the format to db format
      if (fieldReference.name === "dob")
        fieldReference.value = setDateInDbFormat(fieldReference.value);

      tmpArray[fieldReference.name] = fieldReference.value;
    }
  }
}

export function updateLookupCollectionCustom(lookupDataCollection) {}

export function lookupDataFeed(lookupDataCollection, fieldReference) {
  let selectListReference = document.getElementById(fieldReference);

  if (selectListReference.selectedIndex > 0) return;
  selectListReference.length = 0;

  var opt = document.createElement("option");
  opt.innerHTML = "";
  opt.value = "";
  selectListReference.appendChild(opt);

  for (var b = 0; b < lookupDataCollection.length; b++) {
    var opt = document.createElement("option");
    opt.innerHTML = lookupDataCollection[b].options;
    opt.value = lookupDataCollection[b].id;
    selectListReference.appendChild(opt);
  }
}

export function removeErrorForDisabledDivs(parentId) {
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  for (let field of formFields) {
    if (field.disabled == true) {
      field.classList.remove("is-invalid");
      if (
        field.parentElement.lastChild.classList.contains("invalid-feedback")
      ) {
        field.parentElement.removeChild(field.parentElement.lastChild);
      }
    }
  }
}
export function removeErrorForDivs(parentId) {
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  for (let field of formFields) {
    field.classList.remove("is-invalid");
    if (field.parentElement.lastChild.classList.contains("invalid-feedback")) {
      field.parentElement.removeChild(field.parentElement.lastChild);
    }
  }
}
export const handleSaveAndEdit = (parentId, object, insuranceBlocks) => {
  const sampleObject = {
    officeId: "",
    mrn: "",
    race: "",
    ethnicGroup: "",
    genderIdentity: "",
    sexualOrientation: "",
    persons: {
      lastName: "",
      firstName: "",
      middleName: "",
      dob: "",
      sex: "",
      email: "",
      phone: "",
      mobile: "",
      addresses: {
        street1: "",
        street2: "",
        zip: "",
        city: "",
        state: "",
        room: "",
      },
    },
    guarantors: {
      relation: "",
      employer: "",
      persons: {
        lastName: "",
        firstName: "",
        middleName: "",
        dob: "",
        sex: "",
        phone: "",
        addresses: {
          street1: "",
          street2: "",
          zip: "",
          city: "",
          state: "",
        },
      },
    },
    patientInsurances: [
      {
        insuranceCompanyId: "",
        policyId: "",
        relation: "",
        groupId: "",
        groupName: "",
        persons: {
          lastName: "",
          firstName: "",
          middleName: "",
          sex: "",
          dob: "",
          phone: "",
          addresses: {
            useSameAddressAsPatient: "on",
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
          },
        },
      },
      {
        insuranceCompanyId: "",
        policyId: "",
        relation: "",
        groupId: "",
        groupName: "",
        persons: {
          lastName: "",
          firstName: "",
          middleName: "",
          sex: "",
          dob: "",
          phone: "",
          addresses: {
            useSameAddressAsPatient: "on",
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
          },
        },
      },
      {
        insuranceCompanyId: "",
        policyId: "",
        relation: "",
        groupId: "",
        groupName: "",
        persons: {
          lastName: "",
          firstName: "",
          middleName: "",
          sex: "",
          dob: "",
          phone: "",
          addresses: {
            useSameAddressAsPatient: "on",
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
          },
        },
      },
      {
        insuranceCompanyId: "",
        policyId: "",
        relation: "",
        groupId: "",
        groupName: "",
        persons: {
          lastName: "",
          firstName: "",
          middleName: "",
          sex: "",
          dob: "",
          phone: "",
          addresses: {
            useSameAddressAsPatient: "on",
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
          },
        },
      },
    ],
  };
  if (Object.keys(object).length === 0) {
    object = sampleObject;
  }
  for (var i = numberOfInsurancesInJSON(object); i >= insuranceBlocks; i--) {
    delete object["patientInsurances"][i - 1]["insuranceCompanies"];
    object["patientInsurances"].splice(i, 1);
  }
  const parentDiv = document.getElementById(parentId);
  const formFields = parentDiv.getElementsByClassName("form-control");

  var jsonMap;
  var array = convertToArray(object, "", {});
  let fieldDataType = "";
  for (let field of formFields) {
    if (field.closest(".accordion").hidden === false) {
      jsonMap = field.getAttribute("data-jsonMapping");
      fieldDataType = field.getAttribute("data-type");
      if (field.id.indexOf("dob") > 0) {
        array[field.id] = field.value;
      }
      // if (field.id.indexOf("relation") > 0 && field.value === "31") {
      //   array['guarantors.persons'] = field.value;
      // }
      if (jsonMap !== null) {
        if (field.id.indexOf("insuranceCompanyId") > 0) {
          array[field.id] = field.getAttribute("data-id");
        } else {
          array[jsonMap] = field.value;
        }
      }
    }
  }
  return updateObjectValues(object, "", {}, array);
};
export const updateObjectValues = (obj, key, result, array) => {
  if (typeof obj !== "object") {
    result[key] = obj;
    return result;
  }
  if (obj != null) {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      const newKey = key ? key + "." + keys[i] : keys[i];
      if (typeof obj[keys[i]] !== "object" || obj[keys[i]] == null) {
        if (array[newKey] == "") {
          obj[keys[i]] = null;
        } else {
          obj[keys[i]] = array[newKey];
        }
      }
      updateObjectValues(obj[keys[i]], newKey, result, array);
    }
  }

  return obj;
};

export function convertToArray(obj, key, result) {
  if (typeof obj !== "object") {
    result[key] = obj;
    return result;
  }
  if (obj != null) {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      const newKey = key ? key + "." + keys[i] : keys[i];
      convertToArray(obj[keys[i]], newKey, result);
    }
  } else {
    result[key] = "";
  }
  return result;
}
