import React, { useState, useEffect, Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Row, Col, Container } from "react-bootstrap";
import SearchAndSelectTest from "./SearchAndSelectTest";
import { ApiGetCalls } from "../../../services/Api";
import InputField from "../../../GlobalComponents/FormInputField";
import Textarea from "../../../GlobalComponents/FormTextarea";
import FormSelect from "../../../GlobalComponents/FromSelect";
import FormRadio from "../../../GlobalComponents/FormRadio";
import DatePickerComponent from "../../../GlobalComponents/DatePicker";

function AccControls(props) {
  const [testList, setTestList] = useState([]);
  const [updateArray, setUpdateArray] = useState("");
  const [orderLevelQuestionsArray, setOrderLevelQuestionsArray] = useState({
    testsAoeQuestions: [],
  });
  const [aoePossibleAnswersState, setAoePossibleAnswersState] = useState(
    Object.keys(props.orderData).length > 0 ? props.orderData.aoeAnswers : []
  );
  const [infoDetailState, setInfoDetailState] = useState([]);
  const [infoPopupDetailState, setInfoPopupDetailState] = useState([]);
  const [infoPopupIndexState, setInfoPopupIndexState] = useState();
  const favouritesTestInfoPopupArray = [];
  async function handleClick(testId) {
    await ApiGetCalls("GetTestById/" + testId + "/" + props.patientId).then(
      (data) => {
        setTestList((oldTestsList) => [...oldTestsList, { list: data }]);
        var infoDelimitedDetail = "";

        if (data.specimenRequirement != "") {
          infoDelimitedDetail = data.specimenRequirement;
        }
        // if (data.container != "") {
        //   infoDelimitedDetail =
        //     infoDelimitedDetail + "CONTAINER|" + data.container + "|";
        // }
        // if (data.minimumVolume != "") {
        //   infoDelimitedDetail =
        //     infoDelimitedDetail + "MINIMUN VOLUME|" + data.minimumVolume + "|";
        // }
        // if (data.specimenTransport != "") {
        //   infoDelimitedDetail =
        //     infoDelimitedDetail +
        //     "SPECIMEN TRANSPORT|" +
        //     data.specimenTransport +
        //     "|";
        // }
        // if (data.stability != "") {
        //   infoDelimitedDetail =
        //     infoDelimitedDetail + "STABILITY|" + data.stability;
        // }
        setInfoPopupDetailState((oldArray) => [
          ...oldArray,
          infoDelimitedDetail,
        ]);
        // setInfoPopupDetailState(...infoPopupDetailState, [infoDelimitedDetail]);
      }
    );
  }

  useEffect(() => {
    props.handleClinicalFormChange("aoeAnswers", aoePossibleAnswersState);
  }, [aoePossibleAnswersState]);
  useEffect(() => {
    var testListArray = [];
    if (props.orderData.orderTests != undefined) {
      props.orderData.orderTests.map((item, index) => {
        testListArray.push({ list: item.tests });
        //setTestList([...testList, { list: item.tests }]);
      });
      setTestList(testListArray);
    } else {
      setTestList(testListArray);
      setOrderLevelQuestionsArray({ testsAoeQuestions: [] });
    }
  }, [props.orderData]);
  function handleDelete(testId) {
    commonQuestionsArray = [];
    setOrderLevelQuestionsArray({ testsAoeQuestions: [] });

    testList
      .filter((value) => testId != value.list.id)
      .map((alist, key) => {
        alist.list.testsAoeQuestions.map((value) => {
          if (value.aoeQuestions.isOrderLevel == "Y") {
            if (
              !commonQuestionsArray.some(
                (item) => value.aoeQuestions.id == item.id
              )
            ) {
              commonQuestionsArray.push(value);
            }
          }
        });
      });
    testList
      .filter((value) => testId == value.list.id)
      .map((alist, key) => {
        alist.list.testsAoeQuestions.map((value) => {
          if (value.aoeQuestions.isOrderLevel == "Y") {
            props.orderLevelQuestionIdsAndTests.map((val, ind) => {
              if (val.questionId == value.aoeQuestions.id) {
                console.log(
                  value.testId,
                  val.questionId,
                  value.aoeQuestions.id
                );
                if (
                  props.orderLevelQuestionIdsAndTests[ind].testIds.indexOf(
                    value.testId
                  ) > -1
                ) {
                  props.orderLevelQuestionIdsAndTests[ind].testIds.splice(
                    props.orderLevelQuestionIdsAndTests[ind].testIds.indexOf(
                      value.testId
                    ),
                    1
                  );
                  if (
                    props.orderLevelQuestionIdsAndTests[ind].testIds.length == 0
                  ) {
                    props.orderLevelQuestionIdsAndTests.splice(ind, 1);
                  }
                  props.setOrderLevelQuestionIdsAndTests([
                    ...props.orderLevelQuestionIdsAndTests,
                  ]);
                }
              }
            });
          }
        });
      });
    setOrderLevelQuestionsArray({ testsAoeQuestions: commonQuestionsArray });
    setTestList(testList.filter((value) => testId != value.list.id));
  }
  var commonQuestionsArray = [];

  return (
    <>
      <Accordion defaultActiveKey="0" id="testsAccordions">
        <Accordion.Item eventKey="0">
          <Accordion.Header>TESTS</Accordion.Header>
          <Accordion.Body>
            <SearchAndSelectTest
              propsOnChange={handleClick}
              handleDelete={handleDelete}
              locationBasedData={props.locationBasedData}
              handleClinicalFormChange={props.handleClinicalFormChange}
              orderData={props.orderData}
              infoDetailState={infoDetailState}
              setInfoDetailState={setInfoDetailState}
              infoPopupDetailState={infoPopupDetailState}
              setInfoPopupDetailState={setInfoPopupDetailState}
              setInfoPopupIndexState={setInfoPopupIndexState}
              infoPopupIndexState={infoPopupIndexState}
              favouritesTestInfoPopupArray={favouritesTestInfoPopupArray}
              userLocationId={props.userLocationId}
              orderIdAsParam={props.orderIdAsParam}
              savedOrderId={props.savedOrderId}
              favIconHandler={props.favIconHandler}
              setFavIconHandler={props.setFavIconHandler}
              setHighLightTests={props.setHighLightTests}
              highLightTests={props.highLightTests}
              medicalNecessityResponse={props.medicalNecessityResponse}
              listOptionsDiagnosis={props.listOptionsDiagnosis}
              setListOptionsDiagnosis={props.setListOptionsDiagnosis}
              selectedDiagnosesId={props.selectedDiagnosesId}
              setSelectedDiagnosesId={props.setSelectedDiagnosesId}
              setLocationBasedData={props.setLocationBasedData}
              clinicalInputs={props.clinicalInputs}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion id="clinicalAccordion" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>CLINICAL HISTORY</Accordion.Header>
          <Accordion.Body>
            {orderLevelQuestionsArray.testsAoeQuestions.length > 0 ? (
              <BuildCommonTestQuestions
                testAnswers={orderLevelQuestionsArray}
                commonQuestionsArray={commonQuestionsArray}
                setOrderLevelQuestionsArray={setOrderLevelQuestionsArray}
                setAoePossibleAnswersState={setAoePossibleAnswersState}
                aoePossibleAnswersState={aoePossibleAnswersState}
                setUpdateArray={setUpdateArray}
                updateArray={updateArray}
                orderData={props.orderData}
                selectedDataArray={props.selectedDataArray}
                setSelectedDataArray={props.setSelectedDataArray}
                orderLevelQuestionIdsAndTests={
                  props.orderLevelQuestionIdsAndTests
                }
                setOrderLevelQuestionIdsAndTests={
                  props.setOrderLevelQuestionIdsAndTests
                }
              />
            ) : null}
            {testList.map((value, key) => {
              return (
                <BuildTest
                  key={key}
                  testAnswers={value.list}
                  commonQuestionsArray={commonQuestionsArray}
                  setOrderLevelQuestionsArray={setOrderLevelQuestionsArray}
                  setAoePossibleAnswersState={setAoePossibleAnswersState}
                  aoePossibleAnswersState={aoePossibleAnswersState}
                  setUpdateArray={setUpdateArray}
                  updateArray={updateArray}
                  orderData={props.orderData}
                  selectedDataArray={props.selectedDataArray}
                  setSelectedDataArray={props.setSelectedDataArray}
                  orderLevelQuestionIdsAndTests={
                    props.orderLevelQuestionIdsAndTests
                  }
                  setOrderLevelQuestionIdsAndTests={
                    props.setOrderLevelQuestionIdsAndTests
                  }
                />
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default AccControls;

function BuildCommonTestQuestions(props) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Common Questions</Accordion.Header>
        <Accordion.Body>
          <Container>
            <GenrateForm
              key={"commonQuestion"}
              testValues={props.testAnswers.testsAoeQuestions}
              orderLevel="Y"
              commonQuestionsArray={props.commonQuestionsArray}
              setOrderLevelQuestionsArray={props.setOrderLevelQuestionsArray}
              setAoePossibleAnswersState={props.setAoePossibleAnswersState}
              aoePossibleAnswersState={props.aoePossibleAnswersState}
              setUpdateArray={props.setUpdateArray}
              updateArray={props.updateArray}
              orderData={props.orderData}
              selectedDataArray={props.selectedDataArray}
              setSelectedDataArray={props.setSelectedDataArray}
              orderLevelQuestionIdsAndTests={
                props.orderLevelQuestionIdsAndTests
              }
              setOrderLevelQuestionIdsAndTests={
                props.setOrderLevelQuestionIdsAndTests
              }
            />
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
function BuildTest(props) {
  return props.testAnswers.testsAoeQuestions.length > 0 ? (
    <Accordion
      defaultActiveKey="0"
      className={
        props.testAnswers.testsAoeQuestions.length == 1
          ? props.testAnswers.testsAoeQuestions[0].aoeQuestions.isOrderLevel ==
            "Y"
            ? "hideAccordian"
            : ""
          : ""
      }
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>{props.testAnswers.testName}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <GenrateForm
              key={"questions" + props.testAnswers.testName}
              testValues={props.testAnswers.testsAoeQuestions}
              orderLevel="N"
              commonQuestionsArray={props.commonQuestionsArray}
              setOrderLevelQuestionsArray={props.setOrderLevelQuestionsArray}
              setAoePossibleAnswersState={props.setAoePossibleAnswersState}
              aoePossibleAnswersState={props.aoePossibleAnswersState}
              setUpdateArray={props.setUpdateArray}
              updateArray={props.updateArray}
              orderData={props.orderData}
              selectedDataArray={props.selectedDataArray}
              setSelectedDataArray={props.setSelectedDataArray}
              orderLevelQuestionIdsAndTests={
                props.orderLevelQuestionIdsAndTests
              }
              setOrderLevelQuestionIdsAndTests={
                props.setOrderLevelQuestionIdsAndTests
              }
            />
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  ) : (
    <></>
  );
}
function GenrateForm(props) {
  const onChangeHandle = async (obj) => {
    updateAlerts(props.selectedDataArray, obj);
  };
  function updateAlerts(input, update) {
    let foundAlert = input.formValues.find(
      (item) =>
        item.questionId === update.questionId && item.testId === update.testId
    );

    if (foundAlert) {
      if (update.answerId != null) {
        foundAlert.answerId = update.answerId;
      } else {
        foundAlert.answerValue = update.answerValue;
      }
    } else {
      input.formValues.push(update);
    }
    props.setSelectedDataArray(input);
  }

  var arrayIndex = -1;
  const [orderId, setOrderId] = useState(
    Object.keys(props.orderData).length > 0 ? props.orderData.id : ""
  );
  if (props.orderLevel == "N") {
    props.testValues?.map((value, index) => {
      if (value.aoeQuestions.isOrderLevel == "Y") {
        if (
          props.commonQuestionsArray.filter((item) => {
            return value.aoeQuestions.id == item.aoeQuestions.id;
          }).length < 1
        ) {
          if (
            !props.orderLevelQuestionIdsAndTests.find(
              (val) => val.questionId == value.aoeQuestions.id
            )
          ) {
            props.orderLevelQuestionIdsAndTests.push({
              questionId: value.aoeQuestions.id,
              testIds: [value.testId],
            });
          }
          props.commonQuestionsArray.push(value);
          arrayIndex = index;
        } else {
          props.orderLevelQuestionIdsAndTests.map((val, ind) => {
            if (val.questionId == value.aoeQuestions.id) {
              if (
                props.orderLevelQuestionIdsAndTests[ind].testIds.indexOf(
                  value.testId
                ) < 0
              ) {
                props.orderLevelQuestionIdsAndTests[ind].testIds =
                  props.orderLevelQuestionIdsAndTests[ind].testIds.concat(
                    value.testId
                  );
                let foundAlert = props.selectedDataArray.formValues.find(
                  (item) => item.questionId === value.aoeQuestions.id
                );

                if (foundAlert) {
                  onChangeHandle({
                    orderId: foundAlert.orderId,
                    testId: value.testId,
                    questionId: foundAlert.questionId,
                    answerId: foundAlert.answerId,
                    answerValue: foundAlert.answerValue,
                  });
                }
              }
            }
          });
        }
      }
    });
  }

  // useEffect(() => {
  //   console.log("b", props.orderData.id, "a");
  //   if (props.orderData.id != undefined) {
  //     props.setSelectedDataArray({
  //       formValues:
  //         Object.keys(props.orderData).length > 0
  //           ? props.orderData.aoeAnswers
  //           : [],
  //     });
  //     props.setUpdateArray(arrayIndex);
  //   }
  // }, [props.orderData.id]);
  useEffect(() => {
    props.setOrderLevelQuestionsArray({
      testsAoeQuestions: props.commonQuestionsArray,
    });
    props.setOrderLevelQuestionIdsAndTests([
      ...props.orderLevelQuestionIdsAndTests,
    ]);
  }, [props.updateArray]);
  useEffect(() => {
    props.setAoePossibleAnswersState(props.selectedDataArray.formValues);
  }, [props.selectedDataArray]);
  return (
    <Row key={"rowGenerateForm"}>
      {props.testValues

        ?.filter(
          (filterValue) =>
            filterValue.aoeQuestions.isOrderLevel == props.orderLevel
        )
        .map((value, index) => {
          return (
            <Fragment key={index + "keySpan"}>
              {value.aoeQuestions.answerType == "RADIO" ||
              value.aoeQuestions.answerType == "CHECKBOX" ||
              value.aoeQuestions.answerType == "SELECT" ? (
                value.aoeQuestions.aoePossibleAnswers.length > 0 ? (
                  <Col
                    key={"col" + index}
                    md={{ span: 4 }}
                    className={
                      value.aoeQuestions.required == "Y" ? "mandatory" : ""
                    }
                  >
                    {value.aoeQuestions.answerType == "SELECT" ? (
                      <FormSelect
                        key={"select" + index}
                        disabled={
                          props.orderData.status == "Draft" ||
                          props.orderData.status == undefined
                            ? false
                            : true
                        }
                        required={
                          value.aoeQuestions.required == "Y" ? true : false
                        }
                        testId={
                          value.aoeQuestions.isOrderLevel == "N"
                            ? [value.testId]
                            : props.orderLevelQuestionIdsAndTests.find(
                                (val) => val.questionId == value.aoeQuestions.id
                              ).testIds
                        }
                        questionId={value.aoeQuestions.id}
                        answerType={value.aoeQuestions.answerType}
                        formValue={value.aoeQuestions.question}
                        optionValues={value.aoeQuestions.aoePossibleAnswers}
                        orderId={orderId}
                        onChange={onChangeHandle}
                        defaultValue={
                          props.orderData?.aoeAnswers
                            ? props.orderData?.aoeAnswers?.find((item, index) =>
                                item.questionId == value.aoeQuestions.id &&
                                item.answerType == value.aoeQuestions.answerType
                                  ? item.answerId
                                  : 0
                              )
                            : value.aoeQuestions.saveAnswerWithPatient == "Y" &&
                              value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                                value?.aoeQuestions?.answerType
                            ? value?.aoeQuestions?.aoeAnswers[0]
                                ?.aoePossibleAnswers
                            : value?.aoeQuestions?.aoePossibleAnswers.find(
                                (item, index) =>
                                  item.isDefault == "Yes" ? item.id : 0
                              )
                        }
                      />
                    ) : value.aoeQuestions.answerType == "RADIO" ||
                      value.aoeQuestions.answerType == "CHECKBOX" ? (
                      <FormRadio
                        key={"radio" + index}
                        disabled={
                          props.orderData.status == "Draft" ||
                          props.orderData.status == undefined
                            ? false
                            : true
                        }
                        required={
                          value.aoeQuestions.required == "Y" ? true : false
                        }
                        testId={
                          value.aoeQuestions.isOrderLevel == "N"
                            ? [value.testId]
                            : props.orderLevelQuestionIdsAndTests.find(
                                (val) => val.questionId == value.aoeQuestions.id
                              ).testIds
                        }
                        questionId={value.aoeQuestions.id}
                        orderId={orderId}
                        formValue={value.aoeQuestions.question}
                        answerType={value.aoeQuestions.answerType}
                        name={
                          value.aoeQuestions.answerType == "CHECKBOX"
                            ? value.aoeQuestions.answerType +
                              value.aoeQuestions.id +
                              "[]"
                            : value.aoeQuestions.question
                        }
                        radioValues={value.aoeQuestions.aoePossibleAnswers}
                        type={value.aoeQuestions.answerType}
                        className={
                          value.aoeQuestions.answerType == "CHECKBOX"
                            ? "clinicalRadio checkVal_" +
                              value.aoeQuestions.answerType +
                              value.aoeQuestions.id +
                              "[]"
                            : "clinicalRadio checkVal_" +
                              value.aoeQuestions.question
                        }
                        defaultValue={
                          value.aoeQuestions.answerType == "CHECKBOX"
                            ? props.orderData?.aoeAnswers
                              ? props.orderData?.aoeAnswers?.find(
                                  (item, index) =>
                                    item.questionId == value.aoeQuestions.id &&
                                    item.answerType ==
                                      value.aoeQuestions.answerType
                                      ? item.answerValue
                                      : 0
                                )
                              : value.aoeQuestions.saveAnswerWithPatient ==
                                  "Y" &&
                                value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                                  value?.aoeQuestions?.answerType
                              ? value?.aoeQuestions?.aoeAnswers[0]
                              : undefined
                            : props.orderData?.aoeAnswers
                            ? props.orderData?.aoeAnswers?.find((item, index) =>
                                item.questionId == value.aoeQuestions.id &&
                                item.answerType == value.aoeQuestions.answerType
                                  ? item.answerId
                                  : 0
                              )
                            : value.aoeQuestions.saveAnswerWithPatient == "Y" &&
                              value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                                value?.aoeQuestions?.answerType
                            ? value?.aoeQuestions?.aoeAnswers[0]
                            : undefined
                        }
                        defaultCheckedValue={
                          value.aoeQuestions.aoePossibleAnswers.length > 0
                            ? value.aoeQuestions.aoePossibleAnswers.find(
                                (item, index) =>
                                  item.isDefault == "Yes" ? item.id : 0
                              )?.id
                            : 0
                        }
                        onChange={onChangeHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                ) : (
                  <></>
                )
              ) : (
                <Col
                  key={"col" + index}
                  md={{ span: 4 }}
                  className={
                    value.aoeQuestions.required == "Y" ? "mandatory" : ""
                  }
                >
                  {value.aoeQuestions.answerType == "INPUT" ||
                  value.aoeQuestions.answerType == "NUMBER" ? (
                    <InputField
                      key={"input" + index}
                      type={
                        value.aoeQuestions.answerType == "NUMBER"
                          ? "number"
                          : ""
                      }
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      testId={
                        value.aoeQuestions.isOrderLevel == "N"
                          ? [value.testId]
                          : props.orderLevelQuestionIdsAndTests.find(
                              (val) => val.questionId == value.aoeQuestions.id
                            ).testIds
                      }
                      questionId={value.aoeQuestions.id}
                      answerType={value.aoeQuestions.answerType}
                      orderId={orderId}
                      required={
                        value.aoeQuestions.required == "Y" ? true : false
                      }
                      formValue={value.aoeQuestions.question}
                      onChangeFunction={onChangeHandle}
                      defaultValue={
                        props.orderData?.aoeAnswers != null
                          ? props.orderData?.aoeAnswers?.find((item, index) =>
                              item.questionId == value.aoeQuestions.id &&
                              item.answerType == value.aoeQuestions.answerType
                                ? item.answerValue
                                : ""
                            )
                          : value.aoeQuestions.saveAnswerWithPatient == "Y" &&
                            value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                              value?.aoeQuestions?.answerType
                          ? value?.aoeQuestions?.aoeAnswers[0]
                          : ""
                      }
                    />
                  ) : value.aoeQuestions.answerType == "TEXTAREA" ? (
                    <Textarea
                      key={"textArea" + index}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      testId={
                        value.aoeQuestions.isOrderLevel == "N"
                          ? [value.testId]
                          : props.orderLevelQuestionIdsAndTests.find(
                              (val) => val.questionId == value.aoeQuestions.id
                            ).testIds
                      }
                      questionId={value.aoeQuestions.id}
                      answerType={value.aoeQuestions.answerType}
                      formValue={value.aoeQuestions.question}
                      orderId={orderId}
                      required={
                        value.aoeQuestions.required == "Y" ? true : false
                      }
                      onChange={onChangeHandle}
                      defaultValue={
                        props.orderData?.aoeAnswers
                          ? props.orderData?.aoeAnswers?.find((item, index) =>
                              item.questionId == value.aoeQuestions.id &&
                              item.answerType == value.aoeQuestions.answerType
                                ? item.answerValue
                                : ""
                            )
                          : value.aoeQuestions.saveAnswerWithPatient == "Y" &&
                            value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                              value?.aoeQuestions?.answerType
                          ? value?.aoeQuestions?.aoeAnswers[0]
                          : ""
                      }
                    />
                  ) : value.aoeQuestions.answerType == "DATE" ? (
                    <DatePickerComponent
                      key={"date" + index}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      required={
                        value.aoeQuestions.required == "Y" ? true : false
                      }
                      testId={
                        value.aoeQuestions.isOrderLevel == "N"
                          ? [value.testId]
                          : props.orderLevelQuestionIdsAndTests.find(
                              (val) => val.questionId == value.aoeQuestions.id
                            ).testIds
                      }
                      questionId={value.aoeQuestions.id}
                      answerType={value.aoeQuestions.answerType}
                      orderId={orderId}
                      className="input-style form-control"
                      formValue={value.aoeQuestions.question}
                      onChange={() => {}}
                      dateFormat="MM-dd-yyyy"
                      showTimeSelect={false}
                      onChangeFunction={onChangeHandle}
                      defaultValue2={
                        props.orderData?.aoeAnswers
                          ? props.orderData?.aoeAnswers?.find((item, index) =>
                              item.questionId == value.aoeQuestions.id &&
                              item.answerType == value.aoeQuestions.answerType
                                ? item.answerValue
                                : ""
                            )
                          : value.aoeQuestions.saveAnswerWithPatient == "Y" &&
                            value?.aoeQuestions.aoeAnswers[0]?.answerType ==
                              value?.aoeQuestions?.answerType
                          ? value?.aoeQuestions?.aoeAnswers[0]
                          : ""
                      }
                      // defaultValue2={props.orderData?.aoeAnswers?.find(
                      //   (item, index) =>
                      //     item.questionId == value.aoeQuestions.id &&
                      //     item.answerType == value.aoeQuestions.answerType
                      //       ? item.answerValue
                      //       : ""
                      // )}
                    />
                  ) : (
                    <div key={index + "div1"}></div>
                  )}
                </Col>
              )}
            </Fragment>
          );
          // }
        })}
    </Row>
  );
}
