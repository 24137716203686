import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import SearchPatientAndSelect from "./SearchPatientAndSelect";
import DemographicInfo from "./DemographicInfo";
import GuarantorInfo from "./GuarantorInfo";
import InsuranceInfo from "./InsuranceInfo";
import dateFormat from "dateformat";
import { sampleObject } from "../../../config/config";
import SaveChangesModal from "./SaveChangesModal";

import {
  handleValidation,
  numberOfInsurancesInJSON,
  updateObjectValues,
  convertToArray,
  accordionToggle,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { ApiGetCalls, ApiPostCalls } from "../../../services/Api";
import { snackBar } from "../../../services/Alert";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

function PatientTab({
  showResults,
  setShowResults,
  setPatientDetails,
  patientDetails,
  setSexLookup,
  sexLookup,
  handleClinicalFormChange,
  orderData,
  selectedOrderPatientId,
  setPatientInUpdateMode,
  orderId,
  createPatient,
  patientId,
  setSelectedOrderPatientId,
  facilityId,
  savedOrderId,
}) {
  const navigate = useNavigate();
  const [patientSearchFilter, setPatientSearchFilter] = useState("");
  const [inputs, setInputs] = useState({});
  const [relation, setRelation] = useState({
    guarantors: "",
    "patientInsurances.0": "",
    "patientInsurances.1": "",
    "patientInsurances.2": "",
    "patientInsurances.3": "",
  });
  const [sameAddressAsPatient, setSameAddressAsPatient] = useState({
    guarantors: false,
    "patientInsurances.0": false,
    "patientInsurances.1": false,
    "patientInsurances.2": false,
    "patientInsurances.3": false,
  });
  const [createPatientState, setCreatePatientState] = useState(false);
  const [patientObj, setPatientObj] = React.useState("");
  const [patientDataArray, setPatientDataArray] = React.useState([]);
  const [patienDemographicForm, setpatienDemographicForm] = useState("data");
  const [patientTabMode, setpatientTabMode] = useState(""); // view is for data, form is for inputs
  const [insuranceBlockList, setinsuranceBlockList] = useState(1);
  const [editMode, setEditMode] = useState(false);
  var insuranceBlocksArray = [];
  const [showSaveChangesModal, setshowSaveChangesModal] = useState(false);
  const [saveUserChanges, setsaveUserChanges] = useState(false);
  const [primaryInsurance, setPrimaryInsurance] = useState(0);
  const [patientDataHide, setPatientDataHide] = useState(false);
  const [saveAndPlaceOrder, setSaveAndPlaceOrder] = useState(0);
  const handleAllFormChange = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    if (name.split(".").indexOf("zip") != -1 && value.split("-")[1] == "") {
      value = value.split("-")[0];
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDateChange = (date, DateFieldName) => {
    const name = DateFieldName;
    const value = date;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForSaveAndPlaceOrder = async () => {
    setSaveAndPlaceOrder(saveAndPlaceOrder + 1);
  };
  useEffect(() => {
    if (saveAndPlaceOrder != 0) handleSubmit();
  }, [saveAndPlaceOrder]);
  const handleSubmit = async () => {
    var validation = handleValidation("patientAccordion");
    var endpoint;
    var formJson = {};
    if (validation) {
      if (editMode) {
        var replaceInsuranceBlocksWithSampleInsuranceArray = JSON.parse(
          JSON.stringify(patientObj)
        );
        replaceInsuranceBlocksWithSampleInsuranceArray["patientInsurances"] =
          sampleObject["patientInsurances"];
        formJson = JSON.parse(
          JSON.stringify(
            updateObjectValues(
              replaceInsuranceBlocksWithSampleInsuranceArray,
              "",
              {},
              inputs
            )
          )
        );

        endpoint = "updatePatient";
      } else {
        formJson = JSON.parse(
          JSON.stringify(updateObjectValues(sampleObject, "", {}, inputs))
        );
        endpoint = "createPatient";
      }
      if (formJson["guarantors"] == null) {
        // let replaceGuarantorBlocksWithSampleGuarantor = JSON.parse(
        //   JSON.stringify(patientObj)
        // );
        replaceInsuranceBlocksWithSampleInsuranceArray["guarantors"] =
          sampleObject["guarantors"];
        formJson = JSON.parse(
          JSON.stringify(
            updateObjectValues(
              replaceInsuranceBlocksWithSampleInsuranceArray,
              "",
              {},
              inputs
            )
          )
        );
        delete formJson?.guarantorId;
      }
      if (sameAddressAsPatient["guarantors"]) {
        formJson.guarantors.persons.addresses = null;
      }
      if (formJson.guarantors?.relation == 31) {
        formJson.guarantors.persons = null;
      }
      for (
        var i = numberOfInsurancesInJSON(formJson);
        i >= insuranceBlockList;
        i--
      ) {
        formJson["patientInsurances"].splice(i, 1);
      }
      for (let i = 0; i < numberOfInsurancesInJSON(formJson); i++) {
        delete formJson["patientInsurances"][i]["insuranceCompanies"];
        if (i == primaryInsurance) {
          formJson["patientInsurances"][i]["primaryInsurance"] = "Y";
        } else {
          formJson["patientInsurances"][i]["primaryInsurance"] = "N";
        }
        if (editMode) {
          formJson["patientInsurances"][i]["patientId"] = formJson["id"];
        }
        if (sameAddressAsPatient["patientInsurances." + i.toString()]) {
          formJson["patientInsurances"][i].persons.addresses = null;
        }
        if (formJson["patientInsurances"][i].relation == 31) {
          formJson["patientInsurances"][i].persons = null;
        }
      }
      if (editMode) {
        if (formJson["guarantors"]["persons"] != null) {
          if (
            patientDataArray["guarantors.persons.addressId"] ==
              patientDataArray["persons.addressId"] &&
            !sameAddressAsPatient["guarantors"]
          ) {
            delete formJson?.guarantors?.persons?.addressId;
            delete formJson?.guarantors?.persons?.addresses?.id;
          }
          delete formJson?.guarantors?.persons?.email;
          delete formJson?.guarantors?.persons?.preferredPhone;
          delete formJson?.guarantors?.persons?.mobile;
        }

        if (
          patientDataArray["guarantors.relation"] == 31 &&
          formJson["guarantors"]["relation"] != 31
        ) {
          delete formJson?.guarantors?.persons?.addressId;
          delete formJson?.guarantors?.persons?.addresses?.id;
          delete formJson?.guarantors?.personId;
          delete formJson?.guarantors?.persons?.id;
        }
        for (let i = 0; i < numberOfInsurancesInJSON(formJson); i++) {
          if (formJson["patientInsurances"][i]["persons"] != null) {
            if (
              patientDataArray[
                "patientInsurances." + i + ".persons.addressId"
              ] == patientDataArray["persons.addressId"] &&
              sameAddressAsPatient["patientInsurances" + i]
            ) {
              delete formJson?.patientInsurances?.i?.persons?.addressId;
              delete formJson?.patientInsurances?.i?.persons?.addresses?.id;
            }
          }
          if (
            patientDataArray["patientInsurances." + i + ".relation"] == 31 &&
            formJson["patientInsurances"][i]["relation"] != 31
          ) {
            delete formJson?.patientInsurances?.i?.persons?.addressId;
            delete formJson?.patientInsurances?.i?.persons?.addresses?.id;
            delete formJson?.patientInsurances?.i?.personId;
            delete formJson?.patientInsurances?.i?.persons?.id;
          }
        }
      }

      const response = await ApiPostCalls(JSON.stringify(formJson), endpoint);
      if (response["status"] === "Success") {
        if (createPatientState) {
          // setpatientTabMode("form");
          //handleChange(response["data"]);
          if (saveAndPlaceOrder == 0) {
            navigate("/");
          } else {
            setSelectedOrderPatientId(response["data"]);
            navigate("/order/new");
          }
        } else {
          setpatienDemographicForm("data");
          setpatientTabMode("view");
          setEditMode(false);
          handleChange(response["data"]);
          setPatientInUpdateMode(false);
          accordionToggle("patientAccordion", "close");
        }
        document
          .getElementById("patientHiddenDiv")
          .scrollIntoView({ behavior: "smooth", block: "center" });

        snackBar("Success! Patient data saved successfully", "success");
      } else {
        snackBar("Error! Could not save patient data", "danger");
      }
    } else {
      snackBar(
        "Error! Could not save patient, please fix the indicated errors.",
        "danger"
      );
    }
    // }
  };

  useEffect(() => {
    fetchLookUpTableDataOnLoad();
  }, []);

  useEffect(() => {}, [insuranceBlockList]);
  useEffect(() => {
    if (orderData.patientId != undefined) {
      fetchData(orderData.patientId);
      setPatientDataHide(false);
    } else {
      setPatientDataArray([]);
      setPatientObj("");
      setInputs({});
      setinsuranceBlockList(1);
      if (orderId == undefined && createPatient != "Y")
        setPatientDataHide(true);
    }
  }, [orderData]);
  const [activeKey, setActiveKey] = useState("");
  useEffect(() => {
    if (createPatient == "Y") {
      setCreatePatientState(true);
      // setPatientDataHide(false);

      //setEditMode(true);
      setActiveKey("0");
      if (patientId != undefined) {
        setEditMode(true);
        handleChange(patientId);
      } else {
        setpatientTabMode("form");
      }
    } else {
      if (orderId == undefined) setPatientDataHide(true);
      setCreatePatientState(false);
      setpatientTabMode("view");
      setActiveKey("");
    }
  }, [createPatient]);
  const handleToggleClick = () => {
    if (activeKey == "0") {
      setActiveKey("");
    } else {
      setActiveKey("0");
    }
  };
  useEffect(() => {
    if (selectedOrderPatientId != "" && selectedOrderPatientId != 0) {
      // setPatientDataHide(false);
      fetchData(selectedOrderPatientId);
    } else {
      setPatientObj("");
      setPatientSearchFilter("");
      setPatientDataArray([]);
      setInputs({});
      setinsuranceBlockList(1);
    }
  }, [selectedOrderPatientId, savedOrderId]);

  const [demoDateState, setdemoDateState] = useState(false);
  const [guarDateState, setguarDateState] = useState(false);
  const [incDateState, setincDateState] = useState(false);

  const [lookUpData, setLookUpData] = useState({
    genderData: [],
    sexData: [],
    relationData: [],
    raceData: [],
    ethinicData: [],
    sexualOrientationData: [],
    InsuranceCompaniesData: [],
    vaccinationData: [],
  });

  const fetchData = (value) => {
    ApiGetCalls("GetPatientById/" + value).then((data) => {
      for (var i = 0; i < numberOfInsurancesInJSON(data); i++) {
        if (data.patientInsurances[i]?.persons?.dob == null) {
          data.patientInsurances[i].persons.dob = undefined;
        }
      }
      if (data.guarantors != undefined) {
        if (
          data.guarantors.persons.dob != undefined &&
          data.guarantors.persons.dob == null
        ) {
          data.guarantors.persons.dob = undefined;
        }
      }
      if (data.persons?.dob == null) {
        data.persons.dob = undefined;
      }
      let primaryInsuranceIndex = 0;
      setPatientObj(data);
      setPatientDataArray(
        JSON.parse(JSON.stringify(convertToArray(data, "", {})))
      );
      setInputs(JSON.parse(JSON.stringify(convertToArray(data, "", {}))));
      if (numberOfInsurancesInJSON(data) > 0) {
        setinsuranceBlockList(numberOfInsurancesInJSON(data));
      } else {
        setinsuranceBlockList(1);
      }
      for (var i = 0; i < numberOfInsurancesInJSON(data); i++) {
        if (data.patientInsurances[i].primaryInsurance == "Y") {
          primaryInsuranceIndex = i;
          break;
        }
      }

      setPrimaryInsurance(primaryInsuranceIndex);
      handleClinicalFormChange("patientId", data.id);
      setPatientDetails({
        patientId: data.id,
        patientName: data.persons.lastName + ", " + data.persons.firstName,
        patientDob: dateFormat(data.persons.dob, "mm/dd/yyyy"),
        patientInsurance:
          data.patientInsurances[primaryInsuranceIndex]?.insuranceCompanies
            .name,
        patientPhone: data.persons.phone,
        patientSex: data.persons.sex,
      });
    });
  };
  useEffect(() => {
    if (createPatient == "Y") {
      let objectRelations = {};
      let objectSameAddressesAsPastient = {};
      if (Object.keys(patientDataArray).length != 0) {
        if (patientDataArray["guarantors.relation"] == 31) {
          objectRelations = {
            ...objectRelations,
            guarantors: "self",
          };
        } else {
          objectRelations = {
            ...objectRelations,
            guarantors: "",
          };
        }
        if (
          patientDataArray["guarantors.persons.addressId"] ==
            patientDataArray["persons.addressId"] &&
          patientDataArray["guarantors.relation"] != 31
        ) {
          objectSameAddressesAsPastient = {
            ...objectSameAddressesAsPastient,
            guarantors: true,
          };
        } else {
          objectSameAddressesAsPastient = {
            ...objectSameAddressesAsPastient,
            guarantors: false,
          };
        }
        for (var i = 0; i < patientObj["patientInsurances"].length; i++) {
          if (patientDataArray["patientInsurances." + i + ".relation"] == 31) {
            objectRelations = {
              ...objectRelations,
              ["patientInsurances." + i.toString()]: "self",
            };
          } else {
            objectRelations = {
              ...objectRelations,
              ["patientInsurances." + i.toString()]: "",
            };
          }
          if (
            patientDataArray[
              "patientInsurances." + i + ".persons.addressId"
            ] === patientDataArray["persons.addressId"] &&
            patientDataArray["patientInsurances." + i + ".relation"] != 31
          ) {
            objectSameAddressesAsPastient = {
              ...objectSameAddressesAsPastient,
              ["patientInsurances." + i.toString()]: true,
            };
          } else {
            objectSameAddressesAsPastient = {
              ...objectSameAddressesAsPastient,
              ["patientInsurances." + i.toString()]: false,
            };
          }
          // if (
          //   patientDataArray[
          //     "patientInsurances." + i + ".primaryInsurance"
          //   ] === "Y"
          // ) {
          //   primaryInsuranceNumber =
          //     "patientInsurances." + i + ".primaryInsurance";
          // }
        }
      }
      setRelation(objectRelations);
      setSameAddressAsPatient(objectSameAddressesAsPastient);
    }
  }, [patientDataArray]);
  useEffect(() => {
    if (orderId == undefined && createPatient != "Y") {
      let objectRelations = {};
      let objectSameAddressesAsPastient = {};
      setPatientInUpdateMode(true);
      setInputs(JSON.parse(JSON.stringify(patientDataArray)));
      if (Object.keys(patientDataArray).length != 0) {
        if (patientDataArray["guarantors.relation"] == 31) {
          objectRelations = {
            ...objectRelations,
            guarantors: "self",
          };
        } else {
          objectRelations = {
            ...objectRelations,
            guarantors: "",
          };
        }
        if (
          patientDataArray["guarantors.persons.addressId"] ==
            patientDataArray["persons.addressId"] &&
          patientDataArray["guarantors.relation"] != 31
        ) {
          objectSameAddressesAsPastient = {
            ...objectSameAddressesAsPastient,
            guarantors: true,
          };
        } else {
          objectSameAddressesAsPastient = {
            ...objectSameAddressesAsPastient,
            guarantors: false,
          };
        }
        for (var i = 0; i < patientObj["patientInsurances"].length; i++) {
          if (patientDataArray["patientInsurances." + i + ".relation"] == 31) {
            objectRelations = {
              ...objectRelations,
              ["patientInsurances." + i.toString()]: "self",
            };
          } else {
            objectRelations = {
              ...objectRelations,
              ["patientInsurances." + i.toString()]: "",
            };
          }
          if (
            patientDataArray[
              "patientInsurances." + i + ".persons.addressId"
            ] === patientDataArray["persons.addressId"] &&
            patientDataArray["patientInsurances." + i + ".relation"] != 31
          ) {
            objectSameAddressesAsPastient = {
              ...objectSameAddressesAsPastient,
              ["patientInsurances." + i.toString()]: true,
            };
          } else {
            objectSameAddressesAsPastient = {
              ...objectSameAddressesAsPastient,
              ["patientInsurances." + i.toString()]: false,
            };
          }
          // if (
          //   patientDataArray[
          //     "patientInsurances." + i + ".primaryInsurance"
          //   ] === "Y"
          // ) {
          //   primaryInsuranceNumber =
          //     "patientInsurances." + i + ".primaryInsurance";
          // }
        }
      }
      setRelation(objectRelations);
      setSameAddressAsPatient(objectSameAddressesAsPastient);
      // await setPrimaryInsurance(primaryInsuranceNumber);
      setEditMode(true);
      if (
        patientObj != "" &&
        lookUpData.genderData.length != 0 &&
        lookUpData.sexData.length != 0 &&
        lookUpData.relationData.length != 0 &&
        lookUpData.raceData.length != 0 &&
        lookUpData.ethinicData.length != 0 &&
        lookUpData.sexualOrientationData.length != 0 &&
        lookUpData.InsuranceCompaniesData.length != 0 &&
        lookUpData.vaccinationData.length != 0
      ) {
        setTimeout(() => {
          var validation = handleValidation("patientAccordion");
          if (validation) {
            setPatientInUpdateMode(false);
            setInputs({});
            setSameAddressAsPatient({});
            setRelation({});
            setEditMode(false);
            accordionToggle("patientAccordion", "close");
          } else {
            accordionToggle("patientAccordion", "open");
          }
        }, 300);
      } else {
        if (patientObj == "") {
          setEditMode(false);
        }
      }
    }
  }, [patientObj]);
  async function fetchLookUpTableDataOnLoad() {
    try {
      const [
        gender,
        sex,
        relation,
        race,
        ethnicity,
        sexualOrientation,
        InsuranceCompanies,
        vaccinationData,
      ] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/Gender"),
        await ApiGetCalls("GetQuickLookupsByName/Sex"),
        await ApiGetCalls("GetQuickLookupsByName/Relation"),
        await ApiGetCalls("GetQuickLookupsByName/Race"),
        await ApiGetCalls("GetQuickLookupsByName/Ethnicity"),
        await ApiGetCalls("GetQuickLookupsByName/SexualOrientation"),
        await ApiGetCalls("GetInsuranceCompanies"),
        await ApiGetCalls("GetQuickLookupsByName/Vaccination"),
      ]);
      setLookUpData({
        genderData: gender,
        relationData: relation,
        raceData: race,
        ethinicData: ethnicity,
        sexData: sex,
        sexualOrientationData: sexualOrientation,
        InsuranceCompaniesData: InsuranceCompanies,
        vaccinationData: vaccinationData,
      });
      setSexLookup({ sex });
    } catch (error) {}
  }
  useEffect(() => {
    if (patientObj != "") {
      if (orderId == undefined && createPatient != "Y") {
        // setEditMode(true);
        setTimeout(() => {
          var validation = handleValidation("patientAccordion");
          if (validation) {
            setPatientInUpdateMode(false);
            setInputs({});
            setSameAddressAsPatient({});
            setRelation({});
            setEditMode(false);
            accordionToggle("patientAccordion", "close");
          } else {
            accordionToggle("patientAccordion", "open");
          }
        }, 300);
      }
    }
  }, [lookUpData]);
  function handleChange(selectedPatient) {
    fetchData(selectedPatient);
  }
  const blurHandler = () => setShowResults(false);
  const [datePickerValues, setDatePickerValues] = useState({
    "persons.dob": null,
    "guarantors.persons.dob": null,
    "patientInsurances.0.persons.dob": null,
    "patientInsurances.1.persons.dob": null,
    "patientInsurances.2.persons.dob": null,
    "patientInsurances.3.persons.dob": null,
  });
  const [zipValues, setZipValues] = useState({
    "persons.addresses.zip": null,
    "guarantors.persons.addresses.zip": null,
    "patientInsurances.0.persons.addresses.zip": null,
    "patientInsurances.1.persons.addresses.zip": null,
    "patientInsurances.2.persons.addresses.zip": null,
    "patientInsurances.3.persons.addresses.zip": null,
  });
  const [phoneValues, setPhoneValues] = useState({
    "persons.phone": null,
    "persons.mobile": null,
    "guarantors.persons.phone": null,
    "patientInsurances.0.persons.phone": null,
    "patientInsurances.1.persons.phone": null,
    "patientInsurances.2.persons.phone": null,
    "patientInsurances.3.persons.phone": null,
  });
  const [insuranceCompaniesValues, setInsuranceCompaniesValues] = useState({
    "patientInsurances.0.insuranceCompanies.name": "",
    "patientInsurances.0.insuranceCompanyId": 0,
    "patientInsurances.1.insuranceCompanies.name": "",
    "patientInsurances.1.insuranceCompanyId": 0,
    "patientInsurances.2.insuranceCompanies.name": "",
    "patientInsurances.2.insuranceCompanyId": 0,
    "patientInsurances.3.insuranceCompanies.name": "",
    "patientInsurances.3.insuranceCompanyId": 0,
  });
  useEffect(() => {
    if (patientTabMode == "form") {
      ApiGetCalls("GetAutoIncrementalByFieldName/mrn").then((mrnValue) => {
        setInputs({ ...inputs, mrn: mrnValue });
      });
    }
  }, [patientTabMode]);
  for (let i = 1; i <= insuranceBlockList; i++) {
    insuranceBlocksArray.push(
      <InsuranceInfo
        key={i}
        patientTabMode={patientTabMode}
        patientObj={patientObj}
        datePickerStatus={incDateState}
        setdatePickerStatus={setincDateState}
        insuranceFieldsReferenceNo={i.toString()}
        insuranceBlockList={insuranceBlockList}
        setinsuranceBlockList={setinsuranceBlockList}
        lookUpData={lookUpData}
        hiddenProperty={insuranceBlockList > 0 ? false : true}
        editMode={editMode}
        setEditMode={setEditMode}
        setDatePickerValues={setDatePickerValues}
        datePickerValues={datePickerValues}
        zipValues={zipValues}
        setZipValues={setZipValues}
        phoneValues={phoneValues}
        setPhoneValues={setPhoneValues}
        setInsuranceCompaniesValues={setInsuranceCompaniesValues}
        insuranceCompaniesValues={insuranceCompaniesValues}
        handleAllFormChange={handleAllFormChange}
        handleDateChange={handleDateChange}
        setRelation={setRelation}
        inputs={inputs}
        relation={relation}
        setInputs={setInputs}
        patientDataArray={patientDataArray}
        setSameAddressAsPatient={setSameAddressAsPatient}
        sameAddressAsPatient={sameAddressAsPatient}
        primaryInsurance={primaryInsurance}
        setPrimaryInsurance={setPrimaryInsurance}
      />
    );
  }
  // this function is triggered on cancel button click and checks if user has made any inputs
  // in edit mode it compares patientDataArray with inputs
  // in add mode it looks for input values lengths > 0
  function UserWantToSaveUnSavedData() {
    if (editMode) {
      if (JSON.stringify(patientDataArray) != JSON.stringify(inputs))
        setshowSaveChangesModal(true);
      else {
        setPatientInUpdateMode(false);
        setInputs({});
        setSameAddressAsPatient({});
        setRelation({});
        setEditMode(false);
        if (patientObj.length != 0) {
          if (numberOfInsurancesInJSON(patientObj) > 0) {
            setinsuranceBlockList(numberOfInsurancesInJSON(patientObj));
          } else {
            setinsuranceBlockList(1);
          }
        }
        if (patientObj.length != 0) {
          for (var i = 0; i < numberOfInsurancesInJSON(patientObj); i++) {
            if (patientObj.patientInsurances[i].primaryInsurance == "Y") {
              setPrimaryInsurance(i);
              break;
            }
          }
        }
      }
    } else {
      let flag = false;
      let checkForUserInputs = Object.keys(inputs);
      checkForUserInputs.forEach((checkForUserInput) => {
        if (checkForUserInput != "mrn") {
          if (checkForUserInput.length > 0) flag = true;
        }
      });

      if (flag) setshowSaveChangesModal(true);
      else {
        setPatientInUpdateMode(false);
        setpatienDemographicForm("data");
        setpatientTabMode("view");
        setSameAddressAsPatient({});
        setRelation({});
        if (patientObj.length != 0) {
          if (numberOfInsurancesInJSON(patientObj) > 0) {
            setinsuranceBlockList(numberOfInsurancesInJSON(patientObj));
          } else {
            setinsuranceBlockList(1);
          }
        }
        if (patientObj.length != 0) {
          for (var i = 0; i < numberOfInsurancesInJSON(patientObj); i++) {
            if (patientObj.patientInsurances[i].primaryInsurance == "Y") {
              setPrimaryInsurance(i);
              break;
            }
          }
        }
      }
    }
  }
  return (
    <div>
      <div id="patientHiddenDiv"></div>
      {patientDataHide && (
        <Accordion defaultActiveKey="0" id="searchPatientAccordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Patient Selection</Accordion.Header>
            <Accordion.Body onClick={blurHandler}>
              <SearchPatientAndSelect
                onChange={handleChange}
                setpatienDemographicForm={setpatienDemographicForm}
                patienDemographicForm={patienDemographicForm}
                patientObj={patientObj}
                showResults={showResults}
                setShowResults={setShowResults}
                lookUpData={lookUpData}
                setpatientTabMode={setpatientTabMode}
                patientTabMode={patientTabMode}
                setPatientDetails={setPatientDetails}
                patientDetails={patientDetails}
                setinsuranceBlockList={setinsuranceBlockList}
                sexLookup={sexLookup}
                editMode={editMode}
                setEditMode={setEditMode}
                setDatePickerValues={setDatePickerValues}
                datePickerValues={datePickerValues}
                setZipValues={setZipValues}
                setPhoneValues={setPhoneValues}
                setInsuranceCompaniesValues={setInsuranceCompaniesValues}
                setInputs={setInputs}
                patientDataArray={patientDataArray}
                setRelation={setRelation}
                setSameAddressAsPatient={setSameAddressAsPatient}
                UserWantToSaveUnSavedData={UserWantToSaveUnSavedData}
                saveUserChanges={saveUserChanges}
                inputs={inputs}
                relation={relation}
                sameAddressAsPatient={sameAddressAsPatient}
                insuranceBlockList={insuranceBlockList}
                primaryInsurance={primaryInsurance}
                setPrimaryInsurance={setPrimaryInsurance}
                setPatientInUpdateMode={setPatientInUpdateMode}
                facilityId={facilityId}
                filter={patientSearchFilter}
                setFilter={setPatientSearchFilter}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {createPatient == "Y" ? (
        <div
          onSelect={handleToggleClick}
          activeKey={activeKey}
          id="patientAccordion"
        >
          {/* <Accordion.Item eventKey="0">
            <Accordion.Header>PATIENT</Accordion.Header>
            <Accordion.Body onClick={blurHandler}> */}
          <SaveChangesModal
            setshowSaveChangesModal={setshowSaveChangesModal}
            showSaveChangesModal={showSaveChangesModal}
            setsaveUserChanges={setsaveUserChanges}
          />

          {(orderData.status == "Draft" || orderData.status == undefined) &&
            patientObj.length !== 0 &&
            patientTabMode === "view" && (
              <button
                onClick={async () => {
                  let objectRelations = {};
                  let objectSameAddressesAsPastient = {};
                  // let primaryInsuranceNumber = 0;
                  if (editMode) {
                    var validation = handleValidation("patientAccordion");
                    if (validation) {
                      UserWantToSaveUnSavedData();
                    }
                  } else {
                    setPatientInUpdateMode(true);
                    accordionToggle("patientAccordion", "open");
                    await setInputs(
                      JSON.parse(JSON.stringify(patientDataArray))
                    );
                    if (Object.keys(patientDataArray).length != 0) {
                      if (patientDataArray["guarantors.relation"] == 31) {
                        objectRelations = {
                          ...objectRelations,
                          guarantors: "self",
                        };
                      } else {
                        objectRelations = {
                          ...objectRelations,
                          guarantors: "",
                        };
                      }
                      if (
                        patientDataArray["guarantors.persons.addressId"] ==
                          patientDataArray["persons.addressId"] &&
                        patientDataArray["guarantors.relation"] != 31
                      ) {
                        objectSameAddressesAsPastient = {
                          ...objectSameAddressesAsPastient,
                          guarantors: true,
                        };
                      } else {
                        objectSameAddressesAsPastient = {
                          ...objectSameAddressesAsPastient,
                          guarantors: false,
                        };
                      }
                      for (
                        var i = 0;
                        i < patientObj["patientInsurances"].length;
                        i++
                      ) {
                        if (
                          patientDataArray[
                            "patientInsurances." + i + ".relation"
                          ] == 31
                        ) {
                          objectRelations = {
                            ...objectRelations,
                            ["patientInsurances." + i.toString()]: "self",
                          };
                        } else {
                          objectRelations = {
                            ...objectRelations,
                            ["patientInsurances." + i.toString()]: "",
                          };
                        }
                        if (
                          patientDataArray[
                            "patientInsurances." + i + ".persons.addressId"
                          ] === patientDataArray["persons.addressId"] &&
                          patientDataArray[
                            "patientInsurances." + i + ".relation"
                          ] != 31
                        ) {
                          objectSameAddressesAsPastient = {
                            ...objectSameAddressesAsPastient,
                            ["patientInsurances." + i.toString()]: true,
                          };
                        } else {
                          objectSameAddressesAsPastient = {
                            ...objectSameAddressesAsPastient,
                            ["patientInsurances." + i.toString()]: false,
                          };
                        }
                        // if (
                        //   patientDataArray[
                        //     "patientInsurances." + i + ".primaryInsurance"
                        //   ] === "Y"
                        // ) {
                        //   primaryInsuranceNumber =
                        //     "patientInsurances." + i + ".primaryInsurance";
                        // }
                      }
                    }
                    await setRelation(objectRelations);
                    await setSameAddressAsPatient(
                      objectSameAddressesAsPastient
                    );
                    // await setPrimaryInsurance(primaryInsuranceNumber);
                    await setEditMode(true);
                  }
                }}
                className="primary btn button btn-custom ml-2 float-right mb-3"
              >
                {editMode ? "Cancel" : "Edit"}
              </button>
            )}
          <DemographicInfo
            patientTabMode={patientTabMode}
            setpatientTabMode={setpatientTabMode}
            patientObj={patientObj}
            datePickerStatus={demoDateState}
            setdatePickerStatus={setdemoDateState}
            lookUpData={lookUpData}
            setinsuranceBlockList={setinsuranceBlockList}
            editMode={editMode}
            setEditMode={setEditMode}
            datePickerValues={datePickerValues}
            setDatePickerValues={setDatePickerValues}
            zipValues={zipValues}
            setZipValues={setZipValues}
            phoneValues={phoneValues}
            setPhoneValues={setPhoneValues}
            handleAllFormChange={handleAllFormChange}
            handleDateChange={handleDateChange}
            inputs={inputs}
            setInputs={setInputs}
            patientDataArray={patientDataArray}
            facilityId={facilityId}
            relation={relation}
            sameAddressAsPatient={sameAddressAsPatient}
          />

          {/*}
         <DemographicInfo
          patienDemographicForm={patienDemographicForm}
          patientObj={patientObj}
          dateValue={dateDemogarValue}
          onDateChange={onDemogarDateChange}
          datePickerStatus={demoDateState}
          setdatePickerStatus={setdemoDateState}
          lookUpData={lookUpData}
         />
             */}
          <GuarantorInfo
            patientTabMode={patientTabMode}
            setpatientTabMode={setpatientTabMode}
            patienDemographicForm={patienDemographicForm}
            patientObj={patientObj}
            datePickerStatus={guarDateState}
            setdatePickerStatus={setguarDateState}
            lookUpData={lookUpData}
            setinsuranceBlockList={setinsuranceBlockList}
            editMode={editMode}
            setEditMode={setEditMode}
            datePickerValues={datePickerValues}
            setDatePickerValues={setDatePickerValues}
            zipValues={zipValues}
            setZipValues={setZipValues}
            phoneValues={phoneValues}
            setPhoneValues={setPhoneValues}
            handleAllFormChange={handleAllFormChange}
            handleDateChange={handleDateChange}
            setRelation={setRelation}
            inputs={inputs}
            relation={relation}
            patientDataArray={patientDataArray}
            setInputs={setInputs}
            setSameAddressAsPatient={setSameAddressAsPatient}
            sameAddressAsPatient={sameAddressAsPatient}
          />

          {insuranceBlocksArray}

          {(patientTabMode == "form" || editMode) && (
            <div className="draftbar savePatient floatpatientfooter mb-5">
              <Container className="floatpatientfooterConatiner">
                <button className="primary btn button" onClick={handleSubmit}>
                  Save Patient
                </button>
                &nbsp;
                <button
                  className="primary btn button"
                  onClick={handleSubmitForSaveAndPlaceOrder}
                >
                  Save & Place Order
                </button>
                <span>
                  &nbsp;
                  <button
                    className="outline btn button"
                    onClick={async () => {
                      navigate("/patients");
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </Container>
            </div>
          )}
          {/* </Accordion.Body>
          </Accordion.Item> */}
        </div>
      ) : (
        <Accordion
          onSelect={handleToggleClick}
          activeKey={activeKey}
          id="patientAccordion"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>PATIENT</Accordion.Header>
            <Accordion.Body onClick={blurHandler}>
              <SaveChangesModal
                setshowSaveChangesModal={setshowSaveChangesModal}
                showSaveChangesModal={showSaveChangesModal}
                setsaveUserChanges={setsaveUserChanges}
              />

              {(orderData.status == "Draft" || orderData.status == undefined) &&
                patientObj.length !== 0 &&
                patientTabMode === "view" && (
                  <button
                    onClick={async () => {
                      let objectRelations = {};
                      let objectSameAddressesAsPastient = {};
                      // let primaryInsuranceNumber = 0;
                      if (editMode) {
                        var validation = handleValidation("patientAccordion");
                        if (validation) {
                          UserWantToSaveUnSavedData();
                        }
                      } else {
                        setPatientInUpdateMode(true);
                        accordionToggle("patientAccordion", "open");
                        await setInputs(
                          JSON.parse(JSON.stringify(patientDataArray))
                        );
                        if (Object.keys(patientDataArray).length != 0) {
                          if (patientDataArray["guarantors.relation"] == 31) {
                            objectRelations = {
                              ...objectRelations,
                              guarantors: "self",
                            };
                          } else {
                            objectRelations = {
                              ...objectRelations,
                              guarantors: "",
                            };
                          }
                          if (
                            patientDataArray["guarantors.persons.addressId"] ==
                              patientDataArray["persons.addressId"] &&
                            patientDataArray["guarantors.relation"] != 31
                          ) {
                            objectSameAddressesAsPastient = {
                              ...objectSameAddressesAsPastient,
                              guarantors: true,
                            };
                          } else {
                            objectSameAddressesAsPastient = {
                              ...objectSameAddressesAsPastient,
                              guarantors: false,
                            };
                          }
                          for (
                            var i = 0;
                            i < patientObj["patientInsurances"].length;
                            i++
                          ) {
                            if (
                              patientDataArray[
                                "patientInsurances." + i + ".relation"
                              ] == 31
                            ) {
                              objectRelations = {
                                ...objectRelations,
                                ["patientInsurances." + i.toString()]: "self",
                              };
                            } else {
                              objectRelations = {
                                ...objectRelations,
                                ["patientInsurances." + i.toString()]: "",
                              };
                            }
                            if (
                              patientDataArray[
                                "patientInsurances." + i + ".persons.addressId"
                              ] === patientDataArray["persons.addressId"] &&
                              patientDataArray[
                                "patientInsurances." + i + ".relation"
                              ] != 31
                            ) {
                              objectSameAddressesAsPastient = {
                                ...objectSameAddressesAsPastient,
                                ["patientInsurances." + i.toString()]: true,
                              };
                            } else {
                              objectSameAddressesAsPastient = {
                                ...objectSameAddressesAsPastient,
                                ["patientInsurances." + i.toString()]: false,
                              };
                            }
                            // if (
                            //   patientDataArray[
                            //     "patientInsurances." + i + ".primaryInsurance"
                            //   ] === "Y"
                            // ) {
                            //   primaryInsuranceNumber =
                            //     "patientInsurances." + i + ".primaryInsurance";
                            // }
                          }
                        }
                        await setRelation(objectRelations);
                        await setSameAddressAsPatient(
                          objectSameAddressesAsPastient
                        );
                        // await setPrimaryInsurance(primaryInsuranceNumber);
                        await setEditMode(true);
                      }
                    }}
                    className={
                      editMode
                        ? "outline btn button btn-custom ml-2 float-right mb-3"
                        : "primary btn button btn-custom ml-2 float-right mb-3"
                    }
                  >
                    {editMode ? "Cancel" : "Edit"}
                  </button>
                )}
              <DemographicInfo
                patientTabMode={patientTabMode}
                setpatientTabMode={setpatientTabMode}
                patientObj={patientObj}
                datePickerStatus={demoDateState}
                setdatePickerStatus={setdemoDateState}
                lookUpData={lookUpData}
                setinsuranceBlockList={setinsuranceBlockList}
                editMode={editMode}
                setEditMode={setEditMode}
                datePickerValues={datePickerValues}
                setDatePickerValues={setDatePickerValues}
                zipValues={zipValues}
                setZipValues={setZipValues}
                phoneValues={phoneValues}
                setPhoneValues={setPhoneValues}
                handleAllFormChange={handleAllFormChange}
                handleDateChange={handleDateChange}
                inputs={inputs}
                setInputs={setInputs}
                patientDataArray={patientDataArray}
                facilityId={facilityId}
              />

              {/*}
          <DemographicInfo
            patienDemographicForm={patienDemographicForm}
            patientObj={patientObj}
            dateValue={dateDemogarValue}
            onDateChange={onDemogarDateChange}
            datePickerStatus={demoDateState}
            setdatePickerStatus={setdemoDateState}
            lookUpData={lookUpData}
          />
  */}
              <GuarantorInfo
                patientTabMode={patientTabMode}
                setpatientTabMode={setpatientTabMode}
                patienDemographicForm={patienDemographicForm}
                patientObj={patientObj}
                datePickerStatus={guarDateState}
                setdatePickerStatus={setguarDateState}
                lookUpData={lookUpData}
                setinsuranceBlockList={setinsuranceBlockList}
                editMode={editMode}
                setEditMode={setEditMode}
                datePickerValues={datePickerValues}
                setDatePickerValues={setDatePickerValues}
                zipValues={zipValues}
                setZipValues={setZipValues}
                phoneValues={phoneValues}
                setPhoneValues={setPhoneValues}
                handleAllFormChange={handleAllFormChange}
                handleDateChange={handleDateChange}
                setRelation={setRelation}
                inputs={inputs}
                relation={relation}
                patientDataArray={patientDataArray}
                setInputs={setInputs}
                setSameAddressAsPatient={setSameAddressAsPatient}
                sameAddressAsPatient={sameAddressAsPatient}
              />

              {insuranceBlocksArray}

              {(patientTabMode == "form" || editMode) && (
                <div className="savePatient">
                  <hr></hr>
                  <button className="primary btn button" onClick={handleSubmit}>
                    Save Patient
                  </button>

                  <span>
                    &nbsp;
                    <button
                      className="outline btn button"
                      onClick={async () => {
                        var validation = handleValidation("patientAccordion");
                        if (validation) {
                          UserWantToSaveUnSavedData();
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
}

export default PatientTab;
