import { useEffect } from "react";
import { Form } from "react-bootstrap";

function InputField(props) {
  useEffect(() => {
    if (props.orderId == "") {
      if (props.defaultValue?.answerValue != undefined) {
        props.testId.map((tId) => {
          props.onChangeFunction(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: null,
                  answerValue: props.defaultValue?.answerValue,
                }
              : {
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: null,
                  answerValue: props.defaultValue?.answerValue,
                }
          );
        });
      }
    }
  }, []);
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <Form.Control
        className="input-style form-control"
        disabled={props.disabled}
        aria-label={props.formValue}
        defaultValue={
          props.answerType == "NUMBER"
            ? Number(props.defaultValue?.answerValue)
            : props.defaultValue?.answerValue
        }
        required={props.required}
        name={props.formValue}
        type={
          props.name == "password"
            ? props.name
            : props.type == "number"
            ? "number"
            : "text"
        }
        onBlur={(e) => {
          props.testId.map((tId) => {
            props.onChangeFunction(
              props.orderId != ""
                ? {
                    orderId: props.orderId,
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: e.target.value,
                  }
                : {
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: e.target.value,
                  }
            );
          });
        }}
      />
    </Form.Group>
  );
}

export default InputField;
