import React, { useState, useEffect } from "react";
import CustomNavBar from "../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../GlobalComponents/mobileNavBar";
import CustomFooter from "../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";

import "./OrderPage/OrderPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

function DownloadLabelApp(props) {
  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Download";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    }
  }, []);
  const [sexLookup, setSexLookup] = useState({});
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const handleDownload = () => {
    const fileURL =
      "http://ec2-44-197-177-68.compute-1.amazonaws.com/downloads/atvivoPrintLabel.zip";
    //  window.location.href.split("#")[0] + "downloads/atvivoPrintLabel.zip";

    window.open(fileURL, "_blank");
  };
  return (
    <div>
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="patients-list-pageheight pb-3">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Downloads
              {/* <Button variant="primary" className="primary btn button ml-auto">
                Edit Profile Info
              </Button> */}
            </h3>
            <hr></hr>

            <Container>
              <Row>
                <Col>
                  <a
                    className="btn btn-primary mr-2 mb-2"
                    style={{ color: "white" }}
                    //onClick={handleDownload}
                    href={
                      window.location.href.split("#")[0] +
                      "downloads/ATVIVO Label Print.exe"
                    }
                  >
                    Download Label Print
                  </a>
                  <a
                    className="btn btn-primary mb-2"
                    style={{ color: "white" }}
                    //onClick={handleDownload}
                    href={
                      window.location.href.split("#")[0] +
                      "downloads/AutoConnect.exe"
                    }
                  >
                    Download AutoConnect
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default DownloadLabelApp;
