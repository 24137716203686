import React, {useState} from 'react';
import {Row, Col,Container, Button, Modal} from "react-bootstrap";


function SaveChangesModal({
    setshowSaveChangesModal,
    showSaveChangesModal,
    setsaveUserChanges
    
}
){  
    const handleCloseSaveChangesModal = async () => 
    {
        setshowSaveChangesModal(false);
        await setsaveUserChanges(true);
        setsaveUserChanges(false);
    }
    const handleDoNotLoseChanges = () => {
        setsaveUserChanges(true);
        setshowSaveChangesModal(false);    
    }   
    
      return (
        <>    
          <Modal show={showSaveChangesModal} onHide={handleCloseSaveChangesModal} centered size="md">
            <Modal.Header closeButton>
              <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container>
            <Row><Col>You have some un-saved data which will be lost. Do you want to continue?</Col></Row>            
            
            </Container>
  
            </Modal.Body>
            <Modal.Footer>
              <Button className="outline btn button" onClick={handleDoNotLoseChanges}>
                No
              </Button>
              <Button variant="primary" className="primary btn button"  onClick={handleCloseSaveChangesModal}>
                Yes
              </Button>              
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  export default SaveChangesModal;