import { useEffect } from "react";
import { Form } from "react-bootstrap";

function Textarea(props) {
  useEffect(() => {
    if (props.orderId == "") {
      if (props.defaultValue?.answerValue != undefined) {
        props.testId.map((tId) => {
          props.onChange(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: null,
                  answerValue: props.defaultValue?.answerValue,
                }
              : {
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: null,
                  answerValue: props.defaultValue?.answerValue,
                }
          );
        });
      }
    }
  }, []);
  return (
    <Form.Group className="mb-3" controlId="customTextarea">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <Form.Control
        disabled={props.disabled}
        aria-label={props.formValue}
        as="textarea"
        rows={1}
        className="customTextarea input-style form-control"
        name={props.formValue}
        required={props.required}
        defaultValue={props.defaultValue?.answerValue}
        onBlur={(e) =>
          props.testId.map((tId) => {
            props.onChange(
              props.orderId != ""
                ? {
                    orderId: props.orderId,
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: e.target.value,
                  }
                : {
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: e.target.value,
                  }
            );
          })
        }
      />
    </Form.Group>
  );
}

export default Textarea;
