import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function FirstLoginCheck({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const firstLogin = localStorage.getItem("firstLogin");

    if (firstLogin == "No") {
      navigate("/changePassword");
    }
  }, [navigate]);

  return <>{children}</>;
}

export default FirstLoginCheck;
