import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  handleUpdateClassesOnFormObjects,
  checkIfRelationisSelf,
  copySameAddressAsPatient,
} from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { Form, Row, Col, Container } from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InsuranceInfoView from "./InsuranceInfoView";
import InsuranceInfoForm from "./InsuranceInfoForm";

function InsuranceInfo({
  patientTabMode,
  patientObj,
  datePickerStatus,
  setdatePickerStatus,
  lookUpData,
  insuranceFieldsReferenceNo,
  insuranceBlockList,
  setinsuranceBlockList,
  hiddenProperty,
  editMode,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  insuranceCompainesValues,
  setInsuranceCompainesValues,
  handleAllFormChange,
  handleDateChange,
  setRelation,
  inputs,
  relation,
  setInputs,
  patientDataArray,
  setSameAddressAsPatient,
  sameAddressAsPatient,
  primaryInsurance,
  setPrimaryInsurance,
}) {
  const addDisabled = () => {
    const parentDiv = document.getElementById(
      "InsuranceAccordion" + insuranceFieldsReferenceNo
    );
    const formFields = parentDiv.getElementsByClassName("form-control");
    for (let field of formFields) {
      if (field.disabled === false) field.disabled = true;
      // field.disabled = false;
    }
  };
  useEffect(() => {
    if (
      inputs[
        "patientInsurances." +
          (insuranceFieldsReferenceNo * 1 - 1).toString() +
          ".primaryInsurance"
      ] == "Y"
    ) {
      setPrimaryInsurance(insuranceFieldsReferenceNo - 1);
    }
  }, [inputs]);
  return (
    <Accordion
      defaultActiveKey="0"
      hidden={hiddenProperty}
      id={"InsuranceAccordion" + insuranceFieldsReferenceNo}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header
          className="insurance"
          id={"insurance" + insuranceFieldsReferenceNo}
        >
          Insurance {insuranceFieldsReferenceNo}{" "}
          {insuranceFieldsReferenceNo - 1 == primaryInsurance
            ? "(Primary)"
            : null}
        </Accordion.Header>
        <Accordion.Body>
          {patientTabMode == "view" &&
            !editMode && ( // load demographic section in view mode
              <InsuranceInfoView
                insuranceFieldsReferenceNo={insuranceFieldsReferenceNo}
                patientObj={patientObj}
                lookUpData={lookUpData}
                setinsuranceBlockList={setinsuranceBlockList}
                editMode={editMode}
                setEditMode={setEditMode}
                patientDataArray={patientDataArray}
              />
            )}

          {(patientTabMode == "form" || editMode) && ( // load demographic section in view mode
            <InsuranceInfoForm
              insuranceFieldsReferenceNo={insuranceFieldsReferenceNo}
              patientObj={patientObj}
              datePickerStatus={datePickerStatus}
              setdatePickerStatus={setdatePickerStatus}
              lookUpData={lookUpData}
              insuranceBlockList={insuranceBlockList}
              setinsuranceBlockList={setinsuranceBlockList}
              patientTabMode={patientTabMode}
              setEditMode={setEditMode}
              setDatePickerValues={setDatePickerValues}
              datePickerValues={datePickerValues}
              zipValues={zipValues}
              setZipValues={setZipValues}
              phoneValues={phoneValues}
              setPhoneValues={setPhoneValues}
              insuranceCompainesValues={insuranceCompainesValues}
              setInsuranceCompainesValues={setInsuranceCompainesValues}
              handleAllFormChange={handleAllFormChange}
              handleDateChange={handleDateChange}
              setRelation={setRelation}
              inputs={inputs}
              relation={relation}
              setInputs={setInputs}
              setSameAddressAsPatient={setSameAddressAsPatient}
              sameAddressAsPatient={sameAddressAsPatient}
              primaryInsurance={primaryInsurance}
              setPrimaryInsurance={setPrimaryInsurance}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default InsuranceInfo;
