// Api Url FOr Dev
export const apiUrl = "/backend/";

// API Url For Uat
//export const apiUrl = "https://devportal.atvivo.com/backend/";

// API Url For Local
// export const apiUrl = "https://localhost:7265/";

//////////////////////////////////////////

export const searchSelectedHighlightColor = "yellow";
export const facilitiesListLimit = 10;
export const maxFavLimit = 15;
export const allInputField = 30;
export const employerNameLength = 80;
export const middleNameLength = 2;
export const email = 50;
export const textareaField = 150;
export const addressFieldLimit = 50;
export const standingOrderIdConfig = 51;
//export const standingOrderIdConfig = 45;

export const sampleObject = {
  mrn: "",
  race: "",
  ethnicGroup: "",
  genderIdentity: "",
  genderIdentityOther: "",
  sexualOrientation: "",
  sexualOrientationOther: "",
  vaccinationStatus: "",
  facilityId: "",
  persons: {
    lastName: "",
    firstName: "",
    middleName: "",
    dob: "",
    sex: "",
    email: "",
    preferredPhone: "",
    phone: "",
    mobile: "",
    addresses: {
      street1: "",
      street2: "",
      zip: "",
      city: "",
      state: "",
      room: "",
    },
  },
  guarantors: {
    relation: "",
    employer: "",
    persons: {
      lastName: "",
      firstName: "",
      middleName: "",
      dob: "",
      sex: "",
      phone: "",
      addresses: {
        street1: "",
        street2: "",
        zip: "",
        city: "",
        state: "",
      },
    },
  },
  patientInsurances: [
    {
      insuranceCompanyId: "",
      policyId: "",
      primaryInsurance: "Y",
      relation: "",
      groupId: "",
      groupName: "",
      employerName: "",
      persons: {
        lastName: "",
        firstName: "",
        middleName: "",
        sex: "",
        dob: "",
        phone: "",
        addresses: {
          useSameAddressAsPatient: "on",
          street1: "",
          street2: "",
          city: "",
          zip: "",
          state: "",
        },
      },
    },
    {
      insuranceCompanyId: "",
      policyId: "",
      primaryInsurance: "N",
      relation: "",
      groupId: "",
      groupName: "",
      employerName: "",
      persons: {
        lastName: "",
        firstName: "",
        middleName: "",
        sex: "",
        dob: "",
        phone: "",
        addresses: {
          useSameAddressAsPatient: "on",
          street1: "",
          street2: "",
          city: "",
          zip: "",
          state: "",
        },
      },
    },
    {
      insuranceCompanyId: "",
      policyId: "",
      primaryInsurance: "N",
      relation: "",
      groupId: "",
      groupName: "",
      employerName: "",
      persons: {
        lastName: "",
        firstName: "",
        middleName: "",
        sex: "",
        dob: "",
        phone: "",
        addresses: {
          useSameAddressAsPatient: "on",
          street1: "",
          street2: "",
          city: "",
          zip: "",
          state: "",
        },
      },
    },
    {
      insuranceCompanyId: "",
      policyId: "",
      primaryInsurance: "N",
      relation: "",
      groupId: "",
      groupName: "",
      employerName: "",
      persons: {
        lastName: "",
        firstName: "",
        middleName: "",
        sex: "",
        dob: "",
        phone: "",
        addresses: {
          useSameAddressAsPatient: "on",
          street1: "",
          street2: "",
          city: "",
          zip: "",
          state: "",
        },
      },
    },
  ],
};
