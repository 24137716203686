import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  displayInDateFormat,
  LookupText,
} from "../../../GlobalComponents/ValidatorAndSubmitForm";
import { ApiPostCalls } from "../../../services/Api";
import moment from "moment/moment";
import { LoginInfoContext, LoginUserLocationContext } from "../../../Context";
import ReactPaginate from "react-paginate";
import MaskedInput from "react-text-mask";

function AdvancedSearchModal({
  lookUpData,
  rowClickHandler,
  setFilter,
  patientTabMode,
  editMode,
}) {
  const [searchGridVisible, setsearchGridVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [advancedSearchInputs, setadvancedSearchInputs] = useState({});
  const [advancedSearchGridData, setadvancedSearchGridData] = useState({});
  const [advancedSearchSelectedObj, setadvancedSearchSelectedObj] = useState(
    {}
  );
  const [advancedSearchSelectedObjId, setadvancedSearchSelectedObjId] =
    useState("");

  const rowClickAdvancedSearch = (selectedPatientObj, id) => {
    setadvancedSearchSelectedObj(selectedPatientObj);
    setadvancedSearchSelectedObjId(id);
  };
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagenationState, setPagenationState] = useState(-1);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    // handlePatientJsonChange("pageNumber", event.selected + 1);
    setPagenationState(!pagenationState);
  };

  function handleAdvancedSearchProceedButtonClick() {
    if (advancedSearchSelectedObjId != "") {
      var dataArray = [];
      dataArray.push(advancedSearchSelectedObjId);
      dataArray.push(
        advancedSearchSelectedObj["lastName"] +
          ", " +
          advancedSearchSelectedObj["firstName"]
      );
      rowClickHandler(dataArray, advancedSearchSelectedObjId);
    }
    setShow(false);
  }

  const handleAdvancedSearchInputsChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setadvancedSearchInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDateAdvancedSearchChange = (date, DateFieldName) => {
    const name = DateFieldName;
    const value = date;
    setadvancedSearchInputs((values) => ({ ...values, [name]: value }));
  };

  function handleAdvancedSearchButtonClick() {
    fetchData();
  }
  useEffect(() => {
    if (pagenationState != -1) {
      fetchData();
    }
  }, [pagenationState]);

  async function handleAdvancedResetButtonClick() {
    setadvancedSearchInputs({});

    setadvancedSearchSelectedObjId("");
    setadvancedSearchGridData({});
    setsearchGridVisible(false);
  }

  function AdvancedSearchDataGrid() {
    let ObjectHasSomeData = Object.keys(advancedSearchGridData);
    const advancedSearchDataArray = [];

    if (ObjectHasSomeData.length > 0) {
      for (let i = 0; i < ObjectHasSomeData.length; i++)
        advancedSearchDataArray.push(
          <Row
            className={
              advancedSearchGridData[i].id == advancedSearchSelectedObjId
                ? "selected cursorPointer"
                : "cursorPointer"
            }
            onClick={() => {
              rowClickAdvancedSearch(
                advancedSearchGridData[i],
                advancedSearchGridData[i].id
              );
            }}
          >
            <Col md={{ span: 4 }}>
              {advancedSearchGridData[i].lastName},{" "}
              {advancedSearchGridData[i].firstName}
            </Col>

            <Col className="text-center">
              {advancedSearchGridData[i].phone != null
                ? advancedSearchGridData[i].phone
                : null}
            </Col>
            <Col className="text-center">
              {advancedSearchGridData[i].order.length > 0
                ? advancedSearchGridData[i].order[0].orderNumber
                : null}
            </Col>
            <Col className="text-center">
              {advancedSearchGridData[i].mrn != null
                ? advancedSearchGridData[i].mrn
                : null}
            </Col>
            <Col className="text-center">
              {advancedSearchGridData[i].dob != null
                ? displayInDateFormat(advancedSearchGridData[i].dob)
                : null}
            </Col>
            <Col className="text-center">
              {advancedSearchGridData[i].sex != null
                ? LookupText(lookUpData.sexData, advancedSearchGridData[i].sex)
                : null}
            </Col>
          </Row>
        );
    } else
      advancedSearchDataArray.push(
        <Row>
          <Col>There are no records matching your criteria</Col>
        </Row>
      );
    return advancedSearchDataArray;
  }

  const fetchData = async () => {
    let jsonData = {
      lastName:
        advancedSearchInputs["advancedSearchLastName"] == null ||
        advancedSearchInputs["advancedSearchLastName"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchLastName"],
      firstName:
        advancedSearchInputs["advancedSearchFirstName"] == null ||
        advancedSearchInputs["advancedSearchFirstName"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchFirstName"],
      phone:
        advancedSearchInputs["advancedSearchPhone"] == null ||
        advancedSearchInputs["advancedSearchPhone"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchPhone"],
      orderNumber:
        advancedSearchInputs["advancedSearchOrderNumber"] == null ||
        advancedSearchInputs["advancedSearchOrderNumber"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchOrderNumber"],
      mrn:
        advancedSearchInputs["advancedSearchMrn"] == null ||
        advancedSearchInputs["advancedSearchMrn"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchMrn"],
      dob:
        advancedSearchInputs["advancedSearchDob"] == null ||
        advancedSearchInputs["advancedSearchDob"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchDob"],
      facilityId: selectedLocation,
      pageNumber: currentPage + 1,
      totalRecordPerPage: totalRecordPerPage,
      sortingField: "",
      sortingType: "",
    };
    setPagenationState(-1);
    const data = await ApiPostCalls(
      JSON.stringify(jsonData),
      "AdvancePatientSearch"
    );
    if (data.patients.length > 0) {
      setTotalRecords(Math.ceil(data.totalRecords / totalRecordPerPage));
    } else {
      setTotalRecords(0);
    }
    setadvancedSearchGridData(data.patients);
    //setadvancedSearchGridData(data);
    setsearchGridVisible(true);
  };

  return (
    <>
      <Button
        onClick={handleShow}
        className="primary btn button w-100"
        disabled={patientTabMode === "form" ? true : editMode ? true : false}
      >
        Advanced Search
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Advanced Patient Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={{ span: 4 }}>
                <h5 className="label-style">Last Name</h5>
                <input
                  name="advancedSearchLastName"
                  type="text"
                  autoComplete="off"
                  className="input-style form-control mb-3"
                  onChange={handleAdvancedSearchInputsChange}
                  value={
                    advancedSearchInputs["advancedSearchLastName"] != undefined
                      ? advancedSearchInputs["advancedSearchLastName"]
                      : ""
                  }
                />
              </Col>

              <Col md={{ span: 4 }}>
                <h5 className="label-style">First Name</h5>
                <input
                  name="advancedSearchFirstName"
                  type="text"
                  autoComplete="off"
                  className="input-style form-control mb-3"
                  onChange={handleAdvancedSearchInputsChange}
                  value={
                    advancedSearchInputs["advancedSearchFirstName"] != undefined
                      ? advancedSearchInputs["advancedSearchFirstName"]
                      : ""
                  }
                />
              </Col>

              <Col md={{ span: 4 }}>
                <h5 className="label-style">Phone</h5>
                <input
                  name="advancedSearchPhone"
                  type="text"
                  autoComplete="off"
                  className="input-style form-control mb-3"
                  onChange={handleAdvancedSearchInputsChange}
                  value={
                    advancedSearchInputs["advancedSearchPhone"] != undefined
                      ? advancedSearchInputs["advancedSearchPhone"]
                      : ""
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 4 }}>
                <h5 className="label-style">Requisition #</h5>
                <input
                  name="advancedSearchOrderNumber"
                  type="text"
                  autoComplete="off"
                  className="input-style form-control mb-3"
                  onChange={handleAdvancedSearchInputsChange}
                  value={
                    advancedSearchInputs["advancedSearchOrderNumber"] !=
                    undefined
                      ? advancedSearchInputs["advancedSearchOrderNumber"]
                      : ""
                  }
                />
              </Col>

              <Col md={{ span: 4 }}>
                <h5 className="label-style">MRN</h5>
                <input
                  autoComplete="off"
                  name="advancedSearchMrn"
                  type="text"
                  className="input-style form-control mb-3"
                  onChange={handleAdvancedSearchInputsChange}
                  value={
                    advancedSearchInputs["advancedSearchMrn"] != undefined
                      ? advancedSearchInputs["advancedSearchMrn"]
                      : ""
                  }
                />
              </Col>
              <Col md={{ span: 4 }}>
                <h5 className="label-style">Date of Birth</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  autoComplete="off"
                  name="advancedSearchDob"
                  ariaLabelledBy="Date of birth"
                  className="input-style form-control"
                  selected={
                    advancedSearchInputs["advancedSearchDob"] != undefined
                      ? new Date(advancedSearchInputs["advancedSearchDob"])
                      : ""
                  }
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  onChange={(date) => {
                    if (date != null) {
                      handleDateAdvancedSearchChange(
                        moment(date).format("yyyy-MM-DDT00:00:00"),
                        "advancedSearchDob"
                      );
                    } else {
                      handleDateAdvancedSearchChange(date, "advancedSearchDob");
                    }
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder={"MM/dd/yyyy"}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-row">
                <Button
                  variant="primary"
                  onClick={handleAdvancedResetButtonClick}
                  className="primary btn button "
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  disabled={Object.values(advancedSearchInputs).every(
                    (o) => o === "" || o === null
                  )}
                  onClick={() => {
                    setCurrentPage(0);
                    handleAdvancedSearchButtonClick();
                  }}
                  className="primary btn button ml-auto"
                >
                  Search
                </Button>
                <Button
                  variant="outline "
                  className="outline button ml-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <div
              className="patients-advance-search-results mt-3 mb-3"
              style={{ display: searchGridVisible ? "block" : "none" }}
            >
              <Row className="DarkHeading sticky-top">
                <Col md={{ span: 4 }}>Name</Col>
                <Col className="text-center">Phone</Col>
                <Col className="text-center">Requisition #</Col>
                <Col className="text-center">MRN</Col>
                <Col className="text-center">DOB</Col>
                <Col className="text-center">Gender</Col>
              </Row>
              <AdvancedSearchDataGrid />
            </div>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="primary button"
            variant="primary"
            disabled={advancedSearchSelectedObjId != "" ? false : true}
            onClick={handleAdvancedSearchProceedButtonClick}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AdvancedSearchModal;
