import { Row, Col, Container, Form } from "react-bootstrap";
import {
  updateDataToFields,
  LookupText,
  displayInDateFormat,
} from "../../../GlobalComponents/ValidatorAndSubmitForm";

export default function DemographicInfoView({
  setpatientTabMode,
  patientObj,
  lookUpData,
  setinsuranceBlockList,
  editMode,
  setEditMode,
  patientDataArray,
}) {
  const changePatientMode = () => {
    setpatientTabMode("form");
  };
  return (
    <Container className="label-style parentcontainer">
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Last Name</h5>
          <input
            type="text"
            name="lastName"
            id="Demographic.lastName"
            data-type="text"
            className="input-style form-control person"
            aria-label="Last Name"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.lastName"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">First Name</h5>
          <input
            type="text"
            name="firstName"
            id="Demographic.firstName"
            data-type="text"
            aria-label="First Name"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.firstName"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Date of Birth</h5>
          <input
            type="text"
            name="dob"
            data-type="date"
            id="Demographic.dob"
            aria-label="First Name"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? displayInDateFormat(patientDataArray["persons.dob"])
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender</h5>
          <input
            type="text"
            name="sex"
            id="Demographic.sex"
            data-type="lookup"
            aria-label="Sex"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.sexData,
                    patientDataArray["persons.sex"]
                  )
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Street</h5>
          <input
            type="text"
            name="street1"
            id="Demographic.street1"
            data-type="text"
            aria-label="Street"
            className="input-style form-control address"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.addresses.street1"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">ZIP Code</h5>
          <input
            type="text"
            name="zip"
            id="Demographic.zip"
            data-type="text"
            aria-label="Zip"
            className="input-style form-control address validate_zip"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.addresses.zip"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">City</h5>
          <input
            type="text"
            name="city"
            id="Demographic.city"
            data-type="text"
            aria-label="City"
            className="input-style form-control address"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.addresses.city"]
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">State/Province/Region</h5>
          <input
            type="text"
            name="state"
            id="Demographic.state"
            data-type="text"
            aria-label="State"
            className="input-style form-control address"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.addresses.state"]
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Primary Phone # </h5>
          <input
            type="text"
            name="phone"
            id="Demographic.phone"
            data-type="text"
            aria-label="Primary Phone"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.phone"]
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Race</h5>
          <input
            type="text"
            name="race"
            id="Demographic.race"
            data-type="lookup"
            aria-label="Race"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? LookupText(lookUpData.raceData, patientDataArray["race"])
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Ethinic Group</h5>
          <input
            type="text"
            name="ethnicGroup"
            id="Demographic.ethnicGroup"
            data-type="lookup"
            aria-label="Ethnic Group"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.ethinicData,
                    patientDataArray["ethnicGroup"]
                  )
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Gender Identity</h5>
          <input
            type="text"
            name="genderIdentity"
            id="Demographic.genderIdentity"
            data-type="lookup"
            aria-label="Gender"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.genderData,
                    patientDataArray["genderIdentity"]
                  )
                : ""
            }
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4 }} className="mandatory">
          <h5 className="label-style">Sexual Orientation</h5>
          <input
            type="text"
            name="sexualOrientation"
            id="Demographic.sexualOrientation"
            data-type="lookup"
            aria-label="Sexual Orientation"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? LookupText(
                    lookUpData.sexualOrientationData,
                    patientDataArray["sexualOrientation"]
                  )
                : ""
            }
            disabled
          />
        </Col>
        <Col md={{ span: 4 }} className="textinputwithcheckbox mandatory">
          <h5 className="label-style">Mobile Number</h5>
          <input
            type="text"
            name="mobile"
            id="Demographic.mobile"
            data-type="text"
            aria-label="Mobile"
            className="input-style form-control person"
            value={
              patientDataArray.length != 0
                ? patientDataArray["persons.mobile"]
                : ""
            }
            disabled
          />
        </Col>
      </Row>
    </Container>
  );
}
