import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  handleUpdateClassesOnFormObjects,
  checkIfRelationisSelf,
  copySameAddressAsPatient,
} from "../../../GlobalComponents/ValidatorAndSubmitForm";
import { Form, Row, Col, Container } from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GuarantorInfoView from "./GuarantorInfoView";
import GuarantorInfoForm from "./GuarantorInfoForm";

function GuarantorInfo({
  patientTabMode,
  setpatientTabMode,
  patienDemographicForm,
  patientObj,
  datePickerStatus,
  setdatePickerStatus,
  lookUpData,
  setinsuranceBlockList,
  editMode,
  setEditMode,
  datePickerValues,
  setDatePickerValues,
  setZipValues,
  zipValues,
  phoneValues,
  setPhoneValues,
  handleAllFormChange,
  handleDateChange,
  setRelation,
  inputs,
  relation,
  setInputs,
  patientDataArray,
  setSameAddressAsPatient,
  sameAddressAsPatient,
}) {
  return (
    <Accordion defaultActiveKey="0" id="GuarantorAccordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header id="guarantors">Guarantor</Accordion.Header>
        <Accordion.Body>
          {patientTabMode == "view" &&
            !editMode && ( // load guarantor section in view mode
              <GuarantorInfoView
                setpatientTabMode={setpatientTabMode}
                patientObj={patientObj}
                lookUpData={lookUpData}
                setinsuranceBlockList={setinsuranceBlockList}
                editMode={editMode}
                setEditMode={setEditMode}
                patientDataArray={patientDataArray}
              />
            )}

          {(patientTabMode == "form" || editMode) && ( // load guarantor section in form mode
            <GuarantorInfoForm
              setpatientTabMode={setpatientTabMode}
              patienDemographicForm={patienDemographicForm}
              patientObj={patientObj}
              datePickerStatus={datePickerStatus}
              setdatePickerStatus={setdatePickerStatus}
              lookUpData={lookUpData}
              setinsuranceBlockList={setinsuranceBlockList}
              patientTabMode={patientTabMode}
              setEditMode={setEditMode}
              datePickerValues={datePickerValues}
              setDatePickerValues={setDatePickerValues}
              zipValues={zipValues}
              setZipValues={setZipValues}
              phoneValues={phoneValues}
              setPhoneValues={setPhoneValues}
              handleAllFormChange={handleAllFormChange}
              handleDateChange={handleDateChange}
              setRelation={setRelation}
              inputs={inputs}
              relation={relation}
              setInputs={setInputs}
              setSameAddressAsPatient={setSameAddressAsPatient}
              sameAddressAsPatient={sameAddressAsPatient}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default GuarantorInfo;
