import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { ApiPostCalls } from "../../../services/Api";
import WarningModel from "../../../GlobalComponents/WarningModel";
import { snackBar } from "../../../services/Alert";

function SaveSearchCriteriaModel(props) {
  const [modalShow, setModalShow] = useState(false);

  const saveSearchCriteria = async () => {
    if (
      props.oldValue == props.searchName &&
      props.saveSearchCriteriaPermissionCheck
    ) {
      props.setShowSavedSearchModel(false);
      setModalShow(true);
    } else {
      const saveSearchJson = {
        userId: localStorage.getItem("userId"),
        searchName: props.searchName,
        searchCriteria: JSON.stringify(props.searchFilter),
      };
      const response = await ApiPostCalls(
        JSON.stringify(saveSearchJson),

        "SaveSearchCriteria"
      );
      if (response.status == "Success") {
        props.setShowSavedSearchModel(false);
        props.GetAllSaveSearchCriteria();
        snackBar("Success! Search saved successfully", "success");
      }
      if (response.status == "Error") {
        snackBar("Error! " + response.message, "danger");
      }
    }
  };
  const onConfirmationYes = async () => {
    const saveSearchJson = {
      id: props.savedSearchCriteriaId,
      userId: props.savedSearchCriteriaUserId,
      searchName: props.searchName,
      searchCriteria: JSON.stringify(props.searchFilter),
    };
    const response = await ApiPostCalls(
      JSON.stringify(saveSearchJson),

      "UpdateSearchCriteria"
    );
    if (response.status == "Success") {
      setModalShow(false);
      props.GetAllSaveSearchCriteria();
      snackBar("Success! Search updated successfully", "success");
    }
    if (response.status == "Error") {
      snackBar("Error! Search unable to updated", "error");
    }
  };
  return (
    <>
      <Modal
        show={props.showSavedSearchModel}
        onHide={() => {
          props.setShowSavedSearchModel(false);
        }}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                {" "}
                <h5 className="label-style">Name</h5>{" "}
                <input
                  className="input-style form-control"
                  onChange={(e) => props.setSearchName(e.target.value)}
                  value={props.searchName}
                />
              </Col>
            </Row>
            {props.showText != "" && (
              <Row>
                <Col>
                  <p className="help-text">
                    You cannot overwrite the search <b>{props.showText}</b>{" "}
                    because you did not create it. You can save it with another
                    name.
                  </p>
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline "
            className="outline button ml-2"
            onClick={() => props.setShowSavedSearchModel(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="primary btn button"
            onClick={async () => {
              // if (props.savedSearchCriteriaId != 0) {
              //   props.allSaveSearchCriteria.map(async (val, ind) => {
              //     if (val.id == props.savedSearchCriteriaId) {
              //       if (val.userId == localStorage.getItem("userId")) {
              //         setSaveSearchCriteriaPermissionCheck(true);
              //       } else {
              //         if (
              //           props.userPermissions[0].rolePermissions
              //             .updateFeature == "Y"
              //         ) {
              //           setSaveSearchCriteriaPermissionCheck(true);
              //         } else {
              //           setSaveSearchCriteriaPermissionCheck(false);
              //         }
              //       }
              //     }
              //   });
              // }
              saveSearchCriteria();
              // if (props.oldValue == props.searchName) {
              //   props.setShowSavedSearchModel(false);
              //   setModalShow(true);
              // } else {
              //   const saveSearchJson = {
              //     userId: localStorage.getItem("userId"),
              //     searchName: props.searchName,
              //     searchCriteria: JSON.stringify(props.searchFilter),
              //   };
              //   const response = await ApiPostCalls(
              //     JSON.stringify(saveSearchJson),

              //     "SaveSearchCriteria"
              //   );
              //   if (response.status == "Success") {
              //     props.setShowSavedSearchModel(false);
              //     props.GetAllSaveSearchCriteria();
              //     snackBar("Success! Search saved successfully", "success");
              //   }
              //   if (response.status == "Error") {
              //     snackBar("Error! Search unable to save", "error");
              //   }
              // }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to overwrite this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />
      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Result"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SaveSearchCriteriaModel;
