import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Form } from "react-bootstrap";
import WarningModel from "../../../GlobalComponents/WarningModel";
import { useNavigate } from "react-router-dom";
import { LoginInfoContext, LoginUserLocationContext } from "../../../Context";
import InputWithLoader from "./SearchInputFieldWithLoader";
import SelectBody from "./SelectBody";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { snackBar } from "../../../services/Alert";
import { ApiGetCalls } from "../../../services/Api";

function SearchFacility({
  searchFacilityState,
  setSearchFacilityState,
  setFacility,
  selectFacility,
}) {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userInfo")) != undefined) {
      setOrganizationJson({
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        searchedText: null,
        pageNumber: 1,
        totalRecordPerPage: 500,
      });
    }
    if (
      JSON.parse(localStorage.getItem("userInfo")) != undefined &&
      JSON.parse(localStorage.getItem("userInfo")).userType == "Portal"
    ) {
      //setOrganizationId(context.userInfo.userOrg[0].id);
      GetAllFacilities(
        JSON.parse(localStorage.getItem("userInfo")).userOrg[0].id
      ).then((val) => {
        setDisableFacilities(false);
      });
    } else if (
      JSON.parse(localStorage.getItem("selectedOrganization")) != undefined
    ) {
      var orgName = JSON.parse(
        localStorage.getItem("selectedOrganization")
      ).orgName;
      var orgId = JSON.parse(
        localStorage.getItem("selectedOrganization")
      ).orgId;
      setSearchText(orgName);
      GetAllFacilities(orgId).then((val) => {
        setDisableFacilities(false);
      });
    }
  }, []);
  const [context, setContext] = useContext(LoginInfoContext);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const handleCloseSaveChangesModal = async () => {
    setSearchText("");
    setLocationsDataJson([]);
    setDisableFacilities(true);
    if (selectedFacilty != "") {
      setSelectedFacility(selectedFacilty);
      if (selectedFacilty != selectedLocation && !setFacility) {
        setCancelModalShow(true);
      } else {
        if (selectedFacilty != "") {
          selectFacility(selectedFacilty);
        } else {
          selectFacility(selectedFacility);
        }
      }
    } else if (selectedFacility != "") {
      if (!setFacility && selectedFacility != selectedLocation) {
        setCancelModalShow(true);
      } else {
        selectFacility(selectedFacility);
      }
    }
    selectedFacilityId = "";
    setSearchFacilityState(false);
    setFilter("");
    setFacilityCodeState("");
  };
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [facilityCodeState, setFacilityCodeState] = useState("");
  const [facilitiesCode, setFacilitiesCode] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const rowClickHandler = (selectedFacilityCodeObj, id) => {
    setShowResults(false);
    setFilter("");
    setFacilityCodeState(
      selectedFacilityCodeObj[1] + ": " + selectedFacilityCodeObj[2]
    );
    setSelectedFacility(id);
    setSelectedFacilty("");
    //selectedFacilty = "";
    // accordionToggle("patientAccordion", "open");
    // onChange(id);
  };
  const facilityCodeData = [];
  if (localStorage.getItem("locations") != null) {
    const locations = JSON.parse(localStorage.getItem("selectableLocations"));
    for (let i = 0; i < locations.length; i++) {
      const facilityCodeDataObj = [
        locations[i].id.toString(),
        locations[i].facilityCode,
        locations[i].label,
      ];
      facilityCodeData.push(facilityCodeDataObj);
    }
  }

  var selectedFacilityId = "";
  var favilityDivs;
  const [searchText, setSearchText] = useState("");
  const [organizationData, setOrganizationData] = useState({ data: [] });
  const [organizationJson, setOrganizationJson] = useState({});
  const [organizationId, setOrganizationId] = useState(0);
  const [showResultsForOrg, setShowResultsForOrg] = useState(false);
  const [disableFacilities, setDisableFacilities] = useState(true);
  const [selectedFacilty, setSelectedFacilty] = useState("");
  const [allFacilities, SetAllFacilities] = useState([]);
  const rowClickHandlerForOrg = (selectedOrganizationObj, orgId) => {
    // setSelectedLocation("");
    setShowResultsForOrg(false);
    setSearchText(selectedOrganizationObj[1]);
    setOrganizationId(orgId);
    if (orgId == null) {
      setDisableFacilities(false);
    } else {
      GetAllFacilities(orgId).then((val) => {
        setDisableFacilities(false);
      });
    }
    snackBar("Selected Organization: " + selectedOrganizationObj[1], "info");
  };
  const organizationDataGrid = [];
  for (let i = 0; i < organizationData.data.length; i++) {
    const organizationDataObj = [
      organizationData.data[i].id.toString(),
      organizationData.data[i].organizationName,
    ];
    organizationDataGrid.push(organizationDataObj);
  }
  async function GetAllFacilities(id) {
    if (id != null) {
      const response = await ApiGetCalls("GetAllFacilitiesTree/" + id, true);
      SetAllFacilities(response.data);
      setLocationsDataJson(response.data);
    }
    //setuserTypeDropdownOptions(response);
  }
  useEffect(() => {
    if (allFacilities.length > 0) {
      if (selectedFacilty == "") {
        var nonSelectedfacilityCodes = [];
        setLocationsDataJson(
          getTreeOptionsSelected(allFacilities, "id", nonSelectedfacilityCodes)
        );
      } else {
        setLocationsDataJson(
          getTreeOptionsSelected(allFacilities, "id", [selectedFacilty])
        );
      }
    }
  }, [selectedFacilty]);
  useEffect(() => {
    if (locationsDataJson != null && locationsDataJson.length > 0)
      SetAllFacilities([...locationsDataJson]);
  }, [locationsDataJson]);
  return (
    <>
      <Modal
        show={searchFacilityState}
        onHide={() => {
          setSearchFacilityState(false);
          setSelectedFacility("");
          setFilter("");
          setFacilityCodeState("");
          setSearchText("");
          setLocationsDataJson([]);
          setDisableFacilities(true);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search and Select Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {JSON.parse(localStorage.getItem("userInfo")) != undefined &&
                JSON.parse(localStorage.getItem("userInfo")).userType !=
                  "Portal" && (
                  <Col md={{ span: 12 }}>
                    <h5 className="label-style">Organization</h5>
                    <InputWithLoader
                      id="orgSelect"
                      filter={searchText}
                      placeholder="Start typing..."
                      apiEndPoint={
                        JSON.parse(localStorage.getItem("userInfo")).userType ==
                        "Laboratory"
                          ? "GetAllOrganization"
                          : "GetAllUserOrganization"
                      }
                      setData={setOrganizationData}
                      setShowResults={setShowResultsForOrg}
                      setFilter={setSearchText}
                      name="org"
                      aria_label="Organization"
                      className="input-style form-control mt-1 mb-2"
                      required={true}
                      data_type="text"
                      method={"post"}
                      postApiJson={organizationJson}
                      selectedId={organizationId}
                      loginScreen={true}
                    />
                    {showResultsForOrg ? (
                      <SelectBody
                        rowClickHandler={rowClickHandlerForOrg}
                        filter={searchText}
                        column={["Name"]}
                        dataArray={organizationDataGrid}
                        setShowResults={setShowResultsForOrg}
                      />
                    ) : null}
                  </Col>
                )}
              <Col>
                {" "}
                <h5 className="label-style">Facility</h5>{" "}
                <DropdownTreeSelect
                  placeholder="Search facility name or code"
                  disabled={disableFacilities}
                  mode="radioSelect"
                  keepTreeOnSearch={true}
                  keepChildrenOnSearch={true}
                  keepOpenOnSelect={false}
                  onFocus={() => {
                    setFilter("");
                    setFacilityCodeState("");
                  }}
                  data={locationsDataJson}
                  onChange={(e) => {
                    setSelectedFacilty(e.id);
                  }}
                  className="bootstrap-demo"
                />
              </Col>
              {/* <Col md={{ span: 6 }}>
                <h5 className="label-style">Facility Code</h5> */}

              {/* <Form.Control
                  required
                  aria-label="Facility Code"
                  type="text"
                  name="facilityCode"
                  className="input-style"
                  onChange={(e) => {}}
                /> */}

              {/* <InputWithLoader
                  id="facilityCode"
                  filter={filter}
                  placeholder="Start typing..."
                  apiEndPoint=""
                  setData={setFacilitiesCode}
                  setShowResults={setShowResults}
                  setFilter={setFilter}
                  className="input-style form-control"
                />
                {facilityCodeState != "" && (
                  <span className="facilityCodeState">
                    {facilityCodeState}
                    <button
                      className="facilityCodeStateClose"
                      onClick={() => {
                        setFilter("");
                        setFacilityCodeState("");
                      }}
                    >
                      x
                    </button>
                  </span>
                )}
                {showResults ? (
                  <SelectBody
                    rowClickHandler={rowClickHandler}
                    filter={filter}
                    column={["Facility Code", "Facility Name"]}
                    dataArray={facilityCodeData}
                  />
                ) : null}
              </Col> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="primary btn button"
            onClick={handleCloseSaveChangesModal}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModel
        show={cancelModalShow}
        heading="Warning"
        bodytext="You are trying to change the facility. Any changes that you have made will be lost. Are you sure you want to change the facility?"
        onHide={() => {
          setCancelModalShow(false);
          setSelectedFacility("");
        }}
        onyes={async () => {
          if (
            JSON.parse(localStorage.getItem("userInfo")) != undefined &&
            JSON.parse(localStorage.getItem("userInfo")).userType == "Portal"
          ) {
            const response = await ApiGetCalls(
              "GetUserPermissions/" + selectedFacilty
            );
            if (response["status"] === "Success") {
              localStorage.setItem(
                "userPermissions",
                JSON.stringify(response.data)
              );
            }
          } else {
            const response = await ApiGetCalls(
              "GetUserSelectableFacilities/" + selectedFacilty
            );
            if (response["status"] === "Success") {
              localStorage.setItem(
                "selectableLocations",
                JSON.stringify(response.data)
              );
            }
          }
          localStorage.setItem("selectedLocation", selectedFacilty);
          setSelectedLocation(selectedFacilty);
          setCancelModalShow(false);
          setSelectedFacility("");
          navigate("/patients");
        }}
      />
    </>
  );
}
export default SearchFacility;

export function getTreeOptionsSelected(obj, type, seletedArray) {
  obj.forEach((element, index) => {
    if (element["children"] != undefined) {
      if (element["children"].length != 0) {
        if (seletedArray.indexOf(element[type]) > -1) {
          element.checked = true;
        } else {
          element.checked = false;
        }
        getTreeOptionsSelected(element["children"], type, seletedArray);
      } else {
        if (seletedArray.indexOf(element[type]) > -1) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
    } else {
      if (seletedArray.indexOf(element[type]) > -1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    }
  });
  return obj;
}

export function getAllPhysicalFacilitiesFromTree(obj, facilitiesObjArray) {
  obj.forEach((element, index) => {
    if (element["children"].length != 0) {
      if (element.facilityType == 44) {
        facilitiesObjArray.push({ id: element.id, code: element.facilityCode });
      }
      getAllPhysicalFacilitiesFromTree(element["children"], facilitiesObjArray);
    } else {
      if (element.facilityType == 44) {
        facilitiesObjArray.push({ id: element.id, code: element.facilityCode });
      }
    }
  });
  return facilitiesObjArray;
}
export function getAllPhysicalFromTree(obj, facilitiesObjArray, searchQuery) {
  obj[0].children
    ?.filter((x) => x.label.toLowerCase().includes(searchQuery.toLowerCase()))
    .forEach((element, index) => {
      if (element["children"].length != 0) {
        if (element.id != 0) facilitiesObjArray.push({ id: element.id });

        getAllPhysicalFromTree(element["children"], facilitiesObjArray);
      } else {
        facilitiesObjArray.push({ id: element.id });
      }
    });
  return facilitiesObjArray;
}

export function getChildObjStructure(obj, id) {
  // obj.forEach((element, index) => {
  var objTOreturn = null;
  for (var i = 0; i < obj.length; i++) {
    if (obj[i]["children"].length != 0) {
      if (obj[i].id == id) {
        return obj[i];
      }
      objTOreturn = getChildObjStructure(obj[i]["children"], id);
      if (objTOreturn != null) {
        return objTOreturn;
      }
    } else {
      if (obj[i].id == id) {
        return obj[i];
      }
    }
  }
  // });
  return objTOreturn;
}
