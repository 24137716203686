import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

function FormSelect(props) {
  const [formSelectDropDown, setFormSelectDropDown] = useState("");
  useEffect(() => {
    if (props.defaultValue?.answerId) {
      setFormSelectDropDown(props.defaultValue?.answerId);
    } else {
      setFormSelectDropDown(props.defaultValue?.id);
      if (props.defaultValue != undefined) {
        props.testId.map((tId) => {
          // setFormSelectDropDown(e.target.value);
          props.onChange(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultValue?.id * 1,
                  answerValue: null,
                }
              : {
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultValue?.id * 1,
                  answerValue: null,
                }
          );
        });
      }
    }
  }, []);
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <Form.Select
        aria-label={props.formValue}
        className="input-style form-control"
        disabled={props.disabled}
        required={props.required}
        value={formSelectDropDown}
        onChange={(e) => {
          props.testId.map((tId) => {
            setFormSelectDropDown(e.target.value);
            props.onChange(
              props.orderId != ""
                ? {
                    orderId: props.orderId,
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: e.target.value * 1,
                    answerValue: null,
                  }
                : {
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: e.target.value * 1,
                    answerValue: null,
                  }
            );
          });
        }}
      >
        <option></option>
        {props.optionValues?.map((ansValue, ansIndex) => (
          <option
            key={ansIndex}
            // selected={
            //   props.defaultValue?.answerId == ansValue.id ? true : false
            // }
            value={ansValue.id}
          >
            {ansValue.answer}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default FormSelect;
