import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogIn from "./Pages/LogIn";

function CheckIfUserLoggedIn({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null
    ) {
      var url = window.location.href.split("#")[1];
      localStorage.setItem("Url", url);
      navigate("/");
    }
  }, [navigate]);

  if (
    localStorage.getItem("token") == null ||
    localStorage.getItem("userInfo") == null
  ) {
    return <LogIn />;
  } else {
    return children;
  }
}

export default CheckIfUserLoggedIn;
