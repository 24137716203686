import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Modal, Button } from "react-bootstrap";
import { parseJSON } from "jquery";
import { ApiPostCalls, ApiGetCalls } from "../../../services/Api";
import { handleValidation } from "../../../GlobalComponents/ValidatorAndSubmitForm";
import Spinner from "react-bootstrap/Spinner";
import InputMask from "react-input-mask";
import { removeErrorForDivs } from "./../../../GlobalComponents/ValidatorAndSubmitForm";
import { allInputField, textareaField, email } from "../../../config/config";
function CopyToModel({
  showCopyTo,
  setShowCopyTo,
  copyToLocationPersons,
  locationId,
  setOrderCopyToProvidersList,
  orderCopyToProvidersList,
  handleSubmit,
}) {
  const handleClose = () => setShowCopyTo(false);

  const [copyToInputs, setCopyToInputs] = useState({});
  const handleShow = () => {
    setShowCopyTo(true);
  };
  const [fetchingAddress, setFetchingAddress] = React.useState(false);
  function getAddressByZipCode(zipCode) {
    var filteredZipCode = zipCode.split("-");
    if (filteredZipCode[0] != "") {
      ApiGetCalls("GetAddressByZip/" + filteredZipCode[0]).then((response) => {
        if (response != {}) {
          setCopyToInputs({
            ...copyToInputs,
            city: response?.City,
            state: response?.State,
          });
        }
        setFetchingAddress(false);
      });
    } else {
      setFetchingAddress(false);
    }
  }
  const [zipMask, setzipMask] = React.useState(
    copyToInputs["zip"] != null
      ? copyToInputs["zip"].length > 5
        ? "99999-9999"
        : "99999"
      : "99999"
  );
  function saveCopyToData() {
    var commonListId = 0;
    copyToInputs["facilityId"] = locationId;
    copyToInputs["status"] = "Active";
    delete copyToInputs.locations;

    commonListId = copyToInputs?.common_list;

    delete copyToInputs.common_list;

    ApiPostCalls(
      JSON.stringify(copyToInputs),
      "CreateFacilityCopyToProvider"
    ).then((response) => {
      if (response.status == "Success") {
        var copyToProviderData = {
          name: copyToInputs["lastName"] + ", " + copyToInputs["firstName"],
          copyToOptionSelected: copyToInputs["result_copyto_option"],
          facilityCopyToId: response.data,
        };
        var found = orderCopyToProvidersList.find((obj) => {
          return (
            obj.facilityCopyToId == response.data &&
            obj.copyToOptionSelected == copyToInputs["result_copyto_option"]
          );
        });
        if (!found) {
          orderCopyToProvidersList.push(copyToProviderData);
          setOrderCopyToProvidersList(orderCopyToProvidersList);
        }
      } else {
        var copyToProviderData = {
          name: copyToInputs["lastName"] + ", " + copyToInputs["firstName"],
          copyToOptionSelected: copyToInputs["result_copyto_option"],
          facilityCopyToId: commonListId,
        };
        var found = orderCopyToProvidersList.find((obj) => {
          return (
            obj.facilityCopyToId == commonListId &&
            obj.copyToOptionSelected == copyToInputs["result_copyto_option"]
          );
        });
        if (!found) {
          orderCopyToProvidersList.push(copyToProviderData);
          setOrderCopyToProvidersList(orderCopyToProvidersList);
        }
      }
      handleSubmit(orderCopyToProvidersList);
      setShowCopyTo(false);
    });
  }
  function changeHandler(event) {
    var name = event.target.name;
    var value = event.target.value;
    setCopyToInputs({
      ...copyToInputs,
      [name]: value,
    });
  }
  const [copyToInputsValidation, setcopyToInputsValidation] = useState({
    lastName: true,
    firstName: true,
    email: false,
    country: false,
    fax: false,
    street1: false,
    zip: false,
    city: false,
    state: false,
    phone: false,
  });
  useEffect(() => {
    setCopyToInputs({});
    setcopyToInputsValidation({
      lastName: true,
      firstName: true,
      email: false,
      country: false,
      fax: false,
      street1: false,
      zip: false,
      city: false,
      state: false,
      phone: false,
    });
  }, [showCopyTo]);
  return (
    <>
      <Modal show={showCopyTo} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ordering Provider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container id="copyToModal">
            <Row>
              <Col md={{ span: 4 }} className="mandatory">
                <h5 className="label-style">Result CopyTo Option</h5>
                <Form.Select
                  required
                  aria-label="Result copyTo option"
                  name="result_copyto_option"
                  className="input-style form-control"
                  autoComplete="off"
                  value={
                    copyToInputs["result_copyto_option"] != undefined
                      ? copyToInputs["result_copyto_option"]
                      : ""
                  }
                  onChange={(event) => {
                    removeErrorForDivs("copyToModal");
                    var validationFields = {
                      lastName: true,
                      firstName: true,
                      email: false,
                      country: false,
                      fax: false,
                      street1: false,
                      zip: false,
                      city: false,
                      state: false,
                      phone: false,
                    };
                    if (event.target.value == "email") {
                      validationFields["email"] = true;
                    }
                    if (event.target.value == "fax") {
                      validationFields["fax"] = true;
                      validationFields["country"] = true;
                    }
                    if (event.target.value == "paper") {
                      validationFields["street1"] = true;
                      validationFields["country"] = true;
                      validationFields["city"] = true;
                      validationFields["zip"] = true;
                      validationFields["state"] = true;
                    }
                    if (event.target.value == "call") {
                      validationFields["phone"] = true;
                    }
                    setcopyToInputsValidation(validationFields);
                    changeHandler(event);
                  }}
                >
                  <option value="">Select One</option>
                  <option value="email">Email</option>
                  <option value="fax">Fax</option>
                  {/* <option value="paper">Paper</option>
                  <option value="call">Call</option> */}
                </Form.Select>
              </Col>

              <Col md={{ span: 4 }}>
                <h5 className="label-style">Common List</h5>
                <Form.Select
                  name="common_list"
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={(event) => {
                    removeErrorForDivs("copyToModal");
                    ApiGetCalls(
                      "GetCopyToProvidersById/" + event.target.value
                    ).then((response) => {
                      response["common_list"] = event.target.value;
                      response["result_copyto_option"] =
                        copyToInputs["result_copyto_option"];
                      setCopyToInputs(response);
                    });
                  }}
                >
                  <option value="">Select One</option>
                  {copyToLocationPersons?.map((value, Index) => (
                    <option key={Index} value={value.id}>
                      {value.firstName}, {value.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={{ span: 4 }} className="mandatory">
                <h5 className="label-style">Last Name</h5>
                <input
                  required
                  name="lastName"
                  aria-label="Last name"
                  maxLength={allInputField}
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={
                    copyToInputs["lastName"] != undefined
                      ? copyToInputs["lastName"]
                      : ""
                  }
                />
              </Col>
              <Col md={{ span: 4 }} className="mandatory">
                <h5 className="label-style">First Name</h5>
                <input
                  required
                  name="firstName"
                  maxLength={allInputField}
                  aria-label="First name"
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={
                    copyToInputs["firstName"] != undefined
                      ? copyToInputs["firstName"]
                      : ""
                  }
                />
              </Col>
              <Col md={{ span: 4 }}>
                <h5 className="label-style">UPIN/NPI</h5>
                <input
                  name="upinNpi"
                  maxLength={allInputField}
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={
                    copyToInputs["upinNpi"] != undefined
                      ? copyToInputs["upinNpi"]
                      : ""
                  }
                />
              </Col>

              <Col md={{ span: 4 }}>
                <h5 className="label-style">Code</h5>
                <input
                  name="code"
                  maxLength={allInputField}
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={
                    copyToInputs["code"] != undefined
                      ? copyToInputs["code"]
                      : ""
                  }
                />
              </Col>
              {copyToInputsValidation["country"] && (
                <Col
                  md={{ span: 4 }}
                  className={
                    copyToInputsValidation["country"] ? "mandatory" : ""
                  }
                >
                  <h5 className="label-style">Country</h5>
                  <Form.Select
                    required={copyToInputsValidation["country"]}
                    name="country"
                    aria-label="Country"
                    className="input-style form-control"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["country"] != undefined
                        ? copyToInputs["country"]
                        : ""
                    }
                  >
                    <option value=""></option>
                    <option value="united states">United States</option>
                    <option value="canada">Canada</option>
                  </Form.Select>
                </Col>
              )}

              {copyToInputsValidation["street1"] && (
                <Col
                  md={{ span: 4 }}
                  className={
                    copyToInputsValidation["street1"] ? "mandatory" : ""
                  }
                >
                  <h5 className="label-style">Street 1</h5>
                  <input
                    required={copyToInputsValidation["street1"]}
                    name="street1"
                    maxLength={allInputField}
                    aria-label="Street 1"
                    className="input-style form-control"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["street1"] != undefined
                        ? copyToInputs["street1"]
                        : ""
                    }
                  />
                </Col>
              )}
              {copyToInputsValidation["street1"] && (
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Street 2</h5>
                  <input
                    name="street2"
                    maxLength={allInputField}
                    className="input-style form-control"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["street2"] != undefined
                        ? copyToInputs["street2"]
                        : ""
                    }
                  />
                </Col>
              )}

              {copyToInputsValidation["zip"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["zip"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">Zip</h5>
                  <InputMask
                    mask={zipMask}
                    maskChar=" "
                    required={copyToInputsValidation["zip"]}
                    name="zip"
                    aria-label="Zip"
                    className="input-style form-control validate_zip"
                    autoComplete="off"
                    onChange={changeHandler}
                    onKeyDown={(event) => {
                      if (
                        (event.keyCode >= 48 && event.keyCode <= 57) ||
                        (event.keyCode >= 96 && event.keyCode <= 105)
                      ) {
                        let reGexForZip = /(^\d{5})/;
                        if (reGexForZip.test(event.target.value)) {
                          setzipMask("99999-9999");
                        } else {
                          setzipMask("99999");
                        }
                      }
                    }}
                    onBlur={(event) => {
                      setFetchingAddress(true);
                      getAddressByZipCode(event.target.value);
                    }}
                    value={
                      copyToInputs["zip"] != undefined
                        ? copyToInputs["zip"]
                        : ""
                    }
                  />
                </Col>
              )}

              {copyToInputsValidation["city"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["city"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">City</h5>
                  <input
                    required={copyToInputsValidation["city"]}
                    name="city"
                    aria-label="City"
                    maxLength={allInputField}
                    className="input-style form-control"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["city"] != undefined
                        ? copyToInputs["city"]
                        : ""
                    }
                  />
                  {fetchingAddress ? (
                    <Spinner
                      className="spinner_field"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                </Col>
              )}

              {copyToInputsValidation["state"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["state"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">State</h5>
                  <input
                    required={copyToInputsValidation["state"]}
                    name="state"
                    aria-label="State"
                    maxLength={allInputField}
                    className="input-style form-control"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["state"] != undefined
                        ? copyToInputs["state"]
                        : ""
                    }
                  />
                  {fetchingAddress ? (
                    <Spinner
                      className="spinner_field"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                </Col>
              )}

              {copyToInputsValidation["phone"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["phone"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">Phone</h5>
                  <InputMask
                    mask="+1\ (999) 999-9999"
                    maskChar=" "
                    required={copyToInputsValidation["phone"]}
                    name="phone"
                    aria-label="Phone"
                    className="input-style form-control validate_mobile"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["phone"] != undefined
                        ? copyToInputs["phone"]
                        : ""
                    }
                  />
                </Col>
              )}
              {copyToInputsValidation["fax"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["fax"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">Fax</h5>
                  <InputMask
                    mask="+1\ (999) 999-9999"
                    maskChar=" "
                    required={copyToInputsValidation["fax"]}
                    aria-label="Fax"
                    name="fax"
                    className="input-style form-control validate_mobile"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["fax"] != undefined
                        ? copyToInputs["fax"]
                        : ""
                    }
                  />
                </Col>
              )}
              {copyToInputsValidation["email"] && (
                <Col
                  md={{ span: 4 }}
                  className={copyToInputsValidation["email"] ? "mandatory" : ""}
                >
                  <h5 className="label-style">Email</h5>
                  <input
                    required={copyToInputsValidation["email"]}
                    name="email"
                    aria-label="Email"
                    maxLength={email}
                    className="input-style form-control validate_email"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={
                      copyToInputs["email"] != undefined
                        ? copyToInputs["email"]
                        : ""
                    }
                  />
                </Col>
              )}
              {/* <Col md={{ span: 4 }}>
                <h5 className="label-style">Comment</h5>
                <input
                  name="copyToComments"
                  className="input-style form-control"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={
                    copyToInputs["copyToComments"] != undefined
                      ? copyToInputs["copyToComments"]
                      : ""
                  }
                />
              </Col> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="primary button"
            variant="primary"
            // disabled={advancedSearchSelectedObjId != "" ? false : true}
            onClick={() => {
              if (handleValidation("copyToModal")) {
                saveCopyToData();
              }
            }}
          >
            Save To Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CopyToModel;
