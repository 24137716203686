import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import PatientTab from "./Components/PatientTab";
import ClinicalTab from "./Components/ClinicalTab";
import DiagnosisTab from "./Components/DiagnosisTab";
import OrderInformationTab from "./Components/OrderInformationTab";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents//mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import "./OrderPage.css";
import { ApiGetCalls } from "../../services/Api";
import { useParams } from "react-router-dom";
import { LoginInfoContext, LoginUserLocationContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { snackBar } from "../../services/Alert";
import moment from "moment";
import { useRef } from "react";
import { standingOrderIdConfig } from "../../config/config";

function OrderPage(props) {
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );

  const [showResults, setShowResults] = useState(false);
  const [highLightTests, setHighLightTests] = useState([]);
  const [medicalNecessityResponse, setMedicalNecessityResponse] = useState([]);
  const [sexLookup, setSexLookup] = useState({});
  const [patientInUpdateMode, setPatientInUpdateMode] = useState(false);
  // const [savedOrderId, setSavedOrderId] = useState("");
  const [lookOrderInfo, setLookOrderInfo] = useState({
    billType: [],
    orderingType: [],
  });
  const [tmpOrderNumber, setTmpOrderNumber] = useState("");
  const [patientDetails, setPatientDetails] = useState({
    patientId: "0",
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  var { id } = useParams();
  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      // fetchlocationBasedDataOnLoad();

      if (id != undefined && props.createPatient != "Y") {
        getOrderdata(id);
      } else {
        if (localStorage.getItem("userPermissions") != null) {
          JSON.parse(localStorage.getItem("userPermissions")).map(
            (value, index) => {
              if (value.facilityId == selectedLocation) {
                if (value.featureName == "Orders") {
                  if (value.createFeature == "N") {
                    snackBar(
                      "Error! You are not authorize to create new order",
                      "danger"
                    );
                    navigate("/patients");
                  }
                }
              }
            }
          );
        }
        if (props.createPatient != "Y")
          ApiGetCalls("GetAutoIncrementalByFieldName/order").then(
            (orderNumber) => {
              setTmpOrderNumber(orderNumber);
            }
          );
        handleClinicalFormChange("orderType", "38");
      }
    }
  }, []);
  useEffect(() => {
    document.title =
      props.createPatient != "Y"
        ? "ATVIVO - New Order"
        : "ATVIVO - New Patient";
    if (selectedLocation != "") {
      // fetchlocationBasedDataOnLoad();
    }

    if (
      id != undefined &&
      props.savedOrderId != 0 &&
      props.createPatient != "Y"
    ) {
      getOrderdata(id);
      props.setSavedOrderId("");
    } else {
      setOrderData({});
      setClinicalInputs({});
      setSelectedDataArray({
        formValues:
          Object.keys(orderData).length > 0 ? orderData.aoeAnswers : [],
      });

      if (props.savedOrderId < 0) {
        setPatientDetails({
          patientId: "0",
          patientName: "-",
          patientDob: "-",
          patientInsurance: "-",
          patientOfficeId: "-",
          patientSex: "-",
        });
        props.setSelectedOrderPatientId("");
        // setPatientObj("");
        if (localStorage.getItem("userPermissions") != null) {
          JSON.parse(localStorage.getItem("userPermissions")).map(
            (value, index) => {
              if (value.facilityId == selectedLocation) {
                if (value.featureName == "Orders") {
                  if (value.createFeature == "N") {
                    snackBar(
                      "Error! You are not authorize to create new order",
                      "danger"
                    );
                    navigate("/patients");
                  }
                }
              }
            }
          );
        }
        if (props.createPatient != "Y")
          ApiGetCalls("GetAutoIncrementalByFieldName/order").then(
            (orderNumber) => {
              setTmpOrderNumber(orderNumber);
            }
          );
      }
      handleClinicalFormChange("orderType", "38");
    }
  }, [props.savedOrderId, props.createPatient]);

  const [clinicalInputs, setClinicalInputs] = useState({});
  const [orderData, setOrderData] = useState({});
  const [selectedDataArray, setSelectedDataArray] = useState({
    formValues: Object.keys(orderData).length > 0 ? orderData.aoeAnswers : [],
  });
  const [favIconHandler, setFavIconHandler] = useState(true);
  const [providersApiData, setProvidersApiData] = useState([]);
  const [orderLevelQuestionIdsAndTests, setOrderLevelQuestionIdsAndTests] =
    useState([]);
  const [locationBasedData, setLocationBasedData] = useState({
    favorites: [],
    providers: [],
    locationId: "",
  });
  const [standingOrderDates, setStandingOrderDates] = useState([]);
  const [standingOrderSchedule, setStandingOrderSchedule] = useState({
    type: "weekly",
    startDate: new Date(moment().format("YYYY-MM-DD HH:mm:ss")),
    endDate: new Date(moment().add(5, "months").format("YYYY-MM-DD HH:mm:ss")),
    days: [],
    week: 0,
    orderDates: [],
  });
  var [savedOrderDates, setSavedOrderDates] = useState([]);

  // const [selectedTestIds, setSelectedTestIds] = useState({});

  const handleClinicalFormChange = (name, value) => {
    setClinicalInputs((values) => ({ ...values, [name]: value }));
  };
  async function fetchlocationBasedDataOnLoad() {
    try {
      const [favorites, providers, locationId] = await Promise.all([
        await ApiGetCalls("GetFavoritesByFacility/" + selectedLocation),
        await ApiGetCalls("GetProvidersByFacility/" + selectedLocation),
        //await ApiGetCalls("GetLocationByLocationNumber/" + selectedLocation),
      ]);
      setLocationBasedData({
        favorites: favorites,
        providers: providers.data,
        locationId: selectedLocation,
      });
      setProvidersApiData(providers.data);
    } catch (error) {}
  }
  async function getOrderdata(id) {
    try {
      var urlArray = url.split("/");
      if (urlArray[1] == "order") {
        //const [orderData] = await Promise.all([
        await ApiGetCalls(
          "GetOrderById/" + id + "/" + localStorage.getItem("selectedLocation")
        ).then((data) => {
          if (localStorage.getItem("userPermissions") != null) {
            JSON.parse(localStorage.getItem("userPermissions")).map(
              (value, index) => {
                if (value.facilityId == selectedLocation) {
                  if (value.featureName == "Orders") {
                    if (
                      value.createFeature == "N" &&
                      value.updateFeature == "N"
                    ) {
                      if (value.viewFeature == "Y") {
                        data.status = "Sent";
                      }
                    }
                  }
                }
              }
            );
          }
          if (data.sentOn == null && data.status == "Submitted") {
            data.status = "Draft";
          }
          if (data.status == "Printed") {
            data.status = "Sent";
          }
          if (data.status == "Sent") {
            navigate("/orders/" + data.patientId);
          }
          setOrderData(data);
          setSelectedDataArray({
            formValues: Object.keys(data).length > 0 ? data.aoeAnswers : [],
          });
          setTmpOrderNumber(data.orderNumber);
          setClinicalInputs(data);
          props.setSelectedOrderPatientId(data.patientId);
        });
      } else {
        await ApiGetCalls(
          "GetOrderTemplateById/" +
            id +
            "/" +
            localStorage.getItem("selectedLocation")
        ).then((data) => {
          if (localStorage.getItem("userPermissions") != null) {
            JSON.parse(localStorage.getItem("userPermissions")).map(
              (value, index) => {
                if (value.facilityId == selectedLocation) {
                  if (value.featureName == "Orders") {
                    if (
                      value.createFeature == "N" &&
                      value.updateFeature == "N"
                    ) {
                      if (value.viewFeature == "Y") {
                        data.orders.status = "Sent";
                      }
                    }
                  }
                }
              }
            );
          }
          // if (data.orders.sentOn == null && data.orders.status == "Sent") {
          //   data.orders.status = "Draft";
          // }
          data.orders.status = "Draft";
          data.orders.orderType = standingOrderIdConfig;
          setOrderData(data.orders);
          setTmpOrderNumber(data.orders.orderNumber);
          setClinicalInputs(data.orders);
          data.schedule.startDate = new Date(data.schedule.startDate);
          data.schedule.endDate = new Date(data.schedule.endDate);
          setStandingOrderSchedule(data.schedule);
          setStandingOrderDates(data.schedule.orderDates);
          setSavedOrderDates(data.schedule.orderDates);
          props.setSelectedOrderPatientId(data.orders.patientId);
        });
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (selectedLocation != "" && props.createPatient != "Y") {
      fetchlocationBasedDataOnLoad();
    }
  }, [selectedLocation, favIconHandler, props.createPatient]);
  const [listOptionsDiagnosis, setListOptionsDiagnosis] = useState({
    options: [],
  });
  const [selectedDiagnosesId, setSelectedDiagnosesId] = useState([]);
  return (
    <div onClick={() => setShowResults(false)}>
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
        showNavBar={true}
        savedOrderId={props.savedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
        showNavBar={true}
        savedOrderId={props.savedOrderId}
      />
      <div className="pageheight">
        <Container className="container">
          <PatientTab
            showResults={showResults}
            setShowResults={setShowResults}
            setPatientDetails={setPatientDetails}
            patientDetails={patientDetails}
            setSexLookup={setSexLookup}
            sexLookup={sexLookup}
            handleClinicalFormChange={handleClinicalFormChange}
            orderData={orderData}
            selectedOrderPatientId={props.selectedOrderPatientId}
            setPatientInUpdateMode={setPatientInUpdateMode}
            orderId={props.createPatient != "Y" ? id : undefined}
            patientId={props.createPatient != "Y" ? undefined : id}
            createPatient={props.createPatient}
            facilityId={selectedLocation}
            savedOrderId={props.savedOrderId}
          />

          {props.createPatient != "Y" && (
            <ClinicalTab
              locationBasedData={locationBasedData.favorites}
              handleClinicalFormChange={handleClinicalFormChange}
              orderData={orderData}
              userLocationId={selectedLocation}
              orderIdAsParam={id}
              savedOrderId={props.savedOrderId}
              favIconHandler={favIconHandler}
              setFavIconHandler={setFavIconHandler}
              setHighLightTests={setHighLightTests}
              highLightTests={highLightTests}
              medicalNecessityResponse={medicalNecessityResponse}
              listOptionsDiagnosis={listOptionsDiagnosis}
              setListOptionsDiagnosis={setListOptionsDiagnosis}
              selectedDiagnosesId={selectedDiagnosesId}
              setSelectedDiagnosesId={setSelectedDiagnosesId}
              setLocationBasedData={setLocationBasedData}
              clinicalInputs={clinicalInputs}
              setOrderLevelQuestionIdsAndTests={
                setOrderLevelQuestionIdsAndTests
              }
              orderLevelQuestionIdsAndTests={orderLevelQuestionIdsAndTests}
              selectedDataArray={selectedDataArray}
              setSelectedDataArray={setSelectedDataArray}
              patientId={patientDetails.patientId}
            />
          )}

          {props.createPatient != "Y" && (
            <DiagnosisTab
              locationBasedData={locationBasedData.favorites}
              handleClinicalFormChange={handleClinicalFormChange}
              orderData={orderData}
              userLocationId={locationBasedData.locationId}
              orderIdAsParam={id}
              savedOrderId={props.savedOrderId}
              favIconHandler={favIconHandler}
              setFavIconHandler={setFavIconHandler}
              listOptionsDiagnosis={listOptionsDiagnosis}
              setListOptionsDiagnosis={setListOptionsDiagnosis}
              selectedDiagnosesId={selectedDiagnosesId}
              setSelectedDiagnosesId={setSelectedDiagnosesId}
            />
          )}

          {props.createPatient != "Y" && (
            <OrderInformationTab
              locationBasedData={providersApiData}
              handleClinicalFormChange={handleClinicalFormChange}
              orderData={orderData}
              setLookOrderInfo={setLookOrderInfo}
              lookOrderInfo={lookOrderInfo}
              clinicalInputs={clinicalInputs}
              userLocationId={locationBasedData.locationId}
              standingOrderSchedule={standingOrderSchedule}
              setStandingOrderSchedule={setStandingOrderSchedule}
              setStandingOrderDates={setStandingOrderDates}
              standingOrderDates={standingOrderDates}
              savedOrderDates={savedOrderDates}
              setSavedOrderDates={setSavedOrderDates}
            />
          )}
        </Container>
      </div>
      {props.createPatient != "Y" && (
        <CustomFooter
          showOrderFooterBar={true}
          clinicalInputs={clinicalInputs}
          tmpOrderNumber={tmpOrderNumber}
          facilityId={selectedLocation}
          orderData={orderData}
          setLookOrderInfo={setLookOrderInfo}
          lookOrderInfo={lookOrderInfo}
          patientInUpdateMode={patientInUpdateMode}
          setSavedOrderId={props.setSavedOrderId}
          selectedOrderPatientId={props.selectedOrderPatientId}
          setHighLightTests={setHighLightTests}
          highLightTests={highLightTests}
          medicalNecessityResponse={medicalNecessityResponse}
          setMedicalNecessityResponse={setMedicalNecessityResponse}
          patientDetails={patientDetails}
          standingOrderSchedule={standingOrderSchedule}
          standingOrderDates={standingOrderDates}
        />
      )}
    </div>
  );
}
export default OrderPage;
