import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { parseJSON } from "jquery";

function FormRadio(props) {
  const [selectedCheckBoxArray, setSelectedCheckBoxArray] = useState([]);
  const checkBoxesArray = [];
  var radioValue = "";
  if (props.answerType == "CHECKBOX") {
    var array = props.orderData?.aoeAnswers?.find((item, index) =>
      item.questionId == props.questionId.id ? item.answerId : 0
    );
  } else {
    radioValue = props.orderData?.aoeAnswers?.find((item, index) =>
      item.questionId == props.questionId.id ? item.answerId : 0
    );
  }
  useEffect(() => {
    if (props.answerType == "CHECKBOX" && props.defaultValue != undefined) {
      var parseData = JSON.parse(props.defaultValue?.answerValue);
      parseData.map((value, index) => {
        props.radioValues?.map((item, index) => {
          return item.id == Number(value) && item.isActive == "Yes"
            ? checkBoxesArray.push(value)
            : null;
        });
      });
      setSelectedCheckBoxArray(checkBoxesArray);
      if (props.defaultValue != undefined) {
        props.testId.map((tId) => {
          props.onChange(
            props.answerType == "CHECKBOX"
              ? props.orderId != ""
                ? {
                    orderId: props.orderId,
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: JSON.stringify(checkBoxesArray),
                  }
                : {
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: JSON.stringify(checkBoxesArray),
                  }
              : props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultCheckedValue * 1,
                  answerValue: null,
                }
              : {
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultCheckedValue * 1,
                  answerValue: null,
                }
          );
        });
      }
    }
  }, [props.defaultValue]);
  useEffect(() => {
    if (!props.orderData?.aoeAnswers) {
      if (props.defaultCheckedValue != null) {
        props.testId.map((tId) => {
          setSelectedCheckBoxArray(
            selectedCheckBoxArray.concat(props.defaultCheckedValue)
          );

          props.onChange(
            props.answerType == "CHECKBOX"
              ? props.orderId != ""
                ? {
                    orderId: props.orderId,
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: JSON.stringify([props.defaultCheckedValue]),
                  }
                : {
                    testId: tId,
                    questionId: props.questionId,
                    answerType: props.answerType,
                    answerId: null,
                    answerValue: JSON.stringify([props.defaultCheckedValue]),
                  }
              : props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultCheckedValue * 1,
                  answerValue: null,
                }
              : {
                  testId: tId,
                  questionId: props.questionId,
                  answerType: props.answerType,
                  answerId: props.defaultCheckedValue * 1,
                  answerValue: null,
                }
          );
        });
      }
    }
  }, []);
  return (
    // <Form.Group className="mb-3" controlId="formBasicEmail">
    //   <Form.Label>{props.formValue}</Form.Label>
    //   <Form.Select aria-label="Default select example">
    //     <option>Open this select menu</option>
    //     {props.optionValues?.map((ansValue, ansIndex) => (
    //       <option value={ansValue.Id}>{ansValue.Answer}</option>
    //     ))}
    //   </Form.Select>
    // </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label className={props.className + "Label"}>
        {props.formValue}
      </Form.Label> */}

      <h5 className={props.className + "Label label-style"}>
        {props.formValue}
      </h5>
      {props.radioValues?.map(
        (ansValue, ansIndex) => (
          console.log(
            props.radioValues.some(
              (item) => item.id == props.defaultCheckedValue
            )
          ),
          (
            <Form.Check
              disabled={props.disabled}
              key={ansIndex}
              required={props.required}
              className={props.className}
              name={props.name}
              type={props.type}
              label={ansValue.answer}
              id={ansValue.id}
              defaultChecked={
                props.answerType == "CHECKBOX"
                  ? props.defaultValue?.answerValue != undefined
                    ? JSON.parse(props.defaultValue?.answerValue).some(
                        (value) => value == ansValue.id
                      )
                    : ansValue.id == props.defaultCheckedValue
                  : props.defaultValue?.answerId != undefined
                  ? ansValue.id == props.defaultValue?.answerId
                  : ansValue.id == props.defaultCheckedValue
              }
              value={ansValue.id}
              onChange={(e) => {
                props.testId.map((tId) => {
                  if (e.target.checked) {
                    // if (
                    //   props.answerType == "CHECKBOX" &&
                    //   props.defaultValue != undefined
                    // ) {
                    // //   var parseData = JSON.parse(props.defaultValue?.answerValue);
                    // //   parseData.map((value, index) => {
                    // //     checkBoxesArray.push(value);
                    // //   });
                    // }
                    setSelectedCheckBoxArray(
                      selectedCheckBoxArray.concat(e.target.value)
                    );

                    props.onChange(
                      props.answerType == "CHECKBOX"
                        ? props.orderId != ""
                          ? {
                              orderId: props.orderId,
                              testId: tId,
                              questionId: props.questionId,
                              answerType: props.answerType,
                              answerId: null,
                              answerValue: JSON.stringify(
                                selectedCheckBoxArray.concat(e.target.value)
                              ),
                            }
                          : {
                              testId: tId,
                              questionId: props.questionId,
                              answerType: props.answerType,
                              answerId: null,
                              answerValue: JSON.stringify(
                                selectedCheckBoxArray.concat(e.target.value)
                              ),
                            }
                        : props.orderId != ""
                        ? {
                            orderId: props.orderId,
                            testId: tId,
                            questionId: props.questionId,
                            answerType: props.answerType,
                            answerId: e.target.value * 1,
                            answerValue: null,
                          }
                        : {
                            testId: tId,
                            questionId: props.questionId,
                            answerType: props.answerType,
                            answerId: e.target.id * 1,
                            answerValue: null,
                          }
                    );
                  } else {
                    setSelectedCheckBoxArray(
                      selectedCheckBoxArray.filter((x) => e.target.value != x)
                    );

                    if (props.answerType == "CHECKBOX") {
                      props.onChange(
                        props.orderId != ""
                          ? {
                              orderId: props.orderId,
                              testId: tId,
                              questionId: props.questionId,
                              answerType: props.answerType,
                              answerId: null,
                              answerValue: JSON.stringify(
                                selectedCheckBoxArray.filter(
                                  (x) => e.target.value != x
                                )
                              ),
                            }
                          : {
                              testId: tId,
                              questionId: props.questionId,
                              answerType: props.answerType,
                              answerId: null,
                              answerValue: JSON.stringify(
                                selectedCheckBoxArray.filter(
                                  (x) => e.target.value != x
                                )
                              ),
                            }
                      );
                    }
                  }
                });
              }}
            />
          )
        )
      )}
    </Form.Group>
  );
}

export default FormRadio;
