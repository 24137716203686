import React, { useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import StarIcon from "./StarIcon";
import { FaMinus, FaInfoCircle, FaTrash, FaPlus } from "react-icons/fa";
import { propTypes } from "react-bootstrap/esm/Image";
import { useState } from "react";
import InputWithLoader from "../SearchInputFieldWithLoader";
import SelectBody from "../SelectBody";
import WarningModel from "../../../../GlobalComponents/WarningModel";
import { BsInfoCircle, BsTrash } from "react-icons/bs";
import { MdOutlineGroupAdd } from "react-icons/md";
import { BiAddToQueue } from "react-icons/bi";
import { ApiPostCalls } from "../../../../services/Api";
import CustomLoader from "../../../../GlobalComponents/Loader";

function SelectedTestAndDiagnosisList({
  heading,
  favouritesData,
  setFavouritesData,
  setListOptions,
  listOptions,
  setModalShow,
  setId,
  locationId,
  infoDetailState,
  infoPopupDetailState,
  setInfoModalShow,
  setInfoPopupIndexState,
  setShowComponent,
  orderStatus,
  setTestHeading,
  favIconHandler,
  setFavIconHandler,
  setHighLightTests,
  highLightTests,
  medicalNecessityResponse,
  orderId,
  diagnosislistOptions,
  diagnosisSetlistOptions,
  selectedDiagnosesIdProp,
  setSelectedDiagnosesIdProp,
  setSelectedTestsIdProp,
  selectedTestsIdProp,
  setShowTestGroupModel,
  clinicalInputs,
}) {
  var favObj;
  const updateInfoModalData = (idx) => {
    setShowComponent("list");
    setInfoModalShow(true);
    setInfoPopupIndexState(idx.index);
  };
  const [showLoader, setLoader] = useState(false);
  const [showMedicalNecessityModal, setShowMedicalNecessityModal] =
    useState(false);
  const [showMedicalNecessityModalInTest, setShowMedicalNecessityModalInTest] =
    useState("");
  const [searchTextFromModal, setSearchTextFromModal] = useState("");
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedDiagnosesId, setSelectedDiagnosesId] = useState([]);
  const [listOptionsModal, setListOptionsModal] = useState({ options: [] });
  const [modalShowRecommeded, setModalShowRecommeded] = useState(false);
  const [showOnlyRecommended, setShowOnlyRecommended] = useState(true);
  const [testIdRecommeded, setTestIdRecommeded] = useState("");
  const [RecommendedDiagonsisList, setRecommendedDiagonsisList] = useState({
    options: [],
  });
  const diagnosisDataGrid = [];
  const diagnosisDataGridModelOnly = [];
  var recommendedListTmp = [];
  var cptCodes = "";
  // const favouritesData = [];
  const favouritesArray = [];
  if (showOnlyRecommended) {
    if (diagnosisData.data != undefined) {
      for (let i = 0; i < diagnosisData.data.length; i++) {
        const diagnosisDataObj = [
          diagnosisData.data[i].id.toString(),
          diagnosisData.data[i].diagnosisCode,
          diagnosisData.data[i].diagnosisName,
        ];
        diagnosisDataGrid.push(diagnosisDataObj);
      }
    }
  } else {
    for (let i = 0; i < diagnosisData.length; i++) {
      const diagnosisDataObj = [
        diagnosisData[i].id.toString(),
        diagnosisData[i].diagnosisCode,
        diagnosisData[i].diagnosisName,
      ];
      diagnosisDataGrid.push(diagnosisDataObj);
    }
  }

  const onConfirmationYes = () => {
    setModalShowRecommeded(false);
    handleDeleteFromPanal(testIdRecommeded);
  };
  function handleDeleteFromPanal(testId) {
    var index;
    listOptionsModal.options?.some(function (x, i) {
      if (x.id === testId) return (index = i);
    });
    setSelectedDiagnosesId(
      selectedDiagnosesId.filter((value) => testId != value.diagnosesId)
      // }
    );
    setListOptionsModal({
      options: listOptionsModal.options?.filter((value) => testId != value.id),
    });
  }
  const rowClickHandler = (selectedObj, id) => {
    setShowResults(false);
    setSearchTextFromModal("");
    if (!listOptionsModal.options?.some((item) => id == item.id)) {
      setListOptionsModal(
        // listOptions.options?.concat({ id: id, testName: selectedObj[1] })
        {
          options: listOptionsModal.options?.concat({
            id: id,
            testName: selectedObj[2],
            testCode: selectedObj[1],
          }),
        }
      );
      setSelectedDiagnosesId(
        orderId != ""
          ? selectedDiagnosesId.concat({
              orderId: orderId,
              diagnosesId: id * 1,
            })
          : selectedDiagnosesId.concat({
              diagnosesId: id * 1,
            })
      );
      // props.propsOnChange(id);
    }
    // props.propsOnChange(id);
  };
  const addTestFromFav = (obj) => {
    if (!listOptionsModal.options?.some((item) => obj.id == item.id)) {
      setListOptionsModal({ options: listOptionsModal.options?.concat(obj) });
      setSelectedDiagnosesId(
        orderId != ""
          ? selectedDiagnosesId.concat({
              orderId: orderId,
              diagnosesId: obj.id * 1,
            })
          : selectedDiagnosesId.concat({
              diagnosesId: obj.id * 1,
            })
        // }
      );
    }
  };
  const setSelectedDiagnosisListToJsonObject = () => {
    var selectedDiagnosisIdsList = selectedDiagnosesIdProp;
    selectedDiagnosesId.map((value) => {
      if (
        !selectedDiagnosesIdProp?.some(
          (item) => item.diagnosesId == value.diagnosesId
        )
      ) {
        selectedDiagnosisIdsList.push(value);
      }
    });
    setSelectedDiagnosesIdProp(selectedDiagnosisIdsList);
  };
  function setRecommendedDiagonsis() {
    var array = diagnosislistOptions.options;
    listOptionsModal["options"].map((value, index) => {
      if (!array?.some((item) => value.id == item.id)) {
        array?.push({
          id: value.id,
          testName: value.testName,
          testCode: value.testCode,
        });

        // props.propsOnChange(id);
      }
    });
    diagnosisSetlistOptions(
      // listOptions.options?.concat({ id: id, testName: selectedObj[1] })
      {
        options: array,
      }
    );
  }
  return (
    <>
      {showLoader && <CustomLoader />}
      <Row key={heading}>
        <Col key={heading + "h"} md="12">
          <h5>{heading}</h5>
        </Col>
        {listOptions["options"]?.map(
          (value, index) => (
            (favObj = favouritesData.find((x) => {
              if (x.id == value.id) {
                if (x.favId > 0) {
                  return x.favId;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            })),
            (
              <Col
                key={heading + "t" + index}
                md="4"
                className="mb-3"
                onClick={() => {
                  if (highLightTests.indexOf(value.testCode) > -1) {
                    setShowMedicalNecessityModalInTest(
                      value.id + "_" + value.testCode + ": " + value.testName
                    );
                    // setRecommendedDiagonsisList({ options: [] });
                    setListOptionsModal({ options: [] });
                    setShowMedicalNecessityModal(true);
                    setSelectedDiagnosesId([]);
                    setShowResults(false);
                    setSearchTextFromModal("");
                    recommendedListTmp = [];
                    cptCodes = "";
                    setShowOnlyRecommended(true);
                  }
                }}
              >
                <div
                  title={
                    highLightTests != undefined
                      ? highLightTests.indexOf(value.testCode) > -1
                        ? "Click to view options"
                        : ""
                      : ""
                  }
                  className={
                    heading == "Selected Tests" &&
                    highLightTests.indexOf(value.testCode) > -1
                      ? "highLight pills cursorPointer"
                      : "pills"
                  }
                >
                  <span>{value.testCode + ": " + value.testName}</span>
                  {(orderStatus == "Draft" || orderStatus == undefined) && (
                    <BsTrash
                      title="Remove from selection"
                      key={heading + "ft" + index}
                      className="trashcanIcon ml-1"
                      onClick={(event) => {
                        event.stopPropagation();
                        setModalShow(true);
                        setId(value.id);
                      }}
                    />
                  )}
                  {(orderStatus == "Draft" || orderStatus == undefined) && (
                    <StarIcon
                      key={heading + "st" + index}
                      showIcon={favouritesData.find((x) => {
                        if (x.id == value.id) {
                          if (x.fav == "Y") {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      })}
                      className="favIcon"
                      favouritesData={favouritesData}
                      setFavouritesData={setFavouritesData}
                      testObj={{
                        id: value.id,
                        locationId: locationId,
                        testId:
                          heading == "Selected Tests" ? value.id * 1 : null,
                        diagnosisId:
                          heading == "Selected Tests" ? null : value.id * 1,
                        favId: favObj !== undefined ? favObj.favId : 0,
                        testName: value.testName,
                        testCode: value.testCode,
                        fav: value.fav,
                      }}
                      setListOptions={setListOptions}
                      listOptions={listOptions}
                      favIconHandler={favIconHandler}
                      setFavIconHandler={setFavIconHandler}
                    />
                  )}
                  {heading == "Selected Tests" ? (
                    <BsInfoCircle
                      key={heading + "i" + index}
                      // title={infoDetailState[index]}
                      title={"More info"}
                      color={"#000"}
                      className="iconInfo"
                      onClick={(event) => {
                        event.stopPropagation();
                        updateInfoModalData({
                          index,
                        });
                        setTestHeading(value.testCode + ": " + value.testName);
                      }}
                    />
                  ) : null}
                </div>
              </Col>
            )
          )
        )}
        <Modal
          show={showMedicalNecessityModal}
          onHide={() => {
            setShowMedicalNecessityModal(false);
            listOptionsModal.options?.map((value) => {
              handleDeleteFromPanal(value.id);
            });
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Diagnosis Codes
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="suggestedDiagnosisModal">
            <Row>
              {showMedicalNecessityModal == true &&
                medicalNecessityResponse[0].map((data, index) => {
                  if (
                    data.testCode ==
                    showMedicalNecessityModalInTest.split("_")[1].split(":")[0]
                  ) {
                    data.testsWithStatus.map((cpt, ind) => {
                      cptCodes += cpt.cptCode + ",";
                    });
                  }
                })}
            </Row>
            <Row key="All Diagnosis" className="mt-4">
              <Col key={"All Diagnosis Codes" + "h"} md="12">
                <Row>
                  <Col md={{ span: 8, offset: 1 }}>
                    <h6>{"Diagnosis Code Search"}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8, offset: 1 }}>
                    {" "}
                    {clinicalInputs != undefined ? (
                      <InputWithLoader
                        id="DignosisSelect"
                        filter={searchTextFromModal}
                        placeholder="Start typing..."
                        apiEndPoint={
                          showOnlyRecommended
                            ? "GetDiagnosisByCptcode/" +
                              cptCodes +
                              "/" +
                              clinicalInputs.patientId +
                              "/"
                            : "GetSearchedDiagnosis/"
                        }
                        setData={setDiagnosisData}
                        setShowResults={setShowResults}
                        setFilter={setSearchTextFromModal}
                        name="diagnosis"
                        aria_label="Diagnosis Name"
                        className="input-style form-control mt-1"
                        required={true}
                        data_type="text"
                      />
                    ) : null}
                    {showResults ? (
                      <SelectBody
                        rowClickHandler={rowClickHandler}
                        filter={searchTextFromModal}
                        column={["Code", "Name"]}
                        // dataArray={
                        //   showOnlyRecommended
                        //     ? diagnosisDataGridModelOnly
                        //     : diagnosisDataGrid
                        // }
                        dataArray={diagnosisDataGrid}
                        setShowResults={setShowResults}
                      />
                    ) : null}
                  </Col>
                  <Col md={{ span: 2 }}>
                    <div className="mt-1">
                      <Form.Check
                        title="Show all ICD codes"
                        type="checkbox"
                        label=" Search all"
                        className="pt-1"
                        onClick={(e) => {
                          if (e.target.checked == false) {
                            setShowOnlyRecommended(true);
                          } else {
                            setShowOnlyRecommended(false);
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8, offset: 1 }}>
                    <p>
                      * To search complete ICD code dictionary please check
                      "Search all" box
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            {listOptionsModal["options"]?.length > 0 ? (
              <Row key={"Selected Diagnosis"}>
                <Col key={"Selected Diagnosis" + "h"} md="12">
                  <h5>{"Selected Diagnosis Codes"}</h5>
                </Col>
                {listOptionsModal["options"]?.map((value, index) => (
                  <Col
                    key={"Selected Diagnosis" + "t" + index}
                    md="4"
                    className="mb-3"
                    onClick={() => {}}
                  >
                    <div className={"pills"}>
                      <span>{value.testCode + ": " + value.testName}</span>

                      <BsTrash
                        key={"Selected Diagnosis" + "ft" + index}
                        className="trashcanIcon ml-1"
                        onClick={() => {
                          setModalShowRecommeded(true);
                          setTestIdRecommeded(value.id);
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="primary btn button"
              onClick={async () => {
                setSelectedDiagnosisListToJsonObject();
                setLoader(true);
                var checkMnJson = {
                  orderTests: clinicalInputs.orderTests,
                  patientId: clinicalInputs.patientId,
                  orderDiagnoses: selectedDiagnosesIdProp,
                };
                var jsonParsed = {
                  orders: checkMnJson,
                  mnCheck: true,
                };
                const response = await ApiPostCalls(
                  JSON.stringify(jsonParsed),
                  "checkMN"
                );
                if (response["data"]) {
                  const testsCodeArray = [];

                  response["data"].map((data, index) => {
                    testsCodeArray.push(data.testCode);
                  });
                  setHighLightTests(testsCodeArray);
                  setRecommendedDiagonsis();

                  setShowMedicalNecessityModal(false);

                  setLoader(false);
                }
              }}
            >
              Save
            </Button>
            <Button
              className="primary btn button"
              onClick={() => {
                selectedTestsIdProp.map((value) => {
                  if (
                    value.testId ==
                    showMedicalNecessityModalInTest.split(":")[0].split("_")[0]
                  ) {
                    var index = highLightTests.indexOf(
                      showMedicalNecessityModalInTest
                        .split(":")[0]
                        .split("_")[1]
                    );
                    highLightTests.splice(index, 1);
                    value.isSkipped = "Yes";
                  }
                });
                setSelectedTestsIdProp(selectedTestsIdProp);
                setRecommendedDiagonsis();

                setShowMedicalNecessityModal(false);
                setSelectedDiagnosisListToJsonObject();
              }}
            >
              Skip
            </Button>
            <Button
              className="outline btn button"
              onClick={() => {
                setShowMedicalNecessityModal(false);
                listOptionsModal.options?.map((value) => {
                  handleDeleteFromPanal(value.id);
                });
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <WarningModel
          show={modalShowRecommeded}
          heading="Confirmation"
          bodytext="Are you sure you want to delete this item?"
          onHide={() => setModalShowRecommeded(false)}
          onyes={onConfirmationYes}
        />
      </Row>
    </>
  );
}
export default SelectedTestAndDiagnosisList;
