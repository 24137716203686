import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  FormControl,
  InputGroup,
  Form,
} from "react-bootstrap";
import SelectBody from "./SelectBody";
import InputWithLoader from "./SearchInputFieldWithLoader";
import WarningModel from "../../../GlobalComponents/WarningModel";
import InfoModal from "../../../GlobalComponents/InfoModal";
import Favorites from "./DiagnosisAndTestComponents/Favorites";
import { BsTrash } from "react-icons/bs";
import SelectedTestAndDiagnosisList from "./DiagnosisAndTestComponents/SelectedTestAndDiagnosisList";
import { BiAddToQueue } from "react-icons/bi";
import { ApiGetCalls, ApiPostCalls } from "../../../services/Api";
import StarIcon from "./DiagnosisAndTestComponents/StarIcon";
import { maxFavLimit } from "../../../config/config";
import { snackBar } from "../../../services/Alert";
import CustomLoader from "../../../GlobalComponents/Loader";
var favObj;
function SearchAndSelectTest(props) {
  const [listOptions, setListOptions] = useState({ options: [] });
  const [selectedTestIds, setSelectedTestIds] = useState([]);
  const [addGroupToFavorites, setAddGroupToFavorites] = useState(true);
  useEffect(() => {
    props.handleClinicalFormChange("orderTests", selectedTestIds);
  }, [listOptions]);

  const [showResults, setShowResults] = useState(false);
  const [showResultsInModal, setShowResultsInModal] = useState(false);
  const [selectedGroupTests, setSelectedGroupTests] = useState([]);

  const [showValueField, setShowValueField] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextInModal, setSearchTextInModal] = useState("");
  const [testResult, setTestResult] = useState([]);
  const [locationId, setLocationId] = useState(props.userLocationId);
  const [infoPopupDetailStateFav, setInfoPopupDetailStateFav] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [showComponent, setShowComponent] = useState("fav");
  const [showSearctField, setShowSearctField] = useState(false);
  const [searchTextTestInModal, setSearchTextTestInModal] = useState("");
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  useEffect(() => {
    setLocationId(props.userLocationId);
  }, [props.userLocationId]);
  const rowClickHandlerForModal = (selectedFacilityCodeObj) => {
    var test = {};
    var testarray = [];
    selectedFacilityCodeObj.map((value, index) => {
      if (
        !searchResults[selectedGroupIndex].testsGroups?.some(
          (item) => value.valueArray[0] == item.id
        )
      ) {
        test = {
          id: value.valueArray[0],
          testId: value.valueArray[0],
          testCode: value.valueArray[1],
          testName: value.valueArray[2],
        };
        searchResults[selectedGroupIndex].testsGroups.push(test);
      }
    });
    setShowResultsInModal(false);
    setSearchTextTestInModal("");
  };

  const addTestFromGroup = (groupTests) => {
    var listOptionsTemp = listOptions.options;
    //var selectedTestIdsTemp = selectedTestIds;
    groupTests.map((selectedObj) => {
      if (!listOptions.options?.some((item) => selectedObj.id == item.id)) {
        listOptionsTemp.push({
          id: selectedObj.id,
          testName: selectedObj.testName,
          testCode: selectedObj.testCode,
        });

        orderId != ""
          ? selectedTestIds.push({
              orderId: orderId,
              testId: selectedObj.id * 1,
            })
          : selectedTestIds.push({
              testId: selectedObj.id * 1,
            });
        props.propsOnChange(selectedObj.id);
      }
    });
    setListOptions({ options: listOptionsTemp });
    setSelectedTestIds(selectedTestIds);
  };

  const rowClickHandler = (selectedObj, id) => {
    setShowResults(false);
    setSearchText("");
    if (!listOptions.options?.some((item) => id == item.id)) {
      setListOptions({
        options: listOptions.options?.concat({
          id: id,
          testName: selectedObj[2],
          testCode: selectedObj[1],
        }),
      });
      setSelectedTestIds(
        orderId != ""
          ? selectedTestIds.concat({
              orderId: orderId,
              testId: id * 1,
            })
          : selectedTestIds.concat({
              testId: id * 1,
            })
      );
      props.propsOnChange(id);
    }
  };
  useEffect(() => {
    if (
      props.orderIdAsParam == undefined ||
      props.orderData.status == "Draft"
    ) {
      setShowSearctField(true);
    } else {
      setShowSearctField(false);
    }
    if (
      props.orderData.orderTests != undefined &&
      listOptions.options.length == 0
    ) {
      setSelectedTestIds("");
      setListOptions({ options: [] });
      var listOptionsArray = [];
      var selectedArrayTests = [];
      var InfoPopupDetailArray = [];
      props.orderData.orderTests.map(async (item, index) => {
        setOrderId(item.orderId);
        if (!listOptions.options?.some((value) => item.tests.id == value.id)) {
          listOptionsArray.push({
            id: item.tests.id,
            testName: item.tests.testName,
            testCode: item.tests.testCode,
            specimenRequirement: item.tests.specimenRequirement,

            status: item.tests.status,
          });

          selectedArrayTests.push({
            orderId: item.orderId * 1,
            testId: item.tests.id * 1,
          });
          var infoDelimitedDetail = "";

          if (item.tests.specimenRequirement != "") {
            infoDelimitedDetail = item.tests.specimenRequirement;
          }

          InfoPopupDetailArray.push(infoDelimitedDetail);
        }
      });
      setSelectedTestIds(selectedArrayTests);
      setListOptions({ options: listOptionsArray });
      props.setInfoPopupDetailState(InfoPopupDetailArray);
    } else {
      if (props.orderData.orderTests == undefined) {
        setSelectedTestIds([]);
        setListOptions({ options: [] });
      }
    }
  }, [props.orderData]);
  useEffect(() => {}, [props.locationBasedData]);
  const [favouritesData, setFavouritesData] = useState([]);
  const testResultData = [];

  for (let i = 0; i < testResult.length; i++) {
    const testResultDataObj = [
      testResult[i].id.toString(),
      testResult[i].testCode,
      testResult[i].testName,
      testResult[i].abbreviation,
    ];
    testResultData.push(testResultDataObj);
  }
  const deleteGroupModalYes = () => {
    ApiGetCalls("deleteTestGroup/" + searchResults[selectedGroupIndex].id).then(
      () => {
        setSelectedGroupIndex(null);
        setDeleteGroupModal(false);
        ApiGetCalls("GetFavoritesByFacility/" + locationId).then((value) => {
          props.setLocationBasedData({
            ...props.locationBasedData,
            favorites: value,
          });
          //  setLoader(false);
        });
      }
    );
  };

  useEffect(() => {
    const favouritesArray = [];
    const favouritesTestInfoArray = [];
    const TestInfoPopupArrayFavourites = [];
    for (let i = 0; i < props.locationBasedData.length; i++) {
      if (props.locationBasedData[i].testId !== null) {
        var infoDetail = "";
        var infoDelimitedDetail = "";

        if (props.locationBasedData[i].tests.specimenRequirement != "") {
          infoDelimitedDetail =
            props.locationBasedData[i].tests.specimenRequirement;
        }

        const locationBasedDataObj = {
          id: props.locationBasedData[i].tests.id.toString(),
          testCode: props.locationBasedData[i].tests.testCode,
          favId: props.locationBasedData[i].id.toString(),
          testName: props.locationBasedData[i].tests.testName,
          fav: "Y",
          specimenRequirement:
            props.locationBasedData[i].tests.specimenRequirement,
          testsGroups: [],
        };

        favouritesArray.push(locationBasedDataObj);
        favouritesTestInfoArray.push(infoDetail);
        TestInfoPopupArrayFavourites.push(infoDelimitedDetail);
      }
      if (props.locationBasedData[i].groupId !== null) {
        var infoDetail = "";
        var infoDelimitedDetail = "";
        props.locationBasedData[i].groups.testsGroups.forEach((element) => {
          infoDelimitedDetail +=
            '<div class="mb-3"><div class="pills">' +
            "<span>" +
            element.tests.testCode.toString() +
            ": " +
            element.tests.testName.toString() +
            "</span>" +
            "</div></div>";
        });
        const locationBasedDataObj = {
          id: props.locationBasedData[i].groupId.toString(),
          testCode: "Group",
          favId: props.locationBasedData[i].id.toString(),
          testName: props.locationBasedData[i].groups.groupName,
          fav: "Y",
          testsGroups: props.locationBasedData[i].groups.testsGroups,
        };

        favouritesArray.push(locationBasedDataObj);
        favouritesTestInfoArray.push(infoDetail);
        TestInfoPopupArrayFavourites.push(infoDelimitedDetail);
      }
    }
    setFavouritesData(favouritesArray);
    props.setInfoDetailState(favouritesTestInfoArray);
    setInfoPopupDetailStateFav(TestInfoPopupArrayFavourites);
  }, [props.locationBasedData]);

  const [modalShow, setModalShow] = useState(false);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [testHeading, setTestHeading] = useState("");
  const handleInfoModalClose = () => setInfoModalShow(false);
  const [testId, setTestId] = useState("");

  const onConfirmationYes = () => {
    setModalShow(false);
    props.handleDelete(testId);
    handleDeleteFromPanal(testId);
  };
  function handleDeleteFromPanal(testId) {
    var index;
    listOptions.options?.some(function (x, i) {
      if (x.id === testId) return (index = i);
    });

    var array = [...props.infoPopupDetailState]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      props.setInfoPopupDetailState(array);
    }
    setListOptions({
      options: listOptions.options?.filter((value) => testId != value.id),
    });
    setSelectedTestIds(
      selectedTestIds.filter((value) => testId != value.testId)
    );
  }
  const [showTestGroupModel, setShowTestGroupModel] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [addGroupAction, setAddGroupAction] = useState("");
  const [groupsData, setGroupsData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLoader, setLoader] = useState(false);

  const handleAddGroup = (e) => {
    //if (groupName) {
    const filteredGroup = groups.find((group) => {
      return group.groupName.toLowerCase() == searchTextInModal.toLowerCase();
    });
    if (groups.indexOf(filteredGroup) > -1) {
      setSearchResults(groups);
      setSelectedGroupIndex(groups.indexOf(filteredGroup));
      setIsDropdownOpen(false);
      setSearchTextInModal("");
    } else {
      const newGroup = {
        facilityId: locationId,
        groupName: searchTextInModal,
        testsGroups: [],
      };
      setGroups([...groups, newGroup]);
      setSearchResults([...groups, newGroup]);
      setSelectedGroupIndex(groups.length); // Select the newly added group
      setGroupName("");
      setIsDropdownOpen(false);
      setSearchTextInModal("");
    }

    //}
  };

  const handleRemoveGroup = (groupIndex) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(groupIndex, 1);
    setGroups(updatedGroups);
  };

  const handleToggleGroupTests = (groupIndex) => {
    setSelectedGroupIndex(groupIndex);
    setIsDropdownOpen(false);
    setSearchTextInModal("");
    setSearchResults(groups);
  };

  // Function to filter groups based on search text
  const filterGroups = async () => {
    if (searchTextInModal) {
      // const filteredGroups = groups.filter((group) =>
      //   group.groupName.toLowerCase().includes(searchTextInModal.toLowerCase())
      // );
      var filteredGroups = await ApiGetCalls(
        "getGroups/" + locationId + "/" + searchTextInModal
      );
      //  setSelectedGroupIndex(null);
      setGroups(filteredGroups.data);
      //setSearchResults(filteredGroups.data);
      setIsDropdownOpen(true);
    }
    // else {
    //   setSearchResults([]);
    //   setIsDropdownOpen(false);
    // }
  };
  useEffect(() => {
    filterGroups();
  }, [searchTextInModal]);
  return (
    <>
      {showLoader && <CustomLoader />}
      <InfoModal
        show={infoModalShow}
        onHide={handleInfoModalClose}
        infoPopupDetailState={props.infoPopupDetailState}
        infoPopupIndexState={props.infoPopupIndexState}
        infoPopupDetailStateFav={infoPopupDetailStateFav}
        showComponent={showComponent}
        heading={testHeading}
      />
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />
      {showSearctField && (
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <InputWithLoader
              id="TestSelect"
              filter={searchText}
              placeholder="Start typing..."
              apiEndPoint={"GetSearchedTest/" + locationId + "/"}
              setData={setTestResult}
              setShowResults={setShowResults}
              setFilter={setSearchText}
              name="tests"
              aria_label="test name"
              className="input-style form-control mt-4"
              required={true}
              data_type="text"
            />
            {showResults ? (
              <SelectBody
                rowClickHandler={rowClickHandler}
                filter={searchText}
                column={["Code", "Name", "Abbreviation"]}
                dataArray={testResultData}
                setShowResults={setShowResults}
              />
            ) : null}
          </Col>
          <Col md={{ span: 2 }} className="mt-1">
            <button
              onClick={async () => {
                setShowTestGroupModel(true);

                setGroups(groupsData);
              }}
              className="primary btn button btn-custom mt-3"
            >
              Group
            </button>
          </Col>
        </Row>
      )}

      <Favorites
        title="Add Test"
        favouritesData={favouritesData}
        setFavouritesData={setFavouritesData}
        setListOptions={setListOptions}
        listOptions={listOptions}
        sendRequest={true}
        propsOnChange={props.propsOnChange}
        setSelectedId={setSelectedTestIds}
        selectedId={selectedTestIds}
        objName="testId"
        locationId={locationId}
        infoDetailState={props.infoDetailState}
        orderId={orderId}
        infoPopupDetailState={props.infoPopupDetailState}
        setInfoModalShow={setInfoModalShow}
        setTestHeading={setTestHeading}
        setInfoPopupIndexState={props.setInfoPopupIndexState}
        setShowComponent={setShowComponent}
        orderStatus={props.orderData.status}
        favIconHandler={props.favIconHandler}
        setFavIconHandler={props.setFavIconHandler}
        setShowTestGroupModel={setShowTestGroupModel}
      />
      <div id="selectedTestsAccordions">
        {listOptions["options"]?.length != 0 ? (
          <SelectedTestAndDiagnosisList
            heading="Selected Tests"
            favouritesData={favouritesData}
            setFavouritesData={setFavouritesData}
            setListOptions={setListOptions}
            listOptions={listOptions}
            setId={setTestId}
            setModalShow={setModalShow}
            infoDetailState={props.infoDetailState}
            infoPopupDetailState={props.infoPopupDetailState}
            setInfoModalShow={setInfoModalShow}
            setTestHeading={setTestHeading}
            setInfoPopupIndexState={props.setInfoPopupIndexState}
            setShowComponent={setShowComponent}
            locationId={locationId}
            orderStatus={props.orderData.status}
            favIconHandler={props.favIconHandler}
            setFavIconHandler={props.setFavIconHandler}
            setHighLightTests={props.setHighLightTests}
            highLightTests={props.highLightTests}
            medicalNecessityResponse={props.medicalNecessityResponse}
            orderId={orderId}
            diagnosislistOptions={props.listOptionsDiagnosis}
            diagnosisSetlistOptions={props.setListOptionsDiagnosis}
            selectedDiagnosesIdProp={props.selectedDiagnosesId}
            setSelectedDiagnosesIdProp={props.setSelectedDiagnosesId}
            setSelectedTestsIdProp={setSelectedTestIds}
            selectedTestsIdProp={selectedTestIds}
            setShowTestGroupModel={setShowTestGroupModel}
            clinicalInputs={props.clinicalInputs}
          />
        ) : null}
      </div>

      <Modal
        show={showTestGroupModel}
        onHide={() => {
          setAddGroupAction("");
          setShowTestGroupModel(false);
          setSelectedGroupIndex(null);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Test Groups
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="testsGroupModalBody">
          <Row className="mb-3">
            <Col
              md={{
                span: 11,
              }}
            >
              <FormControl
                placeholder="Search or create a Group..."
                value={searchTextInModal}
                onChange={(e) => {
                  setSearchTextInModal(e.target.value);
                  setIsDropdownOpen(true);
                }}
              />
            </Col>
            <Col md={{ span: 1 }}>
              {
                <Button
                  disabled={
                    (searchTextInModal != "" && searchResults.length > 0) ||
                    (searchResults.length == 0 &&
                      searchTextInModal.replace(/\s/g, "").length > 0)
                      ? false
                      : true
                  }
                  className="primary btn button"
                  onClick={handleAddGroup}
                >
                  Select
                </Button>
              }
            </Col>
          </Row>
          {/* Custom dropdown for search results */}
          {isDropdownOpen && groups.length > 0 && searchTextInModal != "" && (
            <div className={props.multiSelect && "customParentDiv"}>
              <div className="patients-search-results">
                {groups.map((group, groupIndex) => (
                  <div
                    key={groupIndex}
                    className="dropdown-item"
                    onClick={() => {
                      handleToggleGroupTests(groupIndex);
                    }}
                  >
                    <div className={"pills mb-1"}>
                      <span>{group.groupName}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Display selected group and its tests */}
          {selectedGroupIndex !== null && searchResults[selectedGroupIndex] && (
            <>
              <Row key="Selected Group">
                {/* <Col md="12"> */}
                <h5 className="mt-4">
                  {"Selected Group: " +
                    searchResults[selectedGroupIndex].groupName}
                </h5>
                <Col md="4" className="mb-3">
                  <div className={"pills"}>
                    <span>{searchResults[selectedGroupIndex].groupName}</span>
                    <BsTrash
                      title="Remove Group"
                      className="trashcanIcon ml-1"
                      onClick={() => {
                        setDeleteGroupModal(true);
                      }}
                    />
                  </div>
                </Col>
                <Row key="All Diagnosis">
                  <Col key={"All Diagnosis Codes" + "h"} md="12">
                    <Row>
                      {/* <h5>{"All Diagnosis Codes"}</h5> */}
                      <Col className="customModel">
                        {" "}
                        <InputWithLoader
                          id="TestSelectInModel"
                          filter={searchTextTestInModal}
                          placeholder="Search for a Test..."
                          apiEndPoint={"GetSearchedTest/" + locationId + "/"}
                          setData={setTestResult}
                          setShowResults={setShowResultsInModal}
                          setFilter={setSearchTextTestInModal}
                          name="tests"
                          aria_label="test name"
                          className="input-style form-control mt-4 mb-4"
                          required={true}
                          data_type="text"
                        />
                        {showResultsInModal ? (
                          <SelectBody
                            rowClickHandler={rowClickHandlerForModal}
                            filter={searchTextTestInModal}
                            column={["Code", "Name", "Abbreviation"]}
                            dataArray={testResultData}
                            multiSelect={true}
                            setShowResults={setShowResultsInModal}
                            selectedValues={showValueField}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {searchResults[selectedGroupIndex].testsGroups.length > 0 && (
                  <>
                    <h5 className="mt-4">Selected Tests</h5>
                    {searchResults[selectedGroupIndex].testsGroups.map(
                      (test, index) => (
                        <Col md="4" key={index} className="mb-3">
                          <div className={"pills"}>
                            <span>{test.testCode + ": " + test.testName}</span>
                            <BsTrash
                              title="Remove Test"
                              className="trashcanIcon ml-1"
                              onClick={() => {
                                var valueIndex =
                                  searchResults[
                                    selectedGroupIndex
                                  ].testsGroups.indexOf(test);

                                searchResults[
                                  selectedGroupIndex
                                ].testsGroups.splice(valueIndex, 1);
                                setSearchResults((oldSearchResults) => [
                                  ...oldSearchResults,
                                  searchResults,
                                ]);
                              }}
                            />
                          </div>
                        </Col>
                      )
                    )}
                  </>
                )}
                {/* </Col> */}
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Form.Check
            type="checkbox"
            label=" Add to Favorites"
            checked={addGroupToFavorites}
            className="mb-3 mr-auto"
            onClick={() => {
              if (addGroupToFavorites) {
                setAddGroupToFavorites(false);
              } else {
                setAddGroupToFavorites(true);
              }
            }}
          />
          <Button
            disabled={selectedGroupIndex != null ? false : true}
            className="primary btn button"
            onClick={async () => {
              //setLoader(true);
              ApiPostCalls(
                searchResults[selectedGroupIndex],
                "addOrUpdateTestsToGroup"
              ).then(async (addOrUpdateTestsToGroupResponse) => {
                setGroupsData(groups);
                setAddGroupAction("");
                setShowTestGroupModel(false);
                setSelectedGroupIndex(null);
                addTestFromGroup(searchResults[selectedGroupIndex].testsGroups);
                if (addGroupToFavorites) {
                  if (
                    !props.locationBasedData?.some(
                      (item) =>
                        addOrUpdateTestsToGroupResponse.data.id == item.groupId
                    )
                  ) {
                    if (favouritesData.length < maxFavLimit) {
                      var data = await ApiPostCalls(
                        JSON.stringify({
                          facilityId: locationId,
                          testId: null,
                          groupId: addOrUpdateTestsToGroupResponse.data.id,
                          diagnosisId: null,
                        }),
                        "AddFacilityFavorities"
                      ).then(() => {
                        // ApiGetCalls(
                        //   "GetFavoritesByFacility/" + locationId
                        // ).then((value) => {
                        //   props.setLocationBasedData({
                        //     ...props.locationBasedData,
                        //     favorites: value,
                        //   });
                        // });
                      });
                    } else {
                      snackBar(
                        "You can only add a maximum of " +
                          maxFavLimit +
                          " items to favorites",
                        "info"
                      );
                    }
                  }
                }
                ApiGetCalls("GetFavoritesByFacility/" + locationId).then(
                  (value) => {
                    props.setLocationBasedData({
                      ...props.locationBasedData,
                      favorites: value,
                    });
                    //  setLoader(false);
                  }
                );
              });
            }}
          >
            Save
          </Button>
          <Button
            className="outline btn button"
            onClick={() => {
              setAddGroupAction("");
              setShowTestGroupModel(false);
              setSelectedGroupIndex(null);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModel
        show={deleteGroupModal}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setDeleteGroupModal(false)}
        onyes={deleteGroupModalYes}
      />
    </>
  );
}

export default SearchAndSelectTest;
