import React, { useState, useEffect } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { maxFavLimit } from "../../../../config/config";
import { snackBar } from "../../../../services/Alert";
import { ApiPostCalls, ApiGetCalls } from "./../../../../services/Api";

function StarIcon({
  showIcon,
  className,
  favouritesData,
  testObj,
  setFavouritesData,
  setListOptions,
  listOptions,
  favIconHandler,
  setFavIconHandler,
}) {
  const [showFillIcon, setShowFillIcon] = useState(false);
  useEffect(() => {
    setShowFillIcon(showIcon);
  }, [showIcon]);
  return showFillIcon ? (
    <BsStarFill
      title="Remove from Favorites"
      onClick={async (event) => {
        event.stopPropagation();
        ApiGetCalls("RemoveFacilityFavorities/" + testObj.favId);
        await setListOptions({ options: [] });

        setFavouritesData(
          favouritesData.filter((item) => item.id != testObj.id)
        );
        await setListOptions({ options: listOptions.options });
      }}
      fill="#398fda"
      size={"1.35em"}
      className={className}
    />
  ) : (
    <BsStar
      title="Add to Favorites"
      size={"1.35em"}
      onClick={async (event) => {
        event.stopPropagation();
        if (favouritesData.length < maxFavLimit) {
          var data = await ApiPostCalls(
            JSON.stringify({
              facilityId: testObj.locationId,
              testId: testObj.testId,
              diagnosisId: testObj.diagnosisId,
            }),
            "AddFacilityFavorities"
          );
          setFavIconHandler(!favIconHandler);
          testObj = { ...testObj, fav: "Y" };
          if (!favouritesData?.some((value) => testObj.id == value.id)) {
            favouritesData.push(testObj);
          }
          setFavouritesData(
            favouritesData.map((item) => {
              if (item.id == testObj.id) {
                item = { ...item, favId: data.data };
                item = { ...item, fav: "Y" };
                return item;
              } else {
                return item;
              }
            })
          );
          setShowFillIcon(true);
        } else {
          snackBar(
            "You can only add a maximum of " +
              maxFavLimit +
              " items to favorites",
            "info"
          );
        }
      }}
      className={className}
    />
  );
}
export default StarIcon;
