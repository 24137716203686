import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents//mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import {
  displayInDateFormat,
  LookupText,
} from "../../GlobalComponents/ValidatorAndSubmitForm";
import { ApiPostCalls, ApiGetCalls } from "../../services/Api";
import moment from "moment/moment";
import "../OrderPage/OrderPage.css";
import { LoginInfoContext, LoginUserLocationContext } from "../../Context";
import { Row, Col, Table, Form, Button, Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import SearchFacility from "../OrderPage/Components/SearchFacilityModel";
import WarningModel from "../../GlobalComponents/WarningModel";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { snackBar } from "../../services/Alert";
import { BsPencil, BsPlus, BsPlusCircle, BsTrash } from "react-icons/bs";
import { allInputField } from "../../config/config";
import InputMask from "react-input-mask";

function Profile(props) {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Profile";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    }
  }, []);
  const [context, setContext] = useContext(LoginInfoContext);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  const [changeInProfile, setChangeInProfile] = useState(null);
  const [changeInProfileActiveControls, setChangeInProfileActiveControls] =
    useState(null);
  const [searchFacilityModel, setSearchFacilityModel] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [org, setOrg] = useState("");
  useEffect(() => {
    populateProfile();
  }, [changeInProfile, context]);
  useEffect(() => {
    if (context.userInfo != undefined) {
      JSON.parse(localStorage.getItem("selectableLocations")).map((val) => {
        var idToMatch = 0;
        if (selectedFacilityId == null) {
          idToMatch = context.userInfo.defaultFacility;
        } else {
          idToMatch = selectedFacilityId;
        }
        if (val.id == idToMatch) {
          setSelectedFacilityId(val.id);
          setSelectedFacility(val.label);
        } else {
          return null;
        }
      });
    }
  }, [selectedFacilityId, context]);
  function populateProfile() {
    if (context.userInfo != undefined) {
      if (context.userInfo.userFirstName != null) {
        setFirstName(context.userInfo.userFirstName);
      }
      if (context.userInfo.userLastName != null) {
        setLastName(context.userInfo.userLastName);
      }

      if (context.userInfo.userEmail != null) {
        setEmail(context.userInfo.userEmail);
      }
      if (context.userInfo.userOrg != null) {
        const commaSeparatedList = context.userInfo.userOrg.map((val) => {
          return val.organizationName;
        }).join(', ');
        setOrg(commaSeparatedList);
      }
      if (context.userInfo.phone != null) {
        // phone = context.userInfo.userPhone;

        setPhone(context.userInfo.phone);
      }
    }
  }
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  var nameAbbrArray = [];
  //var email = "-";
  //var phone = "-";

  var nameAbbr = "";
  if (context.userInfo != undefined) {
    nameAbbr =
      context.userInfo.userLastName[0] + context.userInfo.userFirstName[0];
  }

  return (
    <div onClick={() => setShowResults(false)}>
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="patients-list-pageheight pb-3">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Profile
              {/* <Button variant="primary" className="primary btn button ml-auto">
                Edit Profile Info
              </Button> */}
              <Button
                variant="primary"
                className="primary btn button  ml-auto"
                onClick={() => {
                  navigate("/changePassword");
                }}
              >
                Change Password
              </Button>
            </h3>
            <hr></hr>

            <Container>
              <Row>
                <Col md={{ span: 4 }}>
                  <div className="profileiconbigger">{nameAbbr}</div>
                </Col>
                <Col md={{ span: 4 }}>
                  <div className="spacingdiv">
                    <h5 className="label-style">Last Name</h5>
                    {/* <p>{fullName}</p> */}
                    <input
                      type="text"
                      name="lastName"
                      maxLength={allInputField}
                      id="lastName"
                      aria-label="LastName"
                      required
                      className="input-style form-control"
                      data-type="text"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setChangeInProfileActiveControls(true);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="spacingdiv">
                    <h5 className="label-style">Email</h5>

                    <input
                      type="text"
                      name="email"
                      id="email"
                      disabled={true}
                      aria-label="Email"
                      required
                      className="input-style form-control"
                      data-type="text"
                      value={email}
                      onChange={(e) => {
                        // setEmail(e.target.value);
                        // setChangeInProfileActiveControls(true);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  {/* <hr /> */}
                  <div className="spacingdiv">
                    <h5 className="label-style">Default Facility </h5>
                    <p>
                      {selectedFacility}
                      {JSON.parse(localStorage.getItem("selectableLocations"))
                        .length > 0 ? (
                        <>
                          {selectedFacility != null ? (
                            <BsPencil
                              className="mb-2 ml-2"
                              size={20}
                              onClick={async () => {
                                setSearchFacilityModel(true);
                                setChangeInProfileActiveControls(true);
                              }}
                            />
                          ) : (
                            <BsPlusCircle
                              className="mb-2 ml-2"
                              size={20}
                              onClick={async () => {
                                setSearchFacilityModel(true);
                                setChangeInProfileActiveControls(true);
                              }}
                            />
                          )}
                          {selectedFacility != null ? (
                            <BsTrash
                              className="mb-2 ml-2"
                              size={20}
                              onClick={async () => {
                                setSelectedFacilityId(0);
                                setSelectedFacility(null);
                                setChangeInProfileActiveControls(true);
                                //  setSearchFacilityModel(true);
                              }}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </p>
                    {/* <Button
                      variant="primary"
                      className="primary btn button ml-auto"
                      onClick={() => {
                        setSearchFacilityModel(true);
                      }}
                    >
                      Change
                    </Button> */}
                  </div>
                </Col>
                <Col md={{ span: 4 }}>
                  <div className="spacingdiv">
                    <h5 className="label-style">First Name</h5>
                    {/* <p>{fullName}</p> */}
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      maxLength={allInputField}
                      aria-label="FirstName"
                      required
                      className="input-style form-control"
                      data-type="text"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setChangeInProfileActiveControls(true);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="spacingdiv">
                    <h5 className="label-style">Phone</h5>
                    <InputMask
                      mask="+1\ (999) 999-9999"
                      maskChar=" "
                      type="text"
                      name="phone"
                      id="phone"
                      aria-label="Phone"
                      required
                      className="input-style form-control"
                      data-type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setChangeInProfileActiveControls(true);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="spacingdiv">
                    <h5 className="label-style">Organization</h5>

                    <input
                      type="text"
                      name="organization"
                      id="organization"
                      aria-label="Organization"
                      required
                      className="input-style form-control"
                      data-type="text"
                      value={org}
                      disabled={true}
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>{" "}
              <Row>
                <span className="float-right">
                  <Button
                    disabled={
                      changeInProfileActiveControls == null
                        ? true
                        : firstName == "" ||
                          lastName == "" ||
                          // email == "" ||
                          phone == ""
                        ? true
                        : false
                    }
                    variant="primary"
                    className="primary btn button float-right ml-2 "
                    onClick={async () => {
                      const body = {
                        userId: localStorage.getItem("userId"),
                        facilityId: selectedFacilityId,
                        firstName: firstName,
                        lastName: lastName,
                        phone: phone,
                        isDefaultFacility: true,
                        // email: email,
                      };
                      const response = await ApiPostCalls(
                        JSON.stringify(body),
                        "UpdateProfile"
                      );
                      if (response.status == "Success") {
                        var userInfo = context.userInfo;
                        userInfo.defaultFacility = selectedFacilityId;
                        //userInfo.userEmail = email;
                        userInfo.phone = phone;
                        userInfo.userFirstName = firstName;
                        userInfo.userLastName = lastName;
                        setContext({ ...context, userInfo: userInfo });
                        localStorage.setItem(
                          "userInfo",
                          JSON.stringify(userInfo)
                        );
                        setChangeInProfileActiveControls(null);
                        snackBar(
                          "Success! Profile updated successfully",
                          "success"
                        );
                      } else {
                        snackBar("Error! Unable to updated profile", "danger");
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    disabled={
                      changeInProfileActiveControls == null ? true : false
                    }
                    variant="primary"
                    className="outline button btn btn-outline float-right"
                    onClick={() => {
                      setSelectedFacilityId(null);
                      //setChangeInProfile(null);
                      setChangeInProfileActiveControls(null);
                      populateProfile();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />

      <SearchFacility
        searchFacilityState={searchFacilityModel}
        setSearchFacilityState={setSearchFacilityModel}
        setFacility={true}
        selectFacility={setSelectedFacilityId}
      />
    </div>
  );
}
export default Profile;
